// BookingFailure.js
import React, { useContext, useEffect, useState } from "react";
import { Typography, Container, Paper, Button } from "@mui/material";
import { useSelector } from "react-redux";
import {
  Timestamp,
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { AuthContext } from "../AuthProvider";
import AppStore from "../screen/AppStore";

const BookingFailure = (props) => {
  const [paymentData, setPaymentData] = useState({});
  const [errorMessage, setErrorMessage] = useState("SOMETHING WENT WRONG");

  const { db } = useContext(AuthContext);

  // const userInfos = useSelector((state) => state);
  // const booking = userInfos.usersDataReducer?.booking;
  const booking = props.bookingDetails;


  const uploadBookingDetails = async (paymentDetails) => {
    const ref = doc(collection(db, "bookings"));
    const id = ref.id;
    try {
      let data = booking.bookingDetails;
      data.status = "failed";
      data.paymentDetails = paymentDetails;
      data.id = id;
      await setDoc(ref, data);
    } catch (e) {
      let data = { status: "FAILED" };
      data.id = id;
      await setDoc(ref, data);
    }
  };

  useEffect(() => {
    // Get the query parameters from the URL
    const queryParams = new URLSearchParams(window.location.search);
    const dataParam = queryParams.get("data");
    // See more here: https://developer.phonepe.com/v1/reference/check-status-api-1#sample-response
    let SAMPLE_FAILED_RESPONSE_UPI = {
      success: false,
      code: "PAYMENT_ERROR",
      message: "Payment Failed",
      data: {
        merchantId: "PGTESTPAYUAT",
        merchantTransactionId: "MT7850590068188104",
        transactionId: "T2111221437456190170379",
        amount: 100,
        state: "FAILED",
        responseCode: "ZM",
        responseCodeDescription: "Invalid m-pin entered",
        paymentInstrument: null,
      },
    };

    let SAMPLE_FAILED_RESPONSE = {
      success: false,
      code: "INTERNAL_SERVER_ERROR",
      message:
        "There is an error trying to process your transaction at the moment. Please try again in a while.",
    };

    let LATEST_RES = {
      success: false,
      code: "PAYMENT_ERROR",
      message: "Payment Failed",
      data: {
        merchantId: "M1LGE5RGIOJO",
        merchantTransactionId: "T1700660046709700848",
        transactionId: "T2311221904343568962967",
        amount: 100,
        state: "FAILED",
        responseCode: "ZM",
        paymentInstrument: {
          type: "UPI",
          utr: "369216070147",
          upiTransactionId: "YBLf5722f5a4e4f4e7fad8aa053f0db8a54",
          cardNetwork: null,
          accountType: "SAVINGS",
        },
      },
    };

    if (dataParam) {
      // Parse the JSON data
      const parsedData = JSON.parse(decodeURIComponent(dataParam));
      setPaymentData(parsedData);
      setErrorMessage(parsedData?.message);
      uploadBookingDetails(parsedData?.data);
      // Now, 'parsedData' contains your object
    }
  }, []);

  return (
    <Container
      maxWidth="md"
      style={{ marginTop: "160px", marginBottom: "160px" }}
    >
      <Paper
        elevation={3}
        style={{
          padding: "20px",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Typography variant="h4" gutterBottom>
          Vehicle Booking Failed!
        </Typography>
        <Typography variant="body1">Please try again after sometime</Typography>
        <Typography
          style={{
            fontWeight: "bold",
            marginTop: "10px",
          }}
          variant="h6"
        >
          {errorMessage}
        </Typography>
        <Typography style={{ marginTop: "50px" }} variant="body2">
          You can see your booking in Profile {">"} My Bookings section
        </Typography>
        <Button
          href="/"
          variant="contained"
          style={{
            backgroundColor: "#fc6b65",
            height: "40px",
            width: "150px",
            marginTop: "50px",
          }}
          size="large"
        >
          GO TO HOME
        </Button>
        {/* You can add more Material-UI components or customize the styles */}
      </Paper>
    </Container>
  );
};

export default AppStore(BookingFailure);
