import React from "react";
import "./Privacy.css";

const Privacy = () => {
	return (
		<div className='Pcontainer'>
			<div className='Pheader'>
				{" "}
				<h2>Privacy Policy</h2>
			</div>
			<section>
				<p style={{ marginBottom: "15px" }}>
					<b>What Information We Collect</b>
				</p>
				<p style={{ marginBottom: "15px" }}>
					<b>For All Transactions:</b>
				</p>
				<p style={{ marginBottom: "15px" }}>
					<b>We collect:</b>
				</p>
				<p className='PlistsHead'>CONTACT INFORMATION, including: </p>
				<ul className='Plists'>
					<li>Name</li>
					<li>Address</li>
					<li>Phone numbers</li>
					<li>Email addresses</li>
				</ul>
				<br />
				<p style={{ marginBottom: "15px" }}>
					<b>When Renting</b>
				</p>
				<p style={{ marginBottom: "15px" }}>
					<b>
						When renting a vehicle, including being added as an authorized
						additional driver, or joining one of our rental programs, we may
						collect:{" "}
					</b>
				</p>
				<p className='PlistsHead'>
					{" "}
					Driver Qualifications and Identify Verification, including:{" "}
				</p>
				<ul className='Plists'>
					<li>
						Driver’s license information, including date of birth and
						photographs you submit of you and your driver’s license
					</li>
					<li>Insurance information</li>
					<li>Passport</li>
					<li>Proof of residence</li>
					<li>Personal references</li>
				</ul>
				<br />
				<p className='PlistsHead'> Transaction Information, including: </p>
				<ul className='Plists'>
					<li>Vehicle rental information</li>
					<li>Rental and return locations</li>
					<li>Dates of the rental</li>
					<li>Type of rental and your vehicle preference</li>
					<li>Airline and flight number</li>
					<li>
						Photographs you submit of receipts through the camera feature
						available on some of our mobile applications and websites
					</li>
					<li>Billing information</li>
					<li>Charges</li>
					<li>Payments</li>
				</ul>
				<br />
				<p className='PlistsHead'> Claims Information </p>
				<ul className='Plists'>
					<li>
						Information related to details of accidents involving our vehicles,
						including that of any third parties such as involved individuals,
						witnesses, and police reports
					</li>
				</ul>
				<br />
				<p className='PlistsHead'> Health Information, including:</p>
				<ul className='Plists'>
					<li>
						Information about relevant health conditions you have that assist us
						in providing you with adaptive driving devices; or related to claims
						involving injuries
					</li>
				</ul>
				<br />
				<p className='PlistsHead'> Optional Information, including:</p>
				<ul className='Plists'>
					<li>Emergency contact information</li>
					<li>Any preferences or special requirements you may have</li>
				</ul>
				<br />
				<p className='PlistsHead'> CCTV and Other Cameras</p>
				Where available, CCTV video recordings and/or photographs of you in
				public areas of our rental locations <br />
				<br />
				<p className='PlistsHead'> Sensitive Information</p>
				Go<span className='redHead'>With</span>car.com collects information that
				is defined as ‘sensitive personal information’ under various state
				privacy laws. While such laws may provide an opt-out opportunity for
				this information, we use and disclose this information only for purposes
				permitted by law that do not require an opt-out opportunity.
			</section>
		</div>
	);
};

export default Privacy;
