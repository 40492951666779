const config = {
  localStorageKey: 'MyAppName',
  baseURL:`http://13.127.228.11/`,
  imageBaseUrl: '',
  playstore: 'NA',
  appstore: 'NA',
  version: {
    IOS: '0.0.1',
    ANDROID: '0.0.1',
  },
};

export default config;
