import React from "react";
import "./wp.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FaBeer, FaWhatsapp } from "react-icons/fa";
const WhatsApp = () => {
	return (
		<>
			<div className='fb_btn_d1'>
				<div className='floating-container'>
					{/* <div className="floating-button">+</div> */}
					<div className='element-container'>
						{/* =================================================== Call ======================================*/}

						<a href='tel:9926222099' className='float-element'>
							<FontAwesomeIcon
								icon={faPhone}
								style={{
									height: "19px",
									width: "19px",
									margin: "30% 0px ",

									color: "	 #ffffff",
								}}
							/>
						</a>
						{/* =================================================== mail ======================================*/}
						<a
							href='mailto:info@gowithcar.com'
							className='float-element tooltip-left'
						>
							<FontAwesomeIcon
								icon={faEnvelope}
								style={{
									height: "19px",
									width: "19px",
									margin: "30% 0px ",
									color: "	 #ffffff",
								}}
							/>
						</a>
						{/* =================================================== whatsapp ======================================*/}
						<a href='https://wa.me/+919926222099' className='float-element'>
							<FaWhatsapp
								style={{
									height: "19px",
									width: "19px",
									margin: "30% 0px ",
									color: "	 #ffffff",
								}}
							/>
						</a>
					</div>
				</div>
			</div>
		</>
	);
};

export default WhatsApp;
