import React, { useEffect, useState } from "react";
import "./fc.css";
import { Button, Row } from "antd";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useContext } from "react";
import { AuthContext } from "../../../../AuthProvider";
import CarCards from "./CarCards/CarCards";
import ShimLoad from "./ShimLoad/ShimLoad";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const FeaturedCars = () => {
    const [loading, setLoading] = useState(true);
    const [services, setServices] = useState([]);
    const loader = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
    const { showPopup, handlePopupButtonClick, db } = useContext(AuthContext);

    // ============================================ fetch data from firebase ===============================================
    const fetchCars = async () => {
        const q = query(
            collection(db, "cars"),
            where("driverType", "==", "Self Drive"),
            where("status", "==", "approved"),
        );
        let arr = [];
        const querySnapshott = await getDocs(q);
        querySnapshott.forEach((doc) => {
            arr.push(doc.data());
        });
        setServices(arr);
        setLoading(false);
    };
    useEffect(() => {
        fetchCars();
    }, []);

    // const CarData = services.slice(0, 9);
    return (
        <div className="fc_main">
            {showPopup && (
                <div className="popup-screen">
                    <div className="popup">
                        <div className="popup_p">
                            <h2>Want to book now?</h2>
                            <FontAwesomeIcon
                                icon={faXmark}
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                    handlePopupButtonClick("Button 2")
                                }
                            />
                        </div>
                        <div className="popup_b">
                            <a href="tel:9926222099">
                                <button
                                    onClick={() =>
                                        handlePopupButtonClick("Button 1")
                                    }
                                >
                                    Call now
                                </button>
                            </a>
                            <a href="https://wa.me/+919926222099">
                                <button
                                    onClick={() =>
                                        handlePopupButtonClick("Button 2")
                                    }
                                >
                                    Message on whatsapp
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            )}
            <div className="fc_d1">
                <h2 className="fc_h1">
                    {" "}
                    Select and book a car rental with Go
                    <span className="introTitleRed"> With</span> Car
                </h2>
                <p className="fc_p1">
                    You can pick a self-drive car rental in Indore from Go
                    <span className="introTitleRed"> With</span> Car, You have
                    the freedom to move around and explore places with Go
                    <span className="introTitleRed"> With</span> Car
                </p>
            </div>
            <div className="fc_d2">
                {/* list our servicess */}
                {loading ? (
                    <Row
                        style={{
                            justifyContent: "center",
                            width: "100%",
                            height: "100%",
                        }}
                        gutter={30}
                    >
                        {loader.map((i) => (
                            <ShimLoad key={i} />
                        ))}
                    </Row>
                ) : (
                    <Row
                        style={{
                            justifyContent: "center",
                            width: "100%",
                            height: "100%",
                        }}
                        gutter={30}
                    >
                        {services
                            ?.sort((item, index) =>
                                item.bookingStatus === "booked" ? 1 : -1,
                            )
                            .map((item, index) => (
                                <CarCards item={item} key={index} />
                            ))}
                    </Row>
                )}
            </div>
            {/* <div className="moreButton">
                <Link
                    onClick={() => {
                        window.scrollTo(0, 0);
                    }}
                    to={"/explore-cars"}
                >
                    <Button type="primary" danger className="moreButtonIn">
                        Show more
                    </Button>
                </Link>
            </div> */}
        </div>
    );
};

export default FeaturedCars;
