import React, { useContext, useEffect, useState } from "react";
import { LuBellMinus, LuBellPlus } from "react-icons/lu";
import "./Notific.css";
import { collection, getDocs, query, getDoc, doc,Timestamp } from "firebase/firestore";
import { AuthContext } from "../../../AuthProvider";


const Notifications = () => {

    const [notifications, setNotifications] = useState([])

    const { db, userHintAd, userId } = useContext(AuthContext);
    console.log('userIduserIduserId', userId);
    const getNotification = async () => {
        // Query a reference to a subcollection
        const querySnapshot = await getDocs(collection(db, "notifications", userId, "notification"));
        console.log("Notifications: ", querySnapshot.docs);
        setNotifications(querySnapshot.docs.length > 0 ? querySnapshot.docs : [])
    };

    console.log("notifications===", notifications)

    useEffect(() => {
        getNotification()
    }, [])
    return (

        <div className="noti_Con">
            <div>
                <h1 className="notiHead">Notifications</h1>
            </div>
            {notifications?.map((c, i) => {
            
                const notification = c.data();
                const date = new Timestamp(
                    notification?.date?.seconds,
                    notification?.date?.nanoseconds
                  ).toDate();
                console.log('notification', notification);
                return (
                    <div className="not-card-con">
                        <div className="not-card">
                            <div>
                                <LuBellPlus className="not-card-icon" />
                            </div>
                            <div className="not-card-des">
                                <div className="not-card-headers">
                                    <div className="not-card-header">
                                        {notification?.title}
                                    </div>
                                    <div> {date?.toLocaleString()}</div>
                                </div>
                                <div className="not-card-text">
                                {notification?.body}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>

    );

};

export default Notifications;
