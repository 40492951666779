import React, { useState } from "react";
import "./CarRentalForm.css";
import DriverBox from "./DriverBox/DriverBox";
import { AuthContext } from "../../AuthProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { collection, getDocs } from "firebase/firestore";
import { useEffect } from "react";
import moment from "moment/moment";

const CarRentalForm = (props) => {
    const [driver, setDriver] = useState(
        props?.passedData?.driver ? props?.passedData?.driver : "Self Drive",
    );
    const [pickupLocation, setPickupLocation] = useState(
        props?.passedData?.pickupLocation
            ? props?.passedData?.pickupLocation
            : "",
    );
    const [touched, setTouched] = useState(false);
    const {
        db,
        duration,
        setDuration,
        pickupDate,
        setPickupDate,
        returnDate,
        setReturnDate,
    } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [locations, setLocations] = useState([]);
    const navigate = useNavigate();

    const fetchLocations = async () => {
        setLoading(true);
        const query = collection(db, "location");
        let arr_blogs = [];
        const querySnapshot = await getDocs(query);
        querySnapshot.forEach((doc) => {
            arr_blogs.push(doc.data());
        });
        setLocations(arr_blogs);
        setLoading(false);
    };

    useEffect(() => {
        fetchLocations();
    }, []);

    const handlePickupLocationChange = (event) => {
        setPickupLocation(event.target.value);
    };

    const handlePickupDateChange = (e) => {
        setPickupDate(e.target.value);
    };

    const handleReturnLocationChange = (e) => {
        setReturnDate(e.target.value);
    };
    const today = new Date().toISOString().split("T")[0];

    const isPastDate = pickupDate < today;
    const isPastDate2 = returnDate < today;

    const handleSubmit = (e) => {
        setTouched(true);
        e.preventDefault();
        const searchQuery = {
            driver,
            pickupLocation,
            pickupDate,
            returnDate,
        };
        localStorage.setItem("carSearch", JSON.stringify(searchQuery));
        if (
            pickupDate &&
            pickupLocation &&
            returnDate &&
            !isPastDate &&
            !isPastDate2 &&
            duration >= 8
        ) {
            if (props.fetchCars) {
                props.fetchCars(searchQuery);
            } else {
                window.scrollTo(0, 0);
                navigate("/searchedcar", { state: searchQuery, driver });
            }
        }
    };

    // duration

    const handleDuration = (pickupDate, returnDate) => {
        const startMoment = moment(pickupDate);
        const endMoment = moment(returnDate);
        const durationInHours = endMoment.diff(startMoment, "hours");
        setDuration(durationInHours);
    };
    useEffect(() => {
        handleDuration(pickupDate, returnDate);
    }, [pickupDate, returnDate]);

    return (
        <form className="car-rental-form" onSubmit={handleSubmit}>
            <div className="form-row">
                <div className="form-row-in">
                    <label htmlFor="drive-type">Driving type</label>
                    <DriverBox
                        passedDataDriveType={props.passedData}
                        setDriver={setDriver}
                    />
                </div>
            </div>
            <div className="form-row-2">
                <div className="rent_2">
                    <label htmlFor="pickup-location">City</label>
                    <div
                        className="inputField-1"
                        style={
                            !pickupLocation && touched
                                ? { border: "3px solid red" }
                                : {}
                        }
                    >
                        <FontAwesomeIcon
                            style={
                                !pickupLocation && touched
                                    ? { color: "red" }
                                    : {}
                            }
                            className="locIcon"
                            icon={faLocationDot}
                        />
                        <select
                            className="pickup-location"
                            value={pickupLocation}
                            placeholder="location"
                            onChange={handlePickupLocationChange}
                        >
                            <option value="">Choose a city</option>

                            {loading ? (
                                <option value="">loading</option>
                            ) : (
                                <>
                                    {locations.map((c, i) => (
                                        <option key={i} value={c.id}>
                                            {c.name}
                                        </option>
                                    ))}
                                </>
                            )}
                        </select>
                    </div>
                    {!pickupLocation && touched && (
                        <p style={{ color: "red" }}>
                            Please choose a pickup location
                        </p>
                    )}
                </div>
                <div className="rent_2">
                    <label htmlFor="pickup-date">Pickup Date & Time</label>
                    <div
                        className="inputField-2"
                        style={
                            !pickupDate & touched || isPastDate
                                ? { border: "3px solid red" }
                                : {}
                        }
                    >
                        <input
                            type="datetime-local"
                            className="pickup-date"
                            value={pickupDate}
                            onChange={handlePickupDateChange}
                        />
                    </div>
                    {isPastDate && touched && (
                        <p style={{ color: "red" }}>
                            Please choose a valid pickup date and time
                        </p>
                    )}
                    {!pickupDate && touched && (
                        <p style={{ color: "red" }}>
                            Please choose a valid pickup date and time
                        </p>
                    )}
                </div>
                <div className="rent_2">
                    <label htmlFor="return-location">Return Date & Time</label>
                    <div
                        className="inputField-2"
                        style={
                            !returnDate & touched || isPastDate
                                ? { border: "3px solid red" }
                                : {}
                        }
                    >
                        <input
                            type="datetime-local"
                            id="return-date"
                            value={returnDate}
                            onChange={handleReturnLocationChange}
                        />
                    </div>

                    {isPastDate2 && touched && (
                        <p style={{ color: "red" }}>
                            Please choose a valid return date and time
                        </p>
                    )}
                    {!returnDate && touched && (
                        <p style={{ color: "red" }}>
                            Please choose a valid pickup date and time
                        </p>
                    )}
                </div>
            </div>
            <div className="rent_bottom">
                <div className="negotiated-input">
                    <p>
                        Duration :{" "}
                        <span style={{ color: "red" }}>
                            {duration >= 8
                                ? `${Math.floor(duration / 24)} days ${
                                      duration % 24
                                  } hours`
                                : 0}
                        </span>{" "}
                    </p>
                </div>
                {duration < 8 && touched && (
                    <p style={{ color: "red" }}>
                        Please choose minimum duration 8 hours
                    </p>
                )}
                <button className="rent-button" type="submit">
                    Search
                </button>
            </div>
        </form>
    );
};

export default CarRentalForm;
