import React, { createContext, useEffect, useState } from 'react';
import { getAuth, GoogleAuthProvider, onAuthStateChanged, signInWithPopup, signOut } from 'firebase/auth';
import { app } from './firebase-config';
import { getStorage } from 'firebase/storage';
import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';
import { getAdminId } from './Utils';

export const AuthContext = createContext();
const auth = getAuth(app);
const storage = getStorage(app);
const db = getFirestore(app)

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [userMobile, setUserMobile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [blogs, setBlogs] = useState([]);
    const provider = new GoogleAuthProvider();
    const [showPopup, setShowPopup] = useState(false);
    const [num, setNum] = useState("");
    const [pickupDate, setPickupDate] = useState(null);
    const [returnDate, setReturnDate] = useState(null);
    const [duration, setDuration] = useState(null);
    const [carBookHint, setCarBookHint] = useState("");
    const [isAdmin, setIsAdmin] = useState(false);
    const [userId, setUserId] = useState(false);

    const fetchUser = async () => {
        if (user?.phoneNumber) {
            const q = query(
                collection(db, "users"),
                where("mobileNumber", "==", user.phoneNumber),
            );
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                if (doc.data()) {
                    if (doc.data().role === "admin") {
                        setUserId(getAdminId(doc.data()))
                        setIsAdmin(true);
                    }
                }
            });
        }
        if (user?.email) {
            const q = query(
                collection(db, "users"),
                where("email", "==", user.email),
            );
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                if (doc.data()) {
                    if (doc.data().role === "admin") {
                        setUserId(getAdminId(doc.data()))
                        setIsAdmin(true);
                    }
                }
            });
        }
    };
    useEffect(() => {
        fetchUser();
    }, [user]);

    const handleClick = () => {
        setShowPopup(true);
    };

    const handlePopupButtonClick = (action) => {
        setShowPopup(false);
    };

    const fetchBLogs = async () => {
        const query = collection(db, "blog");
        let arr_blogs = [];
        const querySnapshot = await getDocs(query);
        querySnapshot.forEach((doc) => {
            arr_blogs.push(doc.data());
        });
        setBlogs(arr_blogs);
        setLoading(false);
    };

    useEffect(() => {
        fetchBLogs();
    }, [])

    const providerLogin = () => {
        setLoading(true);
        return signInWithPopup(auth, provider);
    }

    const logOut = () => {
        return signOut(auth);
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
            setLoading(false);
        });
        return () => {
            unsubscribe();
        }
    }, [])

    const [clicked, setClicked] = useState(1);
    const handleActive = (number) => {
        setLoading(false);
        setClicked(number);
        localStorage.setItem("boxNumber", number);
        setLoading(true);
    };
    const [panelClicked, setPanelClicked] = useState(1);
    const handlePanelActive = (number) => {
        setLoading(false);
        setPanelClicked(number);
        localStorage.setItem("panelBoxNumber", number);
        setLoading(true);
    };
    const [bookClicked, setBookClicked] = useState(1);
    const handleBookActive = (number) => {
        setLoading(false);
        setBookClicked(number);
        localStorage.setItem("bookBoxNumber", number);
        setLoading(true);
    };

    const [name, setName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [address, setAddress] = useState("");
    const [email, setEmail] = useState("");
    const [otp, setOtp] = useState("");

    // bookFlow

    const [carName, setCarName] = useState('');
    const [bookedCarID, setBookedCarID] = useState('');

    // adminPanel

    const [userHintAd, setUserHintAd] = useState('')

    const authInfo = {
        user, loading, setLoading, logOut, db, storage, providerLogin, blogs, clicked, handleActive,
        showPopup, setShowPopup, handleClick, handlePopupButtonClick, panelClicked, handlePanelActive, bookClicked, handleBookActive, name, setName, phoneNumber, setPhoneNumber, address, setAddress, email, setEmail, setUser, num, setNum, otp, setOtp, userMobile, setUserMobile, carBookHint, setCarBookHint, carName, setCarName, bookedCarID, setBookedCarID, duration, setDuration, pickupDate, setPickupDate,
        returnDate, setReturnDate, isAdmin, userHintAd, setUserHintAd, userId

    };

    return (
        <AuthContext.Provider value={authInfo}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;