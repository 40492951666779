import { combineReducers } from "@reduxjs/toolkit";
import MapReducer from "./Map/MapReducer";
import UserReducer from "./User/UserReducer";

//Combine reducers
const rootReducers = combineReducers({
  map: MapReducer,
  user: UserReducer,
});

export default rootReducers;