import React from "react";
import { imgPath } from "../../R/Images/imgPath";
import "./about.css";
import useTitle from "../../CustomHooks/useTitle.js";
import { Helmet, HelmetProvider } from "react-helmet-async";

const About = () => {
    useTitle("About Us | self-drive car rental in Indore  - Go With Car");
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <meta
                        name="description"
                        content="Go With Car is the best self-drive car rental in Indore with various options of luxurious cars across Indore. Choose your next ride with or without a driver with an affordable price. 
"
                    />
                </Helmet>
            </HelmetProvider>
            <div className="about_container">
                <img className="about_logo" src={imgPath.logo} alt="" />
                <h1>Indore’s best self-drive car rental Company</h1>
                <div className="about_text">
                    Go<span className="introTitleRed">With</span>Car.com is a
                    startup of self-drive car rental services and currently
                    providing the self drive car rental services in Indore,
                    India and coming soon in other metro cities in India.
                    <br />
                    <br />
                    Go<span className="introTitleRed"> With</span> Car.com is a
                    new player in the self-drive car market. Go
                    <span className="introTitleRed">With</span>
                    Car.com started by <b>Rohit Yaduvanshi</b> in Feb, 2023 when
                    we felt that self-drive cars are limited with higher price
                    and there is a strong need to provide solution for this
                    situation.
                    <br />
                    <br />
                    If you need a self-drive car, you don’t need to look
                    anywhere else for your need of self-drive cars at an
                    affordable price in Indore, India.
                    <br />
                    <br />
                    Go<span className="introTitleRed"> With</span> Car.com gives
                    you an option of using our amazing self-drive car rental
                    service with your privacy and freedom, so that you can start
                    enjoying your road journey as you wish without waiting to
                    reach your destination.
                    <br />
                    <br />
                    Go<span className="introTitleRed"> With</span> Car.com is a
                    small step towards making you enjoy your journey to the
                    fullest, by concentrating on counting memories.
                    <br />
                    <br />
                    You can get your self-drive car within 5 minutes due to
                    minimal paperwork.
                    <br />
                    <br />
                    <b>
                        Enjoy your journey with #Go
                        <span className="introTitleRed">With</span>
                        Car.com!
                    </b>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <b>
                        Read what Go<span className="redHead">With</span>Car.com
                        is all about!
                    </b>
                    <br />
                    <br />
                    <b>Our Vision</b>
                    <br />
                    <p>
                        “We will lead our industry by defining service
                        excellence and building unmatched customer loyalty.”
                    </p>
                    <br />
                    <b>Our Mission</b>
                    <br />
                    <p>
                        “We will ensure a stress-free car rental experience by
                        providing superior services that cater to our customers’
                        individual needs.
                    </p>
                    <br />
                    <b>Our Values</b>
                    <br />
                    <ul className="aboutValues">
                        <li>Integrity</li>
                        <li>Respect for the Individual</li>
                        <li>Teamwork</li>
                        <li>Growth and Profitability</li>
                        <li>Community Responsibility</li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default About;
