import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { AuthContext } from "../../AuthProvider";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { collection, getDocs, query, where } from "firebase/firestore";

const SignUpM3 = () => {
    const { name, setName, address, setAddress, email, setEmail, num, db } =
        useContext(AuthContext);

    const [isFormValid, setIsFormValid] = useState(false);
    const [existed, setExisted] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleFormSubmit = (e) => {
        setLoading(true);
        e.preventDefault();
        navigate("/signup-mobile-4");
        setLoading(false);
    };

    const handleSkip = () => {
        navigate("/");
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case "name":
                setName(value);
                break;
            case "address":
                setAddress(value);
                break;
            default:
                break;
        }
    };

    const fetchUser = async (email) => {
        const q = query(collection(db, "users"), where("email", "==", email));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            if (doc.data()) {
                setExisted(true);
            }
        });
    };

    const handleInputChange2 = (e) => {
        const { value } = e.target;
        setExisted(false);
        fetchUser(value);
        setEmail(value);
    };

    useEffect(() => {
        setIsFormValid(
            name !== "" && email !== "" && address !== "" && existed !== true,
        );
    }, [name, email, address, existed]);
    return (
        <div className="login2-container">
            <h1 className="login2-header">Contact Details</h1>
            <form onSubmit={handleFormSubmit}>
                <div className="input2-box">
                    <input
                        type="text"
                        name="name"
                        value={name}
                        onChange={handleInputChange}
                        placeholder="Enter Name"
                    />
                </div>
                <div className="input2-box">
                    <input
                        type="text"
                        name="phoneNumber"
                        value={num}
                        readOnly
                    />
                </div>
                <div className="input2-box">
                    <input
                        type="text"
                        name="address"
                        value={address}
                        onChange={handleInputChange}
                        placeholder="Enter Address"
                    />
                </div>
                <div className="input2-box">
                    <input
                        type="text"
                        name="email"
                        value={email}
                        onChange={handleInputChange2}
                        placeholder="Enter Email"
                    />
                </div>
                {existed && (
                    <div>
                        <p style={{ color: "red" }}>Email already in use</p>
                        <br />
                    </div>
                )}
                <button
                    type="submit"
                    className={`proceed2-button ${
                        isFormValid ? "proceed2-buttonShadow" : "disabled"
                    }`}
                    disabled={!isFormValid}
                >
                    {loading ? "Loading..." : " Proceed"}
                </button>
                <div className="divider">Or</div>
                <button onClick={handleSkip} className="proceed2-button-skp">
                    Skip
                </button>
            </form>
        </div>
    );
};

export default SignUpM3;
