import React from "react";
import "./Gmap.css";

const GMap = () => {
  // jubaer worked here
  return (
    <div className="mapContainer">
      <div className="gMapHeader">
        <h2>
          Explore Go
          <span className="introTitleRed"> With</span> Car on Google Map
        </h2>
      </div>
      <div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d33297.434170738925!2d75.86445298497762!3d22.77749649405318!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39631da368f42a5b%3A0x1a1b473e7e86c4a2!2sGoWithcar.com%E2%84%A2%20-%20Self-Drive%20Car%20Rental%20Services%20%7C%20Taxi!5e0!3m2!1sen!2sin!4v1701097224228!5m2!1sen!2sin"
          allowFullScreen=""
          style={{
            border: "0px",
            width: "100%",
            height: "300px",
            borderRadius: "10px",
          }}
          loading="lazy"
          title="googleMap"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default GMap;
