import React from "react";
import Top from "./Top";
import { FaMailBulk, FaMobileAlt, FaSearchLocation } from "react-icons/fa";
import ContactUs from "./ContactUs/ContactUs";
import GMap from "../Home/component/Gmap/GMap";
import useTitle from "../../CustomHooks/useTitle.js";
import { Helmet, HelmetProvider } from "react-helmet-async";
import "./Cont.css";

const Contact = () => {
  useTitle("Contact - Go With Car | self drive rental cars near me");
  return (
    <div className="contactContainer">
      <HelmetProvider>
        <Helmet>
          <meta
            name="description"
            content="Contact Us any time. Mail us at info@gowithcar.com. Call us at +91-9926222099, Reach Us - Ring Road, near Jaguar, Land Rover, Range Rover showroom, Nipania Square, Indore, M.P. India 452010.  Go With Car - get car on rent."
          />
        </Helmet>
      </HelmetProvider>
      <Top />
      <div className="contactLinkContainer">
        <div className="CInfoContainer">
          <h2>Get in touch with us</h2>
          <p className="contact_subHeader">Stay connected with us</p>

          <br />
          <br />

          <h4>Indore Branch</h4>
          <br />
          <br />
          <div className="CInfo">
            <FaMobileAlt className="redContactIcons" />
            <span className="CInfoText">
              <a className="contactAnk" href="tel:9926222099">
                +91-9926222099
              </a>
            </span>
          </div>
          <div className="CInfo">
            <FaMailBulk className="redContactIcons" />
            <p className="CInfoText">info@gowithcar.com</p>
          </div>
          <div className="CInfo">
            <FaSearchLocation className="redContactIcons" />
            <p className="CInfoText">
              Ring Road, near Jaguar, Land Rover, Range Rover showroom, Nipania
              Square, Indore, M.P. India 452010
            </p>
          </div>
          <br />
          <div className="socialContacts">
            <div className="Cconnected_cl">
              <a href="https://www.facebook.com/gowithcar.in" rel="noreferrer">
                <img className="Cicons" src="Icons/Facebook.png" alt="" />
              </a>
            </div>
            <div className="Cconnected_cl">
              <a href="https://twitter.com/gowithcar" rel="noreferrer">
                <img className="Cicons" src="Icons/twitter.png" alt="" />
              </a>
            </div>
            <div className="Cconnected_cl">
              <a
                href="https://instagram.com/gowithcar.in?igshid=NTdlMDg3MTY="
                rel="noreferrer"
              >
                <img className="Cicons" src="Icons/insta.webp" alt="" />
              </a>
            </div>
            <div className="Cconnected_cl">
              <a
                href="https://www.linkedin.com/company/gowithcar/"
                rel="noreferrer"
              >
                <img className="Cicons" src="Icons/linkedin.png" alt="" />
              </a>
            </div>
            <div className="Cconnected_cl">
              <a href="https://www.youtube.com/@gowithcar" rel="noreferrer">
                <img className="Cicons" src="Icons/youtube.png" alt="" />
              </a>
            </div>
            <div className="Cconnected_cl">
              <a href="https://www.pinterest.com/Gowithcar/" rel="noreferrer">
                <img className="Cicons" src="Icons/pin.png" alt="" />
              </a>
            </div>
          </div>
        </div>
        <div className="contactForm">
          <ContactUs />
        </div>
      </div>
      <GMap />
    </div>
  );
};

export default Contact;
