import React, { useState, useRef, useEffect } from "react";
import "./AddBlog.css";
import { Button, Form, Input } from "antd";
import JoditEditor from "jodit-react";
import { useContext } from "react";
import { AuthContext } from "../../AuthProvider";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { doc, setDoc } from "firebase/firestore";

const AddBlog = () => {
    const { user, storage, db } = useContext(AuthContext);
    const [imagesBlogURL, setImagesBlogURL] = useState("");
    const [image, setImage] = useState("");
    const [imageBottom, setImageBottom] = useState("");
    const [imagBlogUrlBottom, setImagBlogUrlBottom] = useState("");
    const [loader, setLoader] = useState(true);
    const editor = useRef(null);

    const [form] = Form.useForm();

    const handleReset = () => {
        form.resetFields();
    };

    const onFinish = (values) => {
        setLoader(false);

        // storage Upload
        if (!imagesBlogURL || !values["title"] || !values["blog"]) {
            alert("Please fill all the form");
        } else {
            handleUploadThumb(values);
        }
    };

    const handleBlogUpload = async (urls, values) => {
        try {
            const id = Math.floor(
                100000 + Math.random() * 9900007567,
            ).toString();
            const blogDetails = {
                id,
                writerEmail: user?.email,
                writer: user?.displayName,
                title: values["title"],
                thumbnail: urls,
                content: values["blog"],
                createdAt: `${new Date().toISOString().slice(0, 10)}`,
            };
            if (urls) {
                await setDoc(doc(db, "blog", id), blogDetails);
                urls = "";
                values["blog"] = "";
                values["title"] = "";
                setImagesBlogURL("");
                handleReset();
                setLoader(true);
                alert("Blog Send Successful");
            }
        } catch {}
    };

    const handleUploadThumb = (values) => {
        const storageRef = ref(storage, `/Blogs_Images/${image.name}`);
        const uploadTask = uploadBytesResumable(storageRef, image);
        uploadTask.on(
            "state_changed",
            (snapshot) => {},
            (err) => {},
            () => {
                // download url
                getDownloadURL(uploadTask.snapshot.ref).then((urls) => {
                    handleBlogUpload(urls, values);
                });
            },
        );
    };
    const handleUpload = () => {
        const storageRef = ref(storage, `/Blogs_Images/${imageBottom.name}`);
        const uploadTask = uploadBytesResumable(storageRef, imageBottom);
        uploadTask.on(
            "state_changed",
            (snapshot) => {},
            (err) => {},
            () => {
                // download url
                getDownloadURL(uploadTask.snapshot.ref).then((urls) => {
                    setImagBlogUrlBottom(urls);
                });
            },
        );
    };
    return (
        <div className="adBLogContainer">
            <h1>Add your blog here</h1>
            <br />
            <br />
            <Form
                form={form}
                name="nest-messages"
                style={{ maxWidth: "100% " }}
                onFinish={onFinish}
            >
                <h4 className="blogFormHeadings">Title</h4>
                <br />
                <Form.Item name="title">
                    <Input placeholder="Enter title here" required />
                </Form.Item>
                <h4 className="blogFormHeadings">Thumbnail Image </h4>
                <br />
                <img
                    style={{ width: 100, height: 100 }}
                    src={`${imagesBlogURL}`}
                    alt=""
                />

                <Form.Item>
                    <input
                        className="urlImport"
                        required
                        type="file"
                        onChange={(e) => {
                            setImage(e.target.files[0]);
                            setImagesBlogURL(
                                URL.createObjectURL(e.target.files[0]),
                            );
                        }}
                    />
                </Form.Item>

                <h4 className="blogFormHeadings">Blog</h4>
                <br />
                <Form.Item name="blog">
                    <JoditEditor
                        ref={editor}
                        required

                        // preferred to use only this option to update the content for performance reasons
                    />
                </Form.Item>
                <Form.Item>
                    {loader ? (
                        <Button type="primary" danger htmlType="submit">
                            Create Blog
                        </Button>
                    ) : (
                        <Button type="primary" danger>
                            Please wait....
                        </Button>
                    )}
                </Form.Item>
            </Form>
            <Form onFinish={handleUpload}>
                <br />
                <h4 className="blogFormHeadings">
                    Upload Image Here And Get URL
                </h4>
                <br />
                <Form.Item>
                    <Form.Item>
                        <input
                            className="urlImport"
                            required
                            type="file"
                            onChange={(e) => {
                                setImageBottom(e.target.files[0]);
                            }}
                        />
                    </Form.Item>
                </Form.Item>
                <Form.Item>
                    <Button danger htmlType="submit">
                        Get Url
                    </Button>
                </Form.Item>
                {imagBlogUrlBottom.length > 1 && (
                    <>
                        <h5>Url :</h5>
                        <p>{imagBlogUrlBottom}</p>
                    </>
                )}
            </Form>
        </div>
    );
};

export default AddBlog;
