import React, { useContext, useState, useEffect } from "react";
import "../../Bookings/Booking/book.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AuthContext } from "../../../../AuthProvider";
import {
  faGasPump,
  faGear,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  updateDoc,
} from "firebase/firestore";
import { Timestamp } from "firebase/firestore";
import { Navigate, useNavigate } from "react-router-dom";

const RefundStatus = () => {
  const { db, user } = useContext(AuthContext);

  const navigate = useNavigate();
  const [bookId, setBookId] = useState("");
  const [bookingStatus, setBookingStatus] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);


  const [userId, setUserId] = useState("");



  const return_date = new Timestamp(
    bookings?.return_date?.seconds,
    bookings?.return_date?.nanoseconds
  ).toDate();
  const pickup_date = new Timestamp(
    bookings?.pickup_date?.seconds,
    bookings?.pickup_date?.nanoseconds
  ).toDate();

  const [notifications, setNotifications] = useState([])


  console.log('userIduserIduserId', userId);
  const isRefunded = async () => {
    // Query a reference to a subcollection
    const querySnapshot = await getDocs(query(collection(db, "bookings"), where("status", "==", "cancelled")));
    console.log("Notifications: ", querySnapshot.docs);
    setBookings(querySnapshot.docs.length > 0 ? querySnapshot.docs : [])
  };

  console.log("notifications===", notifications)

  useEffect(() => {
    isRefunded()
  }, [])

  const handleRefundComplete = async () => {

    await updateDoc(doc(db, "bookings", bookId), {
      isRefunded: true
    })
      .then(() => {
        setShowPopup(false);

        isRefunded()

      })
      .catch((error) => {
        console.error("Error while Refund: ", error);
      });
    setShowPopup(false);

  };

  return (
    <div>
      <div>
        <h1 className="offerHead">Refund Status</h1>
      </div>
      <div className="c_book_c" style={{ justifyContent: 'center', display: 'flex' }}>

        {bookings.length !== 0 ? bookings?.map((c, index) => {
          const details = c.data()
          const return_date = new Timestamp(
            details?.return_date?.seconds,
            details?.return_date?.nanoseconds
          ).toDate();
          const pickup_date = new Timestamp(
            details?.pickup_date?.seconds,
            details?.pickup_date?.nanoseconds
          ).toDate();
          return (
            <div className="book_con">

              {showPopup && (
                <div className="popup-screen">
                  <div className="popup">
                    <div className="popup_p">
                      <h2>Are you sure you want to refund ?</h2>
                      {/* <FontAwesomeIcon
                                    icon={faXmark}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setShowPopup(false)}
                                /> */}
                    </div>
                    <div className="popup_b">
                      <button onClick={() => handleRefundComplete()}>Yes</button>

                      <button
                        onClick={() => {
                          setShowPopup(false);
                        }}
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              )}

              <div className="In_c_c">
                <div className="In_c_c_Info">
                  <small>{details?.car_details?.vehiclesBrands}</small>
                  <h4>{details?.car_details?.vehiclesModalName}</h4>
                  <div className="In_c_c_Info_2">
                    <div className="In_c_c_Info_3">
                      <FontAwesomeIcon
                        icon={faGasPump}
                        style={{
                          height: "15px",
                          width: "15px",
                          paddingRight: "5px",
                          color: "	 #ff8080",
                        }}
                      />
                      <p>{details?.car_details?.fuelType}</p>
                    </div>
                    <div className="In_c_c_Info_3">
                      <FontAwesomeIcon
                        icon={faUserGroup}
                        style={{
                          height: "15px",
                          width: "15px",
                          paddingRight: "5px",
                          color: "	 #ff8080",
                        }}
                      />
                      <p>{details?.car_details?.seatingCapacity}</p>
                    </div>
                    <div className="In_c_c_Info_3">
                      <FontAwesomeIcon
                        icon={faGear}
                        style={{
                          height: "15px",
                          width: "15px",
                          paddingRight: "5px",
                          color: "	 #ff8080",
                        }}
                      />
                      <p>{details?.car_details?.transmissionType}</p>
                    </div>
                  </div>
                </div>
                <div>
                  <img
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      // handleBookAll(details?.car_details?.id);
                    }}
                    className="In_c_pic"
                    src={details?.car_details?.thumbnail}
                    alt=""
                  />
                </div>
              </div>
              <div className="book_con_2">
                <div className="book_con_3">
                  <h4>Passenger Details</h4>
                  <div className="book_con_3_in">
                    <div>Name : {details?.passenger_details?.firstName + " " + details?.passenger_details?.lastName}</div>
                    {/* <div>Adress : {details?.passenger_details?.address}</div> */}
                    <div>Email : {details?.passenger_details?.email}</div>
                    <div>Driving License : {details?.passenger_details?.drivingLicence}</div>
                  </div>
                </div>
                <div className="book_con_3">
                  <h4>Host Details</h4>
                  <div className="book_con_3_in">
                    <div>Name : {details?.host_details?.name}</div>
                    <div>Email :{details?.host_details?.email} </div>
                    <div>Mobile : {details?.host_details?.phoneNumber} </div>
                  </div>
                </div>
                <div className="book_con_3">
                  <h4>Ride Details</h4>
                  <div className="book_con_3_in">
                    <div>Status : {details?.status}</div>
                    <div>Payment Status : {details?.paymentDetails?.state}</div>
                    <div>Payment method : {details?.payment_mode}</div>
                    <div>Refund Status : {details?.isRefunded == true ? 'Refund Completed' : 'Refund Incompleted'}</div>
                    <div>Refundable Amount : {details?.refundableAmount}</div>
                    <div>Car reg number : {details?.car_details?.id}</div>
                    <div>Navigation : {details?.cityName}</div>
                    <div>Duration : {details?.duration}</div>
                    <div>Pick up date : {pickup_date?.toLocaleString()}</div>
                    <div>Return date : {return_date?.toLocaleString()}</div>
                  </div>
                </div>
                {details?.isRefunded === true ? null : <div className="book_con_4">
                  <button
                    onClick={() => {
                      setBookId(details.id);
                      setShowPopup(true);

                      setBookingStatus("completed");
                    }}
                    className="btn_book_ad"
                  >
                    Complete Refund
                  </button>

                </div>}

              </div>

            </div>
          )




        }


        )


          : null}
      </div>
    </div>
  );
};

export default RefundStatus;
