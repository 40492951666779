import React from "react";
import "./Recent.css";
import Blog from "../../../../Blogs/BLog/Blog";
import { useContext } from "react";
import { AuthContext } from "../../../../AuthProvider";

const RecentBlogs = () => {
	const { blogs } = useContext(AuthContext);

	return (
		<div className='recentBlogsContainer'>
			<div>
				<h2>RECENT BLOGS</h2>
			</div>
			<div className='recentBlogCards'>
				{blogs.slice(0, 3).map((b, i) => (
					<Blog b={b} key={i}></Blog>
				))}
			</div>
		</div>
	);
};

export default RecentBlogs;
