import React, { useContext } from "react";
import Lottie from "lottie-react";
import * as animationData from "../../../../R/LottieJson/car_driving.json";
import "./intro.css";

const IntroHome = () => {
    return (
        <div className="ih_main">
            <div className="ih_background">
                <div className="ih_d1">
                    <div className="ih_d2">
                        <div className="ih_d3">
                            <h1 className="ih_h1">
                                Book Self-Drive &<br />
                                With-Driver Car Rental in
                                <span className="introTitleRed">
                                    {" "}
                                    Indore
                                </span>{" "}
                                {/* through Go
                                <span className="introTitleRed"> With</span> Car */}
                            </h1>
                            <p className="ih_p1">
                                Whether you’re planning a road trip outside the
                                city or looking for a convenient way to cruise
                                around when you’re out of town, Go{" "}
                                <span className="introTitleRed">With</span> Car
                                is here to ease your travel.
                            </p>
                        </div>
                        <div className="ih_d3_lottie">
                            <Lottie animationData={animationData} loop={true} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IntroHome;
