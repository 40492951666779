import React, { useState } from "react";
import "./Drv.css";

const DriverBox = (props) => {
    const { setDriver } = props;
    const [activeBox, setActiveBox] = useState(
        props.passedDataDriveType?.driver && props.passedDataDriveType?.driver
            ? props.passedDataDriveType?.driver
            : "Self Drive",
    );
    const handleBoxClick = (boxId) => {
        setActiveBox(boxId);
    };
    return (
        <div className="two-boxes">
            <div
                className={`box ${activeBox === "Self Drive" ? "active" : ""}`}
                onClick={() => {
                    handleBoxClick("Self Drive");
                    setDriver("Self Drive");
                }}
            >
                <img
                    className="pixel-image"
                    src={
                        activeBox === "Self Drive"
                            ? "Icons/wheel2.png"
                            : "Icons/wheel1.png"
                    }
                    alt=""
                />
                <p>
                    Self Drive<span className="box-show"> /Without Driver</span>
                </p>
            </div>
            <div
                className={`box driver_act ${
                    activeBox === "With Driver" ? "active" : ""
                }`}
                onClick={() => {
                    handleBoxClick("With Driver");
                    setDriver("With Driver");
                }}
            >
                <img
                    className="pixel-image"
                    src={
                        activeBox === "With Driver"
                            ? "Icons/driver2.png"
                            : "Icons/driver.png"
                    }
                    alt=""
                />
                <p>With Driver</p>
            </div>
            <div
                className={`box tra_act ${
                    activeBox === "Tempo Traveller"
                        ? "active "
                        : "Tempo TravellerActive"
                }`}
                onClick={() => {
                    handleBoxClick("Tempo Traveller");
                    setDriver("Tempo Traveller");
                }}
            >
                <img
                    className="pixel-image"
                    src={
                        activeBox === "Tempo Traveller"
                            ? "Icons/van.png"
                            : "Icons/van2.png"
                    }
                    alt=""
                />
                <p>Tempo Traveller</p>
            </div>
        </div>
    );
};

export default DriverBox;
