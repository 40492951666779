import React from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";
import { useState } from "react";
import { AuthContext } from "../AuthProvider";
import "./UserBook.css";

const UserBookings = () => {
    const { setCarBookHint } = useContext(AuthContext);
    const [active, setActive] = useState(1);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleBook = async () => {
        navigate("/admin-panel/bookings/searched");
    };
    return (
        <div className="userbook-con">
            <div>
                {" "}
                <div className="ad_book_1">
                    <div className="ad_book_5">
                        <Link
                            className={
                                active === 1 ? "ad_book_3_active" : "ad_book_3"
                            }
                            to={"/user-bookings/user-current"}
                            onClick={() => setActive(1)}
                        >
                            Current Bookings
                        </Link>
                        <Link
                            className={
                                active === 2 ? "ad_book_3_active" : "ad_book_3"
                            }
                            to={"/user-bookings/user-past"}
                            onClick={() => setActive(2)}
                        >
                            Past Bookings
                        </Link>
                        <Link
                            className={
                                active === 3 ? "ad_book_3_active" : "ad_book_3"
                            }
                            to={"/user-bookings/user-upcoming"}
                            onClick={() => setActive(3)}
                        >
                            Upcoming Bookings
                        </Link>
                        <Link
                            className={
                                active === 4 ? "ad_book_3_active" : "ad_book_3"
                            }
                            to={"/user-bookings/user-cancelled"}
                            onClick={() => setActive(4)}
                        >
                            Cancelled Bookings
                        </Link>
                    </div>
                </div>
            </div>
            <div>
                <Outlet />
            </div>
        </div>
    );
};

export default UserBookings;
