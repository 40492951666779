import React, { useContext, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faGasPump,
    faGear,
    faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "../../../../AuthProvider";
import { collection, getDocs, query, where } from "firebase/firestore";
import moment from "moment/moment";
import "./Dbook.css";

const DeletedBookings = () => {
    const { db } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [book, setBook] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState("all"); // Default filter: All bookings
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const handleDateFilterChange = (filter) => {
        setSelectedFilter(filter);
    };

    const filterBookings = (bookings) => {
        let filteredBookings = bookings;
        if (selectedFilter === "last7days") {
            const last7Days = moment().subtract(7, "days");
            filteredBookings = bookings.filter((booking) =>
                moment(booking.pickup_date.toDate()).isAfter(last7Days),
            );
        } else if (selectedFilter === "lastMonth") {
            const lastMonth = moment().subtract(1, "months");
            filteredBookings = bookings.filter((booking) =>
                moment(booking.pickup_date.toDate()).isAfter(lastMonth),
            );
        } else if (selectedFilter === "last3Months") {
            const last3Months = moment().subtract(3, "months");
            filteredBookings = bookings.filter((booking) =>
                moment(booking.pickup_date.toDate()).isAfter(last3Months),
            );
        } else if (selectedFilter === "last6Months") {
            const last6Months = moment().subtract(6, "months");
            filteredBookings = bookings.filter((booking) =>
                moment(booking.pickup_date.toDate()).isAfter(last6Months),
            );
        } else if (selectedFilter === "lastYear") {
            const lastYear = moment().subtract(1, "years");
            filteredBookings = bookings.filter((booking) =>
                moment(booking.pickup_date.toDate()).isAfter(lastYear),
            );
        } else if (selectedFilter === "customDate") {
            if (startDate && endDate) {
                const start = moment(startDate);
                const end = moment(endDate);
                filteredBookings = bookings.filter((booking) =>
                    moment(booking.pickup_date.toDate()).isBetween(start, end),
                );
            }
        } else {
            // No filter selected, return all bookings
        }

        return filteredBookings;
    };

    const fetchBooks = async () => {
        const q = query(
            collection(db, "bookings"),
            where("status", "==", "cancelled"),
        );
        let arr = [];
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            arr.push(doc.data());
        });
        const filteredBookings = filterBookings(arr);
        setBook(filteredBookings);
        setLoading(false);
    };

    useEffect(() => {
        fetchBooks();
    }, [selectedFilter, endDate]);
    return (
        <div>
            <div>
                <h1 className="bookHead">Cancelled Bookings</h1>
            </div>
            <div className="date-filters-con">
                <div>
                    <div className="date-filters">
                        <button
                            className={selectedFilter === "all" ? "active" : ""}
                            onClick={() => handleDateFilterChange("all")}
                        >
                            All
                        </button>
                        <button
                            className={
                                selectedFilter === "last7days" ? "active" : ""
                            }
                            onClick={() => handleDateFilterChange("last7days")}
                        >
                            Last 7 Days
                        </button>
                        <button
                            className={
                                selectedFilter === "lastMonth" ? "active" : ""
                            }
                            onClick={() => handleDateFilterChange("lastMonth")}
                        >
                            Last Month
                        </button>
                        <button
                            className={
                                selectedFilter === "last3Months" ? "active" : ""
                            }
                            onClick={() =>
                                handleDateFilterChange("last3Months")
                            }
                        >
                            Last 3 Months
                        </button>
                        <button
                            className={
                                selectedFilter === "last6Months" ? "active" : ""
                            }
                            onClick={() =>
                                handleDateFilterChange("last6Months")
                            }
                        >
                            Last 6 Months
                        </button>
                        <button
                            className={
                                selectedFilter === "lastYear" ? "active" : ""
                            }
                            onClick={() => handleDateFilterChange("lastYear")}
                        >
                            Last 1 Year
                        </button>
                        <button
                            className={
                                selectedFilter === "customDate" ? "active" : ""
                            }
                            onClick={() => handleDateFilterChange("customDate")}
                        >
                            Custom Date
                        </button>
                    </div>
                    {selectedFilter === "customDate" && (
                        <div className="date-filters-con">
                            <div className="custom-date-range">
                                <label>Start Date:</label>
                                <input
                                    type="date"
                                    placeholder={startDate}
                                    value={startDate}
                                    onChange={(e) => {
                                        setStartDate(e.target.value);
                                    }}
                                />
                                <label>End Date:</label>
                                <input
                                    type="date"
                                    placeholder={endDate}
                                    value={endDate}
                                    onChange={(e) => {
                                        setEndDate(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {/* <div className="d_btn_bck_con">
                <div>
                    <Link
                        onClick={() => {
                            window.scrollTo(0, 0);
                        }}
                        to={"/admin-panel/bookings"}
                    >
                        {" "}
                        <button className="d_btn_bck">
                            <FontAwesomeIcon icon={faArrowAltCircleLeft} /> Go
                            Back{" "}
                        </button>
                    </Link>
                </div>
            </div> */}
            <div>
                {loading ? (
                    <h1>Loading....</h1>
                ) : (
                    <div className="c_book_c">
                        {book.length === 0 && <h2>No bookings</h2>}
                        {book.map((c, i) => (
                            <div key={i} className="book_con">
                                <div className="In_c_c">
                                    <div className="In_c_c_Info">
                                        <small>
                                            {c?.car_details?.vehiclesBrands}
                                        </small>
                                        <h4>
                                            {c?.car_details?.vehiclesModalName}
                                        </h4>
                                        <div className="In_c_c_Info_2">
                                            <div className="In_c_c_Info_3">
                                                <FontAwesomeIcon
                                                    icon={faGasPump}
                                                    style={{
                                                        height: "15px",
                                                        width: "15px",
                                                        paddingRight: "5px",
                                                        color: "	 #ff8080",
                                                    }}
                                                />
                                                <p>
                                                    {c?.car_details?.fuelType}
                                                </p>
                                            </div>
                                            <div className="In_c_c_Info_3">
                                                <FontAwesomeIcon
                                                    icon={faUserGroup}
                                                    style={{
                                                        height: "15px",
                                                        width: "15px",
                                                        paddingRight: "5px",
                                                        color: "	 #ff8080",
                                                    }}
                                                />
                                                <p>
                                                    {
                                                        c?.car_details
                                                            ?.seatingCapacity
                                                    }
                                                </p>
                                            </div>
                                            <div className="In_c_c_Info_3">
                                                <FontAwesomeIcon
                                                    icon={faGear}
                                                    style={{
                                                        height: "15px",
                                                        width: "15px",
                                                        paddingRight: "5px",
                                                        color: "	 #ff8080",
                                                    }}
                                                />
                                                <p>
                                                    {
                                                        c?.car_details
                                                            ?.transmissionType
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <img
                                            style={{ cursor: "pointer" }}
                                            className="In_c_pic"
                                            src={c?.car_details?.thumbnail}
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div className="book_con_2">
                                    <div className="book_con_3">
                                        <h4>Passenger Details</h4>
                                        <div className="book_con_3_in">
                                            <div>
                                                Name :{" "}
                                                {c?.passenger_details?.name}
                                            </div>
                                            <div>
                                                Adress :{" "}
                                                {/* {c?.passenger_details?.address} */}
                                            </div>
                                            <div>
                                                Email :{" "}
                                                {c?.passenger_details?.id}
                                            </div>
                                            <div>
                                                Driving License :{" "}
                                                {
                                                    c?.passenger_details
                                                        ?.drivingLicence
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="book_con_3">
                                        <h4>Host Details</h4>
                                        <div className="book_con_3_in">
                                            <div>
                                                Name : {c?.host_details?.name}
                                            </div>
                                            <div>
                                                Email :{c?.host_details?.id}{" "}
                                            </div>
                                            <div>Mobile : +992324 </div>
                                        </div>
                                    </div>
                                    <div className="book_con_3">
                                        <h4>Ride Details</h4>
                                        <div className="book_con_3_in">
                                            <div>Status : {c?.status}</div>
                                            <div>
                                                Payment Status :{" "}
                                                {c?.paymentStatus}
                                            </div>
                                            <div>
                                                Payment method :{" "}
                                                {c?.payment_mode}
                                            </div>
                                            <div>
                                                Car reg number :{" "}
                                                {c?.car_details?.id}
                                            </div>
                                            <div>
                                                Navigation : {c?.cityName}
                                            </div>
                                            <div>Duration : {c?.duration}</div>
                                            <div>
                                                Pick up date :{" "}
                                                {c?.pickup_date
                                                    .toDate()
                                                    .toLocaleString()}
                                            </div>
                                            <div>
                                                Return date :{" "}
                                                {c?.return_date
                                                    .toDate()
                                                    .toLocaleString()}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default DeletedBookings;
