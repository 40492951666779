import React, { useContext, useEffect, useState } from "react";
import "./HP.css";
import Customer from "./Customer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "../../../AuthProvider";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useLocation, useNavigate } from "react-router-dom";

const HostProfiles = (props) => {
    const location = useLocation()
    const type = location?.state?.type
    console.log("props==",location?.state);
    const { db, userHintAd, setUserHintAd } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState(true);
    const fetchUsers = async () => {
        
        const q = query(collection(db, "users"), where("role", "==", type));
        let arr = [];
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            arr.push(doc.data());
        });
        setUsers(arr);
        setLoading(false);
    };

    useEffect(() => {
        fetchUsers();
    }, [type]);
    const navigate = useNavigate();

    const handleUser = async () => {
        navigate(`/admin-panel/host/${userHintAd}`);
    };
    return (
        <div>
            <div className="ad_comp_1_src_c">
                <div className="ad_comp_1_src_c_2">
                    <FontAwesomeIcon icon={faSearch} />
                    <input
                        placeholder="Mobile number"
                        className="ad_comp_1_src"
                        type="text"
                        onChange={(e) => {
                            setUserHintAd(e.target.value);
                        }}
                    />
                    <button
                        onClick={handleUser}
                        className="btn_host_cus_search"
                    >
                        Search
                    </button>
                </div>
            </div>
            <div>
                <h1 className="hostHead">{type==="host"?"Host Profiles":"User Profiles"}</h1>
            </div>
            <div>
                {loading ? (
                    <h1>Loading....</h1>
                ) : (
                    <div className="ad_h_comp_1">
                        {users?.map((c, i) => (
                            <Customer c={c} key={i} type={type}/>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default HostProfiles;
