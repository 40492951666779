import React from "react";
import "./Bcrs.css";
import InnerCar from "./InnerCar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";
import { AuthContext } from "../../../AuthProvider";
import { useState } from "react";
import { collection, getDocs, limit, query, where } from "firebase/firestore";
import { useEffect } from "react";

const BCars = () => {
    const { db } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [cars, setCars] = useState([]);
    const fetchCars = async () => {
        const q = query(
            collection(db, "cars"),
            where("bookingStatus", "==", "booked"),
            limit(3),
        );
        let arr = [];
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            arr.push(doc.data());
        });
        setCars(arr);
        setLoading(false);
    };

    useEffect(() => {
        fetchCars();
    }, []);
    return (
        <div className="bx_c">
            <div className="bx_cars">
                {loading ? (
                    <h1>Loading....</h1>
                ) : (
                    <div>
                        {cars.map((c, i) => (
                            <InnerCar c={c} key={i} />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default BCars;
