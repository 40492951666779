import { all } from 'redux-saga/effects';

import { mapSaga } from './Map/MapSaga';
import { setBookingDetailsSaga, setUserDataSaga } from './User/UserSaga';

const rootSaga = function* rootSaga() {
  yield all([
    mapSaga(),
    setBookingDetailsSaga(),
    setUserDataSaga()
  ]);
};

export default rootSaga;
