import React, { useContext, useState, useEffect } from "react";
import { query, collection, where, getDocs, doc, updateDoc, } from "@firebase/firestore";
import { AuthContext } from "../../../AuthProvider";
import { useLocation } from "react-router-dom";



const Host = () => {
    const { state,pathname } = useLocation()
    const type = state?.type
    
    const { db, userHintAd} = useContext(AuthContext);
console.log("type=======", state?.mobileNumber);
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [aadharNumber, setAadharNumber] = useState('');
    const [dob, setDob] = useState('');
    const [drivingLicence, setDrivingLicence] = useState('');
    const [accountStatus, setAccountStatus] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [passportNumber, setPassportNumber] = useState('');
    const [userId, setUserId] = useState('');
    console.log("userId", accountStatus)



    const fetchNow = async () => {
        setLoading(true);
        const q = !state?.mobileNumber?query(
            collection(db, "users"),
            where("hostId", "==", state?.id),
        ): query(
            collection(db, "users"),
            where("mobileNumber", "==", state?.mobileNumber),
        );
       
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            setUser(doc.data());
            setName(doc.data().name);
            setEmail(doc.data().email);
            setAadharNumber(doc.data().aadharNumber);
            setDob(doc.data().dob);
            setDrivingLicence(doc.data().drivingLicence);
            setAccountStatus(doc.data().accountStatus);
            setMobileNumber(doc.data().mobileNumber);
            setPassportNumber(doc.data().passportNumber);
            setUserId(type === "host" ? doc.data().hostId : doc.data().id)
            console.log(doc.data());
        });
        setLoading(false);
    };

    useEffect(() => {
        fetchNow();
    }, [userHintAd]);
    const updateHost = async () => {
        let updateField = {
            name: name,
            email: email,
            aadharNumber: aadharNumber,
            dob: dob,
            drivingLicence: drivingLicence,



            mobileNumber: mobileNumber,
            passportNumber: passportNumber,
        }
        if (type === "host") {
            updateField.accountStatus = accountStatus
        }
        const docRefs = doc(db, "users", `${userId}`);
        await updateDoc(docRefs, {

            ...updateField
        })
            .then(() => {
                // File deleted successfu"lly
                console.log("update is done")
                if (userHintAd) {
                    fetchNow();
                }
            })
            .catch((error) => {
                console.log(error);
            });
        setLoading(false);

        window.scroll(0, 0);
    };

    return (
        <div className="ad-host-container">
            <div>
                {loading ? (
                    <div>
                        <h1>Loading...</h1>
                    </div>
                ) : (
                    <div>
                        {user ? (
                            <>
                                <h2>{type === "host" ? "Host Details" : "User Details"}</h2>
                                <br />{" "}

                                <div>
                                    <label>Name : </label>
                                    <br />
                                    <input
                                        className="select-car-ad"
                                        type="text"
                                        placeholder={name}
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />


                                </div>

                                <br />


                                <div>
                                    <label>Email : </label>
                                    <br />
                                    <input
                                        className="select-car-ad"
                                        type="text"
                                        placeholder={email}
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />


                                </div>

                                <br />

                                <div>
                                    <label>Aadhar Number : </label>
                                    <br />
                                    <input
                                        className="select-car-ad"
                                        type="text"
                                        placeholder={aadharNumber}
                                        value={aadharNumber}
                                        onChange={(e) => setAadharNumber(e.target.value)}
                                    />


                                </div>


                                <br />
                                <h4 className="ad-host-info">Aadhar Photo :</h4>
                                <img
                                    className="ad-host-image"
                                    src={user?.aadharFrontPhoto}
                                    alt={user?.name}
                                />
                                <img
                                    className="ad-host-image"
                                    src={user?.aadharBackPhoto}
                                    alt={user?.name}
                                />

                                <div>
                                    <label> Date of Birth  : </label>
                                    <br />

                                    <input width={"100px"}
                                        type="date"

                                        value={dob}
                                        onChange={(e) => setDob(e.target.value)}
                                        style={{
                                            width: '100%',
                                            height: '45px'
                                        }}
                                    />


                                </div>

                                <br />


                                <div>
                                    <label> Driving Licence  : </label>
                                    <br />

                                    <input
                                        className="select-car-ad"
                                        type="text"
                                        placeholder={drivingLicence}
                                        value={drivingLicence}
                                        onChange={(e) => setDrivingLicence(e.target.value)}
                                    />


                                </div>

                                <br />


                                {type === "host" ? <div>
                                    <label> Host Status :</label>
                                    <br />

                                    <select
                                        className="select-car-ad"
                                        value={accountStatus}
                                        placeholder={accountStatus}
                                        onChange={(e) => setAccountStatus(e.target.value)}
                                    >
                                        <option value={accountStatus}>{accountStatus}</option>
                                        <option value="activated">activated</option>
                                        <option value="deactivated">deactivated</option>


                                    </select>


                                </div> : null}




                                <div>
                                    <label>  Mobile Number  : </label>
                                    <br />

                                    <input
                                        className="select-car-ad"
                                        type="text"
                                        placeholder={mobileNumber}
                                        value={mobileNumber}
                                        onChange={(e) => setMobileNumber(e.target.value)}
                                    />


                                </div>

                                <br />


                                <div>
                                    <label>  Passport Number  : </label>
                                    <br />

                                    <input
                                        className="select-car-ad"
                                        type="text"
                                        placeholder={passportNumber}
                                        value={passportNumber}
                                        onChange={(e) => setPassportNumber(e.target.value)}
                                    />


                                </div>

                                <br />

                                <h4 className="ad-host-info">
                                    Passport Photo :
                                </h4>
                                <img
                                    className="ad-host-image"
                                    src={user?.passportPhoto}
                                    alt={user?.name}
                                />
                                <div className="carDtlsbtns">
                                    <button
                                        className="car-dtls-btns-car"
                                        onClick={() => updateHost()}
                                    >
                                        Save changes
                                    </button>

                                </div>
                            </>
                        ) : (
                            <h1 className="ad-host-not-found">{type === "host" ? "No host found" : "No user found"}</h1>
                        )}
                    </div>
                )}
            </div>

        </div>
    );
};

export default Host;
