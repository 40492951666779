import React from "react";
import "./InnerCar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faGasPump,
    faGear,
    faUserGroup,
} from "@fortawesome/free-solid-svg-icons";

const InnerCar = ({ c }) => {
    return (
        <div className="In_c_c">
            <div className="In_c_c_Info">
                <small>{c?.vehiclesBrands}</small>
                <h4>{c?.vehiclesModalName}</h4>
                <div className="In_c_c_Info_2">
                    <div className="In_c_c_Info_3">
                        <FontAwesomeIcon
                            icon={faGasPump}
                            style={{
                                height: "15px",
                                width: "15px",
                                paddingRight: "5px",
                                color: "	 #ff8080",
                            }}
                        />
                        <p>{c?.fuelType}</p>
                    </div>
                    <div className="In_c_c_Info_3">
                        <FontAwesomeIcon
                            icon={faUserGroup}
                            style={{
                                height: "15px",
                                width: "15px",
                                paddingRight: "5px",
                                color: "	 #ff8080",
                            }}
                        />
                        <p>{c?.seatingCapacity}</p>
                    </div>
                    <div className="In_c_c_Info_3">
                        <FontAwesomeIcon
                            icon={faGear}
                            style={{
                                height: "15px",
                                width: "15px",
                                paddingRight: "5px",
                                color: "	 #ff8080",
                            }}
                        />
                        <p>{c?.transmissionType}</p>
                    </div>
                </div>
            </div>
            <div className="In_c_pic">
                <img
                    className="In_c_pic"
                    src={c?.thumbnail}
                    alt={c?.vehiclesModalName}
                />
            </div>
        </div>
    );
};

export default InnerCar;
