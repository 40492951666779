import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../AuthProvider";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGasPump,
  faGear,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import {
  collection,
  getDocs,
  query,
  where,
  Timestamp,
} from "firebase/firestore";

const CurrentBookings = () => {
  const { user, db } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const [book, setBook] = useState([]);
  const fetchBooks = async () => {
    const q = query(
      collection(db, "bookings"),
      where("passengerId", "==", user?.phoneNumber),
      where("status", "==", "running")
    );
    let arr = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      arr.push(doc.data());
    });
    setBook(arr);
    setLoading(false);
  };

  useEffect(() => {
    fetchBooks();
  }, [user?.phoneNumber]);

  return (
    <div>
      <div>
        <h1 className="bookHead">Current Bookings</h1>
      </div>
      <div>
        {loading ? (
          <h1>Loading....</h1>
        ) : (
          <div className="c_book_c">
            {book.length === 0 && <h2>No bookings</h2>}
            {book.map((c, i) => {
              const return_date = new Timestamp(
                c?.return_date?.seconds,
                c?.return_date?.nanoseconds
              ).toDate();
              const pickup_date = new Timestamp(
                c?.pickup_date?.seconds,
                c?.pickup_date?.nanoseconds
              ).toDate();
              return (
                <div key={i} className="book_con">
                  <div className="In_c_c">
                    <div className="In_c_c_Info">
                      <small>{c?.car_details?.vehiclesBrands}</small>
                      <h4>{c?.car_details?.vehiclesModalName}</h4>
                      <div className="In_c_c_Info_2">
                        <div className="In_c_c_Info_3">
                          <FontAwesomeIcon
                            icon={faGasPump}
                            style={{
                              height: "15px",
                              width: "15px",
                              paddingRight: "5px",
                              color: "	 #ff8080",
                            }}
                          />
                          <p>{c?.car_details?.fuelType}</p>
                        </div>
                        <div className="In_c_c_Info_3">
                          <FontAwesomeIcon
                            icon={faUserGroup}
                            style={{
                              height: "15px",
                              width: "15px",
                              paddingRight: "5px",
                              color: "	 #ff8080",
                            }}
                          />
                          <p>{c?.car_details?.seatingCapacity}</p>
                        </div>
                        <div className="In_c_c_Info_3">
                          <FontAwesomeIcon
                            icon={faGear}
                            style={{
                              height: "15px",
                              width: "15px",
                              paddingRight: "5px",
                              color: "	 #ff8080",
                            }}
                          />
                          <p>{c?.car_details?.transmissionType}</p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <img
                        style={{ cursor: "pointer" }}
                        className="In_c_pic"
                        src={c?.car_details?.thumbnail}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="book_con_2">
                    <div className="book_con_3">
                      <h4>Passenger Details</h4>
                      <div className="book_con_3_in">
                        <div>Name : {c?.passenger_details?.name}</div>
                        {/* <div>Adress : {c?.passenger_details?.address}</div> */}
                        <div>Email : {c?.passenger_details?.email}</div>
                        <div>
                          Driving License :{" "}
                          {c?.passenger_details?.drivingLicence}
                        </div>
                      </div>
                    </div>
                    <div className="book_con_3">
                      <h4>Host Details</h4>
                      <div className="book_con_3_in">
                        <div>Name : {c?.host_details?.name}</div>
                        <div>Email :{c?.host_details?.email} </div>
                        <div>Mobile : {c?.host_details?.phoneNumber} </div>
                      </div>
                    </div>
                    <div className="book_con_3">
                      <h4>Ride Details</h4>
                      <div className="book_con_3_in">
                        <div>Status : {c?.status}</div>
                        <div>Payment Status : {c?.paymentDetails?.state}</div>
                        <div>Payment method : {c?.payment_mode}</div>
                        <div>Car reg number : {c?.car_details?.id}</div>
                        <div>Navigation : {c?.cityName}</div>
                        <div>Duration : {c?.duration}</div>
                        <div>
                          Pick up date : {pickup_date?.toLocaleString()}
                        </div>
                        <div>Return date : {return_date?.toLocaleString()}</div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default CurrentBookings;
