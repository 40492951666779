import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { AuthContext } from "../../AuthProvider";
import { useNavigate } from "react-router-dom";
import { collection, getDocs, query, where } from "firebase/firestore";

const Login2 = () => {
    const {
        name,
        setName,
        phoneNumber,
        setPhoneNumber,
        address,
        setAddress,
        email,
        setEmail,
        db,
    } = useContext(AuthContext);

    const [isFormValid, setIsFormValid] = useState(false);
    const [existed, setExisted] = useState(false);

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const handleFormSubmit = (e) => {
        setLoading(true);
        e.preventDefault();
        navigate("/login-3");
        setLoading(false);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        // Update the state based on the input field
        switch (name) {
            case "name":
                setName(value);
                break;

            case "address":
                setAddress(value);
                break;
            case "email":
                setEmail(value);
                break;
            default:
                break;
        }
    };
    const fetchUser = async (numberr) => {
        const q = query(
            collection(db, "users"),
            where("mobileNumber", "==", numberr),
        );
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            if (doc.data()) {
                setExisted(true);
            }
        });
    };

    const handleInputChange2 = (e) => {
        const { value } = e.target;
        setExisted(false);
        fetchUser(value);
        setPhoneNumber(value);
    };

    // Check if all fields are filled to enable the Proceed button
    useEffect(() => {
        setIsFormValid(
            name !== "" &&
                phoneNumber !== "" &&
                address !== "" &&
                existed !== true,
        );
    }, [name, phoneNumber, address, existed]);

    return (
        <div className="login2-container">
            <h1 className="login2-header">Contact Details</h1>
            <form onSubmit={handleFormSubmit}>
                <div className="input2-box">
                    <input
                        type="text"
                        name="name"
                        value={name}
                        onChange={handleInputChange}
                        placeholder="Enter Name"
                    />
                </div>
                <div className="input2-box">
                    <input
                        type="text"
                        name="phoneNumber"
                        value={phoneNumber}
                        onChange={handleInputChange2}
                        placeholder="Enter Phone Number"
                    />
                </div>
                {existed && (
                    <div>
                        <p style={{ color: "red" }}>Number already in use</p>
                        <br />
                    </div>
                )}
                <div className="input2-box">
                    <input
                        type="text"
                        name="address"
                        value={address}
                        onChange={handleInputChange}
                        placeholder="Enter Address"
                    />
                </div>
                <div className="input2-box">
                    <input
                        type="text"
                        name="email"
                        value={email}
                        onChange={handleInputChange}
                        readOnly
                    />
                </div>
                <button
                    type="submit"
                    className={`proceed2-button ${
                        isFormValid ? "proceed2-buttonShadow" : "disabled"
                    }`}
                    disabled={!isFormValid}
                >
                    {loading ? "Loading..." : " Proceed"}
                </button>
            </form>
        </div>
    );
};

export default Login2;
