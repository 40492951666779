import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../AuthProvider";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { collection, getDocs } from "firebase/firestore";
import OTPInput from "react-otp-input";
import AppStore from "../../screen/AppStore";

const SignUpM2 = (props) => {
  const { otp, setOtp, setUserMobile, db } = useContext(AuthContext);
  const [isFormValid, setIsFormValid] = useState(false);
  const [services, setServices] = useState("");
  const [loading, setLoading] = useState(false);
  const [optError, setOtpError] = useState(false);

  const navigate = useNavigate();

  // const dispatch = useDispatch();
  // const userInfo = useSelector((state) => state);
  // const selectedUsers = userInfo.usersDataReducer?.user;
  const selectedUsers = props.userData;

  const fetchUsers = async () => {
    const querySnapshot = await getDocs(collection(db, "users"));
    let arr = [];
    querySnapshot.forEach((doc) => {
      arr.push(doc.data());
    });
    setServices(arr);
  };

  useEffect(() => {
    fetchUsers();
  }, []);



  useEffect(() => {
    if (selectedUsers?.email) {
      if (services) {
        if (services.find((o) => o.email === selectedUsers?.email)) {
        } else {
          submit(selectedUsers?.email);
        }
      }
    }
  }, [services]);

  const submit = async (user) => {
    if (services.find((o) => o.mobileNumber === user.phoneNumber)) {
      setLoading(false);
      navigate("/");
    } else {
      alert("error")
      navigate("/signup-mobile-3");
      setLoading(false);
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
  };

  const onOTPVerify = async () => {

    setLoading(true)
    console.log(loading);

    setOtpError(false);
    // if (loading) {
    window.confirmationResult
      .confirm(otp)
      .then(async (res) => {
        const user = res.user;
        setUserMobile(user?.phoneNumber);
        const mUser = {
          accessToken: user.accessToken,
          displayName: user.displayName,
          email: user.email,
          emailVerified: user.emailVerified,
          phoneNumber: user.phoneNumber,
          photoURL: user.photoURL,
          uid: user.uid,
        }
        await props.setUserDataAction(mUser)
        submit(user);
      })
      .catch((err) => {
        console.log(err);
        setOtpError(true);
        setLoading(false);
      });
    // }
  }
  useEffect(() => {
    setIsFormValid(otp.length === 6);
  }, [otp]);

  return (
    <div className="login2-container">
      <h1 className="login2-header">Please Verify OTP</h1>
      <div id="recaptcha-container"></div>
      <form onSubmit={handleFormSubmit}>
        <div className="input2-box-otp">
          <OTPInput
            className="otp-container"
            value={otp}
            onChange={setOtp}
            numInputs={6}
            inputStyle={{
              width: "30px",
              height: "30px",
              border: "1px solid rgba(255, 0, 0, 0.623)",
              borderRadius: "5px",
              boxShadow: " 0px 0px 7px rgba(255, 0, 0, 0.323)",
            }}
            renderSeparator={<span className="otp-inputs2">-</span>}
            renderInput={(props) => <input {...props} />}
          />
        </div>

        {/* ------------------------OTP Error--------------------------------- */}

        <div className="otp">
          {optError ? "Wrong OTP ! please enter valid OTP" : " "}
        </div>

        <button
          type="submit"
          className={`proceed2-button ${isFormValid ? "proceed2-buttonShadow" : "disabled"
            }`}
          disabled={!isFormValid}
          onClick={() => {
            onOTPVerify()
          }}
        >
          {loading ? "Loading..." : " Verify"}
        </button>
      </form>
    </div>
  );
};

export default AppStore(SignUpM2);
