import React from "react";
import "./Rvn.css";
import { LineChart, Line, CartesianGrid, XAxis, YAxis } from "recharts";

const Revenue = () => {
    const data = [{ name: "Page A", uv: 400, pv: 2400, amt: 2400 }];
    return (
        <div className="rvn_c">
            <div className="rvn_c_g">
                <div>
                    <LineChart
                        style={{ cursor: "pointer" }}
                        width={350}
                        height={340}
                        data={data}
                        margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
                    >
                        <Line type="monotone" dataKey="uv" stroke="#8884d8" />
                        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                        <XAxis dataKey="name" />
                        <YAxis />
                    </LineChart>
                </div>
            </div>
        </div>
    );
};

export default Revenue;
