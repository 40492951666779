import React from "react";
import "./stl.css";

const Cancel = () => {
	return (
		<div className='Ccontainer'>
			<h2 className='Cheader'>Refunds,Charges and Payments</h2>
			<br />
			<p>
				All refunds shall be initiated to the original payment account/ mode.
				All refund initiation shall be processed immediately after the
				cancellation request is received. However, all such refunds may take
				5-15 days to reflect in Guest’s accounts.
			</p>
			<br />
			<p>
				Payments owed by Guests under this Fee Policy or other applicable
				policies will be deducted from the security deposit, if any deposited
				with Go<span className='redHead'>With</span>car.com. If the security
				deposit does not cover such payments, the balance must be paid by the
				Guest electronically within 24 hours of the booking end time, as per Go
				<span className='redHead'>With</span>car.com’s payment instructions.
			</p>
			<br />
			<p>
				The Guest shall ensure that the account from which the amounts are to be
				collected have sufficient funds or credit available to cover any charges
				and/or amount payable to Go<span className='redHead'>With</span>car.com.
				Guests are solely responsible for any associated bank or credit card
				charges or fees. Guests may be charged a processing fee for a declined
				credit or debit card payment.
			</p>
			<br />
			<p>
				In the event the Guest defaults on any payments, Go
				<span className='redHead'>With</span>car.com is entitled to charge
				reminder fees and default interest in accordance with the provisions of
				the law. In addition, Go<span className='redHead'>With</span>car.com may
				designate third parties to collect amounts owed to Go
				<span className='redHead'>With</span>car.com by a Guest.
			</p>
			<br />
			<p>
				Go<span className='redHead'>With</span>car.com reserves the right to
				prohibit a Guest from making a subsequent booking on the Platform until
				all outstanding fees in the Guest's account have been paid in full. In
				the event a fee is incurred, the Guest will receive an email invoice
				from Go<span className='redHead'>With</span>car.com that will have
				detailed payment instructions.
			</p>
			<br />
			<p>
				The Guest should not make any direct payments to the Host or to any Go
				<span className='redHead'>With</span>car.com executive in any mode.
			</p>
			<br />
			<br />
			<h2 className='Cheader'>Cancellation Policy</h2>
			<br />
			<p>
				Cancellation made between 0-6 hrs before booking start time - 100% of
				the Lease rental paid in advance shall be deducted.
			</p>
			<br />
			<p>
				Cancellation made between 6-24 hrs before booking start time - Flat
				cancellation fee of 50% of the Lease Rental paid in advance. Refund of
				the remaining portion of Lease Rental after deduction of Platform Fee.
			</p>
			<br />
			<p>
				Cancellation made after booking start time - No refund of Lease Rental
				paid in advance. Notwithstanding anything contrary stated herein, Go
				<span className='redHead'>With</span>car.com reserves the right to
				withhold any refunds due to cancellations, if Go
				<span className='redHead'>With</span>car.com, at its sole discretion,
				perceives any credit card frauds by a user or where cancellations are
				made by a user with an intention to defraud Go
				<span className='redHead'>With</span>car.com.
			</p>
			<br />
			<br />
			<h2 className='Cheader'>Early Returns Policy</h2>
			<br />
			<p>
				Early returns will be accommodated but reservation can not be prorated
				and no credit for unused time will be given.
			</p>
		</div>
	);
};

export default Cancel;
