import React from "react";
import "./Wind.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun } from "@fortawesome/free-regular-svg-icons";

const Wind = ({ p }) => {
	return (
		<div className='wind_contain'>
			<FontAwesomeIcon style={{ width: "50px", height: "50px" }} icon={faSun} />
			<br />
			<h4>{p.title}</h4>
			<p>{p.details}</p>
			<br />
			<h4>Rs {p.amount} / total</h4>
			<button className='win_btn'>Add</button>
		</div>
	);
};

export default Wind;
