import React from "react";
import "pure-react-carousel/dist/react-carousel.es.css";
import "./Review.css";
import { Avatar, Carousel } from "antd";
import {
    FaChevronLeft,
    FaChevronRight,
    FaQuoteLeft,
    FaQuoteRight,
} from "react-icons/fa";

const Review = () => {
    // jubaer worked here
    const reviews = [
        {
            name: "Hritik Garg",
            nameInitials: "HG",
            time: "a week ago",
            review: "It was a very good experience with 'go with car'.  Of course you should take advantage of their service, they provide quality service in minimum prices",
        },
        {
            name: "Karan",
            nameInitials: "KN",
            time: "two weeks ago",
            review: "Best rental car service in Indore at a very affordable price. I got a 30 Km meter free ride.",
        },
        {
            name: "Arpit",
            nameInitials: "AT",
            time: "a week ago",
            review: "Excellent service provided by this provider I must recommend this service if any one want self drive car services in indore.",
        },
        {
            name: "Shubhamraj Sing",
            nameInitials: "SS",
            time: "three week ago",
            review: "One of the best i have seen in town! Glad to know it..and best service fastest one",
        },
        {
            name: "Nilesh Yadaw",
            nameInitials: "NY",
            time: "a month ago",
            review: "Hey freinds i had a trip to indore with my family and i really loved it the experience with selfdrive car.A very grateful thanks to Rohit sir who always gave the advice or guidance to us. The car they provide was  tata atlroz was in very good condition and also that they had provide the  experience free of cost for the trip.",
        },
        {
            name: "Shk. Sajjadhusain Litre wala",
            nameInitials: "SW",
            time: "a week ago",
            review: "Good car with reasonable prices. Good nature of the dealer. Overall cool experience.",
        },
        {
            name: "ANSHUL TRIPATHI",
            nameInitials: "AT",
            time: "a month ago",
            review: "Best self drive car service and owner nature is friendly",
        },
        {
            name: "shubh chourasia",
            nameInitials: "SC",
            time: "a month ago",
            review: "Top notch service!! well cleaned car and superb customer handling by Rohit the host.",
        },
    ];
    return (
        <div className="review_container">
            <div className="review_header">
                <div>
                    <img className="GIcon" src="Icons/review.png" alt="" />
                </div>
            </div>

            <Carousel
                className="carouselContainer"
                arrows
                prevArrow={<FaChevronLeft />}
                nextArrow={<FaChevronRight />}
                autoplay
            >
                {reviews.map((r, i) => (
                    <div key={i} className="contentStyle">
                        <div className="nameAndStar">
                            <div className="nameInitialContainer">
                                <div>
                                    <Avatar
                                        style={{
                                            backgroundColor: "red",
                                            verticalAlign: "middle",
                                        }}
                                        size="large"
                                        gap={2}
                                    >
                                        {r.nameInitials}
                                    </Avatar>
                                </div>
                                <div className="nameInitial">
                                    <h2>{r.name}</h2>
                                </div>
                            </div>
                            <div className="rateContainer">
                                <div className="starIconsContainer">
                                    <img
                                        className="starIcons"
                                        src="Icons/str.png"
                                        alt=""
                                    />
                                    <img
                                        className="starIcons"
                                        src="Icons/str.png"
                                        alt=""
                                    />
                                    <img
                                        className="starIcons"
                                        src="Icons/str.png"
                                        alt=""
                                    />
                                    <img
                                        className="starIcons"
                                        src="Icons/str.png"
                                        alt=""
                                    />
                                    <img
                                        className="starIcons"
                                        src="Icons/str.png"
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div className="text_container">
                                <p>
                                    <FaQuoteLeft className="reviewQT" />
                                    <span className="review_text">
                                        {r.review}
                                    </span>
                                    <FaQuoteRight className="reviewQT" />
                                </p>
                                <br />
                            </div>
                        </div>
                    </div>
                ))}
            </Carousel>
        </div>
    );
};

export default Review;
