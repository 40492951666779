import { MapActionType } from "./MapAction";

const initialState = {
  mapResponse: undefined,
  mapError: undefined,
};

const MapReducer = (state = initialState, action) => {
  switch (action.type) {
    case MapActionType.MAP_SUCCESS:
      return {
        ...state,
        mapResponse: action.payload,
        mapError: undefined,
      };

    case MapActionType.MAP_FAILURE:
      return {
        ...state,
        // mapResponse: undefined,
        mapError: action.payload,
      };

    default:
      return state;
  }
};
export default MapReducer;
