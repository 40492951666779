import React, { useState } from "react";
import "./Protection.css";
import Basic from "./Plans/Basic";
import Wind from "./Protection/Wind";
import Baby1 from "./Extras/Baby1";
import { useContext } from "react";
import { AuthContext } from "../../../../AuthProvider";
import { collection, getDocs } from "firebase/firestore";
import { useEffect } from "react";
import Driver from "./Extras/Driver";

const Protection = () => {
    const { db, loading } = useContext(AuthContext);
    const [protects, setProtects] = useState([]);
    const [protectsPlans, setProtectsPlans] = useState([]);
    const [protectsExtra, setProtectsExtra] = useState([]);

    const fetchStore = async (col, setData) => {
        const query = collection(db, col);
        let arr = [];
        const querySnapshot = await getDocs(query);
        querySnapshot.forEach((doc) => {
            arr.push(doc.data());
        });
        setData(arr);
    };

    useEffect(() => {
        fetchStore("protection_plans", setProtectsPlans);
        fetchStore("protections", setProtects);
        fetchStore("protection_extras", setProtectsExtra);
    }, []);

    if (!loading) {
        <h1 style={{ padding: "20%" }}>Loading...</h1>;
    }

    if (loading) {
        return (
            <div className="pro_container">
                <div>
                    <div>
                        <h2>Choose your protection & Extra</h2>
                    </div>
                </div>
                <br />
                <br />
                <div>
                    <h3>Protection plans</h3>

                    <div className="p_plans">
                        {protectsPlans.map((p, i) => (
                            <Basic key={i} p={p} />
                        ))}
                    </div>
                </div>
                <br />
                <br />
                <div>
                    <h3>Protections</h3>
                    <div className="p_pro">
                        {protects.map((p, i) => (
                            <Wind key={i} p={p} />
                        ))}
                    </div>
                </div>
                <br />
                <br />
                <div>
                    <h3>Available extras</h3>
                    <div className="p_pro">
                        <Driver />
                        {protectsExtra.map((p, i) => (
                            <Baby1 key={i} p={p} />
                        ))}
                    </div>
                </div>
            </div>
        );
    }
};
export default Protection;
