import React from "react";
import "./footer.css";
import { Navlist } from "../navbar/navlist";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLocationDot,
  faMapLocationDot,
  faMobileScreen,
} from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <div className="footer_main">
      <div className="footer_d1">
        <div className="footer_d2">
          {/*========================== about ================================== */}
          <div className="about_d1">
            <div className="about_log_d">
              <img
                className="about_log"
                src={require("../R/Images/logo.png")}
                alt=""
              />
            </div>
            <p className="about_p1">
              Experience convenient and affordable transportation with our
              self-drive car rental services in Indore with Go
              <span className="introTitleRed"> With</span> Car and drive your
              way to your next destination with style and comfort with Go
              <span className="introTitleRed"> With</span> Car
            </p>
          </div>
          {/* quick links */}
          <div>
            <h3 className="f_m_header">Quick links</h3>
            <div>
              <ul className="FooterMenu">
                <li>
                  <a href="/" className="lnk-menu-footer">
                    Home
                  </a>
                </li>
                <li>
                  <a href="/about" className="lnk-menu-footer">
                    About
                  </a>
                </li>

                <li>
                  <a href="/contact" className="lnk-menu-footer">
                    Contact
                  </a>
                </li>
                <li>
                  <a href="/faq" className="lnk-menu-footer">
                    FAQs
                  </a>
                </li>
                <li>
                  <a href="/explorecars" className="lnk-menu-footer">
                    Explore Cars
                  </a>
                </li>
              </ul>
            </div>
          </div>

          {/*========================== Get In Touch ================================== */}
          <div>
            <h3>Get In Touch</h3>
            <div className="touch_d1">
              <FontAwesomeIcon
                icon={faMobileScreen}
                style={{
                  color: "#878787",
                  height: "17px",
                  width: "17px",
                }}
              />
              <a className="touch_a" href="tel:9926222099">
                +91-9926222099
              </a>
            </div>
            <div className="touch_d1">
              <FontAwesomeIcon
                icon={faEnvelope}
                style={{
                  color: "#878787",
                  height: "17px",
                  width: "17px",
                }}
              />
              <a className="touch_a" href="">
                info@gowithcar.com
              </a>
            </div>
            <div className="touch_d1">
              <FontAwesomeIcon
                icon={faLocationDot}
                style={{
                  color: "#878787",
                  height: "17px",
                  width: "17px",
                }}
              />
              <a className="touch_a" href="">
                Ring Road, near Jaguar, Land Rover,
                <br />
                Range Rover showroom, Nipania Square,
                <br />
                Indore, M.P. India 452010
              </a>
            </div>
          </div>
        </div>
        {/* keywords */}
        <div className="footer_middle">
          <div className="footer_m1_items">
            Rent A Car In Indore <br />
            Car Hire In Indore <br />
            Self Drive Car In Indore <br />
            Car Rentals In Indore
          </div>
          <div className="footer_m1_items">
            Cheap Car Hire In Indore <br />
            Monthly Car Rental Indore <br />
            Car On Rent Without Driver <br />
            Wedding Car Rental
          </div>
          <div className="footer_m1_items">
            Cheap Car Hire In Indore <br />
            Monthly Car Rental Indore <br />
            Car On Rent Without Driver <br />
            Wedding Car Rental
          </div>
          <div className="footer_m1_items">
            Rental Self Drive Car In Indore <br />
            Self-Drive Car For Omkareshwar <br />
            Self-Drive Car For Maheshwar <br />
            Self-Drive Car Rental For Jam Gate
          </div>
        </div>
        {/*========================== copyright and social ================================== */}
        <div className="bottom_f_d1">
          {/*========================== copyright ================================== */}
          <div className="bottom_1">
            <div>
              {" "}
              <p className="copyright">
                © Go
                <span className="introTitleRed">With</span>Car, 2023 - All
                Rights Reserved
              </p>
            </div>
            {/*========================== social ================================== */}
            <div className="icon_container">
              <div className="connected_cl">
                <a href="https://www.facebook.com/gowithcar.in" target="_blank">
                  <img className="icons" src="Icons/Facebook.png" alt="" />
                </a>
              </div>

              <div className="connected_cl">
                <a href="https://twitter.com/gowithcar" target="_blank">
                  <img className="icons" src="Icons/twitter.png" alt="" />
                </a>
              </div>
              <div className="connected_cl">
                <a
                  href="https://instagram.com/gowithcar.in?igshid=NTdlMDg3MTY="
                  target="_blank"
                >
                  <img className="icons" src="Icons/insta.webp" alt="" />
                </a>
              </div>
              <div className="connected_cl">
                <a
                  href="https://www.linkedin.com/company/gowithcar/"
                  target="_blank"
                >
                  <img className="icons" src="Icons/linkedin.png" alt="" />
                </a>
              </div>
              <div className="connected_cl">
                <a href="https://www.youtube.com/@gowithcar" target="_blank">
                  <img className="icons" src="Icons/youtube.png" alt="" />
                </a>
              </div>
              <div className="connected_cl">
                <a href="https://www.pinterest.com/Gowithcar/" target="_blank">
                  <img className="icons" src="Icons/pin.png" alt="" />
                </a>
              </div>
            </div>
            <div className="footericon_d1">
              <Link
                className="touch_tnc"
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
                to="/privacy"
              >
                Privacy Policy
              </Link>
              <Link
                className="touch_tnc"
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
                to="/cookie"
              >
                Cookie Policy
              </Link>
              <Link
                className="touch_tnc"
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
                to="/terms"
              >
                Terms of Use
              </Link>

              <Link
                className="touch_tnc"
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
                to="/cancel-policy"
              >
                Cancellation, Refund & Return Policy
              </Link>
            </div>
          </div>
          <div className="bottom_2">
            <p>
              By continuing past this page, you agree to our Terms of Service,
              Cookie Policy, Privacy Policy and Content Policies. All trademarks
              are properties of their respective owners. 2022-2023 © Go
              <span className="introTitleRed"> With</span> Car™. All rights
              reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
