import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../AuthProvider";
import { useNavigate } from "react-router-dom";
import {
    RecaptchaVerifier,
    getAuth,
    signInWithPhoneNumber,
} from "firebase/auth";
import { app } from "../../firebase-config";
import "./SinUp.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const SignUpM = () => {
    const { setNum, num } = useContext(AuthContext);
    const auth = getAuth(app);
    const [isFormValid, setIsFormValid] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    function onCaptchVerify() {
        if (!window.recaptchaVerifier) {
            window.recaptchaVerifier = new RecaptchaVerifier(
                "recaptcha-container",
                {
                    size: "invisible",
                    callback: (response) => {
                        onSignup();
                    },
                    "expired-callback": () => {},
                },
                auth,
            );
        }
    }

    function onSignup() {
        setLoading(true);
        onCaptchVerify();
        const appVerifier = window.recaptchaVerifier;
        const formatPh = "+" + num;
        signInWithPhoneNumber(auth, formatPh, appVerifier)
            .then((confirmationResult) => {
                window.confirmationResult = confirmationResult;
                setLoading(false);
                navigate("/signup-mobile-2");
            })
            .catch((error) => {
                setLoading(false);
            });
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        e.target.reset();
    };
    useEffect(() => {
        setIsFormValid(num !== "");
    }, [num]);

    return (
        <div className="login2-container">
            <h1 className="login2-header">Please Enter Mobile Number</h1>
            {/* <div id="recaptcha-container"></div> */}
            <form onSubmit={handleFormSubmit}>
                <div className="input2-box-o">
                    <div>
                        <PhoneInput
                            className="mob-in-log"
                            country={"in"}
                            value={num}
                            onChange={setNum}
                        />
                    </div>
                </div>

                <button
                    type="submit"
                    className={`proceed2-button ${
                        isFormValid ? "proceed2-buttonShadow" : "disabled"
                    }`}
                    disabled={!isFormValid}
                    onClick={() => onSignup()}
                >
                    {loading ? "Loading..." : "Request OTP"}
                </button>
            </form>
        </div>
    );
};

export default SignUpM;
