import React, { useState } from "react";
import "./chk.css";
import axios from "axios";
import { SHA256, enc } from "crypto-js";
import { BASE_URL } from "../../../Utils";
// import { Buffer } from "buffer";
window.Buffer = window.Buffer || require("buffer").Buffer;

const Checkout = () => {
  const [amount, setAmount] = useState(0);
  const [name, setName] = useState("");

  const SALT = {
    keyIndex: 1,
    key: "22dc44ff-2724-431e-8327-74f0d400019f",
  };

  const data = {
    name: "Jubaer",
    amount: 10,
    number: "7498608775",
    MUID: "MUID" + Date.now(),
  };

  const generateTransactionId = () => {
    const timestamp = Date.now();
    const random = Math.floor(Math.random() * 1000000);
    const merchantPrefix = "T";
    return `${merchantPrefix}${timestamp}${random}`;
  };

  const onCheckoutClick = async () => {
    try {
      await axios
        .post(`${BASE_URL}api/phonepe/payment`, {
          ...data,
        })
        .then((res) => {
          window.location.href = res.data;
        })
        .then((data) => {});
      //   const userData = {
      //     name: "Jubaer",
      //     amount: 1,
      //     number: "7498608775",
      //     MUID: "MUID" + Date.now(),
      //   };

      //   const merchantTransactionId = generateTransactionId();

      //   const data = {
      //     merchantId: "M1LGE5RGIOJO",
      //     merchantTransactionId: merchantTransactionId,
      //     merchantUserId: userData.MUID,
      //     amount: userData.amount * 100,
      //     redirectUrl: `http://localhost:3000/`,
      //     redirectMode: "POST",
      //     callbackUrl: `http://localhost:5001/api/phonepe/status/${merchantTransactionId}`,
      //     mobileNumber: userData.number,
      //     // name: name,
      //     paymentInstrument: {
      //       type: "PAY_PAGE",
      //     },
      //   };

      //   const payload = JSON.stringify(data);
      //   const mainPayload = Buffer.from(payload).toString("base64");
      //   const string = mainPayload + "/pg/v1/pay" + SALT.key;

      //   const sha256 = SHA256(string).toString(enc.Hex);

      //   //   const sha256 = crypto.createHash("sha256").update(string).digest("hex");
      //   const checksum = sha256 + "###" + SALT.keyIndex;
      //   const URL = "https://api.phonepe.com/apis/hermes/pg/v1/pay";
      //   //   const URL = "https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/pay";

      //   const options = {
      //     method: "POST",
      //     url: URL,
      //     headers: {
      //       accept: "application/json",
      //       "Content-Type": "application/json",
      //       "Access-Control-Allow-Origin": "*",
      //       // "Access-Control-Allow-Credentials": "true",
      //       //   "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
      //       // "Access-Control-Allow-Headers":
      //       //  "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
      //       //   "X-VERIFY": checksum,
      //     },
      //     data: { request: mainPayload },
      //   };

      //   axios
      //     .request(options)
      //     .then(function (response) {
      //     })
      //     .catch(function (error) {
      //       console.error("error", error);
      //     });
    } catch (error) {
      console.error(error); // NOTE - use "error.response.data` (not "error")
    }
  };

  return (
    <div className="chk_contain">
      <div id="contents">
        <h1>Phonepe mIntent</h1>
        <p>This merchant accepts Phonepe only.</p>
        <p>
          <button onClick={onCheckoutClick}>PhonePe Pay</button>
        </p>
      </div>
    </div>
  );
};

export default Checkout;
