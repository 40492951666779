import React from "react";
import "@lottiefiles/lottie-player";
import "./WhyGWT.css";

const WhyGWT = () => {
    // jubaer worked here
    // https://assets7.lottiefiles.com/packages/lf20_gdzapmjf.json
    // https://assets7.lottiefiles.com/packages/lf20_a3emlnqk.json

    return (
        <div className="whyContainer">
            <div className="whyGwtText">
                <h2 className="whyGwtHeader">
                    Why <span className="redHead">Go</span>
                    <span className="blackHead">with</span>
                    <span className="redHead">car.com</span>?
                </h2>
                <div className="whyGwtText">
                    {" "}
                    When you choose Go
                    <span className="introTitleRed"> With</span> Car, you can
                    enjoy: <br />
                    <br />
                    <ul className="whyGwtTextUl">
                        <li>
                            <b>Flexible Rentals – </b>You decide whether you’d
                            like to rent a car for an hour, a week, a month, or
                            on a daily basis.{" "}
                        </li>
                        <br />
                        <li>
                            <b>Several Options – </b>Pick from our wide range of
                            classy hatchbacks, comfortable sedans, and spacious
                            SUVs.
                        </li>
                        <br />
                        <li>
                            <b>24X7 Roadside assistance – </b>Never Get Stuck on
                            the Side of the Road: Enjoy Peace of Mind with 24/7
                            Roadside Assistance.{" "}
                        </li>
                        <br />
                        <li>
                            <b>All-India Permits – </b>GGowithcar.com cars come
                            with an All-India Tourist Permit so that you can
                            drive from Kashmir to{" "}
                        </li>
                        <br />
                        <li>
                            <b>Endless Opportunities – </b>The vehicles you rent
                            from Gowithcar.com can be used for trips out of the
                            city or daily travels through town. Gowithcar.com
                            also provides cars for pick-up and drop-off services
                            at Indore airports and Indore bus and train
                            stations.
                        </li>
                    </ul>
                </div>
            </div>
            <div>
                <lottie-player
                    className="whyAnimation"
                    autoplay
                    loop
                    mode="normal"
                    src="https://assets5.lottiefiles.com/packages/lf20_asjtnqce.json"
                ></lottie-player>
            </div>
        </div>
    );
};

export default WhyGWT;
