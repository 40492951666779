import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAwq6739F20DHL7M3YXm8GfiPc7iKqZQ9E",
  authDomain: "gowithcar-c2eec.firebaseapp.com",
  // authDomain: "auth.gowithcar.com",
  projectId: "gowithcar-c2eec",
  storageBucket: "gowithcar-c2eec.appspot.com",
  messagingSenderId: "861556170969",
  appId: "1:861556170969:web:f411db103ab74708099ec8",
};

// Initialize Firebase

export const app = initializeApp(firebaseConfig);