import React from "react";
import Book from "./Book";
import { useContext } from "react";
import { useState } from "react";
import { AuthContext } from "../../../../AuthProvider";
import {
    collection,
    doc,
    getDocs,
    query,
    updateDoc,
    where,
} from "firebase/firestore";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import moment from "moment/moment";

const UpcomingBooking = () => {
    const { db, user } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [book, setBook] = useState([]);
    const navigate = useNavigate();
    const [bookId, setBookId] = useState("");
    const [carName, setCarName] = useState("");
    const [showPopup, setShowPopup] = useState(false);
    const [showPopup2, setShowPopup2] = useState(false);
    const [userId, setUserId] = useState("");
    const [hostId, setHostId] = useState("");
    const [carId, setCarId] = useState("");
    const [adminId, setAdminId] = useState("");
    const [adminName, setAdminName] = useState("");
    const [fcmTokenHost, setFcmTokenHost] = useState("");
    const [fcmTokenUser, setFcmTokenUser] = useState("");
    const [selectedFilter, setSelectedFilter] = useState("all"); // Default filter: All bookings
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const fetchUser = async () => {
        if (user?.phoneNumber) {
            const q = query(
                collection(db, "users"),
                where("mobileNumber", "==", user.phoneNumber),
            );
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                setAdminName(doc.data().name);
                setAdminId(doc.data().id);
            });
        }
        if (user?.email) {
            const q = query(
                collection(db, "users"),
                where("email", "==", user.email),
            );
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                setAdminName(doc.data().name);
                setAdminId(doc.data().id);
            });
        }
    };

    useEffect(() => {
        fetchUser();
    }, [user]);

    const handleDateFilterChange = (filter) => {
        setSelectedFilter(filter);
        // fetchBooks();
    };

    const filterBookings = (bookings) => {
        const today = new Date();
        let filteredBookings = bookings;
        console.log("filteredBookings", filteredBookings)
        // if (selectedFilter === "last7days") {
        //     const last7Days = new Date(today);
        //     last7Days.setDate(today?.getDate() - 7);
        //     filteredBookings = bookings?.filter(
        //         (booking) => {
        //             console.log('booking', booking)
        //             return new Date(booking?.pickup_date).toDateString() >= last7Days
        //         },
        //     );
        // } else if (selectedFilter === "lastMonth") {
        //     let lastMonth = new Date(today);
        //     lastMonth.setMonth(today?.getMonth() - 1);
        //     filteredBookings = bookings?.filter(
        //         (booking) => { return new Date(booking?.pickup_date).toDateString() >= lastMonth},
        //     );
        // } else if (selectedFilter === "last3Months") {
        //     const last3Months = new Date(today);
        //     last3Months.setMonth(today?.getMonth() - 3);
        //     filteredBookings = bookings?.filter(
        //         (booking) =>  {return new Date(booking?.pickup_date).toDateString() >= last3Months},
        //     );
        // } else if (selectedFilter === "last6Months") {
        //     const last6Months = new Date(today);
        //     last6Months.setMonth(today?.getMonth() - 6);
        //     filteredBookings = bookings?.filter(
        //         (booking) =>  {return new Date(booking?.pickup_date).toDateString() >= last6Months},
        //     );
        // } else if (selectedFilter === "lastYear") {
        //     const lastYear = new Date(today);
        //     lastYear.setFullYear(today?.getFullYear() - 1);
        //     filteredBookings = bookings?.filter(
        //         (booking) =>  {return new Date(booking?.pickup_date).toDateString() >= lastYear},
        //     );
        // } else if (selectedFilter === "customDate") {
        //     if (startDate && endDate) {
        //         const start = new Date(startDate);
        //         const end = new Date(endDate);
        //         filteredBookings = bookings?.filter(
        //             (booking) =>
        //                 booking?.pickup_date?.toDate() >= start &&
        //                 booking?.pickup_date?.toDate() <= end,
        //         );
        //     }
        // } else {
        //     // No filter selected, return all bookings
        // }

        return filteredBookings;
    };

    const fetchBooks = async () => {
        const q = query(
            collection(db, "bookings"),
            where("status", "==", "booked"),
        );
        let arr = [];
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            arr.push(doc.data());
        });
        const filteredBookings = filterBookings(arr);
        setBook(filteredBookings);
        setLoading(false);
    };

    useEffect(() => {
        fetchBooks();
    }, [selectedFilter, endDate]);

    const handleShowAll = () => {
        fetchBooks();
    };

    const handleBookAll = async (Hint) => {
        setLoading(false);
        const q = query(
            collection(db, "bookings"),
            where("car_details.id", "==", Hint),
        );
        const querySnapshot = await getDocs(q);
        let arr = [];
        querySnapshot.forEach((doc) => {
            arr.push(doc.data());
        });
        setBook(arr);
        setLoading(false);
    };

    const fetchtokenUser = async () => {
        const q = query(collection(db, "users"), where("id", "==", userId));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            setFcmTokenUser(doc.data().fcmToken);
        });
    };

    const fetchtokenHost = async () => {
        const q = query(collection(db, "users"), where("id", "==", hostId));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            setFcmTokenHost(doc.data().fcmToken);
        });
    };

    const handleApprove = async () => {
        fetchtokenUser();
        fetchtokenHost();
        const docRef = doc(db, "bookings", bookId);
        await updateDoc(docRef, {
            status: "completed",
            extraKmCharges: extraKmCharges * 1,
            extraDriverCharges: extraDriverCharges * 1,
        }).then(() => { });
        const docRef2 = doc(db, "cars", carId);
        await updateDoc(docRef2, {
            bookingStatus: "available",
        }).then(() => {
            fetchBooks();
        });
        navigate("/admin-panel/bookings");
        setShowPopup(false);
    };

    const [extraKmCharges, setExtraKmCharges] = useState(0);
    const [extraDriverCharges, setExtraDriverCharges] = useState(0);

    const handleDelete = async () => {
        const docRef = doc(db, "bookings", bookId);
        await updateDoc(docRef, {
            deleted: true,
        }).then(() => {
            fetchBooks();
        });
        navigate("/admin-panel/bookings");
        setShowPopup2(false);
    };

    const [isBack, setIsBack] = useState(false);
    return (
        <div>
            {showPopup && (
                <div className="popup-screen">
                    <div className="popup">
                        <div className="popup_p">
                            <div>
                                <h2>Complete booking now?</h2>
                            </div>
                        </div>
                        <div className="popup_p-c">
                            <div>
                                <label htmlFor="">
                                    Extra Charges per KM (Optional)
                                </label>
                                <input
                                    className="popup-b-i-p"
                                    type="number"
                                    placeholder="Extra KM Charges"
                                    onChange={(e) =>
                                        setExtraKmCharges(e.target.value)
                                    }
                                />
                            </div>
                            <div>
                                <label htmlFor="">
                                    Extra Driver Charges (Optional)
                                </label>
                                <input
                                    className="popup-b-i-p"
                                    type="number"
                                    placeholder="Extra Driver Charges"
                                    onChange={(e) =>
                                        setExtraDriverCharges(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                        <div className="popup_b">
                            <button onClick={handleApprove}>Yes</button>
                            <button onClick={() => setShowPopup(false)}>
                                No
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {showPopup2 && (
                <div className="popup-screen">
                    <div className="popup">
                        <div className="popup_p">
                            <h2>Want to remove now?</h2>
                        </div>
                        <div className="popup_b">
                            <button onClick={handleDelete}>Yes</button>

                            <button onClick={() => setShowPopup2(false)}>
                                No
                            </button>
                        </div>
                    </div>
                </div>
            )}

            <div>
                <h1 className="bookHead">Upcoming Bookings</h1>
            </div>
            {/* <div className="date-filters-con">
                <div>
                    <div className="date-filters">
                        <button
                            className={selectedFilter === "all" ? "active" : ""}
                            onClick={() => handleDateFilterChange("all")}
                        >
                            All
                        </button>
                        <button
                            className={
                                selectedFilter === "last7days" ? "active" : ""
                            }
                            onClick={() => handleDateFilterChange("last7days")}
                        >
                            Last 7 Days
                        </button>
                        <button
                            className={
                                selectedFilter === "lastMonth" ? "active" : ""
                            }
                            onClick={() => handleDateFilterChange("lastMonth")}
                        >
                            Last Month
                        </button>
                        <button
                            className={
                                selectedFilter === "last3Months" ? "active" : ""
                            }
                            onClick={() =>
                                handleDateFilterChange("last3Months")
                            }
                        >
                            Last 3 Months
                        </button>
                        <button
                            className={
                                selectedFilter === "last6Months" ? "active" : ""
                            }
                            onClick={() =>
                                handleDateFilterChange("last6Months")
                            }
                        >
                            Last 6 Months
                        </button>
                        <button
                            className={
                                selectedFilter === "lastYear" ? "active" : ""
                            }
                            onClick={() => handleDateFilterChange("lastYear")}
                        >
                            Last 1 Year
                        </button>
                        <button
                            className={
                                selectedFilter === "customDate" ? "active" : ""
                            }
                            onClick={() => handleDateFilterChange("customDate")}
                        >
                            Custom Date
                        </button>
                    </div>
                    {selectedFilter === "customDate" && (
                        <div className="date-filters-con">
                            <div className="custom-date-range">
                                <label>Start Date:</label>
                                <input
                                    type="date"
                                    placeholder={startDate}
                                    value={startDate}
                                    onChange={(e) => {
                                        setStartDate(e.target.value);
                                    }}
                                />
                                <label>End Date:</label>
                                <input
                                    type="date"
                                    placeholder={endDate}
                                    value={endDate}
                                    onChange={(e) => {
                                        setEndDate(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div> */}

            <div className={`d_btn_bck_con ${isBack ? "" : "show_none"}`}>
                <button
                    className="d_btn_bck"
                    onClick={() => {
                        setIsBack(!isBack);
                        handleShowAll();
                    }}
                >
                    <FontAwesomeIcon icon={faArrowAltCircleLeft} /> Go Back{" "}
                </button>
            </div>
            <div>
                {loading ? (
                    <h1>Loading....</h1>
                ) : (
                    <div className="c_book_c">
                        {book.length === 0 && <h2>No bookings</h2>}
                        {book.map((c, i) => (
                            <Book
                                c={c}
                                handleBookAll={handleBookAll}
                                setBookId={setBookId}
                                setShowPopup={setShowPopup}
                                setShowPopup2={setShowPopup2}
                                setCarName={setCarName}
                                setUserId={setUserId}
                                setHostId={setHostId}
                                setCarId={setCarId}
                                key={i}
                            />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default UpcomingBooking;
