import React from "react";
import "./Carsearch.css";
import { useContext } from "react";
import { AuthContext } from "../../AuthProvider";
import { useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import CarRentalForm from "../RentCar/RentCar";
import SearchedCar from "./SearchedCar/SearchedCar";

const CarSearc = () => {
    const { showPopup, handlePopupButtonClick, db, clicked } =
        useContext(AuthContext);
    const [cars, setCars] = useState("");
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const passedData = location.state;

    const fetchCars = async (CarQuery) => {
        setLoading(true);
        const q = query(
            collection(db, "cars"),
            where("status", "==", "approved"),
            where("location", "==", CarQuery.pickupLocation),
            where("driverType", "==", CarQuery.driver),
        );

        let availableCars = [];
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            availableCars.push(doc.data());
        });
        setCars(availableCars);
        setLoading(false);
    };
    useEffect(() => {
        const carSearch = JSON.parse(localStorage.getItem("carSearch"));
        fetchCars(carSearch);
    }, []);
    return (
        <div className="CarSearchConataner">
            {showPopup && (
                <div className="popup-screen">
                    <div className="popup">
                        <div className="popup_p">
                            <h2>Want to book now?</h2>
                            <FontAwesomeIcon
                                icon={faXmark}
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                    handlePopupButtonClick("Button 2")
                                }
                            />
                        </div>
                        <div className="popup_b">
                            <a href="tel:9926222099">
                                <button
                                    onClick={() =>
                                        handlePopupButtonClick("Button 1")
                                    }
                                >
                                    Call now
                                </button>
                            </a>
                            <a href="https://wa.me/+919926222099">
                                <button
                                    onClick={() =>
                                        handlePopupButtonClick("Button 2")
                                    }
                                >
                                    Message on whatsapp
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            )}
            {/* <Top /> */}
            <div
                style={{ position: "relative" }}
                className={clicked === 1 ? "" : "content-hidden"}
            >
                <br />
                <br />
                {/* <br /> <br /> */}
                <CarRentalForm
                    passedData={passedData}
                    fetchCars={(CarQuery) => fetchCars(CarQuery)}
                />
                <br />
                <br />
                <br />
                <h1 className="sHeadCar">Available cars</h1>
                {loading ? (
                    <h1 style={{ textAlign: "center", padding: "20%" }}>
                        Loading....
                    </h1>
                ) : (
                    <SearchedCar cars={cars} />
                )}
            </div>
            <Outlet />
        </div>
    );
};

export default CarSearc;
