import { call, put, takeLatest } from 'redux-saga/effects';
import { MapApiRequest } from './MapApi';
import { MapActionType } from './MapAction';

// -------------*******------------- MAP YIELD -------------*******------------- \\
function* mapYield(action) {
  try {
    const res = yield call(MapApiRequest.mapRequest, action.payload);
    // console.log("datatattatattttttttt==========================================", res);
    if (res && res.data?.root?.VehicleData) {
      console.log('SUCCESS +++++++++++ mapYield', res);

      yield put({
        type: MapActionType.MAP_SUCCESS,
        payload: res.data?.root?.VehicleData,
      });
    } else {
      yield put({
        type: MapActionType.MAP_FAILURE,
        payload: res,
      });
    }
  } catch (error) {
    console.log('ERROR ****** mapYield ', error);
    yield put({
      type: MapActionType.MAP_FAILURE,
      payload: error,
    });
  }
}
export function* mapSaga() {
  yield takeLatest(MapActionType.MAP_REQUEST, mapYield);
}