import React from "react";
import "./IcBar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowLeftRotate,
    faArrowRightRotate,
    faBell,
    faBook,
    faCar,
    faChartLine,
    faContactBook,
    faDashboard,
    faGift,
    faMapLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../../AuthProvider";

const IcBar = () => {
    const { panelClicked, handlePanelActive } = useContext(AuthContext);
    return (
        <div className="ic_Container">
            <Link
                onClick={() => handlePanelActive(1)}
                className={panelClicked === 1 ? "icons_c1_Active" : "icons_c1"}
                to={"/admin-panel/dashboard"}
            >
                <FontAwesomeIcon className="icon_ap" icon={faDashboard} />
                <h4>Dashboard</h4>
            </Link>
            <Link
                onClick={() => handlePanelActive(2)}
                className={panelClicked === 2 ? "icons_c1_Active" : "icons_c1"}
                to={"/admin-panel/carlist/approved"}
            >
                <FontAwesomeIcon className="icon_ap" icon={faCar} />
                <h4>Cars List</h4>
            </Link>
            <Link
                to={"/admin-panel/hosts"}
                state={{type:"host"}}
                onClick={() => handlePanelActive(3)}
                className={panelClicked === 3 ? "icons_c1_Active" : "icons_c1"}
            >
                <FontAwesomeIcon className="icon_ap" icon={faContactBook} />
                <h4>Hosts Profiles</h4>
            </Link>
            <Link
                to={"/admin-panel/hosts"}
            state={{type:"user"}}
                
                onClick={() => handlePanelActive(9)}
                className={panelClicked === 9 ? "icons_c1_Active" : "icons_c1"}
            >
                <FontAwesomeIcon className="icon_ap" icon={faContactBook} />
                <h4>User Profiles</h4>
            </Link>
            <Link
                to={"/admin-panel/bookings/current"}
                onClick={() => handlePanelActive(4)}
                className={panelClicked === 4 ? "icons_c1_Active" : "icons_c1"}
            >
                <FontAwesomeIcon className="icon_ap" icon={faBook} />
                <h4>Bookings</h4>
            </Link>
            <Link
                to={"/admin-panel/offers"}
                onClick={() => handlePanelActive(5)}
                className={panelClicked === 5 ? "icons_c1_Active" : "icons_c1"}
            >
                <FontAwesomeIcon className="icon_ap" icon={faGift} />
                <h4>Offers</h4>
            </Link>
            <Link
                to={"/admin-panel/city-list"}
                onClick={() => handlePanelActive(6)}
                className={panelClicked === 6 ? "icons_c1_Active" : "icons_c1"}
            >
                <FontAwesomeIcon className="icon_ap" icon={faMapLocationDot} />
                <h4>City List</h4>
            </Link>
            <Link
                to={"/admin-panel/revenue"}
                onClick={() => handlePanelActive(7)}
                className={panelClicked === 7 ? "icons_c1_Active" : "icons_c1"}
            >
                <FontAwesomeIcon className="icon_ap" icon={faChartLine} />
                <h4>Revenue</h4>
            </Link>
            <Link
                to={"/admin-panel/notifications"}
                onClick={() => handlePanelActive(8)}
                className={panelClicked === 8 ? "icons_c1_Active" : "icons_c1"}
            >
                <FontAwesomeIcon className="icon_ap" icon={faBell} />
                <h4>Notifications</h4>
            </Link>
            <Link
                to={"/admin-panel/refund-status"}
                onClick={() => handlePanelActive(10)}
                className={panelClicked === 10 ? "icons_c1_Active" : "icons_c1"}
            >
                <FontAwesomeIcon className="icon_ap" icon={faArrowRightRotate} />
                <h4>Refund Status</h4>
            </Link>
        </div>
    );
};

export default IcBar;
