import { call, put, takeLatest } from 'redux-saga/effects';
import { UserActionType } from './UserAction';

// -------------*******------------- SET USER DATA YIELD -------------*******------------- \\
function* setUserDataYield(action) {
  try {
    yield put({
      type: UserActionType.SET_USER_DATA_SUCCESS,
      payload: action.payload,
    });
  } catch (error) {
    console.log('ERROR ****** setUserDataYield ', error);
    yield put({
      type: UserActionType.SET_USER_DATA_FAILURE,
      payload: error,
    });
  }
}
export function* setUserDataSaga() {
  yield takeLatest(UserActionType.SET_USER_DATA_REQUEST, setUserDataYield);
}

function* setBookingDetailsYield(action) {
  try {
    yield put({
      type: UserActionType.SET_BOOKING_DETAILS_SUCCESS,
      payload: action.payload,
    });
  } catch (error) {
    console.log('ERROR ****** setBookingDetailsYield ', error);
    yield put({
      type: UserActionType.SET_BOOKING_DETAILS_FAILURE,
      payload: error,
    });
  }
}
export function* setBookingDetailsSaga() {
  yield takeLatest(UserActionType.SET_BOOKING_DETAILS_REQUEST, setBookingDetailsYield);
}