export const MapActionType = {
  MAP_REQUEST: "MAP_REQUEST",
  MAP_SUCCESS: "MAP_SUCCESS",
  MAP_FAILURE: "MAP_FAILURE",
};

const MapAction = (payload) => {
  return { type: MapActionType.MAP_REQUEST, payload };
};

export {
    MapAction
};
