import React from "react";
import "./BlogDetails.css";
import { useContext } from "react";
import { AuthContext } from "../../AuthProvider";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useState } from "react";
import { useEffect } from "react";

const BlogDetails = () => {
    const [blog, SetBlog] = useState(null);
    const [loading, setLoading] = useState(true);
    const { db } = useContext(AuthContext);
    const blogId = localStorage.getItem("blogId");

    async function fetchMyAPI(blogId) {
        const q = query(collection(db, "blog"), where("id", "==", blogId));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            SetBlog(doc.data());
        });
        setLoading(false);
    }
    useEffect(() => {
        setLoading(true);
        fetchMyAPI(blogId);
    }, [blogId]);
    const stringHtml = (
        <div dangerouslySetInnerHTML={{ __html: blog?.content }} />
    );

    if (loading) {
        return (
            <div style={{ padding: "20%" }}>
                <h1>Loading...</h1>
            </div>
        );
    }

    if (!loading) {
        return (
            <div>
                <div className="blogDtlsContainer">
                    <img className="blogCover" src={blog?.thumbnail} alt="" />
                    <h1 className="blogTitle">{blog?.title}</h1>
                    <p className="blogName">
                        <b>{blog?.writer}</b>
                    </p>
                    <p className="blogDate">{blog?.createdAt}</p>
                    <p className="blogText">{stringHtml}</p>
                </div>
            </div>
        );
    }
};

export default BlogDetails;
