import { Col, Skeleton } from "antd";
import React from "react";

const ShimLoad = () => {
    return (
        <Col lg={8}>
            <div className="shimmerCard">
                {/* main box start */}
                <Skeleton.Input
                    style={{
                        width: "100%",
                        height: "200px",
                        marginBottom: "10px",
                    }}
                    active
                />
                <Skeleton.Input
                    style={{
                        width: "100%",
                        height: "30px",
                        marginBottom: "10px",
                    }}
                    active
                />
                <Skeleton.Input
                    style={{
                        width: "100%",
                        height: "30px",
                        marginBottom: "10px",
                    }}
                    active
                />
            </div>
        </Col>
    );
};

export default ShimLoad;
