import React from "react";
import "./book.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGasPump,
  faGear,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import { Timestamp } from "firebase/firestore";

const Book = ({
  c,
  setBookId,
  setShowPopup,
  setShowPopup2,
  handleBookAll,
  setCarName,
  setUserId,
  setHostId,
  setCarId,
}) => {
  console.log(c?.status)
  const return_date = new Timestamp(
    c?.return_date?.seconds,
    c?.return_date?.nanoseconds
  ).toDate();
  const pickup_date = new Timestamp(
    c?.pickup_date?.seconds,
    c?.pickup_date?.nanoseconds
  ).toDate();
  return (
    <div className="book_con">
      <div className="In_c_c">
        <div className="In_c_c_Info">
          <small>{c?.car_details?.vehiclesBrands}</small>
          <h4>{c?.car_details?.vehiclesModalName}</h4>
          <div className="In_c_c_Info_2">
            <div className="In_c_c_Info_3">
              <FontAwesomeIcon
                icon={faGasPump}
                style={{
                  height: "15px",
                  width: "15px",
                  paddingRight: "5px",
                  color: "	 #ff8080",
                }}
              />
              <p>{c?.car_details?.fuelType}</p>
            </div>
            <div className="In_c_c_Info_3">
              <FontAwesomeIcon
                icon={faUserGroup}
                style={{
                  height: "15px",
                  width: "15px",
                  paddingRight: "5px",
                  color: "	 #ff8080",
                }}
              />
              <p>{c?.car_details?.seatingCapacity}</p>
            </div>
            <div className="In_c_c_Info_3">
              <FontAwesomeIcon
                icon={faGear}
                style={{
                  height: "15px",
                  width: "15px",
                  paddingRight: "5px",
                  color: "	 #ff8080",
                }}
              />
              <p>{c?.car_details?.transmissionType}</p>
            </div>
          </div>
        </div>
        <div>
          <img
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleBookAll(c?.car_details?.id);
            }}
            className="In_c_pic"
            src={c?.car_details?.thumbnail}
            alt=""
          />
        </div>
      </div>
      <div className="book_con_2">
        <div className="book_con_3">
          <h4>Passenger Details</h4>
          <div className="book_con_3_in">
            <div>Name : {c?.passenger_details?.name}</div>
            {/* <div>Adress : {c?.passenger_details?.address}</div> */}
            <div>Email : {c?.passenger_details?.email}</div>
            <div>Driving License : {c?.passenger_details?.drivingLicence}</div>
          </div>
        </div>
        <div className="book_con_3">
          <h4>Host Details</h4>
          <div className="book_con_3_in">
            <div>Name : {c?.host_details?.name}</div>
            <div>Email :{c?.host_details?.email} </div>
            <div>Mobile : {c?.host_details?.phoneNumber} </div>
          </div>
        </div>
        <div className="book_con_3">
          <h4>Ride Details</h4>
          <div className="book_con_3_in">
            <div>Status : {c?.status}</div>
            <div>Payment Status : {c?.paymentDetails?.state}</div>
            <div>Payment method : {c?.payment_mode}</div>
            <div>Car reg number : {c?.car_details?.id}</div>
            <div>Navigation : {c?.cityName}</div>
            <div>Duration : {c?.duration}</div>
            <div>Pick up date : {pickup_date?.toLocaleString()}</div>
            <div>Return date : {return_date?.toLocaleString()}</div>
          </div>
        </div>
       {c?.status==="completed"?null: <div className="book_con_4">
          <button
            onClick={() => {
              setBookId(c.id);
              setShowPopup(true);
              setCarName(c?.car_details?.vehiclesModalName);
              setCarId(c?.car_details?.id);
              setUserId(c?.passenger_details?.id);
              setHostId(c?.host_details?.id);
            }}
            className="btn_book_ad"
          >
            complete the booking
          </button>
          <button
            onClick={() => {
              setBookId(c.id);
              setShowPopup2(true);
              setCarName(c?.car_details?.vehiclesModalName);
              setUserId(c?.passenger_details?.id);
              setCarId(c?.car_details?.id);
              setHostId(c?.host_details?.id);
            }}
            className="btn_book_ad"
          >
            remove the booking
          </button>
        </div>}
      </div>
    </div>
  );
};

export default Book;
