import React from "react";
import faqs from "../../../../FAQ/faqs";
import "./Faq.css";
import { useState } from "react";

const FaqH = () => {
	const [isActive, setIsActive] = useState(null);

	const handleSlide = (i) => {
		if (isActive === i) {
			return setIsActive(null);
		}
		setIsActive(i);
	};
	return (
		<div className='faq_contain_h'>
			<h2>Certainly asked question on rental car service</h2>
			{faqs.slice(0, 3).map((f, i) => (
				<div key={i} className='accordion-item'>
					<div className='accordion-title' onClick={() => handleSlide(i)}>
						<div>
							<b>{f.name}</b>
						</div>
						<div>
							<b>{isActive === i ? "-" : "+"}</b>
						</div>
					</div>
					<div
						className={
							isActive === i
								? "accordion-content-open"
								: "accordion-content-close"
						}
					>
						{f.details}
					</div>
				</div>
			))}
		</div>
	);
};

export default FaqH;
