import React, { useContext } from "react";
import "./SCar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCar,
    faGasPump,
    faGear,
    faRobot,
    faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import { Col } from "antd";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../../AuthProvider";

const SCar = ({ item }) => {
    const { setBookedCarID, user } = useContext(AuthContext);
    const navigate = useNavigate();
    const handleClick = () => {
        if (user) {
            setBookedCarID(item?.id);
            window.scrollTo(0, 0);
            navigate(`/book/${item.vehiclesModalName}`);
        } else {
            navigate(`/login-1`);
            window.scrollTo(0, 0);
        }
    };
    return (
        <Col lg={8}>
            <div className="fc_map_main">
                {/* main box start */}
                <div className="fc_d3">
                    <div className="fc_img_d1">
                        <img
                            className="fc_img1"
                            src={item.thumbnail}
                            alt={item?.vehiclesModalName
                                .toLowerCase()
                                .replace(/\s/g, "-")}
                        />
                    </div>
                    <div className="fc_d4">
                        <h4 className="fc_h2">{item.vehiclesModalName}</h4>
                        <div className="fc_d5">
                            <div className="fc_d9">
                                <FontAwesomeIcon
                                    icon={faGasPump}
                                    style={{
                                        height: "15px",
                                        width: "15px",
                                        paddingRight: "5px",
                                        color: "	 #ff8080",
                                    }}
                                />
                                <span className="fc_span1">
                                    {item.fuelType}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="fc_d6">
                        <div className="fc_d7">
                            <div className="fc_d8">
                                <FontAwesomeIcon
                                    icon={faUserGroup}
                                    style={{
                                        height: "15px",
                                        width: "15px",
                                        paddingRight: "5px",
                                        color: "#ff8080",
                                    }}
                                />
                                <span className="fc_span1">
                                    {item.seatingCapacity}
                                </span>{" "}
                                seater
                            </div>
                            <div className="fc_d9">
                                <FontAwesomeIcon
                                    icon={faGear}
                                    style={{
                                        height: "15px",
                                        width: "15px",
                                        paddingRight: "5px",
                                        color: "	 #ff8080",
                                    }}
                                />
                                <span className="fc_span1">
                                    {item.transmissionType}
                                </span>
                            </div>
                        </div>
                        <div className="fc_d7">
                            <div className="fc_d8">
                                <FontAwesomeIcon
                                    icon={faCar}
                                    style={{
                                        height: "15px",
                                        width: "15px",
                                        paddingRight: "5px",
                                        color: "	 #ff8080",
                                    }}
                                />
                                <span className="fc_span1">{item.mileage}</span>{" "}
                                km / ltr
                            </div>
                            <div className="fc_d9">
                                <FontAwesomeIcon
                                    icon={faRobot}
                                    style={{
                                        height: "15px",
                                        width: "15px",
                                        paddingRight: "5px",
                                        color: "	 #ff8080",
                                    }}
                                />
                                <span className="fc_span1">
                                    Self-Drive & Without Fuel
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="fc_d10">
                        <div>
                            <div>
                                ₹{" "}
                                <span className="fc_span2">
                                    {item.basePrice}
                                </span>{" "}
                                / {item?.price_unit ? item?.price_unit : "hr"}
                            </div>
                            <div>Extra fare - ₹10/KM</div>
                        </div>
                        <div>
                            <button
                                className={
                                    item?.bookingStatus === "booked"
                                        ? "fc_btn1_dis"
                                        : "fc_btn1"
                                }
                                type="submit"
                                onClick={handleClick}
                                disabled={
                                    item?.bookingStatus === "booked"
                                        ? true
                                        : false
                                }
                            >
                                {item?.bookingStatus === "booked"
                                    ? "Not available"
                                    : "Book now"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Col>
    );
};

export default SCar;
