import React from "react";
import "./points.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faClock,
    faHeart,
    faIdCard,
    faMoneyBill1,
} from "@fortawesome/free-solid-svg-icons";

const Points = () => {
    const renderNote = (title, desc, icon) => {
        return (
            <div className="point_box">
                <div style={{ display: "flex", flex: 1, alignItems: "center" }}>
                    <div className="point_box_d1">{icon}</div>
                    <div
                        style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "row",
                        }}
                    >
                        <div className="point_title">{title}</div>
                        <div className="point_desc">{": " + desc}</div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="point_main">
            {/*============================================== heading ================================== */}
            <div id="pointtact" className="point_h_d1">
                <h2 className="point_h_h1">
                    IMPORTANT POINTS TO BOOK <br /> A CAR WITH GO
                    <span className="introTitleRed"> WITH</span> CAR
                </h2>
            </div>

            <div className="point_de_d1">
                <div className="point_de_d2">
                    {renderNote(
                        "Document required",
                        "Original Driving License & Aadhar Card",
                        <FontAwesomeIcon aria-hidden="true" icon={faIdCard} />,
                    )}
                    {renderNote(
                        "Minimum age",
                        "21 years",
                        <FontAwesomeIcon aria-hidden="true" icon={faHeart} />,
                    )}
                    {renderNote(
                        "Security deposit",
                        "₹2000",
                        <FontAwesomeIcon
                            aria-hidden="true"
                            icon={faMoneyBill1}
                        />,
                    )}
                    {renderNote(
                        "Minimum Booking hours",
                        "8 Hours",
                        <FontAwesomeIcon aria-hidden="true" icon={faClock} />,
                    )}
                </div>
            </div>
        </div>
    );
};

export default Points;
