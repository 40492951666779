import React from "react";
import "./contact.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCar,
    faHandshakeSimple,
    faUserCheck,
    faUserPlus,
} from "@fortawesome/free-solid-svg-icons";

const Contact = () => {
    return (
        <div className="con_main">
            {/*============================================== heading ================================== */}
            <div id="contact" className="con_h_d1">
                <h2 className="con_h_h1">Get started with 4 simple steps</h2>
            </div>

            {/*============================================== delling steps ================================== */}
            <div className="con_de_d1">
                <div className="con_de_d2">
                    {/*============================================== steps 1 ================================== */}
                    <div className="con_box">
                        <div className="con_box_d1">
                            <FontAwesomeIcon icon={faUserPlus} />
                        </div>
                        <div>
                            <p>
                                Contact to Go
                                <span className="introTitleRed"> With</span> Car
                            </p>
                        </div>
                    </div>
                    {/*============================================== steps 2 ================================== */}
                    <div className="con_box">
                        <div className="con_box_d2">
                            <FontAwesomeIcon icon={faCar} />
                        </div>
                        <div>
                            <p>Tell us what car you want</p>
                        </div>
                    </div>
                    {/*============================================== steps 3 ================================== */}
                    <div className="con_box">
                        <div className="con_box_d3">
                            <FontAwesomeIcon icon={faUserCheck} />
                        </div>
                        <div>
                            <p>Match With Host</p>
                        </div>
                    </div>
                    {/*============================================== steps 4 ================================== */}
                    <div className="con_box">
                        <div className="con_box_d4">
                            <FontAwesomeIcon icon={faHandshakeSimple} />
                        </div>
                        <div>
                            <p>Make a deal</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;
