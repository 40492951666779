import React from "react";
import "./CarList.css";
import { useContext } from "react";
import { useState } from "react";
import {
    collection,
    doc,
    getDocs,
    query,
    updateDoc,
    where,
} from "firebase/firestore";
import { useEffect } from "react";
import { Col, Row } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCar,
    faGasPump,
    faGear,
    faRobot,
    faSearch,
    faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "../../../AuthProvider";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Box, Button } from "@mui/material";

const DeclinedCars = () => {
    const { db } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [cars, setCars] = useState(false);
    const [carId, setCarId] = useState("");
    const [selectedFilter, setSelectedFilter] = useState("ALL");
    const fetchCar = async (type) => {
        if (type !== "ALL") {
            const q = query(collection(db, "cars"), where("status", "==", "rejected"), where("driverType", "==", type));
            let arr = [];
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                arr.push(doc.data());
            });
            setCars(arr);
            setLoading(true);
        } else {
            const q = query(collection(db, "cars"), where("status", "==", "rejected"));
            let arr = [];
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                arr.push(doc.data());
            });
            setCars(arr);
            setLoading(true);
        }


    };



    useEffect(() => {
        fetchCar('ALL');
    }, []);
    console.log("cars", cars)
    return (
        <div className="ad_con_c">
            {!loading ? (
                <h1>Loading....</h1>
            ) : (
                <>
                    <Box sx={{ alignItems: 'center', justifyContent: 'center', alignSelf: "auto" }}>
                        <Box>
                            <h1 className="bookHead">Declined Cars</h1>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-evenly', flexDirection: 'row', width: '90%', alignItems: 'center', ml: 9 }}>

                            <Button onClick={() => {
                                fetchCar('ALL')
                                setSelectedFilter('ALL')
                            }} sx={{ width: 150 }} variant={selectedFilter == 'ALL' ? "contained" : "outlined"} size="small">
                                All
                            </Button>
                            <Button onClick={() => {
                                fetchCar('Tempo Traveller')
                                setSelectedFilter('Tempo Traveller')
                            }} sx={{ width: 150 }} variant={selectedFilter == 'Tempo Traveller' ? "contained" : "outlined"} size="small">
                                Tempo Treveller
                            </Button>
                            <Button onClick={() => {
                                fetchCar('Self Drive')
                                setSelectedFilter('Self Drive')
                            }} sx={{ width: 150 }} variant={selectedFilter == 'Self Drive' ? "contained" : "outlined"} size="small">
                                Self Drive
                            </Button>
                            <Button onClick={() => {
                                fetchCar('With Driver')
                                setSelectedFilter('With Driver')
                            }} sx={{ width: 150 }} variant={selectedFilter == 'With Driver' ? "contained" : "outlined"} size="small">
                                With Driver
                            </Button>
                        </Box>

                    </Box>
                    {cars.length > 0  ? (
                        <Row
                            style={{
                                justifyContent: "center",
                                width: "100%",
                                height: "100%",
                            }}
                            gutter={30}
                        >
                            {cars
                                ?.sort((item, index) =>
                                    item.bookingStatus === "booked" ? 1 : -1,
                                )
                                .map((c, i) => (
                                    <Col key={i} lg={8}>
                                        <Link
                                            onClick={() => {
                                                setLoading(false);
                                                localStorage.setItem(
                                                    "carId",
                                                    c.id,
                                                );
                                                window.scrollTo(0, 0);
                                            }}
                                            to={"/admin-panel/car-details"}
                                            className="adc_map_main"
                                        >
                                            {/* main box start */}
                                            <div className="adc_d3">
                                                <div className="adc_img_d1">
                                                    <img
                                                        className="adc_img1"
                                                        src={c.thumbnail}
                                                        alt={
                                                            c.vehiclesModalName
                                                        }
                                                    />
                                                </div>
                                                <div className="adc_d4">
                                                    <h4 className="adc_h2">
                                                        {c.vehiclesModalName}
                                                    </h4>
                                                    <div className="adc_d5">
                                                        <div className="adc_d9">
                                                            <FontAwesomeIcon
                                                                icon={faGasPump}
                                                                style={{
                                                                    height: "15px",
                                                                    width: "15px",
                                                                    paddingRight:
                                                                        "5px",
                                                                    color: "	 #ff8080",
                                                                }}
                                                            />
                                                            <span className="adc_span1">
                                                                {c.fuelType}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="adc_d6">
                                                    <div className="adc_d7">
                                                        <div className="adc_d8">
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faUserGroup
                                                                }
                                                                style={{
                                                                    height: "15px",
                                                                    width: "15px",
                                                                    paddingRight:
                                                                        "5px",
                                                                    color: "#ff8080",
                                                                }}
                                                            />
                                                            <span className="adc_span1">
                                                                {
                                                                    c.seatingCapacity
                                                                }
                                                            </span>{" "}
                                                            seater
                                                        </div>
                                                        <div className="adc_d9">
                                                            <FontAwesomeIcon
                                                                icon={faGear}
                                                                style={{
                                                                    height: "15px",
                                                                    width: "15px",
                                                                    paddingRight:
                                                                        "5px",
                                                                    color: "	 #ff8080",
                                                                }}
                                                            />
                                                            <span className="adc_span1">
                                                                {c.transmissionType
                                                                    ? c.transmissionType
                                                                    : "Manual"}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="adc_d7">
                                                        <div className="adc_d8">
                                                            <FontAwesomeIcon
                                                                icon={faCar}
                                                                style={{
                                                                    height: "15px",
                                                                    width: "15px",
                                                                    paddingRight:
                                                                        "5px",
                                                                    color: "	 #ff8080",
                                                                }}
                                                            />
                                                            <span className="adc_span1">
                                                                {c.mileage}
                                                            </span>{" "}
                                                            km / ltr
                                                        </div>
                                                        <div className="adc_d9">
                                                            <FontAwesomeIcon
                                                                icon={faRobot}
                                                                style={{
                                                                    height: "15px",
                                                                    width: "15px",
                                                                    paddingRight:
                                                                        "5px",
                                                                    color: "	 #ff8080",
                                                                }}
                                                            />
                                                            <span className="adc_span1">
                                                                Self-Drive &
                                                                Without Fuel
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="adc_d10">
                                                    <div>
                                                        ₹{" "}
                                                        <span className="adc_span2">
                                                            {c.minPrice}
                                                        </span>{" "}
                                                        /{" "}
                                                        {c.minPrice
                                                            ? c.minPrice
                                                            : "hr"}
                                                    </div>
                                                </div>
                                                {/* {c.pricing && (
                            <div>
                                <PriceTable pricing={c.pricing} />
                            </div>
                        )} */}
                                            </div>
                                            {/* main box end*/}
                                        </Link>
                                    </Col>
                                ))}
                        </Row>
                    ) : (
                        <h1 style={{
                            justifyContent: 'center',
                            display: 'flex',
                            marginTop: '10%'
                        }}>{`No Cars available`}</h1>
                    )}
                </>
            )}
        </div>
    );
};

export default DeclinedCars;
