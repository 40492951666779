import React, { useContext } from "react";
import "./Admin.css";
import IcBar from "./IconsBar/IcBar";
import { Outlet } from "react-router-dom";
import { AuthContext } from "../../AuthProvider";

const AdminPanel = () => {
    const { isAdmin } = useContext(AuthContext);

    return (
        <div>
            {isAdmin ? (
                <div className="ad_p_container">
                    <div className="ad_comp_3">
                        <IcBar />
                    </div>
                    <div className="ad_comp_1">
                        <Outlet />
                    </div>
                </div>
            ) : (
                <div className="ad_p_container_f">
                    <h2>Please login with admin account</h2>
                </div>
            )}
        </div>
    );
};

export default AdminPanel;
