import React from "react";
import "./Lease.css";

const Lease = () => {
    const tableStyle = {
        borderCollapse: "collapse", // Merge borders between cells
        border: "1px solid black", // Add a border around the table
    };

    const cellStyle = {
        border: "1px solid black", // Add a border to table cells
        padding: "8px", // Add padding for cell content
        textAlign: "center", // Center-align cell content
    };
    return (
        <div className="Tcontainer">
            <div className="Theader">
                <h2>
                    Lease Agreement- Go<span className="redHead">with</span>
                    car.com Host and Renter{" "}
                </h2>
            </div>
            <br />
            <section>
                <p>
                    This Vehicle Lease Agreement is made and effective from the
                    date of booking as reflected in the booking details on the
                    App or on offline booking documents (the “ Agreement ”)
                    BETWEEN The “ Lessor ” which expression shall, unless
                    repugnant to the context or meaning thereof, be deemed to
                    mean and include his/her heirs, executors, administrators
                    and assigns) of the ONE PART and details of which shall be
                    provided in the App at the time of booking. AND The “ Lessee
                    ” (which expression shall, unless repugnant to the context
                    or meaning thereof, be deemed to mean and include his/her
                    heirs, executors, administrators, and assigns) of the OTHER
                    PART as shall be captured in the booking details ; The
                    Lessor and the Lessee shall be hereinafter collectively
                    referred to as “ Parties ” and individually as the “ Party
                    ”.
                </p>
                <br />
                <br />
                <p className="header2">WHEREAS:</p> <br />
                <br />
                - The Lessor is the sole legal, beneficial, and registered owner
                of the Vehicle(details of which are captured in the App). - The
                Lessor had listed the Vehicle for leasing on the Platform
                (defined below) subject to the terms and conditions specified
                therein. - Lessee, a user of the Platform, wishes to lease the
                Vehicle from the Lessor and the Lessor is willing to lease it to
                the Lessee, on the terms and conditions of this Agreement.
                <br /> <br />
                <br />
                <p className="header2">
                    NOW THEREFORE IT IS AGREED as follows:
                </p>{" "}
                <br />
                <br />
                <p className="header2">1. Definition and Interpretation</p>
                In this Agreement, unless the context otherwise requires, the
                following expressions have the following meanings: “ Applicable
                Laws” means all applicable Indian statutes, enactments, acts of
                the state legislature or parliament, laws, ordinances, rules,
                bye-laws, regulations, notifications, guidelines, policies,
                directions, directives and orders of any governmental authority,
                statutory authority, tribunal, board, court, as may be
                applicable. Without prejudice to the generality of the
                foregoing, Applicable Laws shall include laws, regulations,
                rules and/or ordinances of lawfully constituted authorities
                relating to the possession, use, storage and transport of the
                Vehicle, including the MVA. “ Motor Vehicles Act ” or “ MVA ”
                means Motor Vehicles Act, 1988, rules and regulations prescribed
                thereunder, as now enacted or as the same may from time to time
                be amended, replaced or re-enacted; “ Platform ” means website
                and mobile application by the name and style of Go
                <span className="redHead">with</span>car.com Host Services
                available on the website of www.Go
                <span className="redHead">with</span>car.com , owned and
                operated by Go<span className="redHead">with</span>car.com which
                includes its marketplace feature/section that provides a
                platform for connecting vehicle owners with persons interested
                in leasing vehicles for personal use. “ Vehicle ” means the
                vehicle together with all its components, accessories and
                devices as described in the booking details in the App “
                Permitted Territory ” shall mean the entire territory of India,
                excluding the Union Ladakh and/or any travel to connected
                countries including but not limited to Nepal, Bhutan ,
                Bangladesh or Pakistan etc. In this Agreement, unless the
                context otherwise requires: - Headings used are for convenience
                and ease of reference only, are not part of this Agreement and
                shall not be relevant to or affect the meaning or interpretation
                of this Agreement. - Words denoted in any gender shall include
                all genders and terms denoting people or persons shall include
                both natural and legal persons (such as company) and vice versa;
                - The terms of this Agreement shall be deemed to be binding on
                both Parties based on their respective conduct notwithstanding
                any error or defect in the execution of this Agreement; and - A
                reference to a clause or schedule is a reference to a clause of
                or schedule to this Agreement and a reference to this Agreement
                includes any of its schedules. All schedules to this Agreement
                and the terms thereof shall be an integral part of this
                Agreement. <br />
                <br />
                <p className="header2">2. Agreement to Lease</p>- Based on the
                covenants, assurances and representations of the Lessee recorded
                herein, the Lessor hereby grants on lease and Lessee hereby
                takes on lease the Vehicle, subject to the terms and conditions
                of this Agreement. - The Lessee expressly understands and agrees
                that this Agreement is a lease only and all along the absolute
                and unencumbered ownership of the Vehicle for all intent and
                purposes, including for regulatory requirement under the MVA,
                shall remain with the Lessor. Nothing stated herein shall be
                construed to be a transfer to the Lessee of any ownership right,
                title or interest in the Vehicle, or even a right to the Lessee
                to demand such transfer. It is hereby clarified that during the
                Lease Term, the Lessee shall have the beneficial possession of
                the Vehicle as a Lessee pursuant to this Agreement. - During the
                Lease Term, the Lessee shall hold the Vehicle as as a trustee of
                the Lessor and shall, at its expense, protect and defend the
                title of Lessor in the Vehicle against creditors of or any
                person(s) claiming through Lessee. <br />
                <br />
                <p className="header2">3. Terms of Vehicle Usage</p>- The Lessee
                hereby agrees and covenants that the Vehicle shall be used in a
                prudent and careful manner solely for the Lessee’s personal use
                within the Permitted Territory and to that extent shall be
                driven only by the Lessee, in strict compliance with the
                requirements of the Applicable Laws (the “ Permitted Use ”).
                -The Lessee further agrees and acknowledges that other than the
                Permitted Use, all other uses of the Vehicle including the
                usages listed below (by the Lessee and/or any other person(s)
                directly or indirectly acting through, authorised by or on
                behalf of the Lessee), are strictly prohibited (the “ Prohibited
                Use s”) and constitute a material breach of this Agreement: -
                Commercial use for any purpose whatsoever including usage for
                transportation services (goods and/or passengers) involving hire
                and/or reward. - For providing driving lessons. - For sports,
                adventure and/or participation in races, rallies, other
                competitive events, endurance test and similar events. - For
                towing, pushing, or propelling any trailer or any other vehicle.
                - For commission of any crime, illegal and/or unlawful
                activity(ies). - For storage of any combustible, noxious or
                hazardous goods or any other good(s) which owing to its weight
                and/or structure adversely affect and/or damage the Vehicles,
                its interior, engine and/or any of its parts thereto and/or any
                other vehicle(s), person(s)/object(s) in such vehicle and/or
                pedestrian(s) passing the Vehicle. - Taking the Vehicle outside
                the Permitted Territory. - Any use(s) or activity(ies) that puts
                the Vehicle at a greater risk of damage. - Any use that
                contravenes the terms and conditions of this Agreement, terms of
                the insurance relating to the Vehicle and/or the requirements of
                all Applicable Laws. <br />
                <br />
                <p className="header2">4. Lease Term</p>- The lease of the
                Vehicle to the Lessee shall be for a term commencing from the
                Delivery Date and shall end as per booking end date as indicated
                in the app. and includes extensions expressly provided herein
                (the “ Lease Term ”). - The Lease Term may be extended for such
                period and on such terms and conditions as may be mutually
                agreed upon by the Parties. Delivery of the Vehicle - On
                execution of this Agreement and subject to payment of Lease
                Rental by the Lessee on the Platform, the Lessor shall notify
                the Lessee that the Vehicle is ready for delivery. The Lessee
                shall take delivery of the Vehicle from the location notified in
                writing by the Lessor (the “ Designated Location ”) together
                with the Vehicle Key(s), original documentation of the Vehicle,
                including the registration certificate, Vehicle Insurance
                policy, Pollution Under Control ( PUC ) Certificate and other
                mandatory documents, if any, prescribed by the relevant
                authorities under Applicable Laws (the “ Vehicle Documentation
                ”) on the date and time mutually agreed by the Parties, as per
                the date requested in the booking details in the App (the
                “Booking Start Date ”). In the event the Lessee requests for
                delivery of the Vehicle to the Lessee’ address/location, the
                Lessor may choose to deliver or arrange for the Vehicle to be
                delivered to the location notified by the Lessee with assistance
                from the Platform as per the delivery charge specified in the
                Fee Policy mentioned on the Platform. along with the Vehicle
                Documentation on the Delivery Date]. - For the security, safety,
                tracking and health monitoring of the Vehicle, certain devices
                may be installed by the Lessor in the Vehicle prior to its
                delivery to the Lessee (“ In-Vehicle Devices ”). The Lessee
                agrees and acknowledges that the In-Vehicle Devices may require
                minor modification from time to time and the Lessee shall
                provide full access of the Vehicle to the Lessor, or any other
                person appointed by the Lessor for the purpose of modification
                of such devices with reasonable prior written notice. - If the
                Lessee desires to get the Vehicle delivered at the location
                notified by him/her, any risk, damages to the Vehicle in transit
                to such location shall be treated as an accident case and the
                repair will be covered as per the provision of Clause 10 below
                (Insurance) . - The Lessee shall remain present in-person to
                [take]/ [receive] the delivery of the Vehicle, produce his/her
                original driving license for inspection, furnish copies of such
                driving license, his/her recent passport size photograph and an
                additional address proof document (voter ID, Aadhar card, or
                valid passport) and sign the necessary documents (viz., delivery
                receipt) at the time of delivery, which will serve as an
                evidence of delivery and acceptance of the Vehicle. Further, the
                Lessee shall inspect the Vehicle (exterior walk-around and an
                interior look-over of the Vehicle) prior to taking delivery and
                shall be deemed to have satisfied himself/herself as to its
                condition, fitness for Lessee's personal use and compliance with
                specified safety standards. Any fault/defect detected in the
                Vehicle thereafter shall be dealt with in accordance with the
                provisions of Clause 11 below (Addition, Maintenance and Repair)
                . - If the Lessee fails to take the delivery of the Vehicle on
                the Booking Start Date for any reason whatsoever, the subsequent
                attempts to deliver the Vehicle to the Lessee will be charged as
                per the Fee Policy . The Lessee hereby agree and acknowledge
                that in case regardless of such failure to take the delivery of
                the Vehicle by the Lessee and consequent delay in takeover of
                the Vehicle by the Lessee, the Lease Term shall commence from
                the Booking Start Date. Provided that in case of failure of the
                Lessee to take delivery or Lessor to provide delivery the
                non-defaulting Party shall have the right to terminate this
                Agreement in accordance with Clause 14 below. <br />
                <br />
                <p className="header2">5. Lease Rental</p>- As security for the
                prompt and full payment for leasing of the vehicle and all other
                sums payable by the Lessee hereunder, and the faithful and
                timely performance of all provisions of this Agreement, and any
                extensions or renewals thereof, the Lessee shall deposit the
                Lease Rental in advance a sum more particularly described in the
                Fee Policy/booking details on the App (“ Lease Rental ”) with
                the Lessor through the Platform simultaneously with the
                execution of this Agreement. The same shall be payable through
                the Platform and shall be retained by Platform until the vehicle
                is returned to the Designated Location on Booking End Date. - If
                the Lessee terminates this Agreement before taking delivery of
                the Vehicle or refuses and/or is unable/unwilling for any reason
                to accept delivery of the Vehicle in accordance with Clause 5
                above, the Lessor shall be entitled to terminate this Agreement
                in accordance with Clause 14 below and any advance Lease Rental
                shall stand forfeited to compensate the Lessor for the costs,
                charges, expenses, losses incurred by the Lessor arising out of
                such an action of the Lessee. Likewise, if the Lessor refuses
                and/or is unable/unwilling for any reason to give delivery of
                the vehicle in accordance with Clause 5 above, the Lessee shall
                be entitled to terminate this Agreement in accordance with
                Clause 14 below and the Lease Rental as paid in advance shall be
                promptly refunded to the Lessee. - On the expiration or earlier
                termination of this Agreement and return of the Vehicle to the
                Lessor (except where the Vehicle is stolen or its completely
                destroyed due to accident or damages), or any extension or
                renewal thereof, provided Lessee has paid all the Lease Rental
                and all other amounts payable by him/her hereunder and fully
                performed all other provisions of this Agreement.
                <br />
                <br />
                <p className="header2">6. Lease Rental</p>- In lieu of the lease
                of the Vehicle and related services hereunder, the Lessee shall
                pay to the Lessor a lease rental fee specified in the booking
                details on the App (the “ Lease Rental ”) at the time of booking
                the vehicle in advance for the Lease Term through the Platform.
                - The Lessee hereby agrees and acknowledges that the Lease
                Rental comprises of the charges towards (i) usage of the Vehicle
                for a pre-agreed mileage as specified in Booking details on the
                App (“ Agreed Mileage ”); (ii) Platform usage fees; (iii)
                maintenance and repair cost; (iv) insurance/ trip protection
                charges; and (v) delivery charges, if applicable. In the event
                the usage of Vehicle exceeds the Agreed Mileage, the excess
                mileage will be charged as per the rate specified in the Fee
                Policy. The difference between the actual usage i.e., lesser, or
                greater than the Agreed Mileage shall be determined and settled
                i.e., paid by the Lessee to the Lessor or vice versa at the time
                of expiry or termination of the Lease Term. - Lessee shall be
                liable to pay any outstanding Lease Rental or other charges
                accruing due to Lessee exceeding Agreed Mileage or otherwise and
                shall be chargeable in accordance with the Fee Policy and may
                also incur penalties or late payment charges for prolonged
                default in clearing such outstanding amount as mentioned in the
                Fee Policy. - Any other payments made by the Lessor on behalf of
                the Lessee pertaining to the Vehicle, under this Agreement or
                otherwise shall be promptly reimbursed by the Lessee to Lessor
                on receipt of written notice in respect thereof from the Lessor
                together with documentary proof of such payments or at the
                option of the Lessor, to set off against amounts, if any, from
                the Lessor to the Lessee.
                <br />
                <br />
                <p className="header2">7. Theft and Accident</p>- Lessee will be
                solely responsible for safety measures/ precautions against any
                damage, accident, or criminal activity on/of the Vehicle and
                subject to provisions of Clause 10 ( Insurance ) the Lessee
                assumes sole financial liability in respect of in case the
                Vehicle is damaged, destroyed or stolen, however caused, or
                occasioned; and for all other risks and liabilities, including
                personal injury or death and property damage, arising with
                respect to the Vehicle, howsoever arising. The Lessee hereby
                agrees and acknowledges that under no circumstances shall the
                Lessor be held liable to the Lessee and/or any third party(ies)
                for any (i) any loss or damage that may be suffered by the
                Lessee or any third party(ies) arising from the above events or
                risks, howsoever caused, or occasioned; and (ii) indirect,
                incidental, special, or consequential damages arising from or
                related the use of Vehicle by the Lessee or his/her co-passenger
                or family members. - In the event of damage, accident or theft
                of the Vehicle or any part and/or accessory thereof, the Lessee:
                shall immediately, but in no event later than [24] hours from
                the occurrence of damage, accident, or theft, notify the
                Platform and the Lessor all details of the damage, accident or
                theft in writing; extend full support and cooperation to the
                Lessor in reporting the damage, accident or theft to the nearest
                police station and obtain a copy of the first information report
                (“ FIR ”); in case of damage or accident, after filing the FIR
                and notifying the damage or accident to the Lessor, as per
                Lessor’s instruction take the Vehicle to the nearest workshop
                specified by the Lessor for repair or assessment of
                repairability. In case of failure to fulfill its obligation
                under this Clause 8.2, the Lessee shall be solely liable for all
                costs, damages and/or consequences arising as a result thereof.
                - In the case of: - theft, accident or damage resulting in
                complete destruction/loss of the Vehicle, the Lessee shall be
                liable to pay the monthly Lease Rental till such time as the
                claim in respect of such theft, accident or damage under the
                trip protection plan /insurance is settled by the insurance
                company. Thereafter, upon payment of the claim amount by the
                insurance company, the Lessor shall reconcile the accounts with
                the Lessee, subject to setoff or adjustment of all amounts that
                may be due from the Lessee to the Lessor under this Agreement. -
                accident or damage resulting in partial or repairable damages in
                the Vehicle, the Lessee shall be liable to continue to pay the
                Lease Rental in accordance with this Agreement, including for
                the period during which the Vehicle is deposited with any
                workshop for repair work. - Notwithstanding anything contained
                herein, in the case of an accident or damage resulting in
                partial or repairable damages to the Vehicle or in case of theft
                where the Vehicle is recovered before or after the claim amount
                under the trip protection plan/insurance is received from the
                insurance company: the Lessor may in its sole discretion
                depending on the condition of the Vehicle, opt not to continue
                the lease of Vehicle and terminate this Agreement under Clause
                14 below and the Lessee shall be liable to promptly pay all
                unpaid dues under the Agreement to the Lessor, including without
                limitation the Excess Mileage Charges; the Lessee shall not be
                entitled to terminate the Agreement and where the Lease Term is
                about to expiry the same will stand extended, until the
                settlement of the trip protection plan/insurance claim and/or
                conclusion of the police investigation and/or proceeding(s) by
                any other competent authority(ies) with respect to the damage,
                accident or theft of the Vehicle.
                <br />
                <br />
                <p className="header2">8. Traffic Violation and Confiscation</p>
                - During the Lease Term and until the return of the Vehicle in
                good working order/condition to the Lessor, the Lessee is solely
                responsible for use of the Vehicle in compliance with traffic
                rules and regulation, including payment of toll charges.
                Accordingly, the Lessee shall be solely liable for all traffic
                violations involving the Vehicle during the aforesaid period
                (the “ Traffic Violation ”) and cost, penalty, fines and/or
                charges imposed in respect thereof, including fines for late
                payment, toll evasion/ interstate taxes or any processing fees
                added by the relevant regulatory authority(ies). The Traffic
                Violation includes, but is not limited to, unauthorised parking,
                excess speeding, breaking red light, photo enforcement, and toll
                violations. - The Lessee shall notify/report all Traffic
                Violations to the Lessor in writing together with all details,
                as soon as possible, not being later than 24 hours prior to
                expiry of period prescribed for taking necessary action in
                respect thereof under Applicable Laws (for example, if the case
                is being taken to court). Thereafter, the Lessee shall forthwith
                pay the relevant fine/s to the authority concerned and provide
                Lessor with the proof of such payment. In the event the Lessee
                fails to do so, the Lessor shall have the right at its sole
                discretion to pay such amounts and the Lessee shall reimburse
                the Lessor for same at the earliest. - The Lessee shall also be
                responsible if the Vehicle is confiscated, seized or impounded
                during the Lease Term and until the return of the Vehicle in
                good working condition to the Lessor, for any reason
                attributable to the Lessee (“ Confiscation ”). In such cases the
                Lessee shall notify/report the Confiscation to the Lessor in
                writing together with all details, as soon as possible, not
                being later than 24 hours prior to the expiry of the period
                prescribed for taking necessary action in respect thereof under
                Applicable Laws. The Lessee shall provide full support,
                assistance and cooperation to the Lessor then take all necessary
                steps/actions, to have the Vehicle released from the relevant
                authorities. In case Lessor assists in the release of the
                Vehicle from Confiscation, including payment of fines, charges,
                penalty, and fees imposed by relevant authority and all the
                costs and expenses incidental thereto. In the event the Lessee
                fails to provide its support and/or pay for the cost involved in
                having the Vehicle released from Confiscation, the Lessor shall
                have the right (at its sole discretion) to take necessary steps
                to secure the release of the Vehicle at its own expense. In such
                cases the Lessee shall promptly reimburse the Lessor for all
                cost/expense incurred for release of the Vehicle. -
                Notwithstanding anything to the contrary stated herein, in case
                of Confiscation (i) if the Lease Term is about to expire prior
                to release of the Vehicle, the Lease Term will stand extended in
                accordance with the terms of this Agreement; and (ii) the Lessee
                shall not be entitled to terminate the Agreement, until the
                Vehicle has been duly released, fines/charges/conditions, if
                any, imposed for release of Vehicle by the relevant authorities
                has been paid or satisfied/fulfilled and necessary documentary
                evidence reflecting conclusive disposal of the matter is
                received by the Lessor.
                <br />
                <br />
                <p className="header2">9. Insurance</p>- Lessor shall at its own
                expense during the Lease Term and until the Vehicle is delivered
                back in good working condition, comprehensively insure the
                Vehicle with an insurance company of its choice (“ Vehicle
                Insurance ”). - The Vehicle Insurance will provide primary
                third-party liability protection, which will be to the extent of
                claims / liabilities covered by the Vehicle insurer on the
                Vehicles for any/ all claims and/or liabilities arising during
                the use or operation of the Vehicle by the Lessee. - This shall
                not however cover the Own Damage and the Lessee will be
                responsible for any associated costs and charges
                directly/indirectly personally attributable to the Lessee’s
                default with the terms of this Agreement. However, it is hereby
                clarified that if this third-party liability protection is
                extended by the Lessor, it will not be applicable if the
                liability arises due to Lessee’ negligence, or breach of
                applicable terms under this Agreement. - The liability
                protection under the Vehicle Insurance applies only to claims of
                third-party bodily injury, death, or property damage, other than
                to the Vehicle, arising from the use of the Vehicle as permitted
                by this Agreement and Applicable Laws. Any such protection
                excludes any claim made by the Lessee or co-passenger or Lessee’
                or co-passengers’ immediate family members who permanently live
                with the Lessee or was travelling with the Lessee at the time of
                incident. This obligation may be limited to the extent the
                Vehicle Insurance’s coverage applies. The Lessor shall in no
                event be liable to the Lessee and/or the co-passenger(s) for any
                indirect, special, punitive, or consequential damages (including
                lost profits) arising from or related to the Vehicle, its use,
                or this Agreement. - In case of damage, theft, or destruction or
                any damage total or partial of the Vehicle during the Lease Term
                and until the return of the Vehicle to the Lessor in good
                working condition, subject to the Lessee fulfilling its
                obligations in respect thereof under Clause 8 above, the Lessee
                shall be liable for any costs that maybe incurred for
                rectification/repair of the Vehicle due to such damages. -
                Incase of a total loss or theft, the Lessee shall promptly
                notify the Platform and the Lessor of the same and shall assist
                the Lessor in filing of complaint (in case of theft) or invoking
                such insurance claims by providing all required documentation
                and details of the accident or damages to ensure that the Lessor
                may effectively administer the claims from the insurance
                company. - Lessor’s decision in that regard shall be final and
                binding on both Parties. In cases where the Lessor decides not
                to file the claim, repair expenses shall be borne by the Lessee.
                except in cases of theft, total loss, and/or situations where
                the Lessor is unable to claim insurance due to actions (or lack
                thereof) attributable to the Lessee. - The expenses associated
                with (a) any risks and ensuing damage to the Vehicle, which are
                not fully covered by the Vehicle Insurance; (b) risks which are
                not borne by the Lessor in terms hereof shall be borne by the
                Lessee. Accordingly, the Lessee shall pay to the Lessor the
                amount of loss and/or damage not paid under the Vehicle
                Insurance and be liable for the following: - Damage to the
                Vehicle: The difference, if any, between the actual amount
                incurred in repairing the damage to the Vehicle and the amounts
                recovered/to be recovered under the Vehicle Insurance.
                Theft/total loss of the Vehicle: (a) The shortfall between the
                claim amount received under the Vehicle Insurance, and the book
                value of the Vehicle at that time of its theft/total loss; (b)
                if usage of Vehicle at the time of its theft/total loss exceeds
                the Agreed Mileage, charge of the excess mileage incurred as per
                the rate specified in the Fee Policy ; (c) other cost/expense
                incurred by the Lessor for or in respect of assessment loss
                suffered by the Vehicle and possibility of its restoration; and
                (d) other charges, if any, remaining unpaid by Lessee under this
                Agreement. - Notwithstanding the above in case the insurance
                claim for damages/loss to the Vehicle under the Vehicle
                Insurance is repudiated or not admitted by the insurance company
                due to the mishandling/ negligence/ fault/ rash driving/ any
                other illegal and unlawful use of the Vehicle by the Lessee; or
                any third-party claims rejected due to negligence/ misconduct/
                rash driving/ other unlawful or illegal act on part of the
                Lessee causing irreparable damage, the Lessee shall be solely
                liable to incur all the related damages, losses, costs and
                expenses.
                <br />
                <br />
                <p className="header2">10. Addition, Maintenance and Repair</p>-
                The Lessor shall through its authorised agent/service provider
                (“ Authorised Service Provider ”) perform the necessary and
                required routine maintenance on the Vehicles and upon request
                from the Lessor, the Lessee shall provide to the Authorised
                Service Provider all details on the Vehicle’s usage, including
                the reading of the distance indicator (“ Odometer ”), tyre
                quality and battery condition of the Vehicle. Any
                defect/discrepancy in the Odometer shall be duly reported by the
                Lessee to the Lessor to ensure the mileage is being duly
                recorded. - All repair and/maintenance of the Vehicle, including
                shall be undertaken by the Lessee subject to the provisions of
                Insurance under Clause 10 in accordance with the terms of this
                Agreement or with prior written consent/confirmation of the
                Lessor. - Subject to requirements of Clause 8 above, all
                incidents requiring repair of the Vehicle must be reported to
                the Lessor and in accordance with the Lessor’s instruction taken
                to the workshop for repair. The Lessee shall be responsible to
                pay for towing, repairs, and other expenses if the incident
                results from a breach of this Agreement and/or use of the
                Vehicle by the Lessee in violation of terms of this Agreement
                and/or its user manual. - All parts and accessories that are
                replaced or added to the Vehicle during the Lease Term and until
                the Vehicle is returned to the Lessor, shall be the sole and
                exclusive property of the Lessor and the Lessee shall not have
                any rights in respect thereof, including right to be compensated
                therefor.
                <br />
                <br />
                <p className="header2">11. Obligations of the Lessee</p>- During
                the Lease Term and until the return of the Vehicle to the Lessor
                in good working condition, the Lessee shall: at his expense
                maintain the cleanliness, condition, and appearance of Vehicle
                in as good an operating condition as it was on the commencement
                date of the Lease Term. Any liability arising out of not
                conforming with this requirement shall be solely to the cost and
                account of the Lessee. use the Vehicle only for the Permitted
                Use in conformity with the owner’s manual instructions provided
                as part of Vehicle Documentation, applying the same degree of
                care when using the Vehicle as would ordinarily be exercised if
                it belonged to the Lessee and shall strictly refrain from:
                Prohibited Use of the Vehicle; an imprudent, negligent, or
                abusive or abnormal use of the Vehicle; driving the Vehicle
                under the influence of drugs, alcohol or any other substance or
                intoxicant; driving in contravention of speed limits as
                prescribed by competent authority. permitting use of Vehicle by
                other person(s) and/or selling, transferring, alienating,
                assigning, licensing or granting/creating any rights or
                interest, of any nature whatsoever, over the Vehicle; driving
                the Vehicle outside the Permitted Territory; driving the Vehicle
                while using a mobile phone or any other mobile communication
                device in any manner whatsoever, including while sending an SMS,
                emailing, using a cell phone with or without a hands-free
                device, otherwise using a mobile communication device that may
                distract the Lessee from driving or otherwise engaging in
                similar activities that may be prohibited by the Applicable
                Laws; burning any substance including tobacco, incense etc.
                inside the Vehicles; use of tobacco, cigarettes, cigars, and
                alcohol inside the Vehicle; transportation of animals in the
                Vehicle; transporting more passengers than the prescribed
                seating capacity of the Vehicle by manufacturer of the Vehicle;
                use of the Vehicle for any kind of activity by any person which
                will affect the normal conditions or materially impair its value
                and/or use; any unreasonable, unwarranted, illegal, unethical or
                inappropriate use of the Vehicle; and jump starting the Vehicle
                without the prior written consent of the Lessor. - maintain
                valid driving license and keep it effective and in force at all
                times during the subsistence of this Agreement and till the
                Vehicle is delivered back to Lessor in good working condition as
                per the requirements of this Agreement; - promptly notify the
                Lessor in case of any expiration, suspension or revocation of
                his/her driving license, or any tickets/citations/conviction
                related to any Traffic Violation, including but not limited to
                driving under the influence of drugs or alcohol, driving while
                intoxicated, reckless driving, exceeding the speed limit, or
                sending an SMS while driving. - maintain effective control of
                the Vehicle and keep the Vehicle in a secure and suitable
                environment when not in use; take all necessary actions to duly
                secure the Vehicle from external factors while parking it or
                leaving it parked, including human or general known adverse
                weather conditions, by closing its windows and locking the
                Vehicle either by way of the central locking system or manual
                locking, as the case may be; - comply with the terms of this
                Agreement and Applicable Laws. This includes, rules or
                regulations relating to use of seat belt, permitted speed/weight
                limit, child restraint and instructions relating to the same
                being displayed/ communicated by the authorities through marks
                or sign boards; - not perform, or allow any person to perform,
                any work in or upon or make modifications, changes, alterations
                or repairs to the Vehicle, except as expressly provided under
                this Agreement; - not tamper and/or remove the In-Vehicle
                Devices and in case the Lessee removes or otherwise tamper the
                devices, pay to the Lessor the actual cost of the In-Vehicle
                Devices. - allow the Lessor or his/her representatives to
                inspect the Vehicle at all reasonable times with prior notice of
                at least 4 hours to inspect the Vehicle and the In-Vehicle
                Devices and shall grant reasonable access to the premise where
                the Vehicle is kept for such inspections; - keep the Vehicle
                free from any liens, claims, charges and encumbrances, of any
                nature whatsoever; - keep the Lessor fully informed of all
                material matters relating to the Vehicle and/or Lessor’s
                right/title/interest in the Vehicle; - deliver the Vehicle at
                the end of the Lease Term or on earlier termination of the
                Agreement at such address as the Lessor requires, or if
                necessary, allow Lessor or its representatives access to the
                site or any premises where the Vehicle is located for the
                purpose of removing the Vehicle; - not do or permit to be done
                anything which could invalidate the Vehicle Insurance or the
                trip protection plan/insurance; and - ensure the safekeeping and
                presence of the Vehicle Documentation in the Vehicle. If these
                documents are lost or stolen, the Lessee will bear the cost of
                obtaining duplicates and/or the inconvenience fee as per the Fee
                Policy and the same shall be remitted to the Lessor along with
                all other charges for damages and Lease Rental as payable to the
                Lessor
                <br />
                <br />
                <p className="header2">12. Representations and Warranties</p>-
                The Lessor hereby represents and warrants to the Lessee that:
                Lessor is the sole legal, and registered owner of the Vehicle;
                Lessor has the full legal right, capacity, power and authority
                to enter into and execute this Agreement, be contractually bound
                by and comply with all rights and obligations contracted under
                this Agreement; and There is no action, investigation or other
                proceedings of any nature whatsoever, by any governmental
                authority or third party against the Lessor, which would
                restrain, prohibit or otherwise challenge the lease of the
                Vehicle to the Lessee as contemplated by this Agreement. Further
                the Lessor also represents that there are no pending insurance
                claims and/or other on-going litigations, legal claims or any
                other claims that may arise in tort or law at the time of
                listing the Vehicle - The Lessee represents and warranties to
                the Lessor that: Lessee is above the legal driving age
                requirement and has a valid driving license for the use and
                operation of the Vehicle in accordance with requirements of
                Applicable Laws; Lessee the full legal right, capacity, power,
                and authority to enter into and execute this Agreement, be
                contractually bound by and comply with all rights and
                obligations contracted under this Agreement; and there is no
                action, investigation, or other proceedings of any nature
                whatsoever, by any governmental authority or third party against
                the Lessee, which would restrain, prohibit, or otherwise
                challenge the transaction as contemplated by this Agreement. -
                Except as expressly provided in this Agreement, the Lessor makes
                no warranty or representation, express or implied, as to the
                fitness, safeness, design, merchantability, condition, quality,
                capacity or workmanship of the Vehicle.
                <br />
                <br />
                <p className="header2">13. Termination</p>- The Lessor may
                terminate this Agreement at any time , by cancelling the booking
                over the Platform. The cancellation by Lessee is subject to
                certain charges that the Lessee may incur and are more
                particularly described in the Fee Policy. Provided that the
                Lessor shall be entitled to terminate this Agreement immediately
                with written notice to the Lessee at any time during the Lease
                Term, including the Lessor Lock-in Period in the following
                cases: The Lessee refuses and/or is unable/unwilling for any
                reason to accept delivery of the Vehicle in accordance with
                Clause 5 above. Prohibited Use of the Vehicle by the Lessee or
                any other third party during the Lease Term; Lessee ceasing to
                hold valid driving license, for any reason whatsoever and/or
                Lessee’ driving license being revoked, detained, impounded
                and/or suspended by concerned authorities; failure by Lessee to
                abide by the terms of this Agreement and/or observe the
                covenants or fulfill his/her material obligations hereunder; the
                Lessee is placed in mandatory liquidation or has a receiver or
                other administrator appointed in respect of his/her assets; in
                case of an accident or damage of the Vehicle, resulting in
                partial or repairable damages in the Vehicle or in case of theft
                where the Vehicle is recovered before or after the claim amount
                under the trip protection plan/insurance is received from the
                insurance company; the Lessee is entering into or proposing to
                enter into any arrangement for the benefit of its creditors; and
                Lessee becomes subject to any event analogous to, or enters into
                any arrangement analogous to, any of those events or
                arrangements specified in (v), (vi) and (vii) of this clause 14.
                - In case of termination of this Agreement on account of expiry
                of the Lease Term or under clauses 14 above: the Lessee shall
                pay to the Lessor, the outstanding Lease Rental (together with
                all late payment/charges thereon) and other unpaid
                sums/charges/costs payable by the Lessee under this Agreement,
                including without limitation the payables under Clause 15 below;
                - Upon the return of the Vehicle to the Lessor in accordance
                with Clause 15 below, the Lessor shall (a) instruct the Platform
                to return to the Lessee the advance Lease Rental, if any, paid
                by the Lessee for the unexpired period of the Lease Term to the
                Lessee subject to adjustment against other outstanding payable
                of the Lessee under this Agreement; (c) except in case of
                termination under Clause 14 (i), return to the Lessee the entire
                advance Lease Rental or then remaining balance of the Lease
                Rental, as the case may be, without interest, provided the
                Lessee has no other amounts payable by him/her hereunder and
                fully performed all other provisions of this Agreement; the
                Parties shall be regarded as discharged from any further
                obligations under this Agreement. Provided that termination
                and/or expiry of this Agreement shall not relieve either Party
                of any obligation or liability accrued prior to the date of
                return of the Vehicle to the Lessor in accordance with Clause 15
                below; and the Parties shall be entitled to pursue any
                additional or alternative remedies provided by law or equity.
                <br />
                <br />
                <p className="header2">
                    14. Repossession/Return of the Vehicle
                </p>
                - Upon the expiry of the Lease Term, or earlier termination of
                this Agreement (except termination of the Agreement on account
                of theft or total destruction/loss of the Vehicle), the Lessee
                shall, at its own cost and expense, and without any delay,
                return the Vehicle with the Vehicle Documentation, Vehicle’s
                key, key fob, in-Vehicle Devices and other starting device to
                the Vehicle in its designated position in the Vehicle at the
                Designated Location. The Lessee agrees and covenants that at the
                time of its return the Vehicle shall be in the almost the same
                order and condition, as the Vehicle was at the time of
                commencement of the Lease Term, except normal wear and tear, as
                per the guidelines set out under (“ Vehicle Condition Guidelines
                ”) appended as Schedule 1 to this Agreement. In case, the Lessee
                returns the Vehicle at a place other than the Designated
                Location the Lessee shall be liable to reimburse the Lessor all
                costs incurred in transporting the Vehicle from such place to
                the Designated Location; the Lessee does not return the Vehicle
                within the specified period, the Lessor (directly or through its
                Authorised Service Provider) shall be entitled to take necessary
                steps to repossess the Vehicle after giving reasonable notice to
                the Lessee and the Lessee hereby irrevocably authorizes Lessor
                and its Authorised Service Provider to take physical possession
                of the Vehicle as aforesaid. In such cases the Lessee shall be
                liable to a pay late return penalty specified in the Fee Policy
                till the date the Vehicle is returned to or repossessed by the
                Lessor as also the costs, expenses, charges etc. incurred by the
                Lessor for repossession of the Vehicle; damage to the Vehicle at
                the time of the return, other than excepted wear and tear as per
                the Vehicle Condition Guidelines. In such cases the Lessee shall
                be liable to a pay penalty for such damages at the rate
                specified in Fee Policy and all the costs, expenses, charges
                etc. incurred by the Lessor to restore the Vehicle to its
                original condition. the Vehicle is returned, but its key, key
                fob, in-Vehicle Devices, other starting device to the Vehicle or
                any component(s) of the Vehicle, including the accessories
                fitted as original equipment on the Vehicle (except where the
                components are replaced or repaired during the Lease Term in
                accordance with this Agreement) and/or any of Vehicle
                Documentation is missing, the Lessee shall be charged with
                inconvenience fee and or penalty/ cost of replacement for such
                parts and documentation as maybe provided in the Fee Policy. The
                Lessee shall also remain responsible for the payment of charges
                against the usage of the Vehicle during such period. The actual
                usage of Vehicle by the Lessee exceeds the Agreed Mileage, the
                excess mileage charge as per the rate specified in the Fee
                Policy shall be paid by the Lessee to the Lessor. If required by
                any relevant regional transport authorities/ statutory transport
                offices, the Lessee agrees to sign on such required documents on
                return of the Vehicle at the end of Lease Term. In turn, the
                Lessor confirms that the Lessee will not be responsible for any
                claims or damages incurred after the Vehicle is returned the
                Lessor.
                <br />
                <br />
                <p className="header2">15. Confidentiality</p>- The Parties
                agrees that there will be sharing, and exchange of confidential
                personal and sensitive personal information between them. To
                safeguard such information each party (“ Receiving Party ”)
                agrees to protect the confidential information of the other
                party (“ Disclosing Party ”) received by it hereunder as the
                Receiving Party would protect its own, or any diligent person or
                entity would protect information of this nature. - The Receiving
                Party agrees to use the confidential information only for the
                purpose of and in performance of its rights and obligations
                under this Agreement. - The obligation not to disclose
                confidential information shall survive notwithstanding the
                termination of this Agreement and at no time will the either
                Party be permitted to disclose confidential information of the
                other party, except to the extent at or after the time of
                disclosure or acquisition, the confidential information is in
                the public domain in the form supplied otherwise than through a
                breach of this Agreement by the Receiving Party.
                <br />
                <br />
                <p className="header2">16. Indemnity</p>- The Lessee shall
                indemnify and hold harmless the Lessor and the Authorised
                Service Provider against all costs, charges, expenses, losses,
                damages, liabilities or claims suffered by the Lessor directly
                or indirectly, on account of any willful default, misconduct,
                negligence, fraud, violation of Applicable Laws or any breach of
                the terms of this Agreement by the Lessee. - Lessee’s obligation
                to indemnify the Lessor hereunder shall survive the expiration,
                cancellation or termination of this Agreement. The Lessee waives
                any immunity Lessee may have under any Applicable Law from its
                obligation to indemnify the Lessor under this Agreement.
                <br />
                <br />
                <p className="header2">17. Miscellaneous</p>- All payments under
                this Agreement, including without limitation the Lease Rental
                and the Damage Protection Fee shall be made through the Platform
                in accordance with the payment policies specified on the
                Platform. - This Agreement shall not be construed insofar as
                creating an association, partnership, joint venture or
                corporation between the Parties, or as imposing any obligation
                or liability of a corporate nature to either party. Neither
                party has the right, power or authority to sign any agreement,
                bind, or in any manner act on behalf of or as a representative
                or agent of the other Parties, unless expressly established
                otherwise herein. - The Lessee shall not assign any of its
                rights under this Agreement or delegate the performance of any
                of his duties hereunder, without the prior written consent of
                the Lessor. - Notices, demands or other communication required
                or permitted to be given or made under this Agreement, shall be
                in writing and delivered personally or sent by courier or by
                email to the intended recipient at their address set forth in
                this Agreement, or to such other address or email address, as
                either Party may from time to time duly notify to the other. -
                Under no circumstances shall the Lessor be liable to the Lessee
                in contract, tort or otherwise for any loss of profits or
                business, or any special, incidental, indirect, exemplary,
                punitive or consequential damages, arising from or as a result
                of this Agreement, even if the Lessor has been advised of the
                possibility of such damages. - This Agreement, its Schedules the
                Fee Policy, the Terms & Conditions for Host & Lessee on Platform
                and any other documents referred to herein (in each case, to the
                extent not inconsistent with this Agreement) constitute the
                entire and only agreement between the Parties relating to the
                subject matter hereof and supersedes and extinguish any prior
                drafts, agreements, undertakings, representations, warranties
                and arrangements of any nature whatsoever, whether or not in
                writing, relating thereto. - This Agreement shall be governed
                and construed in accordance with the laws of the India and the
                courts of Bangalore shall have sole and exclusive jurisdiction
                in case of any disputes, claims and questions arising under or
                pursuant to this Agreement. In case of any change in Applicable
                Laws that has an effect on the terms of this Agreement, the
                Parties agree that the Agreement may be reviewed, and if deemed
                necessary by the Parties, renegotiated in good faith. - If
                during the Lease Term and until the return of the Vehicle to the
                Lessor in accordance with Clause 15 above, any provision of this
                Agreement is or becomes illegal, invalid or unenforceable in any
                respect, such illegality, invalidity or unenforceability shall
                not affect or impair the legality, validity or enforceability of
                any other provisions of this Agreement. In addition, in such
                event the Parties shall negotiate in good faith to modify this
                Agreement so as to affect the original intent of the Parties as
                closely as possible with respect to those provisions which were
                held to be invalid, illegal or unenforceable. - Any amendment of
                or modification to this Agreement shall be effective only if
                mutually agreed in writing and executed by a duly authorized
                representative of each Party. - Any tolerance by any Party with
                respect to the anticipation or delay in the performance of the
                obligations assumed by the Parties will not constitute novation,
                amendment or a waiver of any term set forth in this Agreement. -
                This Agreement may be executed by electronic means (including
                .PDF) and in any number of counterparts, each of which when
                executed and delivered, shall constitute an original, but all of
                which together shall constitute one agreement binding on all
                Parties, notwithstanding that all Parties are not signatories to
                the same counterpart.
                <br />
                <br />
                <p className="header2">
                    SCHEDULE 1: VEHICLE CONDITION GUIDELINES
                </p>
                Your vehicle must follow all laws and regulations for safety,
                condition and operation. You should get a vehicle mechanical and
                safely inspection at least once a year. An inspection would
                include vehicle’s most critical safely items including brakes,
                steering parts, tires and lights. Complete maintenance
                requirement list below - Visual overview and legal registration:
                · RC Copy · Valid insurance Brake tests: · Parking brake ·
                Service brake, 3mm or more (25% of the life of the brakes or
                more, typically) Exhaust system: · Undamaged exhaust system
                components/muffler · No visible blue or black smoke Steering and
                suspension · Steering wheel and box · Suspension/front end ·
                Springs · Shocks · Vehicle height Horn · Sound horn to test for
                adequate signal · The horn must be securely fastened to the
                vehicle Glazing, glass, and windshield wipers · Windshield, no
                cracks; no chips in the line of sight · Windshield wipers and
                washer in working order · Windows, no cracks · Tint to legal
                specifications Engine and powertrain · Engine, including all
                subcomponents · Cooling system · Lubrication system · Electrical
                and electronic management systems · Transmission, including
                clutch, torque converter, and similar components · All fluids
                and lubricants · No check engine light, warning lights, or
                recalls Visibility and lighting devices · Headlight aim ·
                Headlights and rear lights working, no bulbs burned out · Hazard
                lights, directionals/stop/reverse lights, and license plate
                light(s) · Reflectors · Rearview mirror · Mirrors (general)
                Tires and wheels · 4/32” or greater tread depth (50% or higher
                of tread, typically) · Six years old or newer · No cuts, gouges,
                bulges, or bubbles in the sidewall Seat belts and airbags · No
                airbag warning lights or recalls · No safety restraint system
                (SRS) or occupant classification system (OCS) warning lights or
                recalls · Body, including all seals and panels · No hanging body
                panels · No damage to the floor pan, cowl panel, or structural
                pillars
                <br />
                <br />
                <p className="header2">
                    Go<span className="redHead">with</span>car.com takes pride
                    in being completely transparent with our members. Here is a
                    comprehensive list of fees for certain scenarios and a list
                    of penalties that can be levied if members violate certain
                    Go<span className="redHead">with</span>car.com community
                    rules.
                </p>
                <br />
                <br />
                <div className="lease-table">
                    <table style={tableStyle}>
                        <thead>
                            <tr>
                                <th style={cellStyle}>Minimum Duration</th>
                                <td style={cellStyle}>
                                    The minimum booking duration for which the
                                    prices will be charged is 8 hours.
                                </td>
                            </tr>
                            <tr>
                                <th style={cellStyle}>Free Kilometres</th>
                                <td style={cellStyle}>
                                    The free kilometres allotted to the customer
                                    will be proportional to the number of hours
                                    booked and the flexi package chosen.
                                </td>
                            </tr>
                            <tr>
                                <th style={cellStyle}>Pricing plan change</th>
                                <td style={cellStyle}>
                                    Pricing plan (free km per hour) cannot be
                                    changed post booking creation. Pricing will
                                    be charged on an half hour basis.
                                </td>
                            </tr>
                            <tr>
                                <th style={cellStyle}>Fuel Policy</th>
                                <td style={cellStyle}>
                                    The vehicle should be returned by the
                                    Member, upon the completion of the booking,
                                    with fuel at the same level as at the start
                                    of the booking. If the Vehicle is returned
                                    with lesser fuel than the fuel level at the
                                    start of the trip then Refuelling penalty
                                    will be applicable.
                                </td>
                            </tr>
                            <tr>
                                <th style={cellStyle}>Speed Governor</th>
                                <td style={cellStyle}>
                                    The Vehicle may be enabled with speed
                                    governor in compliance with the applicable
                                    Government regulations, that limits speed of
                                    the Vehicle upto 80 km/h
                                </td>
                            </tr>
                            <tr>
                                <th style={cellStyle}>Breakdown of vehicle</th>
                                <td style={cellStyle}>
                                    1. The Member shall be responsible for the
                                    costs related to the repair, recovery, and
                                    loss of use of any Go
                                    <span className="redHead">with</span>car.com
                                    vehicle resulting from any of the foregoing,
                                    up to the current damage fee, as set forth
                                    in the Fee Policy / Rate Schedule (if such
                                    costs are because of the fault of the Member
                                    or if the fault is not directly established
                                    to any other person/ entity, or where any
                                    cost is not paid / reimbursed by the insurer
                                    of Go<span className="redHead">with</span>
                                    car.com vehicles or the insurer of the other
                                    vehicle involved in the accident). 2. Where
                                    the Member is not responsible for the
                                    breakdown of the Go
                                    <span className="redHead">with</span>car.com
                                    vehicle, Go
                                    <span className="redHead">with</span>car.com
                                    shall refund an amount equal to the cost of
                                    the unused hours of such booking. Further,
                                    Go<span className="redHead">with</span>
                                    car.com shall not be liable for any further
                                    costs borne by the Member as a consequence
                                    of the breakdown of the Go
                                    <span className="redHead">with</span>car.com
                                    vehicle.
                                </td>
                            </tr>
                            <tr>
                                <th style={cellStyle}>
                                    Returning the vehicle to the wrong location
                                </th>
                                <td style={cellStyle}>
                                    Up to ₹ 10,000 in wrong location drop
                                    penalties + Car recovery expenses incurred
                                    by Go<span className="redHead">with</span>
                                    car.com until the vehicle is returned to the
                                    correct location. Late Fee Penalty will also
                                    be charged at the effective hourly booking
                                    rate.
                                </td>
                            </tr>
                            <tr>
                                <th style={cellStyle}>
                                    Traffic and Parking violations
                                </th>
                                <td style={cellStyle}>
                                    Full payment of fines + ₹500 per 30 days
                                    late in payment
                                </td>
                            </tr>
                            <tr>
                                <th style={cellStyle}>
                                    Overspeeding >= 125 km/hr
                                </th>
                                <td style={cellStyle}>
                                    ₹2500 (over and above any government fines
                                    that may have been levied) Expulsion from Go
                                    <span className="redHead">with</span>car.com
                                    if overspeeding occurs in two bookings
                                </td>
                            </tr>
                            <tr>
                                <th style={cellStyle}>
                                    Overspeeding >= 150 km/hr
                                </th>
                                <td style={cellStyle}>
                                    Expulsion from Go
                                    <span className="redHead">with</span>car.com
                                </td>
                            </tr>
                            <tr>
                                <th style={cellStyle}>Smoking</th>
                                <td style={cellStyle}>
                                    ₹1,000 + applicable damage costs related to
                                    interiors
                                </td>
                            </tr>
                            <tr>
                                <th style={cellStyle}>Tolls</th>
                                <td style={cellStyle}>
                                    Members are responsible for paying all tolls
                                    at the time they are due.
                                </td>
                            </tr>
                            <tr>
                                <th style={cellStyle}>Wrong Fuelling</th>
                                <td style={cellStyle}>
                                    The member will be liable for the full cost
                                    of damage to the vehicle + miscellaneous
                                    expenses arising out of the damages.
                                    Recommendation- Go
                                    <span className="redHead">with</span>car.com
                                    would recommend that the member consult the
                                    RC Documents of the rented vehicle for
                                    information as to the correct fuel type.
                                </td>
                            </tr>
                            <tr>
                                <th style={cellStyle}>
                                    Key not returned at end of reservation
                                </th>
                                <td style={cellStyle}>
                                    returned at end of reservation ₹ 2000 +
                                    replacement cost, if not returned within 3
                                    days
                                </td>
                            </tr>
                            <tr>
                                <th style={cellStyle}>
                                    Vehicle Damage (Accidental)
                                </th>
                                <td style={cellStyle}>
                                    Any unintentional/accidental damage will be
                                    liable for the damage charges of ₹ 30,000/.
                                </td>
                            </tr>
                            <tr>
                                <th style={cellStyle}>
                                    Vehicle Damage (Intentional or
                                    Consequential)
                                </th>
                                <td style={cellStyle}>
                                    In case of any intentional damage or damage
                                    caused to the vehicle as consequence of the
                                    member driving the car, even after the car
                                    is damaged and need urgent repair (Examples
                                    include but are not limited to: a. driving
                                    in water loggings resulting into engine
                                    breakdown, b. driving after underbody
                                    damages resulting into engine break down
                                    because of oil drainage, c. driving while
                                    under the influence of alcohol or other
                                    intoxicants, d. driving with clutch over
                                    pressing etc. Customer will be liable for
                                    the full cost of repair post damage to the
                                    vehicle + loss of expected revenue arising
                                    out of the damage.
                                </td>
                            </tr>
                            <tr>
                                <th style={cellStyle}>Towing and Impounding</th>
                                <td style={cellStyle}>
                                    No cost if caused by vehicle failure Full
                                    cost if caused by user negligence
                                </td>
                            </tr>
                            <tr>
                                <th style={cellStyle}>
                                    Cleaning required (interiors)
                                </th>
                                <td style={cellStyle}>
                                    Washing of removables (for foot mats etc)
                                    would be charged at ₹500. Interior Washing /
                                    Dry Cleaning (for seat covers, door panels)
                                    would be charged at ₹1,500.
                                </td>
                            </tr>
                        </thead>
                    </table>
                </div>
                <br />
                <br />
                Note: Go<span className="redHead">with</span>car.com reserves
                the right to charge a fee for specific pre-defined accessories
                and payments owed by members will be deducted from the security
                deposit.
            </section>
        </div>
    );
};

export default Lease;
