import React, { useState } from "react";
import "./ContactUs.css";
import { Button, Form, Input } from "antd";
import { addDoc, collection } from "firebase/firestore";
import { useContext } from "react";
import { AuthContext } from "../../../AuthProvider";

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const validateMessages = {
    required: "${label} is required!",
    types: {
        email: "${label} is not a valid email!",
        number: "${label} is not a valid number!",
    },
    number: {
        range: "${label} must be between ${min} and ${max}",
    },
};

const ContactUs = () => {
    const { db } = useContext(AuthContext);
    const userFeedBackCollection = collection(db, "feedback");
    const [form] = Form.useForm();
    const onFinish = (values) => {
        addDoc(userFeedBackCollection, values);
        setTimeout(() => {
            handleReset();
        }, 2000);
    };
    const handleReset = () => {
        form.resetFields();
    };

    return (
        <div>
            <h2>Feel free to contact us</h2>
            <p className="contact_subHeader">
                Let us know what is your feedback
            </p>
            <br />
            <br />

            <Form
                {...layout}
                name="nest-messages"
                onFinish={onFinish}
                className="contactUsForms"
                form={form}
                validateMessages={validateMessages}
            >
                <div className="C_f_name">
                    <div>
                        <Form.Item className="smallForm" name={["name"]}>
                            <Input placeholder="name" required />
                        </Form.Item>
                    </div>
                    <div>
                        <Form.Item
                            name={["email"]}
                            rules={[
                                {
                                    type: "email",
                                },
                            ]}
                            className="smallFormRight"
                        >
                            <Input placeholder="email" required />
                        </Form.Item>
                    </div>
                </div>
                <div className="C_f_name">
                    <div>
                        <Form.Item name={["mobile"]} className="smallForm">
                            <Input placeholder="mobile number" required />
                        </Form.Item>
                    </div>
                    <div>
                        <Form.Item
                            name={["location"]}
                            className="smallFormRight"
                        >
                            <Input placeholder="location" required />
                        </Form.Item>
                    </div>
                </div>

                <Form.Item className="c_TextInput" name={["message"]}>
                    <Input.TextArea
                        className="c_TextInput"
                        placeholder="message"
                        required
                    />
                </Form.Item>

                <Button type="primary" danger shape="round" htmlType="submit">
                    Send now
                </Button>
            </Form>
        </div>
    );
};

export default ContactUs;
