import React, { useContext, useEffect, useState } from "react";
import "./explorecar.css";
import { Row, Select } from "antd";
import { collection, getDocs, query, where } from "firebase/firestore";
import Lottie from "lottie-react";
import { AuthContext } from "../../AuthProvider";
import CarCards from "../Home/component/featuredcars/CarCards/CarCards";
import useTitle from "../../CustomHooks/useTitle.js";
import ArtExplore from "./ArtExplore/ArtExplore";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const ExploreCars = () => {
    const [loading, setLoading] = useState(true);
    const [posts, setPosts] = useState([]);
    const [searchTitle, setSearchTitle] = useState("");
    const [load, setLoad] = useState(100);
    const [services, setServices] = useState([]);
    const { showPopup, handlePopupButtonClick, db } = useContext(AuthContext);

    // ============================================ fetch data from firebase ===============================================
    const fetchCars = async () => {
        const querySnapshots = collection(db, "cars");
        const q = query(querySnapshots, where("status", "==", "approved"));
        let arr = [];
        const querySnapshott = await getDocs(q);
        querySnapshott.forEach((doc) => {
            arr.push(doc.data());
        });
        setServices(arr);
        setLoading(false);
    };
    useEffect(() => {
        fetchCars();
    }, []);

    const Pagination = posts.filter((value) => {
        if (searchTitle === "") {
            return value;
        } else if (
            value.title.toLowerCase().includes(searchTitle.toLowerCase())
        ) {
            return value;
        }
    }).length;

    const LoadMoreData = () => {
        setLoad(load + 3);
        fetchCars();
    };
    useTitle("Explore self-drive car rental in Indore  - Go With Car");

    return (
        <div className="ex_main">
            {showPopup && (
                <div className="popup-screen">
                    <div className="popup">
                        <div className="popup_p">
                            <h2>Want to book now?</h2>
                            <FontAwesomeIcon
                                icon={faXmark}
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                    handlePopupButtonClick("Button 2")
                                }
                            />
                        </div>
                        <div className="popup_b">
                            <a href="tel:9926222099">
                                <button
                                    onClick={() =>
                                        handlePopupButtonClick("Button 1")
                                    }
                                >
                                    Call now
                                </button>
                            </a>
                            <a href="https://wa.me/+919926222099">
                                <button
                                    onClick={() =>
                                        handlePopupButtonClick("Button 2")
                                    }
                                >
                                    Message on whatsapp
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            )}
            <HelmetProvider>
                <Helmet>
                    <meta
                        name="description"
                        content="Select from our wide range of Self-drive car rental in Indore from Go with Car. We have the best collection of luxurious cars for all types of travel & needs. 
						"
                    />
                </Helmet>
            </HelmetProvider>
            <div className="ex_container">
                <div className="ex_h_d1">
                    <h1 className="ex_h_h1">
                        Select a self-drive car rental in Indore with Go
                        <span className="introTitleRed"> With</span> Car
                        <br />
                    </h1>
                </div>
                {/*=================================== search and list=======================================*/}
                <div className="">
                    <div className="ex_se_d1">
                        {/*=================================== search and filter=======================================*/}
                        <div className="ex_se_d2">
                            {/*=================================== search=======================================*/}
                            <div className="ex_se_d3">
                                <input
                                    className="ex_se_in"
                                    type="text"
                                    placeholder="Search..."
                                    onChange={(e) =>
                                        setSearchTitle(e.target.value)
                                    }
                                />
                            </div>
                            {/*=================================== filter =======================================*/}
                            <div className="ex_fil_d1">
                                <Select
                                    onChange={(value) => setSearchTitle(value)}
                                    placeholder="Select car category"
                                    style={{
                                        width: "100%",
                                        color: "black",
                                        backgroundColor: "rgb(240, 244, 251)",
                                    }}
                                >
                                    <Select.Option value={""}>
                                        All Cars
                                    </Select.Option>
                                    {[
                                        ...new Set(
                                            services.map(
                                                (item) =>
                                                    item.vehiclesModalName,
                                            ),
                                        ),
                                    ].map((item, index) => (
                                        <Select.Option value={item} key={index}>
                                            {item.vehiclesModalName}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    </div>

                    {/*=================================== all car list=======================================*/}
                    {loading ? (
                        <div
                            style={{
                                width: "100%",
                                justifyContent: "center",
                                alignItems: "center",
                                display: "flex",
                            }}
                        >
                            <Lottie
                                style={{
                                    width: "50%",
                                    alignSelf: "center",
                                    height: "50%",
                                }}
                                animationData={require("../../R/Effect/loading.json")}
                            />
                        </div>
                    ) : (
                        <Row
                            style={{
                                justifyContent: "center",
                                // alignItems: "center",
                                width: "100%",
                            }}
                            gutter={30}
                        >
                            {loading ? (
                                <h4>Loading ...</h4>
                            ) : (
                                services
                                    .filter((value) => {
                                        if (searchTitle === "") {
                                            return value;
                                        } else if (
                                            value.title
                                                .toLowerCase()
                                                .includes(
                                                    searchTitle.toLowerCase(),
                                                )
                                        ) {
                                            return value;
                                        }
                                    })
                                    .slice(0, load)
                                    ?.sort((item, index) =>
                                        item.bookingStatus === "booked"
                                            ? 1
                                            : -1,
                                    )
                                    .map((item, index) => (
                                        <CarCards item={item} key={index} />
                                    ))
                            )}
                        </Row>
                    )}

                    <div className="ex_se_d1">
                        <div className="ex_se_d2">
                            {Pagination <= load ? (
                                <span className="ex_btn2-text2">
                                    No More Car 🙄
                                </span>
                            ) : (
                                <button
                                    className="ex_btn1"
                                    onClick={() => LoadMoreData()}
                                    type="submit"
                                >
                                    <span className="ex_btn1-text">
                                        Load More
                                    </span>
                                </button>
                            )}
                        </div>
                    </div>
                </div>
                <ArtExplore />
            </div>
        </div>
    );
};

export default ExploreCars;
