import { collection, getDocs, query, where } from "firebase/firestore";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { AuthContext } from "../AuthProvider";


const useBlogger = (email) => {
    const { db } = useContext(AuthContext);
    const [isInternal, setIsInternal] = useState(false);
    useEffect(() => {
        if (email) {
            async function fetchMyAPI() {
                const q = await query(collection(db, "Go_User"), where("userid", "==", email));
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    setIsInternal(doc.data().isInternal);
                });
            }
            fetchMyAPI()
        }
        setIsInternal(false);
    }, [email, db])
    return [isInternal];
};

export default useBlogger;
