export const Navlist = [
  {
    id: 1,
    title: "Home",
    url: "/",
  },
  {
    id: 2,
    title: "Explore cars",
    url: "/explore-cars",
  },
  {
    id: 3,
    title: "About",
    url: "/about",
  },
  {
    id: 4,
    title: "Contact",
    url: "/contact",
  },
  {
    id: 5,
    title: "FAQs",
    url: "/faq",
  },
  {
    id: 6,
    title: "Blogs",
    url: "/blogs",
  }

];
