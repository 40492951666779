import GMap from "./component/Gmap/GMap";
import Contact from "./component/contact";
import FeaturedCars from "./component/featuredcars/FeaturedCars";
import IntroHome from "./component/intro/IntroHome";
import Points from "./component/points";
import Review from "./component/Review/Review";
import "./home.css";
import WhyGWT from "./component/WhyGWT/WhyGWT";
import RecentBlogs from "./component/RecentBlogs/RecentBlogs";
import CarRentalForm from "../RentCar/RentCar";
import Art from "./component/Article/Art";
import FaqH from "./component/Faq/FAQ_h";
import useTitle from "../../CustomHooks/useTitle.js";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Home = () => {
  useTitle("Car rental services in Indore | Car hire in Indore");
  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <meta
            name="description"
            content="Get the best car rental services in Indore with or without a driver from Go With Car. Choose from our wide range of cars on rent with your privacy and freedom. "
          />
        </Helmet>
      </HelmetProvider>
      <IntroHome />
      <div
        style={{
          backgroundColor: "#edf1f7",
          paddingTop: "20px",
          paddingBottom: "10px",
        }}
      >
        <CarRentalForm />
      </div>

      <div className="home_container">
        <FeaturedCars />
        <Points />
        <Contact />
      </div>
      <Review />
      <WhyGWT />
      <RecentBlogs />
      <FaqH />
      <Art />
      <GMap />
    </div>
  );
};

export default Home;
