import React from "react";
import "./Bookings.css";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";
import { AuthContext } from "../../../AuthProvider";
import { useState } from "react";

const Bookings = () => {
    const { setCarBookHint } = useContext(AuthContext);
    const [active, setActive] = useState(1);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const handleBook = async () => {
        navigate("/admin-panel/bookings/searched");
    };
    return (
        <div className="ad_book_1">
            <div>
                <div className="ad_comp_1_src_c">
                    <div className="ad_comp_1_src_c_2">
                        <FontAwesomeIcon icon={faSearch} />
                        <input
                            placeholder="search bookings by ID"
                            className="ad_comp_1_src"
                            type="text"
                            onChange={(e) => {
                                setCarBookHint(e.target.value);
                                localStorage.setItem(
                                    "carBookOfpanel",
                                    e.target.value,
                                );
                            }}
                        />
                        <button
                            onClick={handleBook}
                            className="btn_host_cus_search"
                        >
                            Search
                        </button>
                    </div>
                </div>
            </div>
            <div className="ad_book_5">
                <Link
                    className={active === 1 ? "ad_book_3_active" : "ad_book_3"}
                    to={"/admin-panel/bookings/current"}
                    onClick={() => setActive(1)}
                >
                    Current Bookings
                </Link>
                <Link
                    className={active === 2 ? "ad_book_3_active" : "ad_book_3"}
                    to={"/admin-panel/bookings/past"}
                    onClick={() => setActive(2)}
                >
                    Past Bookings
                </Link>
                <Link
                    className={active === 3 ? "ad_book_3_active" : "ad_book_3"}
                    to={"/admin-panel/bookings/upcoming"}
                    onClick={() => setActive(3)}
                >
                    Upcoming Bookings
                </Link>
                <Link
                    className={active === 4 ? "ad_book_3_active" : "ad_book_3"}
                    to={"/admin-panel/bookings/deleted"}
                    onClick={() => setActive(4)}
                >
                    Cancelled Bookings
                </Link>
            </div>
            <div className="ad_book_4">
                <Outlet />
            </div>
        </div>
    );
};

export default Bookings;
