import React, { useState } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import BlogDetails from "./Blogs/BlogDetails/BlogDetails";
import Blogs from "./Blogs/Blogs";
import WhatsApp from "./component/Whatsapp";
import HostCar from "./Dashboard";
import FAQ from "./FAQ/FAQ";
import Footer from "./Footer";
import CookiePolicy from "./Footer/Polices/CookiePolicy/CookiePolicy";
import Privacy from "./Footer/Polices/Privacy/Privacy";
import Terms from "./Footer/Polices/Terms/Terms";
import Navbar from "./navbar";
import { About, ExploreCars, Home } from "./screen";
import Contact from "./screen/Contact/Contact";
import App2 from "./screen/Home/component/firstModal/firstModal";
import AddBlog from "./Blogs/AddBlog/AddBlog";
import CarSearc from "./screen/CarSearched/CarSearc";
import RentFlow from "./screen/RentFlow/RentFlow/RentFlow";
import Protection from "./screen/RentFlow/RentFlow/Protection/Protection";
import Review from "./screen/RentFlow/Review/Review";
import Cancel from "./Footer/Polices/Cancellation & Early Returns Policy/Cancel";
import AdminPanel from "./screen/AdminPanel/AdminPanel";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Sitemap from "./Sitemap/Sitemap";
import Checkout from "./screen/RentFlow/Checkout/Checkout";
import CarList from "./screen/AdminPanel/CarsList/CarList";
import DashBoard from "./screen/AdminPanel/Dashboard/DashBoard";
import User from "./screen/AdminPanel/Dashboard/UserOrCustomer/Uer";
import Bookings from "./screen/AdminPanel/Bookings/Bookings";
import CurrentBooking from "./screen/AdminPanel/Bookings/Booking/CurrentBooking";
import UpcomingBooking from "./screen/AdminPanel/Bookings/Booking/UpcomingBooking";
import PastBooking from "./screen/AdminPanel/Bookings/Booking/PastBooking";
import RevIn from "./screen/AdminPanel/4Cards/$CardsInner/RevIn";
import TBookIn from "./screen/AdminPanel/4Cards/$CardsInner/TBookIn";
import BookedCarIn from "./screen/AdminPanel/4Cards/$CardsInner/BookedCarIn";
import AvailableIn from "./screen/AdminPanel/4Cards/$CardsInner/AvailableIn";
import HostProfiles from "./screen/AdminPanel/Hosts/HostProfiles";
import Offer from "./screen/AdminPanel/Offers/Offer";
import CityList from "./screen/AdminPanel/CityList/CityList";
import RevenueMain from "./screen/AdminPanel/RevenueMain/RevenueMain";
import DetailsCar from "./screen/AdminPanel/CarsList/Details_car/DetailsCar";
import Host from "./screen/AdminPanel/Hosts/Host";
import DeletedBookings from "./screen/AdminPanel/Bookings/Booking/DeletedBookings";
import Login1 from "./Login/Login/Login1";
import Login2 from "./Login/SignUp/SignUpG";
import Login3 from "./Login/SignUp/SignUpG2";
import SignUp from "./Login/SignUp/SignUp";
import SignUpM from "./Login/SignUp/SignUpM";
import SignUpM2 from "./Login/SignUp/SignUpM2";
import SignUpM3 from "./Login/SignUp/SignUpM3";
import SignUpM4 from "./Login/SignUp/SignUpM4";
import Notifications from "./screen/AdminPanel/Notifications/Notifications";
import Lease from "./screen/Lease/Lease";
import SearchedBookings from "./screen/AdminPanel/Bookings/Booking/SearchedBookings";
import BookingCarRoute from "./screen/RentFlow/BookFlow/BookingCarRoute";
import DirecBooking from "./screen/DirectBooking/DirecBooking";
import { Toaster } from "react-hot-toast";
import UserBookings from "./UserBookings/UserBookings";
import CurrentBookings from "./UserBookings/CurrentBookings/CurrentBookings";
import PastBookings from "./UserBookings/PastBookings/PastBookings";
import UpcomingBookings from "./UserBookings/UpcomingBookings/UpcomingBookings";
import CancelledBookings from "./UserBookings/CancelledBookings/CancelledBookings";
import CarsAdmin from "./screen/AdminPanel/CarsList/CarsAdmin";
import PendingCars from "./screen/AdminPanel/CarsList/PendingCars";
import DeclinedCars from "./screen/AdminPanel/CarsList/DeclinedCars";
import BookingSuccess from "./component/BookingSuccess";
import BookingFailure from "./component/BookingFailure";
import AppStore from "./screen/AppStore";
import RefundStatus from "./screen/AdminPanel/Notifications/RefundStatus/RefundStatus";
const App = (props) => {
  // ================================ use info redux ========================================
  // const userInfos = useSelector((state) => state);
  // const selectedUsers = userInfos.usersDataReducer?.user;
  const selectedUsers = props.userData;

  // console.log('app selectedUsers', selectedUsers);

  const user = localStorage.getItem("user");
  const [open, setOpen] = useState(true);
  const modalData = { open, setOpen };

  return (
    <HelmetProvider>
      <BrowserRouter>
        <Helmet prioritizeSeoTags>
          <link rel="canonical" href="https://www.tacobell.com/" />
        </Helmet>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/explore-cars" element={<ExploreCars />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/lease-agreement" element={<Lease />} />
          <Route path="/login-1" element={<Login1 />} />
          <Route path="/login-2" element={<Login2 />} />
          <Route path="/login-3" element={<Login3 />} />
          <Route path="/signup-mobile" element={<SignUpM />} />
          <Route path="/signup-mobile-2" element={<SignUpM2 />} />
          <Route path="/signup-mobile-3" element={<SignUpM3 />} />
          <Route path="/signup-mobile-4" element={<SignUpM4 />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/sitemap" element={<Sitemap />} />
          <Route path="/admin-panel" element={<AdminPanel />}>
            <Route path="/admin-panel/dashboard" element={<DashBoard />} />
            <Route path="/admin-panel/dashboard-revenue" element={<RevIn />} />
            <Route
              path="/admin-panel/dashboard-top-booking-cars"
              element={<TBookIn />}
            />
            <Route
              path="/admin-panel/dashboard-booked-cars"
              element={<BookedCarIn />}
            />
            <Route
              path="/admin-panel/dashboard-available-cars"
              element={<AvailableIn />}
            />
            <Route
              path="/admin-panel/notifications"
              element={<Notifications />}
            />
            <Route
              path="/admin-panel/refund-status"
              element={<RefundStatus />}
            />
            <Route path="/admin-panel/user/:id" element={<User />} />
            <Route path="/admin-panel/host/:id" element={<Host />} />
            <Route path="/admin-panel/carlist" element={<CarsAdmin />}>
              <Route
                path="/admin-panel/carlist/approved"
                element={<CarList />}
              />
              <Route
                path="/admin-panel/carlist/pending"
                element={<PendingCars />}
              />
              <Route
                path="/admin-panel/carlist/rejected"
                element={<DeclinedCars />}
              />
            </Route>
            <Route path="/admin-panel/car-details" element={<DetailsCar />} />
            <Route path="/admin-panel/hosts" element={<HostProfiles />} />
            <Route path="/admin-panel/bookings" element={<Bookings />}>
              <Route
                path="/admin-panel/bookings/current"
                element={<CurrentBooking />}
              />
              <Route
                path="/admin-panel/bookings/upcoming"
                element={<UpcomingBooking />}
              />
              <Route
                path="/admin-panel/bookings/past"
                element={<PastBooking />}
              />
              <Route
                path="/admin-panel/bookings/deleted"
                element={<DeletedBookings />}
              />
              <Route
                path="/admin-panel/bookings/searched"
                element={<SearchedBookings />}
              />
            </Route>
            <Route path="/admin-panel/offers" element={<Offer />} />
            <Route path="/admin-panel/city-list" element={<CityList />} />
            <Route path="/admin-panel/revenue" element={<RevenueMain />} />
          </Route>
          <Route path="/faq" element={<FAQ />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/adblog" element={<AddBlog />} />
          <Route path={`/blog/:id`} element={<BlogDetails />} />
          <Route path="/about" element={<About />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/cookie" element={<CookiePolicy />} />
          <Route path="/cancel-policy" element={<Cancel />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/searchedcar" element={<CarSearc />}>
            <Route path="/searchedcar/protection" element={<Protection />} />
            <Route path="/searchedcar/review" element={<Review />} />
          </Route>
          <Route path="/rentflow" element={<RentFlow />} />
          <Route path="/user-bookings" element={<UserBookings />}>
            <Route
              path="/user-bookings/user-current"
              element={<CurrentBookings />}
            />
            <Route path="/user-bookings/user-past" element={<PastBookings />} />
            <Route
              path="/user-bookings/user-upcoming"
              element={<UpcomingBookings />}
            />
            <Route
              path="/user-bookings/user-cancelled"
              element={<CancelledBookings />}
            />
          </Route>
          <Route path={`/book/:id`} element={<BookingCarRoute />} />
          <Route path={`/direct-book/:id`} element={<DirecBooking />} />
          <Route path="/success" element={<BookingSuccess />} />
          <Route path="/failure" element={<BookingFailure />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route
            path={`/${
              selectedUsers?.displayName || selectedUsers?.phoneNumber || ""
            }`}
            element={<HostCar />}
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        <Navbar />
        <Toaster />
        {/* jubaer worked here */}
        {!user && <App2 modalData={modalData}></App2>}
        <div id="recaptcha-container"></div>
        <WhatsApp />
        <Footer />
      </BrowserRouter>
    </HelmetProvider>
  );
};

export default AppStore(App);
