import React from 'react';
import './Cookie.css'


const CookiePolicy = () => {
    return (
        <div className='Ccontainer'>
            <div className='Cheader'> <h2 >Cookie Policy</h2></div>
            <section>
                <p>The cookies we use allow our website to remember your preferences, improve the user experience and tailor the advertisements you see to those that are most relevant to you. These cookies refresh upon each website visit or to another site that recognises the cookie based on the categories described below.
          </p><br />
                <p>We also use other forms of technology which serve a similar purpose to cookies, such a pixel tags, and which allow us to monitor and improve our sites and mobile apps. When we talk about cookies in this Policy, this term includes these similar technologies.</p><br />
                <b>Why Do We Use Cookies? </b><br /><br />
             <b>   We use the following types of cookies:</b> <br /><br />
                <div className='Clists'>
                Strictly necessary cookies are used to allow you to navigate a website or mobile app and use its features such as accessing secure areas of the website or making a reservation. These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling out forms. You can set your browser or device settings to block or alert you about these cookies, but if you do so, some parts of the site or mobile application may not work. These cookies do not store any personally identifiable information. These types of cookies will expire within one year from your last website visit.
                <br /><br />
                Functional cookies  allow us to measure website usage and improve website and mobile app performance and customer experiences, such as remembering the choices you make and providing more personal features like storing your login account and language or geographic preferences. Depending on their function, some of these cookies may expire seven days after your last website visit, while others may remain persistently until they are deleted by you
                <br /><br />
                Advertising cookies are used to deliver advertisements that are more relevant to you. Third parties that serve ads on our behalf use cookies to help select the ads that are displayed to you and ensure that you do not see the same ads repeatedly as well as to measure the effectiveness of advertising campaigns. Depending on their function, some of these cookies may expire thirty days after your last website visit, while others may remain persistently until they are deleted by you

                </div>
                <br /><br />
                For more information on how we process your personal data and to exercise your rights in relation to your personal data please consult our Privacy Policy.
            </section>
            
        </div>
    );
};

export default CookiePolicy;