import React from "react";
import "./sitemap.css";
import { Link } from "react-router-dom";

const Sitemap = () => {
	return (
		<div className='site_container'>
			<ul>
				<li>
					<Link to={"/"}>Home</Link>
				</li>
				<li>
					<Link to={"/explore-cars"}>ExploreCars</Link>
				</li>
				<li>
					<Link to={"/contact"}>Contact</Link>
				</li>
				<li>
					<Link to={"/faq"}>FAQ</Link>
				</li>
				<li>
					<Link to={"/blogs"}>Blogs</Link>
				</li>
				<li>
					<Link to={"/about"}>About</Link>
				</li>
				<li>
					<Link to={"/privacy"}>Privacy Policy</Link>
				</li>
				<li>
					<Link to={"/cookie"}>Cookie Policy</Link>
				</li>
				<li>
					<Link to={"/cancel-policy"}>Cancel Policy</Link>
				</li>
				<li>
					<Link to={"/terms"}>Terms of use</Link>
				</li>
			</ul>
		</div>
	);
};

export default Sitemap;
