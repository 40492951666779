import { Button, Modal } from 'antd';
import { useState } from 'react';
import { imgPath } from "../../../../R/Images/imgPath";
import './modal.css';

const App2 = ({modalData}) => {
    // jubaer working here.
  const [loading, setLoading] = useState(false);
  const {open,setOpen}=modalData;
  const handleOk = () => {
    localStorage.setItem('user','cookie accepted');
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 200);
  };
  const handleCancel = () => {
    localStorage.setItem('user','cookie denied');
    setOpen(false);
  };
  return (
      <Modal 
        className='modal_container'
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <div>
            <div  style={{width:'100%',marginInline:'auto',textAlign:'center'}}>
              <img className="about_logo" src={imgPath.logo} alt="" />
              </div>
        Please note that by continuing to use our websites, products, or services, you acknowledge that you have read and agree to the terms of the Privacy Policy...
        </div>
        <div className='modal_buttons'>
        <div>
         <Button className='btn' key="submit" type="primary"  danger loading={loading} onClick={handleOk}>
            Agree and close
          </Button>
         </div>
        <div>
        <Button className='btn' key="back"  danger onClick={handleCancel}>
            Disagree and close
          </Button>
        </div>
        
          {/* <div>
          <Button
            className='btn'
            key="link"
            href="https://google.com"
            danger
            loading={loading}
            onClick={handleOk}
          >
            Learn More 
          </Button>
          </div> */}
        </div>
      </Modal>
  );
};
export default App2;