import React from "react";
import "./Top.css";

const Top = () => {
    return (
        <div className="Ch_main">
            <div className="Ch_background">
                <div className="Ch_d1">
                    <div className="Ch_d2">
                        <div className="Ch_d3">
                            <h1 className="Ch_h1">
                                Contact us
                                {/* to get the best service from Go
								<span className='introTitleRed'>With</span>Car */}
                            </h1>
                            <p className="Ch_p1">
                                With Go
                                <span className="introTitleRed">
                                    {" "}
                                    With
                                </span>{" "}
                                Car, you can rent a car at a time that is
                                convenient to you and get it delivered right to
                                your doorstep. Apart from an hourly rate
                                starting at just Rs. 159/hour, Go
                                <span className="introTitleRed"> With</span> Car
                                also offers flexible car rental services.
                            </p>
                        </div>
                        <div className="Ch_d3">
                            <lottie-player
                                autoplay
                                loop
                                mode="normal"
                                src=" https://assets1.lottiefiles.com/packages/lf20_cwb6avsb.json"
                            ></lottie-player>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Top;
