import React, { useContext, useState, useEffect } from "react";
import "./CBook.css";
import Book from "./Book";
import { AuthContext } from "../../../../AuthProvider";
import {
    collection,
    getDocs,
    query,
    where,
    doc,
    updateDoc,
} from "firebase/firestore";
import { useNavigate } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";

const CurrentBooking = () => {
    const { db, user } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [book, setBook] = useState([]);
    const navigate = useNavigate();
    const [bookId, setBookId] = useState("");
    const [carName, setCarName] = useState("");
    const [showPopup, setShowPopup] = useState(false);
    const [showPopup2, setShowPopup2] = useState(false);
    const [userId, setUserId] = useState("");
    const [hostId, setHostId] = useState("");
    const [carId, setCarId] = useState("");
    const [adminId, setAdminId] = useState("");
    const [adminName, setAdminName] = useState("");
    const [fcmTokenHost, setFcmTokenHost] = useState("");
    const [fcmTokenUser, setFcmTokenUser] = useState("");
    const [selectedFilter, setSelectedFilter] = useState("all"); // Default filter: All bookings
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const fetchUser = async () => {
        if (user?.phoneNumber) {
            const q = query(
                collection(db, "users"),
                where("mobileNumber", "==", user.phoneNumber),
            );
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                setAdminName(doc.data().name);
                setAdminId(doc.data().id);
            });
        }
        if (user?.email) {
            const q = query(
                collection(db, "users"),
                where("email", "==", user.email),
            );
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                setAdminName(doc.data().name);
                setAdminId(doc.data().id);
            });
        }
    };

    useEffect(() => {
        fetchUser();
    }, [user]);

    const handleDateFilterChange = (filter) => {
        setSelectedFilter(filter);
    };

    const filterBookings = (bookings) => {
        const today = new Date();
        let filteredBookings = bookings;
        if (selectedFilter === "last7days") {
            const last7Days = new Date(today);
            last7Days.setDate(today.getDate() - 7);
            filteredBookings = bookings.filter(
                (booking) => booking.pickup_date.toDate() >= last7Days,
            );
        } else if (selectedFilter === "lastMonth") {
            const lastMonth = new Date(today);
            lastMonth.setMonth(today.getMonth() - 1);
            filteredBookings = bookings.filter(
                (booking) => booking.pickup_date.toDate() >= lastMonth,
            );
        } else if (selectedFilter === "last3Months") {
            const last3Months = new Date(today);
            last3Months.setMonth(today.getMonth() - 3);
            filteredBookings = bookings.filter(
                (booking) => booking.pickup_date.toDate() >= last3Months,
            );
        } else if (selectedFilter === "last6Months") {
            const last6Months = new Date(today);
            last6Months.setMonth(today.getMonth() - 6);
            filteredBookings = bookings.filter(
                (booking) => booking.pickup_date.toDate() >= last6Months,
            );
        } else if (selectedFilter === "lastYear") {
            const lastYear = new Date(today);
            lastYear.setFullYear(today.getFullYear() - 1);
            filteredBookings = bookings.filter(
                (booking) => booking.pickup_date.toDate() >= lastYear,
            );
        } else if (selectedFilter === "customDate") {
            if (startDate && endDate) {
                const start = new Date(startDate);
                const end = new Date(endDate);
                filteredBookings = bookings.filter(
                    (booking) =>
                        booking.pickup_date.toDate() >= start &&
                        booking.pickup_date.toDate() <= end,
                );
            }
        } else {
            // No filter selected, return all bookings
        }

        return filteredBookings;
    };

    const fetchCurrentBookings = async () => {
        try {
            const q = query(
                collection(db, "bookings"),
                where("status", "==", "running"),
            );
            const arr = [];

            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                arr.push(doc.data());
            });

            const filteredBookings = filterBookings(arr);
            setBook(filteredBookings);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching current bookings:", error);
            // Handle the error appropriately (e.g., show an error message to the user).
        }
    };

    useEffect(() => {
        fetchCurrentBookings();
    }, [selectedFilter, endDate]);

    const handleShowAll = () => {
        fetchCurrentBookings();
    };

    const handleBookAll = async (Hint) => {
        setLoading(false);
        const q = query(
            collection(db, "bookings"),
            where("car_details.id", "==", Hint),
        );
        const querySnapshot = await getDocs(q);
        let arr = [];
        querySnapshot.forEach((doc) => {
            arr.push(doc.data());
        });
        setBook(arr);
        setLoading(false);
    };

    const fetchtokenUser = async () => {
        const q = query(collection(db, "users"), where("id", "==", userId));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            setFcmTokenUser(doc.data().fcmToken);
        });
    };

    const fetchtokenHost = async () => {
        const q = query(collection(db, "users"), where("id", "==", hostId));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            setFcmTokenHost(doc.data().fcmToken);
        });
    };

    const handleApprove = async () => {
        fetchtokenUser();
        fetchtokenHost();
        notApprove();
        const docRef = doc(db, "bookings", bookId);
        await updateDoc(docRef, {
            status: "completed",
            extraKmCharges: extraKmCharges * 1,
            extraDriverCharges: extraDriverCharges * 1,
        }).then(() => {});
        const docRef2 = doc(db, "cars", carId);
        await updateDoc(docRef2, {
            bookingStatus: "available",
        }).then(() => {
            fetchCurrentBookings();
        });
        navigate("/admin-panel/bookings/current");
        setShowPopup(false);
    };

    const notApprove = async () => {
        fetch("https://fcm.googleapis.com/fcm/send", {
            method: "POST",
            headers: {
                Authorization:
                    "key=AAAAyJi_4Nk:APA91bG5GURylNJNyMezcKb9e3RH9LprJWIMZlzZIygbsNWZ5YYVAamsT_rr-N1LZhUN0m-6IcFVa9v9Z953JD24jij7WalA5aEX3uppmB2mQey4JTU0CXC6761umQ4eH45WMHVn4tg9",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                registration_ids: [fcmTokenHost, fcmTokenUser],
                notification: {
                    title: "One booking has been approved",
                    body: `${adminName} approved a booking of ${carName}`,
                    channelId: "GoWithCar",
                    channelName: "GoWithCar",
                },
            }),
        })
            .then((res) => res.json())
            .then((res) => {
                const ref = doc(collection(db, "users"));
                db.collection("notifications")
                    .doc(adminId)
                    .collection("notification")
                    .doc(ref.id)
                    .set({
                        id: ref.id,
                        from: adminId,
                        to: [userId, hostId],
                        title: "One booking has been approved",
                        body: `${adminName} approved a booking of ${carName}`,
                        type: "approved",
                        isRead: false,
                        date: new Date(),
                    });
            })
            .catch((e) => {});
    };

    const [extraKmCharges, setExtraKmCharges] = useState(0);
    const [extraDriverCharges, setExtraDriverCharges] = useState(0);

    const handleDelete = async () => {
        notCancel();
        const docRef = doc(db, "bookings", bookId);
        await updateDoc(docRef, {
            deleted: true,
        }).then(() => {
            fetchCurrentBookings();
        });
        navigate("/admin-panel/bookings/current");
        setShowPopup2(false);
    };

    const notCancel = async () => {
        fetch("https://fcm.googleapis.com/fcm/send", {
            method: "POST",
            headers: {
                Authorization:
                    "key=AAAAyJi_4Nk:APA91bG5GURylNJNyMezcKb9e3RH9LprJWIMZlzZIygbsNWZ5YYVAamsT_rr-N1LZhUN0m-6IcFVa9v9Z953JD24jij7WalA5aEX3uppmB2mQey4JTU0CXC6761umQ4eH45WMHVn4tg9",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                registration_ids: [fcmTokenHost, fcmTokenUser],
                notification: {
                    title: "One booking has been cancelled",
                    body: `${adminName} cancelled a booking of ${carName}`,
                    channelId: "GoWithCar",
                    channelName: "GoWithCar",
                },
            }),
        })
            .then((res) => res.json())
            .then((res) => {
                const ref = doc(collection(db, "users"));
                db.collection("notifications")
                    .doc(adminId)
                    .collection("notification")
                    .doc(ref.id)
                    .set({
                        id: ref.id,
                        from: adminId,
                        to: [userId, hostId],
                        title: "One booking has been cancelled",
                        body: `${adminName} cancelled a booking of ${carName}`,
                        type: "cancelled",
                        isRead: false,
                        date: new Date(),
                    });
            })
            .catch((e) => {});
    };

    const [isBack, setIsBack] = useState(false);

    return (
        <div>
            {showPopup && (
                <div className="popup-screen">
                    <div className="popup">
                        <div className="popup_p">
                            <div>
                                <h2>Complete booking now?</h2>
                            </div>
                        </div>
                        <div className="popup_p-c">
                            <div>
                                <label htmlFor="">
                                    Extra Charges per KM (Optional)
                                </label>
                                <input
                                    className="popup-b-i-p"
                                    type="number"
                                    placeholder="Extra KM Charges"
                                    onChange={(e) =>
                                        setExtraKmCharges(e.target.value)
                                    }
                                />
                            </div>
                            <div>
                                <label htmlFor="">
                                    Extra Driver Charges (Optional)
                                </label>
                                <input
                                    className="popup-b-i-p"
                                    type="number"
                                    placeholder="Extra Driver Charges"
                                    onChange={(e) =>
                                        setExtraDriverCharges(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                        <div className="popup_b">
                            <button onClick={handleApprove}>Yes</button>
                            <button onClick={() => setShowPopup(false)}>
                                No
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {showPopup2 && (
                <div className="popup-screen">
                    <div className="popup">
                        <div className="popup_p">
                            <h2>Want to remove now?</h2>
                        </div>
                        <div className="popup_b">
                            <button onClick={handleDelete}>Yes</button>

                            <button onClick={() => setShowPopup2(false)}>
                                No
                            </button>
                        </div>
                    </div>
                </div>
            )}

            <div>
                <h1 className="bookHead">Current Bookings</h1>
            </div>
            {/* <div className="date-filters-con">
                <div>
                    <div className="date-filters">
                        <button
                            className={selectedFilter === "all" ? "active" : ""}
                            onClick={() => handleDateFilterChange("all")}
                        >
                            All
                        </button>
                        <button
                            className={
                                selectedFilter === "last7days" ? "active" : ""
                            }
                            onClick={() => handleDateFilterChange("last7days")}
                        >
                            Last 7 Days
                        </button>
                        <button
                            className={
                                selectedFilter === "lastMonth" ? "active" : ""
                            }
                            onClick={() => handleDateFilterChange("lastMonth")}
                        >
                            Last Month
                        </button>
                        <button
                            className={
                                selectedFilter === "last3Months" ? "active" : ""
                            }
                            onClick={() =>
                                handleDateFilterChange("last3Months")
                            }
                        >
                            Last 3 Months
                        </button>
                        <button
                            className={
                                selectedFilter === "last6Months" ? "active" : ""
                            }
                            onClick={() =>
                                handleDateFilterChange("last6Months")
                            }
                        >
                            Last 6 Months
                        </button>
                        <button
                            className={
                                selectedFilter === "lastYear" ? "active" : ""
                            }
                            onClick={() => handleDateFilterChange("lastYear")}
                        >
                            Last 1 Year
                        </button>
                        <button
                            className={
                                selectedFilter === "customDate" ? "active" : ""
                            }
                            onClick={() => handleDateFilterChange("customDate")}
                        >
                            Custom Date
                        </button>
                    </div>
                    {selectedFilter === "customDate" && (
                        <div className="date-filters-con">
                            <div className="custom-date-range">
                                <label>Start Date:</label>
                                <input
                                    type="date"
                                    placeholder={startDate}
                                    value={startDate}
                                    onChange={(e) => {
                                        setStartDate(e.target.value);
                                    }}
                                />
                                <label>End Date:</label>
                                <input
                                    type="date"
                                    placeholder={endDate}
                                    value={endDate}
                                    onChange={(e) => {
                                        setEndDate(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div> */}
            <div className={`d_btn_bck_con ${isBack ? "" : "show_none"}`}>
                <button
                    className="d_btn_bck"
                    onClick={() => {
                        setIsBack(!isBack);
                        handleShowAll();
                    }}
                >
                    <FontAwesomeIcon icon={faArrowAltCircleLeft} /> Go Back{" "}
                </button>
            </div>
            <div>
                {loading ? (
                    <h1>Loading....</h1>
                ) : (
                    <div className="c_book_c" style={{justifyContent:'center',display:'flex'}}>
                        {book.length === 0 && <h2>No bookings</h2>}
                        {book.map((c, i) => (
                            <Book
                                c={c}
                                handleBookAll={handleBookAll}
                                setBookId={setBookId}
                                setShowPopup={setShowPopup}
                                setShowPopup2={setShowPopup2}
                                setCarName={setCarName}
                                setUserId={setUserId}
                                setHostId={setHostId}
                                setCarId={setCarId}
                                key={i}
                            />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default CurrentBooking;
