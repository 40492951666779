import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../AuthProvider";
import {
  Timestamp,
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGasPump,
  faGear,
  faLocationDot,
  faMinus,
  faPercent,
  faPlus,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import "./BookingCarRoute.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { BASE_URL, numberWithCommas } from "../../../Utils";
import { useDispatch } from "react-redux";
import AppStore from "../../AppStore";
// import { setBookingDetails } from "../../../Redux/Action/Action";

const BookingCarRoute = (props) => {
  const {
    db,
    bookedCarID,
    duration,
    pickupDate,
    returnDate,
    user = {},
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [car, setCar] = useState(null);
  const [numBook, setnumBook] = useState(0);
  const [input1, setInput1] = useState("");
  const [input2, setInput2] = useState("");
  const [syncInputs, setSyncInputs] = useState(false);
  const [isActive, setIsActive] = useState(null);
  const [amount, setAmount] = useState(0);
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [isChecked3, setIsChecked3] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [promos, setPromos] = useState([]);
  const [promo, setPromo] = useState(null);
  const [promoId, setPromoId] = useState([]);
  const [promoCode, setPromoCode] = useState("");
  const [userUsed, setUserUsed] = useState(false);
  const [promoWarning, setPromoWarnig] = useState("");
  const [touched, setTouched] = useState(false);
  const [promoted, setpromoted] = useState(false);
  const [location, setLocation] = useState("");
  const [basePrice, setBasePrice] = useState(null);
  const [total, setTotal] = useState(null);
  const [preTotal, setPritotal] = useState(null);
  const [unlimitedAdd, setUnlimitedAdd] = useState(false);
  const [selected, setSelected] = useState("");
  const [isPopupOpen2, setIsPopupOpen2] = useState(false);
  const [propop, setpropop] = useState(false);
  const [pickupDateNow, setPickupDateNow] = useState({ date: "", time: "" });
  const [rturnDateNow, setReturnDateNow] = useState({ date: "", time: "" });
  const [offerDetails, setOfferDetails] = useState("");
  const [distance, setDistance] = useState(112);
  const [showDue, setShowDue] = useState(false);

  const handleCheckbox1Change = () => {
    setIsChecked1(!isChecked1);
    if (!isChecked1) {
      setAmount(amount + 500);
    } else {
      setAmount(amount - 500);
    }
  };

  const handleCheckbox2Change = () => {
    setIsChecked2(!isChecked2);
    if (!isChecked2) {
      setAmount(amount + 500);
    } else {
      setAmount(amount - 500);
    }
  };

  const handlePopupAgree = () => {
    setIsPopupOpen(false);
    setIsChecked3(true);
  };

  const handleSlide = (i) => {
    if (isActive === i) {
      return setIsActive(null);
    }
    setIsActive(i);
  };

  const handleInputChange = (event, inputNumber) => {
    const inputValue = event.target.value;
    if (inputNumber === 1) {
      setInput1(inputValue);
      if (syncInputs) {
        setInput2(inputValue);
      }
    }
    if (inputNumber === 3) {
      setPromoCode(inputValue);
    } else if (inputNumber === 2) {
      setInput2(inputValue);
    }
  };

  const handleSyncCheckboxChange = () => {
    if (!syncInputs) {
      setInput2(input1);
    } else {
      setInput2("");
    }
    setSyncInputs(!syncInputs);
  };

  const handleCar = async () => {
    const q = query(collection(db, "cars"), where("id", "==", bookedCarID));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      setCar(doc.data());
      handleLocation(doc.data().location);
      basePriceCalculate(doc.data().basePrice);
      setnumBook(doc.data()?.numOfBooking);
    });

    setLoading(false);
  };

  useEffect(() => {
    handleCar();
  }, [bookedCarID]);

  // promocode here

  const handlePromos = async () => {
    const q = query(collection(db, "offers"));
    let availableCar = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      availableCar.push(doc.data());
    });
    setPromos(availableCar);
  };
  const handlePromo = async () => {
    const q = query(collection(db, "offers"), where("id", "==", promoId));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      setPromo(doc.data());
      setOfferDetails(doc.data());
    });
  };

  const [userDetails, setUserDetails] = useState("");
  const handlePromoUsed = async () => {
    console.log("user 170", user);
    if (user?.phoneNumber) {
      const q = query(
        collection(db, "users"),
        where("mobileNumber", "==", user?.phoneNumber)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        setUserDetails(doc.data());
        if (doc?.data()?.offers) {
          const found = doc.data().offers.some((offer) => offer === promoCode);
          setUserUsed(found);
        }
      });
    }
  };

  useEffect(() => {
    handlePromo();
    handlePromoUsed();
  }, [promoId, user, promoCode]);

  const handleLocation = async (location) => {
    try {
      const q = query(collection(db, "location"), where("id", "==", location));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          setLocation(doc.data().name);
        });
      } else {
        // Handle the case where no location matches the query.
      }
    } catch (error) {
      console.error("Error fetching location:", error);
    }
  };

  const handlePromoCode = (code) => {
    if (!userUsed) {
      setpromoted(true);
    }
    setTouched(true);
    if (code === "pny") {
      if (promo.multiple === false && userUsed) {
        setPromoWarnig("Already Used this coupon");
      } else {
        // Calculate 20% of the base price
        const percentDiscount = total * 0.2;
        // Apply the maximum discount of 300
        const discountedPrice = Math.max(
          total - Math.min(percentDiscount, 300),
          0
        );
        setTotal(discountedPrice);
        setPromoWarnig("");
        const ref = doc(
          collection(db, "users"),
          where("mobileNumber", "==", user.phoneNumber)
        );
        db.doc(ref)
          .set(
            {
              offers: ["pny"],
            },
            { merge: true }
          )
          .then(() => {
            handlePromoUsed();
          })
          .catch((error) => console.log(error));
      }
    } else if (code === "GWC10") {
      if (promo.multiple === false && userUsed) {
        setPromoWarnig("Already Used this coupon");
      } else {
        // Cut 200 from the base price
        const discountedPrice = Math.max(total - 200, 0);
        setTotal(discountedPrice);
        setPromoWarnig("");
        // handleUpToStore();
      }
    }
  };

  const handleUpToStore = async () => {
    const ref = doc(
      collection(db, "users"),
      where("mobileNumber", "==", user.phoneNumber)
    );
    const offers = [promoCode];
    await setDoc(
      ref,
      {
        offers,
      },
      { merge: true }
    )
      .then(() => { })
      .catch((error) => console.log(error));
  };

  // pricings here

  const handleUnlimited = () => {
    if (unlimitedAdd) {
      setUnlimitedAdd(!unlimitedAdd);
      setSelected("");
    } else {
      setIsPopupOpen2(!isPopupOpen2);
    }
  };

  const basePriceCalculate = (baseprice) => {
    setLoading(true);
    const carDuration = duration * 1;
    const carBasePrice = 1 * baseprice;
    const calculateBasePrice = () => {
      const percentage = (percent, value) => {
        return (percent / 100) * value;
      };
      if (carDuration <= 8) {
        return carBasePrice * 8;
      } else if (carDuration <= 12 && carDuration > 8) {
        let percent = percentage(10, carBasePrice);
        let totalBasePrice = carBasePrice - percent;
        return 1 * totalBasePrice * carDuration;
      } else if (carDuration <= 15 && carDuration > 12) {
        let percent = percentage(20, carBasePrice);
        let totalBasePrice = carBasePrice - percent;
        return 1 * totalBasePrice * carDuration;
      } else if (carDuration <= 18 && carDuration > 15) {
        let percent = percentage(25, carBasePrice);
        let totalBasePrice = carBasePrice - percent;
        return 1 * totalBasePrice * carDuration;
      } else {
        let percent = percentage(30, carBasePrice);
        let totalBasePrice = carBasePrice - percent;
        return 1 * totalBasePrice * carDuration;
      }
    };
    setBasePrice(calculateBasePrice());
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    const base2 = 1 * amount;
    const total = 2000 + basePrice + base2;
    const mainTotal = selected === "unlimited" ? total + 2600 : total;
    setPritotal(mainTotal);
    setTotal(mainTotal);
    setLoading(false);
  }, [basePrice, amount, selected]);

  useEffect(() => {
    setLoading(true);
    handleCar();
    handlePromos();
  }, [bookedCarID]);

  // date range here

  useEffect(() => {
    const pickupDateString = pickupDate;
    const returnDateString = returnDate;

    const parseAndFormat = (dateString) => {
      const parsedDate = new Date(dateString);
      return {
        date: parsedDate.toLocaleDateString(),
        time: parsedDate.toLocaleTimeString(),
      };
    };
    setPickupDateNow(parseAndFormat(pickupDateString));
    setReturnDateNow(parseAndFormat(returnDateString));
  }, []);

  // proceed

  const handlepropop = () => {
    if (userDetails?.dueAmount) {
      setShowDue(true);
    } else {
      setpropop(!propop);
    }
  };

  // const handleUpdateCar = async () => {
  //   const docRef = doc(db, "cars", car?.id);
  //   // Update the timestamp field with the value from the server
  //   await updateDoc(docRef, {
  //     numOfBooking: numBook + 1,
  //     bookingStatus: "booked",
  //   }).then(() => {});
  // };

  const setData = async () => {
    const bookingDetails = {
      status: "booked",
      payment_mode: "online",
      pickup_date: Timestamp.fromDate(new Date(pickupDate)),
      return_date: Timestamp.fromDate(new Date(returnDate)),
      applied_offer: offerDetails,
      total_amount: total,
      protection_extras: 0,
      protection_plans: "",
      protections: "",
      car_details: car,
      passenger_details: userDetails,
      hostId: car?.hostId,
      passengerId: userDetails?.mobileNumber,
      host_details: {
        id: car.hostId,
      },
      duration: duration,
      cityId: car.location,
      cityName: location,
      driverType: car.driverType,
      delivery_pick_charge: amount,
      pickupLocation: input1,
      dropLocation: input2,
      ourLocation: car.pickupAddress,
      security_deposit: 2000,
      basePrice: basePrice,
      selectedPlan: selected,
      distance: selected === "unlimited" ? 0 : distance,
    };
    props.setBookingDetailsAction({ bookingDetails })
    // await dispatch(setBookingDetails({ bookingDetails }));
  };

  // const handleUploadData = async () => {
  //   try {
  //     const ref = doc(collection(db, "bookings"));
  //     const data = {
  //       id: ref.id,
  //       status: "booked",
  //       payment_mode: "online",
  //       pickup_date: Timestamp.fromDate(new Date(pickupDate)),
  //       return_date: Timestamp.fromDate(new Date(returnDate)),
  //       applied_offer: offerDetails,
  //       total_amount: total,
  //       protection_extras: 0,
  //       protection_plans: "",
  //       protections: "",
  //       car_details: car,
  //       passenger_details: userDetails,
  //       hostId: car?.hostId,
  //       passengerId: userDetails?.mobileNumber,
  //       host_details: {
  //         id: car.hostId,
  //       },
  //       duration: duration,
  //       cityId: car.location,
  //       cityName: location,
  //       driverType: car.driverType,
  //       delivery_pick_charge: amount,
  //       pickupLocation: input1,
  //       dropLocation: input2,
  //       ourLocation: car.pickupAddress,
  //       security_deposit: 2000,
  //       basePrice: basePrice,
  //       selectedPlan: selected,
  //       distance: selected === "unlimited" ? 0 : distance,
  //     };

  //     await setDoc(ref, data);
  //   } catch (error) {
  //     console.error("Error uploading data:", error);
  //   }
  // };

  const handleProceed = async () => {
    try {
      const data = {
        name: user?.displayName,
        amount: total,
        number: user?.phoneNumber,
        MUID: "MUID" + Date.now(),
      };

      setLoading(true);

      // At instance level
      const instance = axios.create({
        // httpsAgent: new https.Agent({
        //   rejectUnauthorized: false,
        // }),
      });
      // instance.get("https://something.com/foo");

      // At request level
      // const agent = new https.Agent({
      //   rejectUnauthorized: false,
      // });
      // axios.get("https://something.com/foo", { httpsAgent: agent });

      await instance
        .post(`${BASE_URL}api/phonepe/payment`, {
          ...data,
        })
        .then(async (res) => {
          await setData();
          setLoading(false);
          window.location.href = res.data;

          // navigate("/success");
          //   handleUploadData();
          //   handleUpdateCar();
          //   navigate("/");
          //   toast.success("Booking confirmed");
          //   window.scroll(0, 0);
          //   setpropop(false);
        })
        .then((data) => {
          setLoading(false);
        });
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <div className="book-car-con">
      {showDue && (
        <div className="popup-screen-book">
          <div className="popup-book">
            <div className="popup_p-book">
              <h3
                style={{
                  textAlign: "center",
                }}
              >
                Due Amount
              </h3>
            </div>
            <div className="popup_p-book-2">
              Please pay {userDetails?.dueAmount}₹ to book a car again
            </div>
            <div className="popup-ul-book"></div>
            <button
              className="book-proceed2-buttonShadow book-proceed2-button"
              onClick={() => {
                setShowDue(false);
              }}
            >
              Close
            </button>
          </div>
        </div>
      )}
      {isPopupOpen2 && (
        <div className="popup-screen-book">
          <div className="popup-book">
            <div className="popup_p-book">
              <h2 className="high-text-book">Add Plan</h2>
              <p>Duration/day</p>
              <br />
              <h3>KM Plan</h3>
            </div>
            <div className="popup_p-book-2">
              <div className="high-text-book-2">
                <p>2600/day</p>
                <small>unlimited km</small>
              </div>
            </div>
            <div className="popup-ul-book">
              <ul>
                Please note : <br />
                <br />
                <li>
                  Pricing Plan cannot be changed after the creation of a
                  booking. Extra kms charge: Rs 10/km
                </li>
                <li>
                  We do not permit taking Rev vehicles to Leh/Ladakh region,
                  Kaza/Nako region and Spiti valley
                </li>
              </ul>
            </div>
            <button
              className="book-proceed2-buttonShadow book-proceed2-button"
              onClick={() => {
                setIsPopupOpen2(false);
                setUnlimitedAdd(true);
                setSelected("unlimited");
              }}
            >
              Add
            </button>
          </div>
        </div>
      )}
      {propop && (
        <div className="popup-screen-book">
          <div className="popup-book">
            <div className="popup_p-book">
              <h2 className="high-text-book">Confirm Booking?</h2>
            </div>
            <div className="popup-ul-book">
              {/* Note : <br /> */}
              Pay INR {numberWithCommas(total)} to confirm the booking?
            </div>
            <div className="proceed-book-btn">
              <button
                className="book-proceed2-buttonShadow book-proceed2-button"
                onClick={() => {
                  handleProceed();
                }}
              >
                Confirm
              </button>
              <button
                className="book-proceed2-buttonShadow book-proceed2-button"
                onClick={() => {
                  setpropop(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {loading ? (
        <div>
          <h1>Loading...</h1>
        </div>
      ) : (
        <div className="bc_map_main">
          {/* main box start */}
          <div className="bc_d3">
            <div className="options">
              <div className="option-box">
                <div className="bc_d7">
                  <div className="spec-book-car">
                    <h4>Specifications :</h4>
                  </div>
                  <div className="bc_d9">
                    <FontAwesomeIcon
                      icon={faUserGroup}
                      style={{
                        height: "15px",
                        width: "15px",
                        paddingRight: "5px",
                        paddingLeft: "10px",
                        color: "#ff8080",
                      }}
                    />
                    <span className="bc_span1">{car?.seatingCapacity}</span>
                    seater
                  </div>
                  <div className="bc_d9">
                    <FontAwesomeIcon
                      icon={faGear}
                      style={{
                        height: "15px",
                        width: "15px",
                        paddingRight: "5px",
                        paddingLeft: "10px",
                        color: "#ff8080",
                      }}
                    />
                    <span className="bc_span1">{car?.transmissionType}</span>
                  </div>
                  <div className="bc_d9">
                    <FontAwesomeIcon
                      icon={faGasPump}
                      style={{
                        height: "15px",
                        width: "15px",
                        paddingRight: "5px",
                        paddingLeft: "10px",
                        color: "	 #ff8080",
                      }}
                    />
                    <span className="bc_span1">{car?.fuelType}</span>
                  </div>
                </div>
              </div>
              <div className="option-box-1">
                <div className="inner-option-1">
                  <div>
                    <small>{`${Math.floor(duration / 24)} days ${duration % 24
                      } hours`}</small>
                  </div>
                </div>
                <div className="inner-option-2">
                  <div className="duration-div-book">
                    {pickupDateNow.date}
                    <FontAwesomeIcon
                      style={{
                        color: "rgba(255, 0, 0, 0.658)",
                        marginLeft: "5px",
                      }}
                      icon={faLocationDot}
                    />
                    <span className="duration-gap-book">-----------</span>
                    <FontAwesomeIcon
                      style={{
                        color: "rgba(255, 0, 0, 0.658)",
                        marginRight: "5px",
                      }}
                      icon={faLocationDot}
                    />
                    {rturnDateNow.date}
                  </div>
                </div>
                <div className="inner-option-3">
                  <div>
                    <small>{pickupDateNow.time}</small>
                  </div>
                  <div>
                    <small>{rturnDateNow.time}</small>
                  </div>
                </div>
              </div>
              {car?.driverType === "Self Drive" && (
                <div className="option-box-2">
                  <div className="option-box-2-inner">
                    <div className="box-5-book-inner-1">
                      {" "}
                      <FontAwesomeIcon
                        style={{
                          height: "30px",
                          color: "rgba(255, 0, 0, 0.658)",
                        }}
                        icon={faLocationDot}
                      />{" "}
                      <p className="option-box-2-inner-p">
                        {" "}
                        Add pick up and drop location{" "}
                      </p>{" "}
                    </div>
                    <div className="box-5-book-inner-1">
                      {isActive === 1 ? (
                        <FontAwesomeIcon
                          style={{
                            height: "20px",
                            color: "rgba(255, 0, 0, 0.658)",
                            cursor: "pointer",
                          }}
                          icon={faMinus}
                          onClick={() => handleSlide(1)}
                        />
                      ) : (
                        <FontAwesomeIcon
                          style={{
                            height: "20px",
                            color: "rgba(255, 0, 0, 0.658)",
                            cursor: "pointer",
                          }}
                          icon={faPlus}
                          onClick={() => handleSlide(1)}
                        />
                      )}
                    </div>
                  </div>
                  <div className="accordion-book-item">
                    <div
                      className={
                        isActive === 1
                          ? "accordion-book-content-open"
                          : "accordion-book-content-close"
                      }
                    >
                      <div>
                        <label>Pickup Location : </label>
                        <input
                          className="book-input-location"
                          type="text"
                          value={input1}
                          onChange={(e) => handleInputChange(e, 1)}
                        />
                        <div className="checkbox-container">
                          <input
                            type="checkbox"
                            checked={isChecked1}
                            onChange={handleCheckbox1Change}
                          />
                          <label className="checkbox-label">
                            {isChecked1 ? (
                              <span className="greenHead">
                                Pickup fee ₹ 500 included
                              </span>
                            ) : (
                              "Confirm Location"
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="book-input-location-checkbox">
                        <label>Same Pickup Location & Drop Location :</label>
                        <input
                          className="book-input-location-checkbox-inner"
                          type="checkbox"
                          checked={syncInputs}
                          onChange={handleSyncCheckboxChange}
                        />
                      </div>
                      <div>
                        <label>Drop Location : </label>
                        <input
                          className="book-input-location"
                          type="text"
                          value={input2}
                          onChange={(e) => handleInputChange(e, 2)}
                        />
                      </div>
                      <div className="checkbox-container">
                        <input
                          type="checkbox"
                          checked={isChecked2}
                          onChange={handleCheckbox2Change}
                        />{" "}
                        <label className="checkbox-label">
                          {isChecked2 ? (
                            <span className="greenHead">
                              Drop fee ₹ 500 included
                            </span>
                          ) : (
                            "Confirm Location"
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="option-box">
                <div className="book-box-4">
                  <div className="box-4-inner">
                    <span className="high-text-book">112 km </span>
                    included,{" "}
                    <span className="high-text-book">without fuel</span> Extra
                    kms @ ₹ 10/km
                  </div>
                  <div className="box-4-inner">
                    <button
                      onClick={() => {
                        handleUnlimited();
                        // setSelected("unlimited");
                      }}
                      className={
                        !unlimitedAdd
                          ? "btn-book-unlimited"
                          : "btn-book-unlimited-dis"
                      }
                    >
                      {!unlimitedAdd ? "Add unlimited" : "Remove unlimited"}
                    </button>
                  </div>
                </div>
              </div>

              <div className="option-box">
                <div className="box-5-book">
                  <div className="box-5-book-inner-11">
                    <div className="box-5-book-inner-1">
                      <FontAwesomeIcon
                        style={{
                          height: "20px",
                          color: "rgba(255, 0, 0, 0.658)",
                          marginRight: "10px",
                        }}
                        icon={faPercent}
                      />
                      Apply Promo Code
                    </div>
                    <div className="box-5-book-inner-1">
                      {isActive === 2 ? (
                        <FontAwesomeIcon
                          style={{
                            height: "20px",
                            color: "rgba(255, 0, 0, 0.658)",
                            cursor: "pointer",
                          }}
                          icon={faMinus}
                          onClick={() => handleSlide(2)}
                        />
                      ) : (
                        <FontAwesomeIcon
                          style={{
                            height: "20px",
                            color: "rgba(255, 0, 0, 0.658)",
                            cursor: "pointer",
                          }}
                          icon={faPlus}
                          onClick={() => handleSlide(2)}
                        />
                      )}
                    </div>
                  </div>
                  <div className="accordion-book-item">
                    <div
                      className={
                        isActive === 2
                          ? "accordion-book-content-open"
                          : "accordion-book-content-close"
                      }
                    >
                      <p-abel>Promo code : </p-abel>
                      <div className="box-5-book-inner-drop">
                        <input
                          className="book-input-promo"
                          type="text"
                          value={promoCode}
                          onChange={(e) => handleInputChange(e, 3)}
                        />
                        <button
                          onClick={() => {
                            handlePromoCode(promoCode);
                          }}
                          className="btn-book-unlimited"
                          disabled={promoted && !userUsed ? true : false}
                        >
                          {promoted && !userUsed ? "Coupon Applied" : "Done"}
                        </button>
                      </div>
                      <span className="high-text-book">
                        {" "}
                        {touched && promoWarning}
                      </span>
                      <div>
                        <br />
                        <h3>Available offers</h3>
                        <br />
                        <div>
                          {promos.map((p, i) => (
                            <div key={i} className="inner-promocode-show">
                              <div className="inner-promocode-show-1">
                                <h4>{p.title}</h4>
                                <p>{p.dec}</p>
                              </div>
                              <div className="inner-promocode-show-2">
                                <button
                                  onClick={() => {
                                    setPromoCode(p.coupon);
                                    setPromoId(p.id);
                                  }}
                                  className="btn-add-promo-item"
                                >
                                  Add
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="box-5-book-inner-22">
                    <div>Base Price</div>
                    <div className="introTitleBlack">
                      ₹ {loading ? "loading..." : numberWithCommas(basePrice)}
                    </div>
                  </div>
                  <div className="box-5-book-inner-33">
                    <div>Delivery and pickup charge</div>
                    <div className="introTitleBlack">
                      ₹ {numberWithCommas(amount)}
                    </div>
                  </div>
                  <div className="box-5-book-inner-33">
                    <div>Security Deposit (Refundable)</div>
                    <div className="introTitleBlack">
                      ₹ {numberWithCommas(2000)}
                    </div>
                  </div>
                  <div className="box-5-book-inner-33">
                    <div>
                      <h4>Protection and Extras</h4>
                      <p>Protection Plans</p>
                      <small>-Basic</small>
                    </div>
                    <div className="introTitleBlack">
                      ₹ {numberWithCommas(0)}
                    </div>
                  </div>
                  <div className="box-5-book-inner-66">
                    <div>
                      <h4 className="green-title-head">Applied discount</h4>
                    </div>
                    <div className="introTitleBlack side-back-book">
                      <p>Total : ₹ {numberWithCommas(preTotal)}</p>
                      <small>- {numberWithCommas(preTotal - total)}</small>
                    </div>
                  </div>
                  <div className="box-5-book-inner-44">
                    <div>
                      <h3>Total</h3>
                      <small>Inclusive of taxes & insurances</small>
                    </div>
                    <div className="introTitleBlack">
                      ₹ {loading ? "loading..." : numberWithCommas(total)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="option-box">
                <div className="book-last-box-inner">
                  <div className="book-last-box-1-inner">
                    <input
                      className="book-input-location-checkbox-inner-last"
                      type="checkbox"
                      checked={isChecked3}
                      onChange={() => {
                        if (!isChecked3) {
                          setIsPopupOpen(!isPopupOpen);
                        } else {
                          setIsChecked3(false);
                        }
                      }}
                    />
                  </div>
                  <div className="book-last-box-2-inner">
                    By Clicking on 'check box' you are agreeing to the <br />
                    <span className="high-text-book">T&C for extra charge</span>
                  </div>
                </div>
              </div>
              {isPopupOpen && (
                <div className="popup-screen-book">
                  <div className="popup-book">
                    <div className="popup_p-book">
                      <h2 className="high-text-book ">
                        Extra charge conditions
                      </h2>
                    </div>
                    <div className="popup-ul-book">
                      <ul>
                        <li>
                          After driving 252(max KM) KM a day you will be charged
                          ₹ 10/KM extra
                        </li>
                        <li>
                          Tolls, Border Tax and Parking charges not included
                          (Will be paid by you)
                        </li>
                      </ul>
                    </div>
                    <button
                      className="book-proceed2-buttonShadow book-proceed2-button"
                      onClick={handlePopupAgree}
                    >
                      Agree T&C
                    </button>
                  </div>
                </div>
              )}

              <div className="option-box">
                <div className="book-last-box-inner">
                  <div className="book-last-box-1-inner">
                    <input
                      className="book-input-location-checkbox-inner-last"
                      type="checkbox"
                    />
                  </div>
                  <div className="book-last-box-2-inner">
                    By Clicking on 'check box' you are agreeing to the <br />
                    <Link to={"/terms"}>
                      Terms and conditions of Go with car
                    </Link>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="book-proceed2-buttonShadow book-proceed2-button"
                onClick={handlepropop}
              >
                Proceed
              </button>
            </div>
          </div>
          {/* main box end*/}
          <div className="bc_img_d1">
            <div>
              <h1 className="bc_h2">{car?.vehiclesModalName}</h1>
            </div>
            <img
              className="bc_img1"
              src={car?.thumbnail}
              alt={car?.vehiclesModalName.toLowerCase().replace(/\s/g, "-")}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AppStore(BookingCarRoute);
