import React from "react";
import "./RevMain.css";
import RevIn from "../4Cards/$CardsInner/RevIn";

const RevenueMain = () => {
	return (
		<div>
			<RevIn />
		</div>
	);
};

export default RevenueMain;
