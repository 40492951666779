import React, { useContext } from "react";
import "./cst.css";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import { AuthContext } from "../../../AuthProvider";
import { useNavigate } from "react-router-dom";

const Customer = ({ c ,type}) => {
    const { setUserHintAd } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleUser = async (userHint) => {
        navigate(`/admin-panel/host/${userHint}`,{state:{type, mobileNumber:userHint}});
        window.scroll(0, 0);
    };
console.log("type", type)
    return (
        <div
            onClick={() => {
                setUserHintAd(c?.mobileNumber);
                handleUser(c?.mobileNumber);
            }}
            className="customer-container"
        >
            <div className="customer-info">
                <h3>
                    Aadhar Id : {c?.aadharNumber?.match(/.{1,4}/g)?.join("-")}
                </h3>
            </div>
            <div className="customer-info">
                <PhotoProvider>
                    <PhotoView src={c?.aadharFrontPhoto}>
                        <img
                            className="customer-image"
                            src={c?.aadharFrontPhoto}
                            // alt={c?.name}
                        />
                    </PhotoView>
                </PhotoProvider>
                <h3>{c?.firstName + " " + c?.lastName}</h3>
            </div>
            <div className="customer-info customer-details">
                <p>Date Of Birth : {c?.dob}</p>
                {/* <p className='customer-active'>Active</p> */}
            </div>
            {type=="host"? <div className="customer-earnings">
                <p>Earned</p>
                <h5>0</h5>
            </div>:null}
        </div>
    );
};

export default Customer;
