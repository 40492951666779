import React from "react";
import "./RentFlow.css";
import { Outlet } from "react-router-dom";
import CarSearc from "../../CarSearched/CarSearc";

const RentFlow = () => {
	return (
		<div>
			<CarSearc />
			<Outlet />
		</div>
	);
};

export default RentFlow;
