import React from "react";
import "./CarList.css";
import { useContext } from "react";
import { useState } from "react";
import { collection, getDocs, query, where, updateDoc, doc } from "firebase/firestore";
import { useEffect } from "react";
import { Col, Row } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCar,
  faGasPump,
  faGear,
  faRobot,
  faSearch,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "../../../AuthProvider";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Box, Button } from "@mui/material";
import { type } from "@testing-library/user-event/dist/type";

const CarList = () => {
  const { db } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [cars, setCars] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("ALL");
  const fetchCar = async (type) => {
    if (type !== "ALL") {
      const q = query(collection(db, "cars"), where("status", "==", "approved"), where("driverType", "==", type));
      let arr = [];
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        arr.push(doc.data());
      });
      setCars(arr);
      setLoading(true);
    } else {
      const q = query(collection(db, "cars"), where("status", "==", "approved"));
      let arr = [];
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        arr.push(doc.data());
      });
      setCars(arr);
      setLoading(true);
    }


  };



  useEffect(() => {
    fetchCar('ALL');
  }, []);

  const label = { inputProps: { 'aria-label': 'Size switch demo' } };
  // const [checked, setChecked] = useState([]);




  const handleChange = (e, car, index) => {
    updateDoc(doc(db, "cars", car.id), {
      bookingStatus: car.bookingStatus == 'available' ? 'booked' : 'available',
    }).then(() => {
      fetchCar(selectedFilter);
      // File deleted successfully

    })
      .catch((error) => {
        console.log(error);
      });

    console.log('car', car.bookingStatus)
    // if (checked[index]) {
    //   setChecked(checked => ({
    //     ...checked,
    //     [index]: false
    //   }))
    // } else {
    //   setChecked(checked => ({
    //     ...checked,
    //     [index]: true
    //   }))
    // }
  };

  console.log('selectedFilter', cars)
  return (
    <div className="ad_con_c">
      {!loading ? (
        <h1>Loading....</h1>
      ) : (
        <>
          <Box sx={{ alignItems: 'center', justifyContent: 'center', alignSelf: "auto" }}>
            <Box>
              <h1 className="bookHead">Approved Cars</h1>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-evenly', flexDirection: 'row', width: '90%', alignItems: 'center', ml: 9 }}>

              <Button onClick={() => {
                fetchCar('ALL')
                setSelectedFilter('ALL')
              }} sx={{ width: 150 }} variant={selectedFilter == 'ALL' ? "contained" : "outlined"} size="small">
                All
              </Button>
              <Button onClick={() => {
                fetchCar('Tempo Traveller')
                setSelectedFilter('Tempo Traveller')
              }} sx={{ width: 150 }} variant={selectedFilter == 'Tempo Traveller' ? "contained" : "outlined"} size="small">
                Tempo Treveller
              </Button>
              <Button onClick={() => {
                fetchCar('Self Drive')
                setSelectedFilter('Self Drive')
              }} sx={{ width: 150 }} variant={selectedFilter == 'Self Drive' ? "contained" : "outlined"} size="small">
                Self Drive
              </Button>
              <Button onClick={() => {
                fetchCar('With Driver')
                setSelectedFilter('With Driver')
              }} sx={{ width: 150 }} variant={selectedFilter == 'With Driver' ? "contained" : "outlined"} size="small">
                With Driver
              </Button>
            </Box>

          </Box>

          {cars.length > 0 ? (
            <Row
              style={{
                justifyContent: "center",
                width: "100%",
                height: "100%",
              }}
              gutter={30}
            >
              {cars
                ?.sort((item, index) =>
                  item.bookingStatus === "booked" ? 1 : -1
                )
                .map((c, i) => (
                  <Col key={i} lg={8}>

                    {/* main box start */}
                    <div style={{ marginTop: '40px' }} className="adc_d3">
                      <Link
                        onClick={() => {
                          setLoading(false);
                          localStorage.setItem("carId", c.id);
                          window.scrollTo(0, 0);
                        }}
                        to={"/admin-panel/car-details"}

                      >
                        <div className="adc_img_d1">
                          <img
                            className="adc_img1"
                            src={c.thumbnail}
                            alt={c.vehiclesModalName}
                          />
                        </div>
                        <div className="adc_d4">
                          <h4 className="adc_h2">{c.vehiclesModalName}</h4>
                          <div className="adc_d5">
                            <div className="adc_d9">
                              <FontAwesomeIcon
                                icon={faGasPump}
                                style={{
                                  height: "15px",
                                  width: "15px",
                                  paddingRight: "5px",
                                  color: "	 #ff8080",
                                }}
                              />
                              <span className="adc_span1">{c.fuelType}</span>
                            </div>
                          </div>
                        </div>
                        <div className="adc_d6">
                          <div className="adc_d7">
                            <div className="adc_d8">
                              <FontAwesomeIcon
                                icon={faUserGroup}
                                style={{
                                  height: "15px",
                                  width: "15px",
                                  paddingRight: "5px",
                                  color: "#ff8080",
                                }}
                              />
                              <span className="adc_span1">
                                {c.seatingCapacity}
                              </span>{" "}
                              seater
                            </div>
                            <div className="adc_d9">
                              <FontAwesomeIcon
                                icon={faGear}
                                style={{
                                  height: "15px",
                                  width: "15px",
                                  paddingRight: "5px",
                                  color: "	 #ff8080",
                                }}
                              />
                              <span className="adc_span1">
                                {c.transmissionType
                                  ? c.transmissionType
                                  : "Manual"}
                              </span>
                            </div>
                          </div>
                          <div className="adc_d7">
                            <div className="adc_d8">
                              <FontAwesomeIcon
                                icon={faCar}
                                style={{
                                  height: "15px",
                                  width: "15px",
                                  paddingRight: "5px",
                                  color: "	 #ff8080",
                                }}
                              />
                              <span className="adc_span1">{c.mileage}</span> km
                              / ltr
                            </div>
                            <div className="adc_d9">
                              <FontAwesomeIcon
                                icon={faRobot}
                                style={{
                                  height: "15px",
                                  width: "15px",
                                  paddingRight: "5px",
                                  color: "	 #ff8080",
                                }}
                              />
                              <span className="adc_span1">
                                Self-Drive & Without Fuel
                              </span>
                            </div>
                          </div>
                        </div>
                      </Link>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>

                        <Box>
                          <Typography>
                            ₹ <span className="adc_span2">{c.basePrice}</span> /{" hr"}
                          </Typography>

                          <Typography>Extra fare - ₹10/KM</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 3 }}>
                          <Typography variant="subtitle2" sx={{ ml: 1 }}>
                            Booked
                          </Typography>
                          <Switch
                            checked={c.bookingStatus == "available"}
                            onChange={(e) => handleChange(e, c, i)}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                          <Typography sx={{}} variant="subtitle2">
                            Available
                          </Typography>
                        </Box>
                      </Box>


                    </div>
                    {/* main box end*/}

                  </Col>
                ))}
            </Row>
          ) : (
            <h1 style={{
              justifyContent: 'center',
              display: 'flex',
              marginTop: '10%'
            }}>{`No Cars available`}</h1>
          )}
        </>
      )}
    </div>
  );
};

export default CarList;
