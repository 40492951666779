import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setBookingDetailsAction, setUserDataAction } from "../Redux/User/UserAction";
import { MapAction } from "../Redux/Map/MapAction";

const mapStateToProps = (state) => ({
  // ================================= Auth state ============================
  mapResponse: state.map.mapResponse,
  mapError: state.map.mapError,

  userData: state.user.userData,
  userDataError: state.user.userDataError,

  bookingDetails: state.user.bookingDetails,
  bookingDetailsError: state.user.bookingDetailsError,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      // =============================== Auth Action =========================
      MapAction: MapAction,
      setUserDataAction: setUserDataAction,
      setBookingDetailsAction: setBookingDetailsAction,
    },
    dispatch
  );

const foo = (AppStore) => {
  return connect(mapStateToProps, mapDispatchToProps)(AppStore);
};

export default foo
