import React from "react";
import "./Blog.css";
import { Card } from "antd";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../AuthProvider";

const { Meta } = Card;

const Blog = ({ b }) => {
    const { content, writer, thumbnail, title, createdAt, id } = b;
    const stringHtml = <div dangerouslySetInnerHTML={{ __html: content }} />;
    const { setLoading } = useContext(AuthContext);

    return (
        <Link
            onClick={() => {
                setLoading(false);
                localStorage.setItem("blogId", id);
                window.scrollTo(0, 0);
            }}
            className="cardAnk"
            to={`/blog/${title.toLowerCase().replace(/\s/g, "-")}`}
        >
            <Card
                style={{ fontFamily: "Poppins" }}
                className="blogcard"
                cover={
                    <img
                        className="blogImage"
                        alt={title.toLowerCase().replace(/\s/g, "-")}
                        src={thumbnail}
                    />
                }
                actions={[<p>{writer}</p>, <p>{createdAt}</p>]}
            >
                <Meta
                    className="description-blog"
                    title={title}
                    description={stringHtml}
                />
            </Card>
        </Link>
    );
};

export default Blog;
