import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../AuthProvider";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { collection, getDocs, query, where } from "firebase/firestore";

import { Col, Row } from "antd";
import {
    faCar,
    faGasPump,
    faGear,
    faRobot,
    faUserGroup,
} from "@fortawesome/free-solid-svg-icons";

const CarsAdmin = () => {
    const { setCarBookHint } = useContext(AuthContext);
    const [active, setActive] = useState(1);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { db } = useContext(AuthContext);
    const [cars, setCars] = useState(false);
    const [touch, setTouch] = useState(false);

    // const fetchCar = async () => {
    //     const q = query(collection(db, "cars"));
    //     let arr = [];
    //     const querySnapshot = await getDocs(q);
    //     querySnapshot.forEach((doc) => {
    //         arr.push(doc.data());
    //     });
    //     setCars(arr);
    //     setLoading(true);
    // };

    // useEffect(() => {
    //     fetchCar();
    // }, []);
    const [carHint, setCarHint] = useState("");

    const handleCar = async () => {
        setTouch(true);
        setLoading(false);
        const q = query(
            collection(db, "cars"),
            where("licenseNumber", "==", carHint),
        );
        const querySnapshot = await getDocs(q);
        let arr = [];
        querySnapshot.forEach((doc) => {
            arr.push(doc.data());
        });
        setCars(arr);
        setLoading(true);
    };
    return (
        <div className="ad_con_c">
            <div className="ad_comp_1_src_c">
                <div className="ad_comp_1_src_c_2">
                    <FontAwesomeIcon icon={faSearch} />
                    <input
                        placeholder="search a car"
                        className="ad_comp_1_src"
                        type="text"
                        onChange={(e) => {
                            setCarHint(e.target.value);
                            localStorage.setItem("carOfpanel", e.target.value);
                        }}
                    />
                    <button onClick={handleCar} className="btn_host_cus_search">
                        Search
                    </button>
                </div>
            </div>
            <div className="ad_book_5">
                <Link
                    className={active === 1 ? "ad_book_3_active" : "ad_book_3"}
                    to={"/admin-panel/carlist/approved"}
                    onClick={() => {
                        setActive(1);
                        setTouch(false);
                    }}
                >
                    Approved
                </Link>
                <Link
                    className={active === 2 ? "ad_book_3_active" : "ad_book_3"}
                    to={"/admin-panel/carlist/pending"}
                    onClick={() => {
                        setActive(2);
                        setTouch(false);
                    }}
                >
                    Pending
                </Link>
                <Link
                    className={active === 3 ? "ad_book_3_active" : "ad_book_3"}
                    to={"/admin-panel/carlist/rejected"}
                    onClick={() => {
                        setActive(3);
                        setTouch(false);
                    }}
                >
                    Declined
                </Link>
            </div>
            <div className="ad_book_4">
                {cars && touch && (
                    <div>
                        <h2>Searched Cars</h2>
                        <br />
                        <br />
                        <Row
                            style={{
                                justifyContent: "center",
                                width: "100%",
                                height: "100%",
                            }}
                            gutter={30}
                        >
                            {cars.map((c, i) => (
                                <Col key={i} lg={8}>
                                    <Link
                                        onClick={() => {
                                            setLoading(false);
                                            localStorage.setItem("carId", c.id);
                                            window.scrollTo(0, 0);
                                        }}
                                        to={"/admin-panel/car-details"}
                                        className="adc_map_main"
                                    >
                                        {/* main box start */}
                                        <div className="adc_d3">
                                            <div className="adc_img_d1">
                                                <img
                                                    className="adc_img1"
                                                    src={c.thumbnail}
                                                    alt={c.vehiclesModalName}
                                                />
                                            </div>
                                            <div className="adc_d4">
                                                <h4 className="adc_h2">
                                                    {c.vehiclesModalName}
                                                </h4>
                                                <div className="adc_d5">
                                                    <div className="adc_d9">
                                                        <FontAwesomeIcon
                                                            icon={faGasPump}
                                                            style={{
                                                                height: "15px",
                                                                width: "15px",
                                                                paddingRight:
                                                                    "5px",
                                                                color: "	 #ff8080",
                                                            }}
                                                        />
                                                        <span className="adc_span1">
                                                            {c.fuelType}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="adc_d6">
                                                <div className="adc_d7">
                                                    <div className="adc_d8">
                                                        <FontAwesomeIcon
                                                            icon={faUserGroup}
                                                            style={{
                                                                height: "15px",
                                                                width: "15px",
                                                                paddingRight:
                                                                    "5px",
                                                                color: "#ff8080",
                                                            }}
                                                        />
                                                        <span className="adc_span1">
                                                            {c.seatingCapacity}
                                                        </span>{" "}
                                                        seater
                                                    </div>
                                                    <div className="adc_d9">
                                                        <FontAwesomeIcon
                                                            icon={faGear}
                                                            style={{
                                                                height: "15px",
                                                                width: "15px",
                                                                paddingRight:
                                                                    "5px",
                                                                color: "	 #ff8080",
                                                            }}
                                                        />
                                                        <span className="adc_span1">
                                                            {c.transmissionType
                                                                ? c.transmissionType
                                                                : "Manual"}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="adc_d7">
                                                    <div className="adc_d8">
                                                        <FontAwesomeIcon
                                                            icon={faCar}
                                                            style={{
                                                                height: "15px",
                                                                width: "15px",
                                                                paddingRight:
                                                                    "5px",
                                                                color: "	 #ff8080",
                                                            }}
                                                        />
                                                        <span className="adc_span1">
                                                            {c.mileage}
                                                        </span>{" "}
                                                        km / ltr
                                                    </div>
                                                    <div className="adc_d9">
                                                        <FontAwesomeIcon
                                                            icon={faRobot}
                                                            style={{
                                                                height: "15px",
                                                                width: "15px",
                                                                paddingRight:
                                                                    "5px",
                                                                color: "	 #ff8080",
                                                            }}
                                                        />
                                                        <span className="adc_span1">
                                                            Self-Drive & Without
                                                            Fuel
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="adc_d10">
                                                <div>
                                                    ₹{" "}
                                                    <span className="adc_span2">
                                                        {c.minPrice}
                                                    </span>{" "}
                                                    /{" "}
                                                    {c.minPrice
                                                        ? c.minPrice
                                                        : "hr"}
                                                </div>
                                            </div>
                                            {/* {c.pricing && (
                            <div>
                                <PriceTable pricing={c.pricing} />
                            </div>
                        )} */}
                                        </div>
                                        {/* main box end*/}
                                    </Link>
                                </Col>
                            ))}
                        </Row>
                    </div>
                )}
                <Outlet />
            </div>
        </div>
    );
};

export default CarsAdmin;
