import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import Revenue from "../4Cards/Revenue";
import TBCars from "../4Cards/TBCars";
import BCars from "../4Cards/BCars";
import ACars from "../4Cards/Acars";
import "./DB.css";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../AuthProvider";

const DashBoard = () => {
    const { userHintAd, setUserHintAd } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleUser = async () => {
        navigate(`/admin-panel/user/${userHintAd}`);
    };
    return (
        <div className="ad_comp_1_t">
            <div className="ad_comp_1_src_c">
                <div className="ad_comp_1_src_c_2">
                    <FontAwesomeIcon icon={faSearch} />
                    <input
                        placeholder="search host or customer"
                        className="ad_comp_1_src"
                        type="text"
                        onChange={(e) => {
                            setUserHintAd(e.target.value);
                        }}
                    />
                    <button
                        onClick={handleUser}
                        className="btn_host_cus_search"
                    >
                        Search
                    </button>
                </div>
            </div>
            <div>
                <h1 className="dashboardHead"> Dashboard</h1>
            </div>
            <div className="ad_comp_1_cards_c">
                <div>
                    <Link
                        className="ad_comp_1_cards_c_lnk"
                        to={"/admin-panel/dashboard-revenue"}
                    >
                        <h3>Revenue</h3>
                        <Revenue />
                    </Link>
                </div>
                <div>
                    <Link
                        className="ad_comp_1_cards_c_lnk"
                        to={"/admin-panel/dashboard-top-booking-cars"}
                    >
                        <h3>Top Booking Cars</h3>
                        <TBCars />
                    </Link>
                </div>
                <div>
                    <Link
                        className="ad_comp_1_cards_c_lnk"
                        to={"/admin-panel/dashboard-booked-cars"}
                    >
                        <h3>Booked Cars</h3>
                        <BCars />
                    </Link>
                </div>
                <div>
                    <Link
                        className="ad_comp_1_cards_c_lnk"
                        to={"/admin-panel/dashboard-available-cars"}
                    >
                        <h3>Available Cars</h3>
                        <ACars />
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default DashBoard;
