import { UserActionType } from "./UserAction";

const initialState = {
  userData: undefined,
  userDataError: undefined,

  bookingDetails: undefined,
  bookingDetailsError: undefined,
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case UserActionType.SET_USER_DATA_SUCCESS:
      return {
        ...state,
        userData: action.payload,
        userDataError: undefined,
      };

    case UserActionType.SET_USER_DATA_FAILURE:
      return {
        ...state,
        userData: undefined,
        userDataError: action.payload,
      };

    case UserActionType.SET_BOOKING_DETAILS_SUCCESS:
      return {
        ...state,
        bookingDetails: action.payload,
        bookingDetailsError: undefined,
      };

    case UserActionType.SET_BOOKING_DETAILS_FAILURE:
      return {
        ...state,
        bookingDetails: undefined,
        bookingDetailsError: action.payload,
      };

    default:
      return state;
  }
};
export default UserReducer;
