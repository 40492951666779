import React from "react";
import Blog from "./BLog/Blog";
import "./Blogs.css";
import { useContext } from "react";
import { AuthContext } from "../AuthProvider";
import { Skeleton } from "antd";
import { collection, getDocs } from "firebase/firestore";
import { useState } from "react";
import { useEffect } from "react";
import useTitle from "../CustomHooks/useTitle.js";

const Blogs = () => {
    useTitle("Blogs_GoWithCar");
    const { db } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [blogs, setBlogs] = useState([]);
    const loader = [1, 2, 3, 4];
    const fetchBLogs = async () => {
        const query = collection(db, "blog");
        let arr_blogs = [];
        const querySnapshot = await getDocs(query);
        querySnapshot.forEach((doc) => {
            arr_blogs.push(doc.data());
        });
        setBlogs(arr_blogs);
        setLoading(false);
    };

    useEffect(() => {
        fetchBLogs();
    }, []);

    return (
        <div className="blogsContainer">
            <h1>Top Blogs</h1>

            {loading ? (
                <div className="blogCards">
                    {loader.map((i) => (
                        <div key={i} className="shimmerCard">
                            {/* main box start */}
                            <Skeleton.Input
                                style={{
                                    width: "100%",
                                    height: "300px",
                                    marginBottom: "10px",
                                }}
                                active
                            />
                            <Skeleton.Input
                                style={{
                                    width: "100%",
                                    height: "30px",
                                    marginBottom: "10px",
                                }}
                                active
                            />
                            <Skeleton.Input
                                style={{
                                    width: "100%",
                                    height: "30px",
                                    marginBottom: "10px",
                                }}
                                active
                            />
                        </div>
                    ))}
                </div>
            ) : (
                <div className="blogCards">
                    {blogs.map((b, i) => (
                        <Blog b={b} key={i}></Blog>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Blogs;
