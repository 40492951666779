import React, { useContext, useEffect, useState } from "react";
import "./User.css"; // Import your CSS file
import { query, collection, where, getDocs } from "@firebase/firestore";
import { AuthContext } from "../../../../AuthProvider";

const User = () => {
    const { db, userHintAd } = useContext(AuthContext);

    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(false);

    const fetchNow = async () => {
        setLoading(true);
        const q = query(
            collection(db, "users"),
            where("mobileNumber", "==", userHintAd),
        );
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            setUser(doc.data());
        });
        setLoading(false);
    };

    useEffect(() => {
        fetchNow();
    }, [userHintAd]);

    return (
        <div className="user-add-container">
            <div>
                {loading ? (
                    <div>
                        <h1>Loading...</h1>
                    </div>
                ) : (
                    <div>
                        {user ? (
                            <>
                                {" "}
                                <h2>
                                    {user?.role === "host"
                                        ? "Host Details"
                                        : "User Details"}
                                    :{" "}
                                </h2>
                                <br />{" "}
                                <h4 className="user-add-info">
                                    Name : {user?.name}
                                </h4>
                                <br />
                                <h4 className="user-add-info">
                                    Mobile Number : {user?.mobileNumber}
                                </h4>
                                <br />
                                <h4 className="user-add-info">
                                    Email : {user?.id}
                                </h4>
                                <br />
                                <h4 className="user-add-info">
                                    Aadhar Number : {user?.aadharNumber}
                                </h4>
                                <br />
                                <h4 className="user-add-info">
                                    Aadhar Photo :
                                </h4>
                                <img
                                    className="user-add-image"
                                    src={user?.aadharFrontPhoto}
                                    alt=""
                                />
                                <img
                                    className="user-add-image"
                                    src={user?.aadharBackPhoto}
                                    alt=""
                                />
                                <h4 className="user-add-info">
                                    Date of Birth : {user?.dob}
                                </h4>
                                <br />
                                <h4 className="user-add-info">
                                    Driving Licence : {user?.drivingLicence}
                                </h4>
                                <br />
                                <h4 className="user-add-info">
                                    Host Status : {user?.host_status}
                                </h4>
                                <br />
                                <h4 className="user-add-info">
                                    Passport Number : {user?.passportNumber}
                                </h4>
                                <br />
                                <h4 className="user-add-info">
                                    Passport Photo :
                                </h4>
                                <img
                                    className="user-add-image"
                                    src={user?.passportPhoto}
                                    alt=""
                                />
                            </>
                        ) : (
                            <h1 className="user-add-not-found">
                                No user found
                            </h1>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default User;
