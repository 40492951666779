import React, { useContext, useEffect, useState } from "react";
import "./Offers.css";
import {
    collection,
    deleteDoc,
    doc,
    getDocs,
    query,
    setDoc,
    updateDoc,
    where,
} from "firebase/firestore";
import { AuthContext } from "../../../AuthProvider";
import { Navigate } from "react-router-dom";
import {
    deleteObject,
    getDownloadURL,
    ref,
    uploadBytesResumable,
} from "firebase/storage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const Offer = () => {
    const { db, storage } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [offers, setOffers] = useState(true);
    const [offerId, setOfferId] = useState("");
    const [showPopup, setShowPopup] = useState(false);
    const [showPopup2, setShowPopup2] = useState(false);
    const [showPopup3, setShowPopup3] = useState(false);

    const fetchOffers = async () => {
        const q = query(collection(db, "offers"));
        let arr = [];
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            arr.push(doc.data());
        });
        setOffers(arr);
        setLoading(false);
    };

    useEffect(() => {
        fetchOffers();
    }, []);

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [coupon, setCoupon] = useState("");
    const [imageFile, setImageFile] = useState([]);
    const [image, setImage] = useState("");
    const [maxDiscount, setMaxDiscount] = useState("");
    const [isPercent, setIsPercent] = useState(true);
    const [dPercent, setDPercent] = useState("");
    const [dPrice, setDPrice] = useState("");
    const [isActive, setIsActive] = useState(true);

    const handleToggle = () => {
        setIsActive(!isActive);
    };

    async function fetchMyAPI(Id) {
        const q = query(collection(db, "offers"),where("id","==",Id));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            setOfferId(doc.data().id);
            setTitle(doc.data().title);
            setDescription(doc.data().dec);
            setCoupon(doc.data().coupon);
            setImage(doc.data().image);
            setDPercent(doc.data().discountPercent);
            setMaxDiscount(doc.data().maxDiscount);
            setDPrice(doc.data().discountPrice);
            if (doc.data().type === "percent") {
                setIsPercent(true);
            }
            if (doc.data().type === "price") {
                setIsPercent(false);
            }
            setInputFields(doc.data().tac);
            setIsActive(doc.data().isActive);
            setLoading(false);
        });
    }

    const handleUpdate = async () => {
        setLoading(true);
        if (imageFile) {
            handleDeletePicture(image);
            const storageRef = ref(storage, `/offer_posters/${imageFile.name}`);
            const uploadTask = uploadBytesResumable(storageRef, imageFile);
            uploadTask.on(
                "state_changed",
                (snapshot) => {},
                (err) => {},
                () => {
                    // download url
                    getDownloadURL(uploadTask.snapshot.ref).then((urls) => {
                        const docRef = doc(db, "offers", offerId);
                        updateDoc(docRef, {
                            coupon: coupon,
                            dec: description,
                            image: urls,
                            title: title,
                            maxDiscount: maxDiscount * 1,
                            discountPercent: dPercent * 1,
                            discountPrice: dPrice * 1,
                            tac: inputFields,
                            isActive: isActive,
                        }).then(() => {
                            fetchOffers();
                        });
                        setShowPopup(false);
                    });
                },
            );
        } else {
            handleUpload();
        }
    };
    console.log("offerId", offerId)
    const handleUpload = async () => {
        const docRef = doc(db, "offers", offerId);
        // Update the timestamp field with the value from the server
        await updateDoc(docRef, {
            coupon: coupon,
            dec: description,
            image: image,
            title: title,
            maxDiscount: maxDiscount * 1,
            discountPercent: dPercent * 1,
            discountPrice: dPrice * 1,
            tac: inputFields,
            isActive: isActive,
        }).then(() => {
            fetchOffers();
        });
        setShowPopup(false);
    };
    const handleDeletePicture = (pictureUrl) => {
        const desertRef = ref(storage, `${pictureUrl}`);
        // Delete the file
        deleteObject(desertRef)
            .then(() => {
                // File deleted successfully
            })
            .catch((error) => {
            });
    };
    const handleDelete = async () => {
        setLoading(true);
        handleDeletePicture(image);
        await deleteDoc(doc(db, "offers", offerId))
            .then(() => {
                setShowPopup2(false);
                Navigate("/admin-panel/offers");
            })
            .catch((error) => {
                console.error("Error removing document: ", error);
            });
        setShowPopup2(false);
        fetchOffers();
    };
    const handleAdd = () => {
        setTitle("");
        setDescription("");
        setMaxDiscount("");
        setCoupon("");
        setImage("");
        setDPercent("");
        setDPrice("");
        setIsPercent("");
        setInputFields([{ id: 1, value: "" }]);
        setIsPercent(true);
        setShowPopup3(true);
    };

    const [inputFields, setInputFields] = useState([{ id: 1, value: "" }]);

    const handleAddField = () => {
        const newFieldId = inputFields.length + 1;
        const newField = { id: newFieldId, value: "" };
        setInputFields([...inputFields, newField]);
    };

    const handleInputChange = (id, value) => {
        const updatedFields = inputFields.map((field) =>
            field.id === id ? { ...field, value } : field,
        );
        setInputFields(updatedFields);
    };

    const handleAddOffer = async () => {
        setLoading(true);
        const storageRef = ref(storage, `/offer_posters/${imageFile.name}`);
        const uploadTask = uploadBytesResumable(storageRef, imageFile);
        uploadTask.on(
            "state_changed",
            (snapshot) => {},
            (err) => {},
            () => {
                // download url
                getDownloadURL(uploadTask.snapshot.ref).then((urls) => {
                    setImage(urls);
                    const id = doc(collection(db, "offers"));
                    setDoc(id, {
                        id: id.id,
                        coupon: coupon,
                        isActive: true,
                        dec: description,
                        image: urls,
                        title: title,
                        maxDiscount: maxDiscount * 1,
                        type: `${isPercent ? "percent" : "price"}`,
                        discountPercent: dPercent * 1,
                        discountPrice: dPrice * 1,
                        tac: inputFields,
                    });
                    fetchOffers();
                    setShowPopup3(false);
                });
            },
        );
    };
    const handleOptionChange = () => {
        setIsPercent(!isPercent);
    };
    return (
        <div className="offer_Con">
            {showPopup3 && (
                <div className="popup-screen-offer">
                    <div className="popup">
                        <div className="popup-2">
                            <h2>Add offer</h2>
                            <label>Title</label>
                            <br />
                            <input
                                type="text"
                                placeholder={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                            <br />
                            <label>Description</label>
                            <br />
                            <input
                                type="text"
                                placeholder={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                            <br />
                            <label>Coupon</label>
                            <br />
                            <input
                                type="text"
                                placeholder={coupon}
                                onChange={(e) => setCoupon(e.target.value)}
                            />
                            <br />
                            <label>Max Discount</label>
                            <br />
                            <input
                                type="text"
                                placeholder={maxDiscount}
                                onChange={(e) => setMaxDiscount(e.target.value)}
                            />
                            <br />
                            <label>Terms and conditions</label>
                            <br />
                            <div>
                                {inputFields.map((field) => (
                                    <div key={field.id}>
                                        <input
                                            type="text"
                                            value={field.value}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    field.id,
                                                    e.target.value,
                                                )
                                            }
                                        />
                                    </div>
                                ))}
                                <button onClick={handleAddField}>
                                    <FontAwesomeIcon
                                        className="plusTerm"
                                        icon={faPlus}
                                    />
                                </button>
                            </div>

                            <br />
                            <label>Offer Type</label>
                            <br />
                            <div>
                                <br />
                                <div>
                                    {" "}
                                    <select
                                        name=""
                                        id=""
                                        onChange={handleOptionChange}
                                    >
                                        <option>Percent </option>
                                        <option> Price</option>
                                    </select>
                                </div>
                                <br />
                                <div>
                                    {isPercent ? (
                                        <div>
                                            <label>Dicount Percent</label>
                                            <br />
                                            <input
                                                type="text"
                                                placeholder={dPercent}
                                                onChange={(e) =>
                                                    setDPercent(e.target.value)
                                                }
                                            />
                                        </div>
                                    ) : (
                                        <div>
                                            <label>Dicount Price</label>
                                            <br />
                                            <input
                                                type="text"
                                                placeholder={dPrice}
                                                onChange={(e) =>
                                                    setDPrice(e.target.value)
                                                }
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>

                            <br />

                            <label>Image </label>
                            <br />
                            <input
                                className="dash_file1"
                                type="file"
                                onChange={(e) => {
                                    setImageFile(e.target.files[0]);
                                }}
                            />
                            <br />
                        </div>
                        <div className="popup_b">
                            <button onClick={handleAddOffer}>Save</button>

                            <button
                                onClick={() => {
                                    setShowPopup3(false);
                                }}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {showPopup && (
                <div className="popup-screen-offer">
                    <div className="popup">
                        <div className="popup-2">
                            <h2>Update offer</h2>
                            <label>Title</label>
                            <br />
                            <input
                                type="text"
                                placeholder={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                            <br />
                            <label>Description</label>
                            <br />
                            <input
                                type="text"
                                placeholder={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                            <br />
                            <label>Coupon</label>
                            <br />
                            <input
                                type="text"
                                placeholder={coupon}
                                onChange={(e) => setCoupon(e.target.value)}
                            />
                            <br />
                            <br />
                            <label>Max Discount</label>
                            <br />
                            <input
                                type="text"
                                placeholder={maxDiscount}
                                onChange={(e) => setMaxDiscount(e.target.value)}
                            />
                            <br />
                            <label>Terms and conditions</label>
                            <br />
                            <div>
                                {inputFields.map((field) => (
                                    <div key={field.id}>
                                        <input
                                            type="text"
                                            value={field.value}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    field.id,
                                                    e.target.value,
                                                )
                                            }
                                        />
                                    </div>
                                ))}
                                <button onClick={handleAddField}>
                                    <FontAwesomeIcon
                                        className="plusTerm"
                                        icon={faPlus}
                                    />
                                </button>
                            </div>

                            <br />

                            <div>
                                <br />
                                <div>
                                    {isPercent ? (
                                        <div>
                                            <label>Dicount Percent</label>
                                            <br />
                                            <input
                                                type="text"
                                                placeholder={dPercent}
                                                onChange={(e) =>
                                                    setDPercent(e.target.value)
                                                }
                                            />
                                        </div>
                                    ) : (
                                        <div>
                                            <label>Dicount Price</label>
                                            <br />
                                            <input
                                                type="text"
                                                placeholder={dPrice}
                                                onChange={(e) =>
                                                    setDPrice(e.target.value)
                                                }
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <br />
                            <div>
                                <label>
                                    Active check:
                                    <input
                                        type="checkbox"
                                        checked={isActive}
                                        onChange={handleToggle}
                                    />
                                </label>
                                <p>Active Status: {isActive ? "ON" : "OFF"}</p>
                            </div>

                            <br />
                            <label>Image URL</label>
                            <br />
                            <input
                                className="dash_file1"
                                type="file"
                                onChange={(e) => {
                                    setImageFile(e.target.files[0]);
                                }}
                            />
                            <br />
                        </div>
                        <div className="popup_b">
                            <button onClick={handleUpdate}>Yes</button>

                            <button
                                onClick={() => {
                                    setShowPopup(false);
                                }}
                            >
                                No
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {showPopup2 && (
                <div className="popup-screen-offer">
                    <div className="popup">
                        <div className="popup_p">
                            <h2>Want to delete now?</h2>
                            {/* <FontAwesomeIcon
                                    icon={faXmark}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setShowPopup(false)}
                                /> */}
                        </div>
                        <div className="popup_b">
                            <button onClick={handleDelete}>Yes</button>

                            <button
                                onClick={() => {
                                    setShowPopup2(false);
                                }}
                            >
                                No
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <div>
                <h1 className="offerHead">Offers</h1>
            </div>
            <div>
                {loading ? (
                    <h1>Loading....</h1>
                ) : (
                    <div className="offer_grid">
                        {offers?.map((c, i) => (
                            <div className="offer_Card" key={i}>
                                <img className="off_img" src={c.image} alt="" />
                                <div className="off_dec">
                                    <h4>{c.title}</h4>
                                    <p>{c.dec}</p>
                                    <p>Coupon : {c.coupon}</p>
                                </div>
                                <div className="off_btn">
                                    <button
                                        onClick={() => {
                                            setOfferId(c.id);
                                            setShowPopup(true);
                                            fetchMyAPI(c.id);
                                        }}
                                        className="off_btn_1"
                                    >
                                        Update
                                    </button>
                                    <button
                                        onClick={() => {
                                            setOfferId(c.id);
                                            setImage(c.image);
                                            setShowPopup2(true);
                                        }}
                                        className="off_btn_1"
                                    >
                                        Delete
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div className="offer_bot">
                <button onClick={handleAdd} className="off_btn_2">
                    + Add offer{" "}
                </button>
            </div>
        </div>
    );
};

export default Offer;
