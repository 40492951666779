import React, { useState } from "react";
import "./FAQ.css";
import Accordion from "./Acordion/Acordion";
import faqs from "./faqs";
import { Button } from "antd";
import useTitle from "../CustomHooks/useTitle.js";

const FAQ = () => {
    const [isOpenAll, setIsOpenAll] = useState(false);
    const [isActive, setIsActive] = useState(null);

    const handleOpen = () => {
        setIsOpenAll(!isOpenAll);
    };
    const handleSlide = (i) => {
        if (isActive === i) {
            return setIsActive(null);
        }
        setIsActive(i);
    };
    useTitle("FAQs_GoWithCar");
    return (
        <div className="faqContainer">
            <div className="faqsTop">
                <div>
                    <h1>
                        About Go <span className="introTitleRed">With </span>Car
                    </h1>
                </div>
                <div>
                    <Button type="primary" danger onClick={handleOpen}>
                        {!isOpenAll ? "Expand all" : "Close all"}
                    </Button>
                </div>
            </div>
            <div className="faqItems">
                {faqs.map((f, i) => (
                    <Accordion
                        key={i}
                        i={i}
                        name={f.name}
                        details={f.details}
                        handleSlide={handleSlide}
                        isActive={isActive}
                        isOpenAll={isOpenAll}
                    />
                ))}
            </div>
        </div>
    );
};

export default FAQ;
