import React from "react";
import "./Terms.css";

const Terms = () => {
    return (
        <div className="Tcontainer">
            <div className="Theader">
                <h2>Term to use</h2>
            </div>
            <br />
            <section>
                <p>
                    Go<span className="redHead">with</span>car.com service (or
                    We) provides this Website (Site) or Mobile App for your use,
                    subject to these Terms of Use and all applicable laws and
                    regulations. Please read these Terms of Use carefully. By
                    accessing and/or using the Site or Mobile App, you fully and
                    unconditionally accept and agree to be bound by these Terms
                    of Use, including binding arbitration. If you do not agree
                    to them, please do not visit or use the Site or Mobile App.
                    Go<span className="redHead">with</span>car.com reserves the
                    right to revise these Terms of Use, so please check back
                    periodically for changes. Your continued use of the Site or
                    Mobile App following the posting of any changes to these
                    Terms of Use constitutes your acceptance of those changes.
                    Updates will be evidenced by a more recent Last Updated date
                    at the top of this page. All changes are effective
                    immediately when We post them, and apply to use of the Site
                    thereafter
                </p>
                <br />
                <p className="header2">USE OF THE SITE OR MOBILE APP</p>
                Go<span className="redHead">with</span>car.com maintains the
                Site or Mobile App for your non-commercial personal use. Your
                use of the Site or Mobile App for any other purpose is
                permissible only upon the express prior written consent of Go
                <span className="redHead">with</span>car.com. Without limiting
                the foregoing, you may not: (1) use the Site or Mobile App in a
                commercial manner, including by distributing, transmitting or
                publishing the Site or Mobile App or any of its content; (2)
                interfere with others' use of the Site or Mobile App; (3) impair
                the Site or Mobile App's operation or interfere with or disrupt
                the servers or networks connected to it; (4) interfere with Go
                <span className="redHead">with</span>car.com’s intellectual
                property rights; (5) frame or otherwise co-brand the Site or
                Mobile App or any of its content; (6) deep-link to any portion
                of the Site or Mobile App; or (7) use the Site or Mobile App for
                any illegal purpose. We reserve the right in our sole discretion
                to terminate or restrict your use of the Site or Mobile App,
                without notice, for any or no reason, and without liability to
                you or any third party. In such event, we may inform your
                Internet service provider of your activities and take
                appropriate legal action.
                <br />
                <br />
                <p className="header2">SCRAPERS AND BOTS</p>
                Use of any robot, spider, site search, retrieval application or
                other manual or automatic device to retrieve, index, scrape,
                data mine or in any way gather or extract discount coupons or
                other content on or available through the Site or Mobile App or
                reproduce or circumvent the navigational structure or
                presentation on the Site or Mobile App without Go
                <span className="redHead">with</span>car.com’s express written
                consent is prohibited.
                <br />
                <br />
                <p className="header2">SITE OR MOBILE APP MODIFICATIONS</p>
                We reserve the right, in our sole discretion, to modify, suspend
                or discontinue any part of the Site or Mobile App at any time,
                without notice or liability to you or any third party. We also
                reserve the right, in our sole discretion, to impose limits on
                certain features and services.
                <br />
                <br />
                <p className="header2">PRIVACY POLICY</p>
                Go<span className="redHead">with</span>car.com takes your
                privacy seriously. Any information submitted on or collected
                through the Site is subject to our PRIVACY POLICY, the terms of
                which are incorporated into these Terms of Use.
                <br />
                <br />
                <p className="header2">LINKS TO OTHER SITES OR MOBILE APPS</p>
                The Site or Mobile App may include links to third-party Websites
                or Mobile Apps. Go<span className="redHead">with</span>car.com
                does not control and is not responsible for the content or
                privacy policies of any linked site or mobile app, and the
                inclusion of any link on the Site or Mobile App does not imply
                our endorsement of it.
                <br />
                <br />
                <p className="header2">RESERVATIONS AND TRANSACTIONS</p>
                All reservations and transactions made through the Site or
                Mobile App are subject to Go
                <span className="redHead">with</span>car.com’s acceptance, which
                is in our sole discretion. Without limitation, this means that
                Go<span className="redHead">with</span>car.com may refuse to
                accept or may cancel any reservation or transaction, whether or
                not the reservation or transaction has been confirmed, for any
                or no reason, in our sole discretion, and without liability to
                you or any third party. Rental contracts between Go
                <span className="redHead">with</span>car.com and website users
                are exclusively entered into at branch locations of Go
                <span className="redHead">with</span>car.com and its affiliates
                and their respective franchisees and are not entered into
                through this Site.
                <br />
                <br />
                <p className="header2">COUPONS</p>
                Gowiithcar.com provides specific discount coupons from time to
                time for the exclusive use of employees or members of specific
                entities with which Go<span className="redHead">with</span>
                car.com has a contractual relationship. These entity-specific
                coupons and the proprietary codes affixed on them are our
                proprietary property and may only be used by the employees or
                members of the specific entities for which the coupons apply.
                UNAUTHORIZED USE OR DUPLICATION OF ANY OF OUR ENTITY-SPECIFIC
                DISCOUNT COUPONS IS STRICTLY PROHIBITED.
                <br />
                <br />
                <p className="header2">ELECTRONIC COMMUNICATIONS</p>
                By using the Site or Mobile App, you consent to receiving
                electronic communications and notices from Go
                <span className="redHead">with</span>
                car.com. You agree that any notice, agreement, disclosure or
                other communications that we send to you electronically will
                satisfy any legal communication requirements, including that
                such communications be in writing.
                <br />
                <br />
                <p className="header2">INTELLECTUAL PROPERTY</p>
                All copyrightable text, graphics, sound, downloads, software and
                other material (collectively, the "Content"), the selection,
                compilation, arrangement and presentation of all materials, and
                the overall design of the Site or Mobile App are copyrighted by
                Go
                <span className="redHead">with</span>car.com and are protected
                by law. All rights reserved. You may print a copy of the Content
                on your computer only for your own personal, non-commercial home
                use, provided that you do not remove any copyright, trademark or
                other proprietary notices from the Content. Any other use of the
                Content is strictly prohibited, unless you have our prior
                written permission. The Site or Mobile App may also contain
                content that is owned by third parties, including our
                advertisers. You may use such third-party content only as
                expressly authorized by the applicable owner. All requests for
                permission to reprint or make any other use of the Content
                should be addressed to Intellectual Property Manager, Copyright
                Reprint Permission, info@Go<span className="redHead">with</span>
                car.com. Go
                <span className="redHead">with</span>car.com does not warrant
                that your use of materials displayed on or linked to the Site or
                Mobile App will not infringe the rights of third parties.
                <br />
                <br />
                <p className="header2">Claims of Copyright Infringement.</p>
                If you in believe that materials posted on the Site or Mobile
                App violate your intellectual property rights, please contact at
                Info@Go
                <span className="redHead">with</span>car.com. Please include:
                (1) an electronic or physical signature of the person authorized
                to act on behalf of the owner of the copyright or other
                proprietary interest; (2) a description of the copyrighted work
                or other protected material that you claim has been infringed
                upon; (3) a description of where the material is located on the
                Site or Mobile App; (4) your address, telephone number and
                e-mail address; (5) your statement that you have a good faith
                belief that the disputed use is not authorized by the copyright
                or other interest owner, its agent or the law; and (6) your
                statement, made under penalty of perjury, that the information
                in your notice is accurate and that you are the copyright or
                other interest owner or are authorized to act on the owner's
                behalf.
                <br />
                The Go<span className="redHead">with</span>car.com Car Rental,
                the flag design marks are included in the family of trademarks
                and service marks owned by Go
                <span className="redHead">with</span>car.com (this is not an
                all-inclusive list of Go<span className="redHead">with</span>
                car.com’s trademarks and service marks). Trademarks and services
                are registered with the INDIA.Our trademarks and service marks
                may not be used in connection with any product or service
                without our prior written permission. All other brands,
                trademarks and names not owned by Go
                <span className="redHead">with</span>car.com that appear on the
                Site are the property of their respective owners, who may or not
                be affiliated with, connected to or endorsed by Go
                <span className="redHead">with</span>car.com
                <br />
                Go<span className="redHead">with</span>car.com has a number of
                pending patent applications, covering various aspects of this
                Site or Mobile App, and users are cautioned from copying,
                adopting, using or otherwise borrowing any techniques, menu
                operations, or other functional aspects or features as doing so
                may well result in liability for patent infringement.
                <br />
                <br />
                <p className="header2">USE OF INFORMATION SUBMITTED</p>
                You agree that Go<span className="redHead">with</span>car.com is
                free to use any comments, information or ideas contained in any
                communication you may send to us, without compensation,
                acknowledgement or payment to you, for any purpose whatsoever,
                including, but not limited to, developing, manufacturing and
                marketing products and services and creating, modifying or
                improving the Site or Mobile App or other products or services
                <br />
                <br />
                <p className="header2">NO WARRANTY</p>
                While we use our reasonable efforts to maintain the accuracy and
                reliability of the Site or Mobile App, we do not warrant or
                represent that it will always function or be error-free. We
                assume no responsibility or liability for errors or omissions on
                the Site or Mobile App or for problems with its operation. Your
                access and use of the Site or Mobile App are at your own risk.
                Without limiting the foregoing, THE SITE OR MOBILE APP, ITS
                CONTENT AND ANY PRODUCT OR SERVICE OFFERED THROUGH IT ARE
                PROVIDED "AS IS" AND "AS AVAILABLE," WITHOUT WARRANTY OF ANY
                KIND BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
                NON-INFRINGEMENT. Some jurisdictions do not allow the exclusion
                of certain warranties, so the above may not apply to you
                <br />
                <br />
                <p className="header2">LIMITATION OF LIABILITY</p>
                IN NO EVENT SHALL Go<span className="redHead">with</span>
                car.COM, ITS RELATED COMPANIES, AND EACH SUCH COMPANY'S
                DIRECTORS, OFFICERS, EMPLOYEES AND AGENTS BE LIABLE FOR ANY
                DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE
                DAMAGES ARISING OUT OF OR IN CONNECTION WITH YOUR ACCESS, USE OR
                INABILITY TO USE THE SITE, ANY INFORMATION PROVIDED BY THE SITE
                OR ANY TRANSACTION ENTERED INTO THROUGH THE SITE OR MOBILE APP{" "}
                <br />
                Some jurisdictions do not allow the exclusion of certain
                damages, so the above may not apply to you. If any authority
                holds any portion of this section to be unenforceable, then
                liability will be limited to the fullest extent permitted by
                applicable law.
                <br />
                <br />
                <p className="header2">INDEMNITY</p>
                You agree to indemnify and hold Go
                <span className="redHead">with</span>
                car.com and its related companies and each of their respective
                directors, officers, employees and agents harmless from and
                against any third-party claim or cause of action, including
                reasonable attorneys' fees and costs, arising, directly or
                indirectly, in whole or in part, out of your violation of any
                law or the rights of any person.
                <br />
                <br />
                <p className="header2">GENERAL</p>
                If any provision of these Terms of Use is held to be invalid or
                unenforceable, that provision must be construed consistent with
                applicable law, and the remaining provisions must be enforced.
                Headings are for reference purposes only and in no way define,
                limit, construe or describe the scope of such section. Our
                failure to enforce any provision of these Terms of Use shall not
                constitute a waiver of that or any other provision. These Terms
                of Use set forth the entire agreement between you and Go
                <span className="redHead">with</span>car.com in connection with
                your use of the Site or Mobile App.
                <br />
                <br />
                <p className="header2">CONTACT US</p>
                If you have any questions about these Terms of Use, please
                contact us at{" "}
                <a href="Info@Go<span className='redHead'>with</span>car.com.">
                    Info@Go<span className="redHead">with</span>car.com.
                </a>
            </section>
        </div>
    );
};

export default Terms;
