import { faSun } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const Driver = () => {
	return (
		<div className='wind_contain'>
			<FontAwesomeIcon style={{ width: "50px", height: "50px" }} icon={faSun} />
			<br />
			<h4>Driver</h4>
			<p>
				A driver who steers, brakes, changes gears, and accelerates smoothly is
				a good driver.
			</p>
			<br />
			<h4>Rs 600/day total</h4>
			<button className='win_btn'>Hire</button>
		</div>
	);
};

export default Driver;
