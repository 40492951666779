import { collection, getDocs } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { FaGoogle, FaMobile } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../AuthProvider";
import { useDispatch, useSelector } from "react-redux";
import AppStore from "../../screen/AppStore";

const SignUp = (props) => {
  const { db, providerLogin, setEmail } = useContext(AuthContext);
  const navigate = useNavigate();
  const [services, setServices] = useState("");
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  // const userInfo = useSelector((state) => state);
  // const selectedUsers = userInfo.usersDataReducer?.user;
  
  const selectedUsers = props.userData;

  const fetchUsers = async () => {
    const querySnapshot = await getDocs(collection(db, "users"));
    let arr = [];
    querySnapshot.forEach((doc) => {
      arr.push(doc.data());
    });
    setServices(arr);
  };
  useEffect(() => {
    fetchUsers();
  }, []);
  useEffect(() => {}, [services]);

  useEffect(() => {
    if (selectedUsers?.email) {
      if (services) {
        if (services.find((o) => o.email === selectedUsers?.email)) {
        } else {
          submit(selectedUsers?.email);
        }
      }
    }
  }, [services]);

  const submit = async (user) => {
    if (services.find((o) => o.email === user.email)) {
      navigate("/");
    } else {
      navigate("/login-2");
    }
    setLoading(false);
  };
  const LoginWithGoogle = () => {
    providerLogin()
      .then((result) => {
        setLoading(true);
        const user = result.user;
        props.setUserDataAction(user);
        setEmail(user?.email);
        fetchUsers();
        submit(user);
      })
      .catch((error) => {
      });
  };
  return (
    <div className="login1-container">
      {/* <div id="sign-in-button-con"></div> */}
      <h1 className="login1-header">Want to Sign up? Click here.</h1>

      <div className="login1-boxes">
        {/* <Link
                    onClick={LoginWithGoogle}
                    className="login1-box login1-google"
                >
                    <span>Sign up with Google </span>
                    <FaGoogle className="login_Icons" />
                </Link> */}
        <Link to={"/signup-mobile"} className="login1-box login1-google">
          <span>Sign up with Mobile </span>
          <FaMobile className="login_Icons" />
        </Link>
      </div>
    </div>
  );
};

export default AppStore(SignUp);
