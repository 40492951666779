import React, { useContext, useEffect, useState } from "react";
import "./City.css";
import {
    collection,
    deleteDoc,
    doc,
    getDocs,
    query,
    setDoc,
    updateDoc,
    where,
} from "firebase/firestore";
import { AuthContext } from "../../../AuthProvider";
import { useNavigate } from "react-router-dom";

const CityList = () => {
    const { db } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [city, setCity] = useState([]);
    const [cityId, setCityId] = useState("");
    const [showPopup, setShowPopup] = useState(false);
    const [showPopup2, setShowPopup2] = useState(false);
    const [showPopup3, setShowPopup3] = useState(false);
    const fetchCity = async () => {
        // const query = collection(db, "cars");
        // const q = query(query, where("booking_status", "==", "booked"));
        const q = query(collection(db, "location"));
        let arr = [];
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            arr.push(doc.data());
        });
        setCity(arr);
        setLoading(false);
    };

    useEffect(() => {
        fetchCity();
    }, []);

    const [title, setTitle] = useState("");
    const [zip, setZip] = useState("");

    async function fetchMyAPI(Id) {
        const q = query(collection(db, "location"), where("id", "==", Id));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            setCityId(doc.data().id);
            setTitle(doc.data().name);
            setZip(doc.data().zipcode);
            setLoading(false);
        });
    }
    const Navigate = useNavigate();

    const handleUpdate = async () => {
        const docRef = doc(db, "location", cityId);

        // Update the timestamp field with the value from the server
        await updateDoc(docRef, {
            name: title,
            zipcode: zip,
        });
        setShowPopup(false);
        fetchCity()
    };
    // console.log('city', Id)
    const handleDelete = async () => {
        await deleteDoc(doc(db, "location", cityId))
            .then(() => {
                setShowPopup2(false);
                Navigate("/admin-panel/city-list");
                fetchCity()
            })
            .catch((error) => {
                console.error("Error removing document: ", error);
            });
        setShowPopup2(false);
        
    };
    const handleAdd = () => {
        setTitle("");
        setZip("");
        setShowPopup3(true);
        
    };
    const handleAddCity = async () => {
        const id = Math.floor(100000 + Math.random() * 9900007567).toString();
        await setDoc(doc(db, "location", id), {
            id,
            name: title,
            zipcode: zip,
        });
        setShowPopup3(false);
        fetchCity()

    };
    return (
        <div className="city_Con">
            {showPopup3 && (
                <div className="popup-screen">
                    <div className="popup">
                        <div className="popup-2">
                            <h2>Add City</h2>
                            <label>Name</label>
                            <br />
                            <input
                                type="text"
                                placeholder={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                            <br />

                            <label>Zipcode</label>
                            <br />
                            <input
                                type="text"
                                placeholder={zip}
                                onChange={(e) => setZip(e.target.value)}
                            />
                            <br />
                        </div>
                        <div className="popup_b">
                            <button onClick={handleAddCity}>Save</button>

                            <button
                                onClick={() => {
                                    setShowPopup3(false);
                                }}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {showPopup && (
                <div className="popup-screen">
                    <div className="popup">
                        <div className="popup-2">
                            <h2>Update City</h2>
                            <label>Name</label>
                            <br />
                            <input
                                type="text"
                                placeholder={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                            <br />

                            <label>Zipcode</label>
                            <br />
                            <input
                                type="text"
                                placeholder={zip}
                                onChange={(e) => setZip(e.target.value)}
                            />
                            <br />
                        </div>
                        <div className="popup_b">
                            <button onClick={handleUpdate}>Yes</button>

                            <button
                                onClick={() => {
                                    setShowPopup(false);
                                }}
                            >
                                No
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {showPopup2 && (
                <div className="popup-screen">
                    <div className="popup">
                        <div className="popup_p">
                            <h2>Want to delete now?</h2>
                            {/* <FontAwesomeIcon
                                    icon={faXmark}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setShowPopup(false)}
                                /> */}
                        </div>
                        <div className="popup_b">
                            <button onClick={handleDelete}>Yes</button>

                            <button
                                onClick={() => {
                                    setShowPopup2(false);
                                }}
                            >
                                No
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <div>
                <h1 className="cityHead">City list</h1>
            </div>
            <div>
                {loading ? (
                    <h1>Loading....</h1>
                ) : (
                    <div className="city_grid">
                        {city?.map((c, i) => (
                            <div className="city_Card" key={i}>
                                <div>
                                    <h4 className="city-name">
                                        {i + 1} : {c.name}
                                    </h4>
                                </div>

                                <div>
                                    <button
                                        onClick={() => {
                                            setCityId(c.id);
                                            setShowPopup(true);
                                            fetchMyAPI(c.id);
                                        }}
                                        className="city_btn_1"
                                    >
                                        Update
                                    </button>
                                </div>
                                <div>
                                    <button
                                        onClick={() => {
                                            setCityId(c.id);
                                            setShowPopup2(true);
                                        }}
                                        className="city_btn_1"
                                    >
                                        Delete
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div className="city_bot">
                <button onClick={handleAdd} className="city_btn_2">
                    + Add new city{" "}
                </button>
            </div>
        </div>
    );
};

export default CityList;
