import React from "react";
import "./Basic.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-regular-svg-icons";

const Basic = ({ p }) => {
    return (
        <div className="b_contain">
            <div className="b_top">
                <h4>
                    {p.title} {""}
                    <FontAwesomeIcon icon={faStar} />
                </h4>
                <p>Excess: Rs {p.excess}</p>
                <br />
                <h4>Included</h4>
                <p>TOTAL Rs {p.includedExcess}</p>
            </div>
            <div>
                <div className="b_mid">
                    {p.plans.map((p, i) => (
                        <p
                            key={i}
                            className={p.isAvalable ? "" : "mid_disabled_p"}
                        >
                            <FontAwesomeIcon
                                style={p.isAvalable ? { color: "green" } : {}}
                                className="b_icon"
                                icon={p.isAvalable ? faCheck : faXmark}
                            />
                            {p.title}
                        </p>
                    ))}
                </div>
                <div className="b_bot">
                    {/* <p>
						More details{" "}
						<FontAwesomeIcon className='b_icon' icon={faArrowRight} />
					</p> */}
                    <button>Select</button>
                </div>
            </div>
        </div>
    );
};

export default Basic;
