import React from "react";
import "./Extra.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun } from "@fortawesome/free-regular-svg-icons";
import { useState } from "react";

const Baby1 = ({ p }) => {
	const [sit1, setSit1] = useState(0);

	return (
		<div className='wind_contain'>
			<FontAwesomeIcon style={{ width: "50px", height: "50px" }} icon={faSun} />
			<br />
			<h4>{p.title}</h4>
			<p>{p.details}</p>
			<br />
			<h4>Rs {p.price}/ total</h4>
			<div className='ex_bot'>
				<button
					disabled={sit1 === 0 ? true : false}
					onClick={() => setSit1(sit1 - 1)}
					style={{ color: " red", border: "1px solid red" }}
				>
					-
				</button>
				<p>{sit1}</p>
				<button
					onClick={() => setSit1(sit1 + 1)}
					style={{ color: " green", border: "1px solid green" }}
				>
					+
				</button>
			</div>
		</div>
	);
};

export default Baby1;
