import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { collection, doc, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../AuthProvider";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

const SignUpM4 = () => {
    const { storage, db, num, name, address, email } = useContext(AuthContext);
    const [dob, setDob] = useState("");
    const [idProof, setIdProof] = useState("");
    const [adharFrontImage, setAdharFrontImage] = useState(null);
    const [adharBackImage, setAdharBackImage] = useState(null);
    const [adharNumber, setAdharNumber] = useState("");
    const [passportImage, setPassportImage] = useState(null);
    const [passportNumber, setPassportNumber] = useState("");
    const [licenseNumber, setLicenseNumber] = useState("");
    const [token, setToken] = useState("");
    const [loading, setLoading] = useState(false);
    const [touch, setTouch] = useState(false);
    const [aged, setAged] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const navigate = useNavigate();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setTouch(true);
        switch (name) {
            case "dob":
                setDob(value);
                const inputDate = new Date(value);
                const currentDate = new Date();
                const ageDifference =
                    currentDate.getFullYear() - inputDate.getFullYear();
                if (ageDifference > 18) {
                    setAged(true);
                } else {
                    setAged(false);
                }
                break;
            case "idProof":
                setIdProof(value);
                break;
            case "adharNumber":
                setAdharNumber(value);
                break;
            case "passportNumber":
                setPassportNumber(value);
                break;
            case "licenseNumber":
                setLicenseNumber(value);
                break;
            default:
                break;
        }
    };

    const handleAdharFrontImageChange = (e) => {
        const file = e.target.files[0];
        setAdharFrontImage(file);
    };

    const handleAdharBackImageChange = (e) => {
        const file = e.target.files[0];
        setAdharBackImage(file);
    };

    const handlePassportImageChange = (e) => {
        const file = e.target.files[0];
        setPassportImage(file);
    };

    const handleFormSubmit = (e) => {
        setLoading(true);
        e.preventDefault();
        const phoneNumber = "+" + num;
        if (adharBackImage && adharFrontImage) {
            const storageRef = ref(
                storage,
                `/user_pics/${adharBackImage.name}`,
            );
            const uploadTask = uploadBytesResumable(storageRef, adharBackImage);
            uploadTask.on(
                "state_changed",
                (snapshot) => {},
                (err) => {},
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((urls) => {
                        const aadharBackPhotoUrl = urls;
                        const storageRef = ref(
                            storage,
                            `/user_pics/${adharFrontImage.name}`,
                        );
                        const uploadTask = uploadBytesResumable(
                            storageRef,
                            adharFrontImage,
                        );
                        uploadTask.on(
                            "state_changed",
                            (snapshot) => {},
                            (err) => {},
                            () => {
                                getDownloadURL(uploadTask.snapshot.ref).then(
                                    (urls) => {
                                        const ref = doc(
                                            collection(db, "users"),
                                        );
                                        const userDetails = {
                                            id: ref.id,
                                            email: email,
                                            address: address,
                                            dob: dob,
                                            name,
                                            mobileNumber: phoneNumber,
                                            drivingLicence: licenseNumber,
                                            aadharNumber: adharNumber,
                                            aadharBackPhoto: aadharBackPhotoUrl,
                                            isHostVerified: true,
                                            aadharFrontPhoto: urls,
                                            passportNumber: passportNumber,
                                            passportPhoto: "",
                                            role: "user",
                                            fcmToken: token,
                                        };
                                        setDoc(ref, userDetails);
                                        navigate("/");
                                        setLoading(false);
                                    },
                                );
                            },
                        );
                    });
                },
            );
        }
        if (passportImage) {
            const storageRef = ref(storage, `/user_pics/${passportImage.name}`);
            const uploadTask = uploadBytesResumable(storageRef, passportImage);
            uploadTask.on(
                "state_changed",
                (snapshot) => {},
                (err) => {
                    console.log(err);
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((urls) => {
                        const ref = doc(collection(db, "users"));
                        const userDetails = {
                            id: ref.id,
                            email,
                            address: address,
                            dob: dob,
                            name,
                            mobileNumber: phoneNumber,
                            drivingLicence: licenseNumber,
                            aadharNumber: adharNumber,
                            aadharBackPhoto: "",
                            isHostVerified: true,
                            aadharFrontPhoto: "",
                            passportNumber: passportNumber,
                            passportPhoto: urls,
                            role: "user",
                            fcmToken: token,
                        };
                        setDoc(ref, userDetails);
                        navigate("/");
                        setLoading(false);
                    });
                },
            );
        }
    };

    useEffect(() => {
        const inputDate = new Date(dob);
        const currentDate = new Date();
        const ageDifference =
            currentDate.getFullYear() - inputDate.getFullYear();
        if (idProof === "adhar") {
            setIsFormValid(
                ageDifference > 18 &&
                    dob !== "" &&
                    idProof !== "" &&
                    adharFrontImage !== null &&
                    adharBackImage !== null &&
                    adharNumber !== "" &&
                    licenseNumber !== "",
            );
        } else if (idProof === "passport") {
            setIsFormValid(
                ageDifference > 18 &&
                    dob !== "" &&
                    idProof !== "" &&
                    passportImage !== null &&
                    passportNumber !== "" &&
                    licenseNumber !== "",
            );
        } else {
            setIsFormValid(false);
        }
    }, [
        dob,
        idProof,
        adharFrontImage,
        adharBackImage,
        adharNumber,
        passportImage,
        passportNumber,
        licenseNumber,
    ]);

    return (
        <div className="login3-container">
            <h1 className="login3-header">User Details</h1>
            <form onSubmit={handleFormSubmit}>
                <div className="input3-box">
                    <label>Date of Birth</label>
                    <input
                        type="date"
                        name="dob"
                        value={dob}
                        onChange={handleInputChange}
                        placeholder="dd/mm/yyyy"
                    />
                    {!aged && touch && (
                        <p
                            style={{
                                margin: "5px 10px",
                                color: "red",
                            }}
                        >
                            You must be over 18
                        </p>
                    )}
                </div>
                <div className="input3-box">
                    <label>ID Proof</label>
                    <div className="radio3-inputs">
                        <label>
                            <input
                                type="radio"
                                name="idProof"
                                value="adhar"
                                checked={idProof === "adhar"}
                                onChange={handleInputChange}
                            />
                            Adhar
                        </label>
                        <label>
                            <input
                                type="radio"
                                name="idProof"
                                value="passport"
                                checked={idProof === "passport"}
                                onChange={handleInputChange}
                            />
                            Passport
                        </label>
                    </div>
                </div>
                {idProof === "adhar" && (
                    <div className="input3-box">
                        <label>Adhar Card Front Image</label>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleAdharFrontImageChange}
                        />
                    </div>
                )}
                {idProof === "adhar" && (
                    <div className="input3-box">
                        <label>Adhar Card Back Image</label>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleAdharBackImageChange}
                        />
                    </div>
                )}
                {idProof === "adhar" && (
                    <div className="input3-box">
                        <label>Adhar Number</label>
                        <input
                            type="text"
                            name="adharNumber"
                            value={adharNumber}
                            onChange={handleInputChange}
                            placeholder="Enter Adhar Number"
                        />
                    </div>
                )}
                {idProof === "passport" && (
                    <div className="input3-box">
                        <label>Passport Image</label>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handlePassportImageChange}
                        />
                    </div>
                )}
                {idProof === "passport" && (
                    <div className="input3-box">
                        <label>Passport Number</label>
                        <input
                            type="text"
                            name="passportNumber"
                            value={passportNumber}
                            onChange={handleInputChange}
                            placeholder="Enter Passport Number"
                        />
                    </div>
                )}
                <div className="input3-box">
                    <label>Driving License</label>
                    <input
                        type="text"
                        name="licenseNumber"
                        value={licenseNumber}
                        onChange={handleInputChange}
                        placeholder="Enter License Number"
                    />
                </div>
                <button
                    type="submit"
                    className={`proceed2-button ${
                        isFormValid ? "proceed2-buttonShadow" : "disabled"
                    }`}
                    disabled={!isFormValid}
                >
                    {loading ? "Loading..." : "Sign up"}
                </button>
            </form>
        </div>
    );
};

export default SignUpM4;
