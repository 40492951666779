export function numberWithCommas(x) {
  return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "";
}

// export const BASE_URL = "http://3.110.42.144:5002/";
export const BASE_URL = "https://api.gowithcar.com/";
// export const BASE_URL = "http://localhost:5001/"

export function getAdminId(admin) {
  return admin?.id ? admin.id : admin?.hostId ? admin.hostId : ''
}