import React from "react";
import "./RevIn.css";
import { useContext } from "react";
import { AuthContext } from "../../../../AuthProvider";
import { useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { useEffect } from "react";
import { CartesianGrid, Line, LineChart, XAxis, YAxis } from "recharts";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";


const RevIn = () => {
    const { db,userHintAd } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [rev, setRev] = useState([]);
    const navigate = useNavigate();

    const fetchPays = async () => {
        const query = collection(db, "bookings");
        let arr = [];
        const querySnapshot = await getDocs(query);
        querySnapshot.forEach((doc) => {
            arr.push(doc.data());
        });
        setRev(arr);
        setLoading(false);
    };

    useEffect(() => {
        fetchPays();
    }, []);
    const handleUser = async (id) => {
        navigate(`/admin-panel/host/${'hostdetails'}`, {
            state: {
                id: id, type: 'host'
            }
        });
    };
    const handleUser2 = async (id) => {
        navigate(`/admin-panel/car-details`
           );
           localStorage.setItem("carId", id);
    };
    return (
        <div>
            <h1 className="revHead">Revenue</h1>
            {loading ? (
                <h1>Loading....</h1>
            ) : (
                <table className="rev-table">
                    <thead>
                        <tr className="header-row">
                            
                            <th className="header-cell">Name</th>
                            <th className="header-cell">Mobile Number</th>
                            <th className="header-cell">User Email</th>
                            <th className="header-cell">Booking Id</th>
                            <th className="header-cell">Creation Date</th>
                            
                            <th className="header-cell">Car Name</th>

                            <th className="header-cell">Payment Mode</th>
                            <th className="header-cell">Amount</th>
                            <th className="header-cell">Host Email</th>

                            
                        </tr>
                    </thead> 
                    <tbody>
                        {rev.map((r, i) => (
                            <tr key={i} className="data-row">
                                <td className="data-cell">{r.passenger_details?.firstName !== undefined ? r.passenger_details?.firstName + " " + r.passenger_details?.lastName:"Not Available"}</td>
                                <td className="data-cell">{r.passenger_details?.mobileNumber }</td>
                                <td className="data-cell">{r.passenger_details?.email}</td>
                                <td className="data-cell">{r.id}</td>
                                
                                <td className="data-cell">{r.car_details?.createdDate}</td>
                                <td className="data-cell">
                                <Button onClick={()=>handleUser2(r.car_details?.id)} variant="contained" disableElevation>
                                {r.car_details?.vehiclesModalName}
                                </Button>
                                </td>

                                <td className="data-cell">{r.payment_mode}</td>
                                <td className="data-cell">{r.paymentDetails?.data?.amount ? r.paymentDetails?.data?.amount : '0'}</td>
                                <td className="data-cell"><Button onClick={()=>handleUser(r.host_details?.id)} variant="contained" disableElevation>
                                    Host details
                                </Button></td>

                               
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default RevIn;
