import React from "react";
import "./BookedIn.css";
import { useContext } from "react";
import { AuthContext } from "../../../../AuthProvider";
import { useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect } from "react";
import { Col, Row } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCar,
    faGasPump,
    faGear,
    faRobot,
    faUserGroup,
} from "@fortawesome/free-solid-svg-icons";

const BookedCarIn = () => {
    const { db } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [cars, setCars] = useState([]);
    const fetchCars = async () => {
        // const query = collection(db, "cars");
        // const q = query(query, where("booking_status", "==", "booked"));
        const q = query(
            collection(db, "cars"),
            where("bookingStatus", "==", "booked"),
        );
        let arr = [];
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            arr.push(doc.data());
        });
        setCars(arr);
        setLoading(false);
    };

    useEffect(() => {
        fetchCars();
    }, []);
    return (
        <div>
            <h1 className="dashboardBCHead">Booked Cars</h1>
            {loading ? (
                <h1>Loading....</h1>
            ) : (
                <Row
                    style={{
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                    }}
                    gutter={30}
                >
                    {cars?.map((c, i) => (
                        <Col lg={8}>
                            <div className="fc_map_main">
                                {/* main box start */}
                                <div className="fc_d3">
                                    <div className="fc_img_d1">
                                        <img
                                            className="fc_img1"
                                            src={c.thumbnail}
                                            alt={c.vehiclesModalName}
                                        />
                                    </div>
                                    <div className="fc_d4">
                                        <h4 className="fc_h2">
                                            {c.vehiclesModalName}
                                        </h4>
                                        <div className="fc_d5">
                                            <div className="fc_d9">
                                                <FontAwesomeIcon
                                                    icon={faGasPump}
                                                    style={{
                                                        height: "15px",
                                                        width: "15px",
                                                        paddingRight: "5px",
                                                        color: "	 #ff8080",
                                                    }}
                                                />
                                                <span className="fc_span1">
                                                    {c.fuelType}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fc_d6">
                                        <div className="fc_d7">
                                            <div className="fc_d8">
                                                <FontAwesomeIcon
                                                    icon={faUserGroup}
                                                    style={{
                                                        height: "15px",
                                                        width: "15px",
                                                        paddingRight: "5px",
                                                        color: "#ff8080",
                                                    }}
                                                />
                                                <span className="fc_span1">
                                                    {c.seatingCapacity}
                                                </span>{" "}
                                                seater
                                            </div>
                                            <div className="fc_d9">
                                                <FontAwesomeIcon
                                                    icon={faGear}
                                                    style={{
                                                        height: "15px",
                                                        width: "15px",
                                                        paddingRight: "5px",
                                                        color: "	 #ff8080",
                                                    }}
                                                />
                                                <span className="fc_span1">
                                                    {c.transmissionType
                                                        ? c.transmissionType
                                                        : "Manual"}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="fc_d7">
                                            <div className="fc_d8">
                                                <FontAwesomeIcon
                                                    icon={faCar}
                                                    style={{
                                                        height: "15px",
                                                        width: "15px",
                                                        paddingRight: "5px",
                                                        color: "	 #ff8080",
                                                    }}
                                                />
                                                <span className="fc_span1">
                                                    {c.mileage}
                                                </span>{" "}
                                                km / ltr
                                            </div>
                                            <div className="fc_d9">
                                                <FontAwesomeIcon
                                                    icon={faRobot}
                                                    style={{
                                                        height: "15px",
                                                        width: "15px",
                                                        paddingRight: "5px",
                                                        color: "	 #ff8080",
                                                    }}
                                                />
                                                <span className="fc_span1">
                                                    Self-Drive & Without Fuel
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="fc_d10">
                                        <div>
                                            ₹{" "}
                                            <span className="fc_span2">
                                                {c.minPrice}
                                            </span>{" "}
                                            / {c.minPrice ? c.minPrice : "hr"}
                                        </div>
                                    </div>
                                    {/* {c.pricing && (
                                    <div>
                                        <PriceTable pricing={c.pricing} />
                                    </div>
                                )} */}
                                </div>
                                {/* main box end*/}
                            </div>
                        </Col>
                    ))}
                </Row>
            )}
        </div>
    );
};

export default BookedCarIn;
