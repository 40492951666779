import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../AuthProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGasPump,
  faGear,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
  Timestamp,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

const UpcomingBookings = () => {
  const { user, db, storage } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [popup, setPopup] = useState(false);
  const [car1File, setcar1File] = useState("");
  const [car1, setcar1] = useState("");
  const [car2File, setcar2File] = useState("");
  const [car2, setcar2] = useState("");
  const [meterFile, setmeterFile] = useState("");
  const [meter, setmeter] = useState("");
  const [bookId, setBookId] = useState("");
  const [book, setBook] = useState([]);

  const fetchBooks = async () => {
    const q = query(
      collection(db, "bookings"),
      where("passengerId", "==", user?.phoneNumber),
      where("status", "==", "booked")
    );
    let arr = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      arr.push(doc.data());
    });
    setBook(arr);
    setLoading(false);
  };

  useEffect(() => {
    fetchBooks();
  }, [user?.phoneNumber]);

  const handleUploadPic = (imageFile) => {
    return new Promise((resolve, reject) => {
      let ids1 = Math.floor(100000 + Math.random() * 90000).toString();
      let ids2 = new Date();
      let users = user?.phoneNumber;
      let url_path = users + "_" + ids1 + "_" + ids2;
      const storageRef = ref(
        storage,
        `/MainUser/${users}/StartBooking/${url_path}`
      );
      const uploadTask = uploadBytesResumable(storageRef, imageFile);
      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (err) => {
          reject(err);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref)
            .then((url) => {
              resolve(url);
            })
            .catch((err) => {
              reject(err);
            });
        }
      );
    });
  };

  const handleSub = async () => {
    const car1URL = await handleUploadPic(car1File);
    const car2URL = await handleUploadPic(car2File);
    const meterURL = await handleUploadPic(meterFile);

    setPopup(false);
    setLoading(true);
    window.scroll(0, 0);
    const docRef = doc(db, "bookings", bookId);
    await updateDoc(docRef, {
      carPhoto: [car1URL, car2URL],
      meterReding: meterURL,
      status: "running",
    })
      .then(() => {
        fetchBooks();
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  return (
    <div>
      {popup && (
        <div className="popup-screen">
          <div className="popup">
            <div className="popup_p">
              <div>
                <h2>Add these images</h2>
              </div>
            </div>
            <div className="popup_p-c">
              <div className="popup_p-c-2">
                <div>
                  <label>Car Photos</label>
                  <div>
                    <br />
                    <img className="acc-image" src={car1} alt="" />
                  </div>
                  <div>
                    <input
                      className="dash_file1"
                      type="file"
                      onChange={(e) => {
                        setcar1(URL.createObjectURL(e.target.files[0]));
                        setcar1File(e.target.files[0]);
                      }}
                    />
                  </div>
                </div>
                <div>
                  <div>
                    <br />
                    <img className="acc-image" src={car2} alt="" />
                  </div>
                  <div>
                    <input
                      className="dash_file1"
                      type="file"
                      onChange={(e) => {
                        setcar2(URL.createObjectURL(e.target.files[0]));
                        setcar2File(e.target.files[0]);
                      }}
                    />
                  </div>
                </div>
                <br />
                <div>
                  <label>Meter Photo</label>
                  <div>
                    <br />
                    <img className="acc-image" src={meter} alt="" />
                  </div>
                  <div>
                    <input
                      className="dash_file1"
                      type="file"
                      onChange={(e) => {
                        setmeter(URL.createObjectURL(e.target.files[0]));
                        setmeterFile(e.target.files[0]);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="popup_b">
              <button onClick={handleSub}>Start</button>
              <button onClick={() => setPopup(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
      <div>
        <h1 className="bookHead">Upcoming Bookings</h1>
      </div>
      <div>
        {loading ? (
          <h1>Loading....</h1>
        ) : (
          <div className="c_book_c">
            {book.length === 0 && <h2>No bookings</h2>}
            {book.map((c, i) => {
              const return_date = new Timestamp(
                c?.return_date?.seconds,
                c?.return_date?.nanoseconds
              ).toDate();
              const pickup_date = new Timestamp(
                c?.pickup_date?.seconds,
                c?.pickup_date?.nanoseconds
              ).toDate();

              return (
                <div key={i} className="book_con">
                  <div className="In_c_c">
                    <div className="In_c_c_Info">
                      <small>{c?.car_details?.vehiclesBrands}</small>
                      <h4>{c?.car_details?.vehiclesModalName}</h4>
                      <div className="In_c_c_Info_2">
                        <div className="In_c_c_Info_3">
                          <FontAwesomeIcon
                            icon={faGasPump}
                            style={{
                              height: "15px",
                              width: "15px",
                              paddingRight: "5px",
                              color: "	 #ff8080",
                            }}
                          />
                          <p>{c?.car_details?.fuelType}</p>
                        </div>
                        <div className="In_c_c_Info_3">
                          <FontAwesomeIcon
                            icon={faUserGroup}
                            style={{
                              height: "15px",
                              width: "15px",
                              paddingRight: "5px",
                              color: "	 #ff8080",
                            }}
                          />
                          <p>{c?.car_details?.seatingCapacity}</p>
                        </div>
                        <div className="In_c_c_Info_3">
                          <FontAwesomeIcon
                            icon={faGear}
                            style={{
                              height: "15px",
                              width: "15px",
                              paddingRight: "5px",
                              color: "	 #ff8080",
                            }}
                          />
                          <p>{c?.car_details?.transmissionType}</p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <img
                        style={{ cursor: "pointer" }}
                        className="In_c_pic"
                        src={c?.car_details?.thumbnail}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="book_con_2">
                    <div className="book_con_3">
                      <h4>Passenger Details</h4>
                      <div className="book_con_3_in">
                        <div>Name : {c?.passenger_details?.name}</div>
                        {/* <div>Adress : {c?.passenger_details?.address}</div> */}
                        <div>Email : {c?.passenger_details?.email}</div>
                        <div>
                          Driving License :{" "}
                          {c?.passenger_details?.drivingLicence}
                        </div>
                      </div>
                    </div>
                    <div className="book_con_3">
                      <h4>Host Details</h4>
                      <div className="book_con_3_in">
                        <div>Name : {c?.host_details?.name}</div>
                        <div>Email :{c?.host_details?.email} </div>
                        <div>Mobile : {c?.host_details?.phoneNumber} </div>
                      </div>
                    </div>
                    <div className="book_con_3">
                      <h4>Ride Details</h4>
                      <div className="book_con_3_in">
                        <div>Status : {c?.status}</div>
                        <div>Payment Status : {c?.paymentDetails?.state}</div>
                        <div>Payment method : {c?.payment_mode}</div>
                        <div>Car reg number : {c?.car_details?.id}</div>
                        <div>Navigation : {c?.cityName}</div>
                        <div>Duration : {c?.duration}</div>
                        <div>
                          Pick up date : {pickup_date?.toLocaleString()}
                        </div>
                        <div>Return date : {return_date?.toLocaleString()}</div>
                      </div>
                    </div>
                  </div>
                  <div className="book_con_2">
                    <button
                      onClick={() => {
                        setPopup(true);
                        setBookId(c?.id);
                      }}
                      className="btn_book_ad_user"
                    >
                      Start
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default UpcomingBookings;
