import React from "react";
import "./SearchedCar.css";
import { Row } from "antd";
import SCar from "./SCar/SCar";

const SearchedCar = ({ cars }) => {
    return (
        <div className="s_c_Container">
            <Row
                style={{
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                }}
                gutter={30}
            >
                {cars.map((item, i) => (
                    <SCar key={i} item={item} />
                ))}
                {cars?.sort((item, index) =>
                    item.bookingStatus === "booked" ? 1 : -1,
                ).length === 0 && (
                    <h3 style={{ textAlign: "center", paddingInline: "20%" }}>
                        No cars available
                    </h3>
                )}
            </Row>
        </div>
    );
};

export default SearchedCar;
