import React from "react";
import "./acrd.css";

const Accordion = ({ name, details, handleSlide, isActive, i, isOpenAll }) => {
	return (
		<div className='accordion-item'>
			<div className='accordion-title' onClick={() => handleSlide(i)}>
				<div>
					<b>{name}</b>
				</div>
				<div>
					<b>{isActive === i || isOpenAll ? "-" : "+"}</b>
				</div>
			</div>
			<div
				className={
					isActive === i || isOpenAll
						? "accordion-content-open"
						: "accordion-content-close"
				}
			>
				{details}
			</div>
		</div>
	);
};

export default Accordion;
