import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Account from "./component/Account/Account";
import "./dashboard.css";
import { useContext } from "react";
import { AuthContext } from "../AuthProvider";
import AppStore from "../screen/AppStore";

const HostCar = (props) => {
  const { db } = useContext(AuthContext);
  const [services, setServices] = useState("");
  // ============================================ fetch data from firebase ===============================================
  const fetchCars = async () => {
    const querySnapshots = collection(db, "Go_User");
    const q = query(
      querySnapshots,
      where("userid", "==", `${selectedUsers?.email}`)
    );
    const querySnapshott = await getDocs(q);
    querySnapshott.forEach((doc) => {
      setServices(doc.data());
    });
  };
  useEffect(() => {
    fetchCars();
  }, []);

  // const userInfos = useSelector((state) => state);
  // const selectedUsers = userInfos.usersDataReducer?.user;
  const selectedUsers = props.userData;

  return (
    <div className="dash_host_main">
      {/* <div className="dash_host_name">
                <h2>{selectedUsers?.displayName}</h2>
            </div> */}
      <Tabs className={"Tabs_host"}>
        {/* <TabList className={"tabList_host"}>
                    {/* <Tab>My listing</Tab>
                    <Tab>Add Car</Tab> */}
        {/* <Tab>My Account</Tab>
                </TabList> */}
        {/* 
                <TabPanel>
                    {services.status === "internal" ? (
                        <AdminCarListing />
                    ) : (
                        <CarListing />
                    )}
                </TabPanel>
                <TabPanel>
                    <CarAdd />
                </TabPanel> */}
        <TabPanel>
          <Account />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default AppStore(HostCar);
