import React, { useContext, useEffect, useRef, useState } from "react";
import { imgPath } from "../R/Images/imgPath";
import "./navbar.css";
import { Navlist } from "./navlist";
import { useDispatch, useSelector } from "react-redux";
import { collection, getDocs, query, where } from "firebase/firestore";
import useBlogger from "../CustomHooks/UseBlogger";
import { AuthContext } from "../AuthProvider";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faBookAtlas,
  faCarAlt,
  faIdBadge,
  faRightFromBracket,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import AppStore from "../screen/AppStore";

const Navbar = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const [kebab, setKebab] = useState(false);
  const [services, setServices] = useState("");
  const [loading, setLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const { user, logOut, db } = useContext(AuthContext);
  const [isInternal] = useBlogger(user?.email);

  const fetchUsers = async () => {
    const querySnapshot = await getDocs(collection(db, "users"));
    let arr = [];
    querySnapshot.forEach((doc) => {
      arr.push(doc.data());
    });
    setServices(arr);
  };
  const fetchUser = async () => {
    setLoading(true);
    if (user?.phoneNumber) {
      const q = query(
        collection(db, "users"),
        where("mobileNumber", "==", user.phoneNumber)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        if (doc.data()) {
          if (doc.data().role === "admin") {
            setIsAdmin(true);
            setLoading(false);
          }
          setLoading(false);
        }
      });
    }

    if (user?.email) {
      const q = query(
        collection(db, "users"),
        where("email", "==", user.email)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        if (doc.data()) {
          if (doc.data().role === "admin") {
            setIsAdmin(true);
            setLoading(false);
          }
          setLoading(false);
        }
      });
    } else {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    fetchUser();
  }, [user]);

  useEffect(() => {
    if (selectedUsers?.email) {
      if (services) {
        if (services.find((o) => o.id === selectedUsers?.email)) {
        } else {
        }
      }
    }
  }, [services]);

  // ================================ use info redux ========================================

  // const userInfos = useSelector((state) => state);
  // const selectedUsers = userInfos.usersDataReducer?.user;
  const selectedUsers = props.userData;
  // console.log("selectedUsers", selectedUsers);

  // ======================================== SignOut ============================================
  const SignOut = async () => {
    setIsAdmin(false);

    await logOut()
      .then((res) => {
        console.log("logOut res", res);
        props.setUserDataAction(undefined)
      })
      .catch((error) => {
        props.setUserDataAction(undefined)
        console.log("logOut error", error);
      });
  };
  // ======================================== Google Login =============================================
  const navigate = useNavigate();
  const LoginWithGoogle = () => {
    navigate("/login-1");
  };

  // ========================================= redux for login ================================

  // const dispatch = useDispatch();

  // ========================================= icon change ===================================
  const menuRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsVisible(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    // // Unbind the event listener on cleanup
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  const kebabRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (kebabRef.current && !kebabRef.current.contains(event.target)) {
        setKebab(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    // // Unbind the event listener on cleanup
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [kebabRef]);

  return (
    <header className="header">
      {/* pop up menu  */}
      <div ref={menuRef}>
        {isVisible ? (
          <button onClick={() => setIsVisible(false)}>
            <FontAwesomeIcon
              className="toggle-menu"
              icon={faXmark}
              style={{ color: "#949494" }}
            />
          </button>
        ) : (
          <button onClick={() => setIsVisible(true)}>
            <FontAwesomeIcon
              className="toggle-menu"
              icon={faBars}
              style={{ color: "#949494" }}
            />
          </button>
        )}
        <nav className={isVisible ? "menu" : "menuClose"}>
          <ul className="hamburgerMenu">
            <li className="loginLi">
              {!selectedUsers?.uid && (
                <div
                  style={{
                    paddingLeft: "20px",
                    cursor: "pointer",
                  }}
                >
                  <Link
                    className="login_menu"
                    to={"/login-1"}
                    onClick={() => {
                      setIsVisible(false);
                    }}
                  >
                    Login
                  </Link>
                </div>
              )}
            </li>
            <li>
              <Link
                onClick={() => {
                  window.scrollTo(0, 0);
                  setIsVisible(false);
                }}
                to="/"
                className="lnk-menu"
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  window.scrollTo(0, 0);
                  setIsVisible(false);
                }}
                to="/about"
                className="lnk-menu"
              >
                About
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  window.scrollTo(0, 0);
                  setIsVisible(false);
                }}
                to="/blogs"
                className="lnk-menu"
              >
                Blogs
              </Link>
            </li>
            {isInternal && (
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setIsVisible(false);
                  }}
                  className="lnk-menu"
                  to="/adblog"
                >
                  Add Blog
                </Link>
              </li>
            )}
            <li>
              <Link
                onClick={() => {
                  window.scrollTo(0, 0);
                  setIsVisible(false);
                }}
                to="/contact"
                className="lnk-menu"
              >
                Contact
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  window.scrollTo(0, 0);
                  setIsVisible(false);
                }}
                to="/faq"
                className="lnk-menu"
              >
                FAQs
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  window.scrollTo(0, 0);
                  setIsVisible(false);
                }}
                to="/explore-cars"
                className="lnk-menu"
              >
                Explore Cars
              </Link>
            </li>

            <li className="whatLi">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <lottie-player
                  style={{ width: "30px" }}
                  autoplay
                  loop
                  mode="normal"
                  src=" https://assets5.lottiefiles.com/private_files/lf30_lghsciar.json"
                ></lottie-player>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setIsVisible(false);
                  }}
                  className="whatLiAnk"
                  to="https://wa.me/+919926222099"
                >
                  WhatsApp
                </Link>
              </div>
            </li>
            <li className="whatLi">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <lottie-player
                  style={{ width: "30px" }}
                  autoplay
                  loop
                  mode="normal"
                  src=" https://assets1.lottiefiles.com/packages/lf20_u3ascu0i.json"
                ></lottie-player>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setIsVisible(false);
                  }}
                  to="tel:9926222099"
                  className="whatLiAnk"
                >
                  Call now
                </Link>
              </div>
            </li>
          </ul>
        </nav>
      </div>
      {/*================================== kebab menu===================================*/}

      {/* ========================================== logo ================================================ */}
      <div className="hd_d1">
        {/* ========================================== logo ================================================ */}
        <span>
          <img className="nb_logo" src={imgPath.logo} alt="" />
        </span>

        {/* ========================================== menu ================================================ */}
        <div className="menuD1">
          {/* ========================================== menu list ================================================ */}
          <div className="nav_menu_list">
            <ul className={isVisible ? "nav-menu" : "nav-menu active"}>
              {Navlist.map((item, index) => {
                return (
                  <li key={index}>
                    <Link
                      onClick={() => {
                        window.scrollTo(0, 0);
                      }}
                      className="nav-links"
                      to={item.url}
                    >
                      {item.title}
                    </Link>
                  </li>
                );
              })}
              {isInternal && (
                <li>
                  <Link
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                    className="nav-links"
                    to="/adblog"
                  >
                    Add Blog
                  </Link>
                </li>
              )}
            </ul>
          </div>

          {/* ========================================== host button ================================================ */}
          <div className="host_d1">
            {selectedUsers?.uid ? (
              /* ========================================== user button ================================================ */
              <div ref={kebabRef}>
                {" "}
                {kebab ? (
                  <button
                    className="user_btn"
                    onClick={() => {
                      setKebab(false);
                    }}
                  >
                    <h2 className="userLogo">
                      {selectedUsers?.displayName?.charAt(0) || "U"}
                    </h2>
                  </button>
                ) : (
                  <button
                    className="user_btn"
                    onClick={() => {
                      setKebab(true);
                    }}
                  >
                    <h2 className="userLogo">
                      {selectedUsers?.displayName?.charAt(0) || "U"}
                    </h2>
                  </button>
                )}
                <div>
                  {kebab ? (
                    <div className="km_d1">
                      {loading ? (
                        <div>
                          <br />
                          <br />
                          Loading...
                        </div>
                      ) : (
                        <div className="km_d2">
                          <p className="km_p1">
                            {selectedUsers?.displayName ||
                              selectedUsers?.phoneNumber}
                          </p>

                          <div className="km_d3">
                            <FontAwesomeIcon
                              icon={faIdBadge}
                              style={{
                                color: "#878787",
                                height: "17px",
                                width: "17px",
                              }}
                            />
                            <Link
                              onClick={() => {
                                window.scrollTo(0, 0);
                                setKebab(false);
                              }}
                              className="km_a1"
                              to={`/${selectedUsers?.displayName ||
                                selectedUsers?.phoneNumber
                                }`}
                            >
                              My Account
                            </Link>
                          </div>
                          {isAdmin && (
                            <div className="km_d3">
                              <FontAwesomeIcon
                                icon={faBookAtlas}
                                style={{
                                  color: "#878787",
                                  height: "17px",
                                  width: "17px",
                                }}
                              />
                              <Link
                                onClick={() => {
                                  setKebab(false);
                                  window.scrollTo(0, 0);
                                }}
                                className="km_a1"
                                to="/admin-panel/dashboard"
                              >
                                Control Panel
                              </Link>
                            </div>
                          )}
                          <div className="km_d3">
                            <FontAwesomeIcon
                              icon={faCarAlt}
                              style={{
                                color: "#878787",
                                height: "17px",
                                width: "17px",
                              }}
                            />
                            <Link
                              onClick={() => {
                                setKebab(false);
                                window.scrollTo(0, 0);
                              }}
                              className="km_a1"
                              to={"/user-bookings/user-current"}
                            >
                              My Bookings
                            </Link>
                          </div>

                          <div className="km_d3">
                            <FontAwesomeIcon
                              icon={faRightFromBracket}
                              style={{
                                color: "#878787",
                                height: "17px",
                                width: "17px",
                              }}
                            />
                            <Link
                              onClick={() => {
                                setKebab(false);
                                SignOut();
                                window.scrollTo(0, 0);
                              }}
                              className="km_a1"
                              to="/"
                            >
                              Sign out
                            </Link>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
            ) : (
              <button
                onClick={() => {
                  LoginWithGoogle();
                }}
                className="host_btn1"
                type="submit"
              >
                Host Car
              </button>
            )}
          </div>
          {/* ========================================== singIN button ================================================ */}
          {!selectedUsers?.uid && (
            <div className="auth_button">
              <Link
                className="login_menu"
                to={"/login-1"}
                onClick={() => {
                  setIsVisible(false);
                }}
              >
                Login
              </Link>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default AppStore(Navbar);
