import React, { useContext, useEffect, useState } from "react";
import "./DetailsCar.css";
import { AuthContext } from "../../../../AuthProvider";
import { Icon } from '@iconify-icon/react';
import {
  deleteDoc,
  doc,
  query,
  collection,
  where,
  getDocs,
  updateDoc,
} from "@firebase/firestore";
import { useNavigate } from "react-router";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { Box, Button } from "@mui/material";
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';
import { Loader } from "@googlemaps/js-api-loader"
import AppStore from "../../../AppStore";
const MAP_API = "AIzaSyC55XKQNgRo0LJNfY0S7ZGMhG-AGAFkAVQ"



const DetailsCar = (props) => {
  const { loading, db, setLoading, storage } = useContext(AuthContext);
  const navigate = useNavigate();

  const carId = localStorage.getItem("carId");

  const [car, setCar] = useState({});

  const fetchMyAPI = async () => {
    const q = query(collection(db, "cars"), where("id", "==", carId));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // console.log('car details', doc.data());
      fetchHost(doc.data().hostId)

      setCar(doc.data());
      setCarName(doc.data().vehiclesModalName);
      setCarBrand(doc.data().vehiclesBrands);
      setvehiclesSegmen(doc.data().vehiclesSegment);
      setacType(doc.data().acType);
      setBookingStatus(doc.data().bookingStatus);

      setCarNumber(doc.data().licenseNumber);

      setdriverType(doc.data().driverType);
      setfuelType(doc.data().fuelType);
      setLicenseNumber(doc.data().licenseNumber);
      setlocation(doc.data().location);
      setmileage(doc.data().mileage);
      setprice(doc.data().basePrice);
      setseatingCapacity(doc.data().seatingCapacity);
      settransmissionType(doc.data().transmissionType);
      setpickupAddress(doc.data().pickupAddress);
      setcarID(doc.data().id);
      setImageURL(doc.data().thumbnail);
      setStatus(doc.data().status);
      setLoading(true);
    });
  };

  const fetchHost = async (hostId) => {
    const q = query(collection(db, "users"), where("hostId", "==", hostId));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      setHostDetails(doc.data())
    });
  };
  const [carName, setCarName] = useState("");
  const [licenseNumber, setLicenseNumber] = useState("");
  const [carBrand, setCarBrand] = useState("");
  const [vehiclesSegmen, setvehiclesSegmen] = useState("");
  const [acType, setacType] = useState("");
  const [bookingStatus, setBookingStatus] = useState("");
  const [hostDetails, setHostDetails] = useState("");
  const [driverType, setdriverType] = useState("");
  const [fuelType, setfuelType] = useState("");
  const [location, setlocation] = useState("");
  const [mileage, setmileage] = useState("");
  const [price, setprice] = useState("");
  const [seatingCapacity, setseatingCapacity] = useState("");
  const [transmissionType, settransmissionType] = useState("");
  const [carID, setcarID] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [showPopup2, setShowPopup2] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [imageURL, setImageURL] = useState("");
  const [pickupAddress, setpickupAddress] = useState("");
  const [status, setStatus] = useState("");
  const [CarNumber, setCarNumber] = useState("");
  const [product, setData] = useState([]);
  // var position = []
  // const [position, setPosition] = useState({});
  // console.log("position", position);
  // console.log("products==$$$$$$$$$$$$$$$=====", product);
  // ----------------------------------------------------MAP--------------------------------------------------


  const getData = async () => {
    //   // const res = await fetch('http://13.127.228.11/webservice?token=getLiveData&user=gwc@hypertrack.in&pass=Abc@1234&company=GoWithCar&format=json')
    //   // const Result = await res.json()
    //   const Result = {
    //     "VehicleData": [
    //       {
    //         "Vehicle_Name": "MP09WK6331- Tata Altroz",
    //         "Company": "GoWithCar",
    //         "Temperature": "--",
    //         "Latitude": "22.7710872",
    //         "GPS": "ON",
    //         "Vehicle_No": "Tata Altroz",
    //         "Door1": "--",
    //         "Door4": "--",
    //         "Branch": "GoWithCar",
    //         "Vehicletype": "Hatchback",
    //         "Door2": "--",
    //         "Door3": "--",
    //         "GPSActualTime": "30-01-2024 13:00:09",
    //         "Datetime": "30-01-2024 13:00:11",
    //         "Status": "STOP",
    //         "DeviceModel": "V5",
    //         "Speed": "0",
    //         "AC": "--",
    //         "Imeino": "867440064150931",
    //         "Odometer": "0",
    //         "POI": "--",
    //         "Driver_Middle_Name": "--",
    //         "Longitude": "75.9082761",
    //         "Immobilize_State": "Off",
    //         "IGN": "OFF",
    //         "Driver_First_Name": "--",
    //         "Angle": "276",
    //         "SOS": "--",
    //         "Fuel": [],
    //         "battery_percentage": 100,
    //         "ExternalVolt": "12.35",
    //         "Driver_Last_Name": "--",
    //         "Power": "ON",
    //         "Location": "pawan fuels, TT Nagar, Pipliya Kumar, Indore, Madhya Pradesh (NW)"
    //       },
    //       {
    //         "Vehicle_Name": "MP07ZA6879 - Venue Blue New",
    //         "Company": "GoWithCar",
    //         "Temperature": "--",
    //         "Latitude": "22.7450817",
    //         "GPS": "ON",
    //         "Vehicle_No": "MP07ZA6879",
    //         "Door1": "--",
    //         "Door4": "--",
    //         "Branch": "GoWithCar",
    //         "Vehicletype": "Hatchback",
    //         "Door2": "--",
    //         "Door3": "--",
    //         "GPSActualTime": "30-01-2024 13:00:19",
    //         "Datetime": "30-01-2024 13:00:19",
    //         "Status": "STOP",
    //         "DeviceModel": "V5",
    //         "Speed": "0",
    //         "AC": "--",
    //         "Imeino": "867440062080163",
    //         "Odometer": "0",
    //         "POI": "--",
    //         "Driver_Middle_Name": "--",
    //         "Longitude": "75.9083667",
    //         "Immobilize_State": "Off",
    //         "IGN": "OFF",
    //         "Driver_First_Name": "--",
    //         "Angle": "218",
    //         "SOS": "--",
    //         "Fuel": [],
    //         "battery_percentage": 100,
    //         "ExternalVolt": "12.51",
    //         "Driver_Last_Name": "--",
    //         "Power": "ON",
    //         "Location": "Patel Kirana store, Harsiddhi Nagar, Indore, Madhya Pradesh (SE)"
    //       },
    //       {
    //         "Vehicle_Name": "MP09WE9363- Blue Tiago",
    //         "Company": "GoWithCar",
    //         "Temperature": "--",
    //         "Latitude": "23.181645",
    //         "GPS": "ON",
    //         "Vehicle_No": "Tata tiago blue",
    //         "Door1": "--",
    //         "Door4": "--",
    //         "Branch": "GoWithCar",
    //         "Vehicletype": "Hatchback",
    //         "Door2": "--",
    //         "Door3": "--",
    //         "GPSActualTime": "30-01-2024 13:00:19",
    //         "Datetime": "30-01-2024 13:00:19",
    //         "Status": "STOP",
    //         "DeviceModel": "V5",
    //         "Speed": "0",
    //         "AC": "--",
    //         "Imeino": "867440063837009",
    //         "Odometer": "0",
    //         "POI": "--",
    //         "Driver_Middle_Name": "--",
    //         "Longitude": "75.7709194",
    //         "Immobilize_State": "Off",
    //         "IGN": "OFF",
    //         "Driver_First_Name": "--",
    //         "Angle": "260",
    //         "SOS": "--",
    //         "Fuel": [],
    //         "battery_percentage": 100,
    //         "ExternalVolt": "12.76",
    //         "Driver_Last_Name": "--",
    //         "Power": "ON",
    //         "Location": "Hotel High Light, Shivam Imperial, 38/3, Ahilya Bai Marg, Opposite Hotel, New Harifatak, Ujjain, Madhya Pradesh (SW)"
    //       },
    //       {
    //         "Vehicle_Name": "MP09WH9952 - Red Swift",
    //         "Company": "GoWithCar",
    //         "Temperature": "--",
    //         "Latitude": "22.7708794",
    //         "GPS": "ON",
    //         "Vehicle_No": "MP09WH9952",
    //         "Door1": "--",
    //         "Door4": "--",
    //         "Branch": "GoWithCar",
    //         "Vehicletype": "Hatchback",
    //         "Door2": "--",
    //         "Door3": "--",
    //         "GPSActualTime": "30-01-2024 13:00:04",
    //         "Datetime": "30-01-2024 13:00:04",
    //         "Status": "STOP",
    //         "DeviceModel": "V5",
    //         "Speed": "0",
    //         "AC": "--",
    //         "Imeino": "867440062197223",
    //         "Odometer": "0",
    //         "POI": "--",
    //         "Driver_Middle_Name": "--",
    //         "Longitude": "75.9082867",
    //         "Immobilize_State": "Off",
    //         "IGN": "OFF",
    //         "Driver_First_Name": "--",
    //         "Angle": "334",
    //         "SOS": "--",
    //         "Fuel": [],
    //         "battery_percentage": 83,
    //         "ExternalVolt": "12.66",
    //         "Driver_Last_Name": "--",
    //         "Power": "ON",
    //         "Location": "pawan fuels, TT Nagar, Pipliya Kumar, Indore, Madhya Pradesh (SW)"
    //       },
    //       {
    //         "Vehicle_Name": "MP04CQ7751 - Alto K10",
    //         "Company": "GoWithCar",
    //         "Temperature": "--",
    //         "Latitude": "22.77095",
    //         "GPS": "ON",
    //         "Vehicle_No": "MP04CQ7751",
    //         "Door1": "--",
    //         "Door4": "--",
    //         "Branch": "GoWithCar",
    //         "Vehicletype": "Hatchback",
    //         "Door2": "--",
    //         "Door3": "--",
    //         "GPSActualTime": "30-01-2024 13:00:04",
    //         "Datetime": "30-01-2024 13:00:04",
    //         "Status": "STOP",
    //         "DeviceModel": "V5",
    //         "Speed": "0",
    //         "AC": "--",
    //         "Imeino": "867440064172455",
    //         "Odometer": "0",
    //         "POI": "--",
    //         "Driver_Middle_Name": "--",
    //         "Longitude": "75.9082933",
    //         "Immobilize_State": "Off",
    //         "IGN": "OFF",
    //         "Driver_First_Name": "--",
    //         "Angle": "113",
    //         "SOS": "--",
    //         "Fuel": [],
    //         "battery_percentage": 100,
    //         "ExternalVolt": "12.56",
    //         "Driver_Last_Name": "--",
    //         "Power": "ON",
    //         "Location": "pawan fuels, TT Nagar, Pipliya Kumar, Indore, Madhya Pradesh (SW)"
    //       },
    //       {
    //         "Vehicle_Name": "MP09WJ7960 - Venue White",
    //         "Company": "GoWithCar",
    //         "Temperature": "--",
    //         "Latitude": "22.7709156",
    //         "GPS": "ON",
    //         "Vehicle_No": "MP09WJ7960",
    //         "Door1": "--",
    //         "Door4": "--",
    //         "Branch": "GoWithCar",
    //         "Vehicletype": "Hatchback",
    //         "Door2": "--",
    //         "Door3": "--",
    //         "GPSActualTime": "30-01-2024 12:53:50",
    //         "Datetime": "30-01-2024 13:02:35",
    //         "Status": "IDLE",
    //         "DeviceModel": "V5",
    //         "Speed": "0",
    //         "AC": "--",
    //         "Imeino": "867440061936712",
    //         "Odometer": "0",
    //         "POI": "--",
    //         "Driver_Middle_Name": "--",
    //         "Longitude": "75.9083333",
    //         "Immobilize_State": "--",
    //         "IGN": "ON",
    //         "Driver_First_Name": "--",
    //         "Angle": "11",
    //         "SOS": "--",
    //         "Fuel": [],
    //         "battery_percentage": 100,
    //         "ExternalVolt": "0.00",
    //         "Driver_Last_Name": "--",
    //         "Power": "ON",
    //         "Location": "pawan fuels, TT Nagar, Pipliya Kumar, Indore, Madhya Pradesh (SW)"
    //       },
    //       {
    //         "Vehicle_Name": "MP09WJ6437 - old venue blue",
    //         "Company": "GoWithCar",
    //         "Temperature": "--",
    //         "Latitude": "22.7354211",
    //         "GPS": "ON",
    //         "Vehicle_No": "MP09Wj6437",
    //         "Door1": "--",
    //         "Door4": "--",
    //         "Branch": "GoWithCar",
    //         "Vehicletype": "Hatchback",
    //         "Door2": "--",
    //         "Door3": "--",
    //         "GPSActualTime": "30-01-2024 13:00:22",
    //         "Datetime": "30-01-2024 13:00:23",
    //         "Status": "STOP",
    //         "DeviceModel": "V5",
    //         "Speed": "0",
    //         "AC": "--",
    //         "Imeino": "867440062082284",
    //         "Odometer": "0",
    //         "POI": "--",
    //         "Driver_Middle_Name": "--",
    //         "Longitude": "75.8154422",
    //         "Immobilize_State": "Off",
    //         "IGN": "OFF",
    //         "Driver_First_Name": "--",
    //         "Angle": "346",
    //         "SOS": "--",
    //         "Fuel": [],
    //         "battery_percentage": 100,
    //         "ExternalVolt": "12.81",
    //         "Driver_Last_Name": "--",
    //         "Power": "ON",
    //         "Location": "Leeds Enclave, Leeds Enclave , Opposite, HIlink City, Manu Shree Nagar, Indore, Madhya Pradesh (NE)"
    //       },
    //       {
    //         "Vehicle_Name": "MP09CY2596 - Swift white",
    //         "Company": "GoWithCar",
    //         "Temperature": "--",
    //         "Latitude": "22.9876483",
    //         "GPS": "ON",
    //         "Vehicle_No": "MP09CY2596",
    //         "Door1": "--",
    //         "Door4": "--",
    //         "Branch": "GoWithCar",
    //         "Vehicletype": "Hatchback",
    //         "Door2": "--",
    //         "Door3": "--",
    //         "GPSActualTime": "30-01-2024 13:00:29",
    //         "Datetime": "30-01-2024 13:00:29",
    //         "Status": "STOP",
    //         "DeviceModel": "V5",
    //         "Speed": "0",
    //         "AC": "--",
    //         "Imeino": "867440063837777",
    //         "Odometer": "0",
    //         "POI": "--",
    //         "Driver_Middle_Name": "--",
    //         "Longitude": "76.2628594",
    //         "Immobilize_State": "--",
    //         "IGN": "OFF",
    //         "Driver_First_Name": "--",
    //         "Angle": "130",
    //         "SOS": "--",
    //         "Fuel": [],
    //         "battery_percentage": 100,
    //         "ExternalVolt": "12.96",
    //         "Driver_Last_Name": "--",
    //         "Power": "ON",
    //         "Location": "Indore - Bhopal Rd, Bhalaikhurd, Madhya Pradesh (NE)"
    //       },
    //       {
    //         "Vehicle_Name": "DL1CQ6457",
    //         "Company": "GoWithCar",
    //         "Temperature": "--",
    //         "Latitude": "22.6903367",
    //         "GPS": "ON",
    //         "Vehicle_No": "DL1CQ6457",
    //         "Door1": "--",
    //         "Door4": "--",
    //         "Branch": "GoWithCar",
    //         "Vehicletype": "Hatchback",
    //         "Door2": "--",
    //         "Door3": "--",
    //         "GPSActualTime": "30-01-2024 13:00:05",
    //         "Datetime": "30-01-2024 13:00:05",
    //         "Status": "STOP",
    //         "DeviceModel": "V5",
    //         "Speed": "0",
    //         "AC": "--",
    //         "Imeino": "867440063836209",
    //         "Odometer": "0",
    //         "POI": "--",
    //         "Driver_Middle_Name": "--",
    //         "Longitude": "75.9188011",
    //         "Immobilize_State": "Off",
    //         "IGN": "OFF",
    //         "Driver_First_Name": "--",
    //         "Angle": "309",
    //         "SOS": "--",
    //         "Fuel": [],
    //         "battery_percentage": 100,
    //         "ExternalVolt": "12.71",
    //         "Driver_Last_Name": "--",
    //         "Power": "ON",
    //         "Location": "Leteshwar Hanuman Mandir, Udhyog Nagar, Palda, Deoguradia, Madhya Pradesh (NE)"
    //       },
    //       {
    //         "Vehicle_Name": "MP09ZS3422",
    //         "Company": "GoWithCar",
    //         "Temperature": "--",
    //         "Latitude": "22.7488756",
    //         "GPS": "ON",
    //         "Vehicle_No": "MP09ZS3422",
    //         "Door1": "--",
    //         "Door4": "--",
    //         "Branch": "GoWithCar",
    //         "Vehicletype": "Hatchback",
    //         "Door2": "--",
    //         "Door3": "--",
    //         "GPSActualTime": "30-01-2024 13:00:33",
    //         "Datetime": "30-01-2024 13:00:33",
    //         "Status": "STOP",
    //         "DeviceModel": "V5",
    //         "Speed": "0",
    //         "AC": "--",
    //         "Imeino": "867440061926176",
    //         "Odometer": "0",
    //         "POI": "--",
    //         "Driver_Middle_Name": "--",
    //         "Longitude": "75.8734061",
    //         "Immobilize_State": "Off",
    //         "IGN": "OFF",
    //         "Driver_First_Name": "--",
    //         "Angle": "332",
    //         "SOS": "--",
    //         "Fuel": [],
    //         "battery_percentage": 100,
    //         "ExternalVolt": "12.71",
    //         "Driver_Last_Name": "--",
    //         "Power": "ON",
    //         "Location": "Adarsh Bal Vinay Mandir, Utthan, 249, Clark Colony Near Kankeshvari, Clerk Colony, Indore, Madhya Pradesh (SE)"
    //       },
    //       {
    //         "Vehicle_Name": "MP43ZD3256",
    //         "Company": "GoWithCar",
    //         "Temperature": "--",
    //         "Latitude": "22.7708889",
    //         "GPS": "ON",
    //         "Vehicle_No": "MP43ZD3256",
    //         "Door1": "--",
    //         "Door4": "--",
    //         "Branch": "GoWithCar",
    //         "Vehicletype": "Hatchback",
    //         "Door2": "--",
    //         "Door3": "--",
    //         "GPSActualTime": "30-01-2024 13:03:06",
    //         "Datetime": "30-01-2024 13:03:06",
    //         "Status": "STOP",
    //         "DeviceModel": "PT06",
    //         "Speed": "0",
    //         "AC": "--",
    //         "Imeino": "356218602282632",
    //         "Odometer": "0",
    //         "POI": "--",
    //         "Driver_Middle_Name": "--",
    //         "Longitude": "75.9082822",
    //         "Immobilize_State": "--",
    //         "IGN": "--",
    //         "Driver_First_Name": "--",
    //         "Angle": "0",
    //         "SOS": "--",
    //         "Fuel": [],
    //         "battery_percentage": -11,
    //         "ExternalVolt": "--",
    //         "Driver_Last_Name": "--",
    //         "Power": "--",
    //         "Location": "pawan fuels, TT Nagar, Pipliya Kumar, Indore, Madhya Pradesh (SW)"
    //       }
    //     ]
    //   }

    let result = []
    result = props.mapResponse;
    setData(result);
  }


  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    fetchMyAPI();
    let k = props.MapAction()
    // console.log("-========call============", k);
  }, []);

  const handleUpdate = async () => {
    setLoading(true);
    if (imageFile) {
      handleDeletePicture(imageURL);
      const storageRef = ref(storage, `/images/${imageFile.name}`);
      const uploadTask = uploadBytesResumable(storageRef, imageFile);
      uploadTask.on(
        "state_changed",
        (snapshot) => { },
        (err) => { },
        () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then((urls) => {
            updateDoc(doc(db, "cars", carID), {
              vehiclesModalName: carName,
              vehiclesBrands: carBrand,
              vehiclesSegment: vehiclesSegmen,
              acType: acType,
              bookingStatus: bookingStatus,
              status: status,
              driverType: driverType,
              fuelType: fuelType,
              licenseNumber: licenseNumber,
              location,
              pickupAddress,
              mileage: mileage * 1,
              thumbnail: urls,
              price: price * 1,
              seatingCapacity: seatingCapacity * 1,
              transmissionType: transmissionType,
            })
              .then(() => {
                // File deleted successfully
              })
              .catch((error) => {
                console.log(error);
              });
            setShowPopup(false);
            setLoading(false);
          });
        }
      );
    } else {
      handleSub();
    }
  };

  const handleDeletePicture = (pictureUrl) => {
    const desertRef = ref(storage, `${pictureUrl}`);
    // Delete the file
    deleteObject(desertRef)
      .then(() => {
        // File deleted successfully
      })
      .catch((error) => { });
  };

  const handleSub = async () => {
    const myCar = {
      carName,
      carBrand,
      vehiclesSegmen,
      acType,
      bookingStatus,
      status,
      driverType,
      fuelType,
      licenseNumber,
      location,
      mileage,
      price,
      seatingCapacity,
      transmissionType,
    };

    const docRef = doc(db, "cars", carID);
    // Update the timestamp field with the value from the server
    await updateDoc(docRef, {
      vehiclesModalName: carName,
      vehiclesBrands: carBrand,
      vehiclesSegment: vehiclesSegmen,
      acType: acType,
      bookingStatus: bookingStatus,
      status: status,
      driverType: driverType,
      pickupAddress,
      fuelType: fuelType,
      location: location,
      mileage: mileage * 1,
      basePrice: price * 1,
      seatingCapacity: seatingCapacity * 1,
      transmissionType: transmissionType,
    })
      .then(() => {
        // File deleted successfully
      })
      .catch((error) => {
        console.log(error);
      });
    setLoading(false);
    navigate("/admin-panel/carlist/approved");
    window.scroll(0, 0);
  };

  const handleDelete = async () => {
    await deleteDoc(doc(db, "cars", car.id))
      .then(() => {
        navigate("/admin-panel/carlist");
      })
      .catch((error) => {
        console.error("Error removing document: ", error);
      });
  };

  const [segs, setSegs] = useState([]);

  const fetchSeg = async () => {
    const query = collection(db, "segment");
    let arr_blogs = [];
    const querySnapshot = await getDocs(query);
    querySnapshot.forEach((doc) => {
      arr_blogs.push(doc.data());
    });
    setSegs(arr_blogs);
  };
  const [brands, setbrand] = useState([]);

  const fetchbrand = async () => {
    const query = collection(db, "brands");
    let arr_blogs = [];
    const querySnapshot = await getDocs(query);
    querySnapshot.forEach((doc) => {
      arr_blogs.push(doc.data());
    });
    setbrand(arr_blogs);
  };
  const [locations, setlocations] = useState([]);

  const { setUserHintAd } = useContext(AuthContext);

  const handleUser = async (userHint) => {
    navigate(`/admin-panel/host/${userHint}`);
    window.scroll(0, 0);
  };

  const fetchlocations = async () => {
    const query = collection(db, "location");
    let arr_blogs = [];
    const querySnapshot = await getDocs(query);
    querySnapshot.forEach((doc) => {
      arr_blogs.push(doc.data());
    });
    setlocations(arr_blogs);
  };
  const [location1, setlocation1] = useState("");

  const fetchlocation = async () => {
    const q = query(collection(db, "location"), where("id", "==", location));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      setlocation1(doc.data().name);
    });
  };

  useEffect(() => {
    fetchlocation();
  }, [location]);


  


  useEffect(() => {
    fetchSeg();
    fetchbrand();
    fetchlocations();
   
  }, []);

  useEffect(() => {
    let map;
    const loader = new Loader({
      apiKey: "AIzaSyC55XKQNgRo0LJNfY0S7ZGMhG-AGAFkAVQ",
      version: "weekly",
    });

    product?.map((item) => {
      if (item.Vehicle_No == CarNumber) {
        var pos = {
          lat: parseFloat(item.Latitude),
          lng: parseFloat(item.Longitude)
        }

        // console.log("pos", pos);
        loader.load().then(async () => {
          const { Map } = await window.google.maps.importLibrary("maps");
          map = new Map(document.getElementById("map"), {
            center: pos,
            zoom: 16,
          });
          new window.google.maps.Marker({
            position: pos,
            map,
            title: "Hello World!",
            icon:'https://i.imgur.com/uzR7HTw.png',
            
          });
        });
      }
    })
  })

  if (!loading) {
    return (
      <div style={{ padding: "20%" }}>
        <h1>Loading...</h1>
      </div>
    );
  }



  


  if (loading) {
    // ...

    return (
      <div className="carDtlsContainer">
        {showPopup && (
          <div className="popup-screen">
            <div className="popup">
              <div className="popup_p">
                <h2>Want to save now?</h2>
          
              </div>
              <div className="popup_b">
                <button onClick={handleUpdate}>Yes</button>

                <button onClick={() => setShowPopup(false)}>No</button>
              </div>
            </div>
          </div>
        )}
        {showPopup2 && (
          <div className="popup-screen">
            <div className="popup">
              <div className="popup_p">
                <h2>Want to delete now?</h2>
                
              </div>
              <div className="popup_b">
                <button onClick={handleDelete}>Yes</button>

                <button onClick={() => setShowPopup2(false)}>No</button>
              </div>
            </div>
          </div>
        )}
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>

          <h1>Car details :</h1>
          <Button onClick={() => {
            setUserHintAd(hostDetails?.mobileNumber);
            handleUser(hostDetails?.mobileNumber);
          }} variant="contained" disableElevation>
            Host details
          </Button>
        </Box>
        <br />

        <div>
          <label>Vehicle Brand : {carBrand}</label>
          <br />
          <select
            className="select-car-ad"
            value={carBrand}
            placeholder="location"
            onChange={(e) => setCarBrand(e.target.value)}
          >
            <option value={carBrand}>{carBrand}</option>

            {brands.map((c, i) => (
              <option key={i} value={c.name}>
                {c.name}
              </option>
            ))}
          </select>
        </div>

        <br />
        <label>Vehicle Model Name</label>
        <br />
        <input
          className="select-car-ad"
          type="text"
          placeholder={carName}
          value={carName}
          onChange={(e) => setCarName(e.target.value)}
        />
        <br />
        <br />
        <div>
          <label>Vehicle Segment : {vehiclesSegmen}</label>
          <br />
          <select
            className="select-car-ad"
            value={vehiclesSegmen}
            placeholder="location"
            onChange={(e) => setvehiclesSegmen(e.target.value)}
          >
            <option value={vehiclesSegmen}>{vehiclesSegmen}</option>

            {segs.map((c, i) => (
              <option key={i} value={c.name}>
                {c.name}
              </option>
            ))}
          </select>
        </div>
        <br />

        <div>
          <label>Vehicle acType : {acType}</label>
          <br />
          <select
            className="select-car-ad"
            value={acType}
            placeholder="transmissionType"
            onChange={(e) => setacType(e.target.value)}
          >
            <option value={acType}>{acType}</option>
            <option value="AC">AC</option>
            <option value="None AC">None AC</option>
          </select>
        </div>
        <br />

        <div>
          <label>Vehicle booking status : {bookingStatus}</label>
          <br />
          <select
            className="select-car-ad"
            value={bookingStatus}
            placeholder="transmissionType"
            onChange={(e) => setBookingStatus(e.target.value)}
          >
            <option value={bookingStatus}>{bookingStatus}</option>
            <option value="available">Available</option>
            <option value="booked">Booked</option>
          </select>
        </div>
        <br />
        <div>
          <label>Status : {status} </label>
          <br />
          <select
            className="select-car-ad"
            value={status}
            placeholder="transmissionType"
            onChange={(e) => setStatus(e.target.value)}
          >
            {/* <option value={status}>{status}</option> */}
            <option value="approved">Approved</option>
            <option value="pending">Pending</option>
            <option value="rejected">Rejected</option>
          </select>
        </div>
        <br />

        <div>
          <label>Driver Type : {driverType}</label>
          <br />
          <select
            className="select-car-ad"
            value={driverType}
            onChange={(e) => setdriverType(e.target.value)}
          >
            <option value={driverType}>{driverType}</option>
            <option value="Self Drive">Self Drive</option>
            <option value="With Driver">With Driver</option>
            <option value="Tempo Traveller">Tempo Traveller</option>
          </select>
        </div>

        <br />
        <div>
          <label>Fuel Type : {fuelType}</label>
          <br />
          <select
            className="select-car-ad"
            value={fuelType}
            onChange={(e) => setfuelType(e.target.value)}
          >
            <option value={fuelType}>{fuelType}</option>
            <option value="Petrol">Petrol</option>
            <option value="Petrol">Electric</option>
            <option value="Petrol">CNG + Petrol</option>
            <option value="Diesel">Diesel</option>
          </select>
        </div>

        <br />
        <label>License Number</label>
        <br />
        <input
          className="select-car-ad"
          type="text"
          placeholder={licenseNumber}
          value={licenseNumber}
          onChange={(e) => setpickupAddress(e.target.value)}
        />
        <br />

        <br />
        <div>
          <label>Location : {location1}</label>
          <br />
          <select
            className="select-car-ad"
            value={location}
            placeholder="location"
            onChange={(e) => setlocation(e.target.value)}
          >
            <option value={location}>{location1}</option>

            <>
              {locations.map((c, i) => (
                <option key={i} value={c.id}>
                  {c.name}
                </option>
              ))}
            </>
          </select>
        </div>
        <br />
        <label>Pickup Address</label>
        <br />
        <input
          className="select-car-ad"
          type="text"
          placeholder={pickupAddress}
          value={pickupAddress}
          onChange={(e) => setpickupAddress(e.target.value)}
        />
        <br />
        <br />
        <label>Mileage</label>
        <br />
        <input
          className="select-car-ad"
          type="text"
          placeholder={mileage}
          value={mileage}
          onChange={(e) => setmileage(e.target.value)}
        />
        <br />
        <br />
        <label>price</label>
        <br />
        <input
          className="select-car-ad"
          type="text"
          placeholder={price}
          value={price}
          onChange={(e) => setprice(e.target.value)}
        />
        <br />
        <br />
        <label>Seating Capacity</label>
        <br />
        <input
          className="select-car-ad"
          type="text"
          placeholder={seatingCapacity}
          value={seatingCapacity}
          onChange={(e) => setseatingCapacity(e.target.value)}
        />
        <br />
        <br />
        <div>
          <label>Transmission Type : {transmissionType}</label>
          <br />
          <select
            className="select-car-ad"
            value={transmissionType}
            placeholder="transmissionType"
            onChange={(e) => settransmissionType(e.target.value)}
          >
            <option value={transmissionType}>{transmissionType}</option>
            <option value="Manual">Manual</option>
            <option value="Automatic">Automatic</option>
          </select>
        </div>
        <br />
        <div>
          <label>Car Image </label> <br />
          <div className="img-car-ad">
            {imageURL ? (
              <div>
                <br />
                <img
                  className="add-acc-image"
                  src={imageURL}
                  alt={car?.vehiclesModalName}
                />
              </div>
            ) : (
              ""
            )}
            <div>
              {" "}
              <br />
              <input
                className="select-car-ad"
                type="file"
                onChange={async (e) => {
                  setImageFile(e.target.files[0]);
                  setImageURL(URL.createObjectURL(e.target.files[0]));
                }}
              />
            </div>
          </div>
        </div>

        {/* =========================================MAP======================================= */}
        {/* <div id="map" style={{ height: '100vh', width: '100%' }}></div> */}


        {CarNumber ? <div id="map" style={{ height: '100vh', width: '100%' }}></div> : ""}


        {/* --------------------------------------------------------- */}
        <br />
        <div className="carDtlsbtns">
          <button
            className="car-dtls-btns-car"
            onClick={() => setShowPopup(true)}
          >
            Save changes
          </button>
          <button
            className="car-dtls-btns-car-red"
            onClick={() => setShowPopup2(true)}
          >
            Delete car
          </button>
        </div>
      </div>
    );
    // ...
  }
};

export default AppStore(DetailsCar);
