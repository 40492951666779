import React, { useState } from "react";
import "./Rev.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { AuthContext } from "../../../AuthProvider";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect } from "react";
import {
    faAngleDown,
    faAngleUp,
    faCar,
    faGasPump,
    faGear,
    faRobot,
    faUserGroup,
} from "@fortawesome/free-solid-svg-icons";

const Review = () => {
    const [isActive, setIsActive] = useState(false);
    const handleSlide = () => {
        setIsActive(!isActive);
    };
    const [car, SetCar] = useState();
    const { loading, db, setLoading } = useContext(AuthContext);
    const carId = localStorage.getItem("carModel");

    async function fetchMyAPI(Id) {
        setLoading(false);
        const q = query(collection(db, "cars"), where("id", "==", Id));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            SetCar(doc.data());
            setLoading(true);
        });
    }
    useEffect(() => {
        fetchMyAPI(carId);
    }, [carId]);
    const carSearch = JSON.parse(localStorage.getItem("carSearch"));
    return (
        <div className="rev_container">
            <div className="rev_box">
                <h2>Review and proceed to booking</h2>
                <h2>Rs 30,194.03</h2>
            </div>
            <br />
            <h3>Review</h3>
            <br />
            <div className="vehicle_sec">
                <div className="rev_box_1">
                    <h3>Vehicle</h3>
                    <div className="rev_car_details">
                        <div>
                            <img
                                className="r_img_1"
                                src={car?.thumbnail}
                                alt=""
                            />
                        </div>
                        <div>
                            <h4>{car?.vehiclesModalName}</h4>
                        </div>
                        <div className="v_r_1">
                            <div className="rc_d9">
                                <FontAwesomeIcon
                                    icon={faGasPump}
                                    style={{
                                        height: "15px",
                                        width: "15px",
                                        paddingRight: "5px",
                                        color: "	 #ff8080",
                                    }}
                                />
                                <span>{car?.fuelType[0]}</span>
                            </div>

                            <div className="rc_d9">
                                <FontAwesomeIcon
                                    icon={faUserGroup}
                                    style={{
                                        height: "15px",
                                        width: "15px",
                                        paddingRight: "5px",
                                        color: "#ff8080",
                                    }}
                                />
                                <span>{car?.seatingCapacity}</span>
                            </div>
                            <div className="rc_d9">
                                <FontAwesomeIcon
                                    icon={faGear}
                                    style={{
                                        height: "15px",
                                        width: "15px",
                                        paddingRight: "5px",
                                        color: "	 #ff8080",
                                    }}
                                />
                                <span>
                                    {car?.transmission_type
                                        ? car?.transmission_type
                                        : "M"}
                                </span>
                            </div>
                            <div className="rc_d9">
                                <FontAwesomeIcon
                                    icon={faCar}
                                    style={{
                                        height: "15px",
                                        width: "15px",
                                        paddingRight: "5px",
                                        color: "	 #ff8080",
                                    }}
                                />
                                <span>{car?.mileage}</span>
                            </div>
                            <div className="rc_d9">
                                <FontAwesomeIcon
                                    icon={faRobot}
                                    style={{
                                        height: "15px",
                                        width: "15px",
                                        paddingRight: "5px",
                                        color: "	 #ff8080",
                                    }}
                                />
                                <span>S</span>
                            </div>
                        </div>
                    </div>
                    <h3>Pick up & return</h3>
                    <div className="rev_loc_details">
                        <div className="pick_rev">
                            <small>Pick up</small>
                            <br />
                            <h4>{carSearch.pickupDate}</h4>
                            <h4>{carSearch.pickupLocation}</h4>
                        </div>
                        <div>
                            <small>Return</small>
                            <br />
                            <h4>{carSearch.returnDate}</h4>
                            <h4>{carSearch.pickupLocation}</h4>
                        </div>
                    </div>
                </div>
                <div className="rev_box_2">
                    <h3>Rs 67,057.15</h3>
                    <br />
                    <br />
                    <table>
                        <tr>
                            <td>
                                <b>Basic rate</b> (for 33 days)
                            </td>
                            <td>Included Rs 67,057.15</td>
                        </tr>
                        <tr>
                            <td>Mileage</td>
                            <td>Unlimited</td>
                        </tr>
                        <tr>
                            <td>5132</td>
                            <td>Included</td>
                        </tr>
                        <tr>
                            <td>Airport/Railway Station Surcharge</td>
                            <td>Included</td>
                        </tr>
                        <tr>
                            <td>
                                VAT{" "}
                                <b style={{ color: "rgba(255, 0, 0, 0.692)" }}>
                                    See full T&Cs
                                </b>
                            </td>
                            <td>Included 15 %</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div className="pro_sec">
                <div className="pro_div_1">
                    <h3>Protections & Extras</h3>
                    <h3>Rs 0.00</h3>
                </div>
                <div className="pro_div_1">
                    <small>Basic</small>
                    <small>For 33 day(s)</small>
                    <small>included</small>
                </div>
            </div>
            <div className="coupon_sec">
                <p onClick={handleSlide}>
                    Add a coupon code{" "}
                    <FontAwesomeIcon
                        icon={!isActive ? faAngleDown : faAngleUp}
                    />
                </p>
                <div
                    className={isActive ? "content-open-r" : "content-close-r"}
                >
                    <input className="c_in" type="text" />
                    <button className="c_btn">Apply the coupon</button>
                </div>
            </div>
            <div className="total_sec">
                <div>
                    <h2>Total</h2>
                    <p>To pay at desk in local currency</p>
                </div>
                <div className="total_sec_2">
                    <h2>Rs 67,057.15</h2>
                    <p>ZAR 11,533.01</p>
                </div>
            </div>
        </div>
    );
};

export default Review;
