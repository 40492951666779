export const UserActionType = {
  SET_USER_DATA_REQUEST: "SET_USER_DATA_REQUEST",
  SET_USER_DATA_SUCCESS: "SET_USER_DATA_SUCCESS",
  SET_USER_DATA_FAILURE: "SET_USER_DATA_FAILURE",

  SET_BOOKING_DETAILS_REQUEST: "SET_BOOKING_DETAILS_REQUEST",
  SET_BOOKING_DETAILS_SUCCESS: "SET_BOOKING_DETAILS_SUCCESS",
  SET_BOOKING_DETAILS_FAILURE: "SET_BOOKING_DETAILS_FAILURE",
};

const setUserDataAction = (payload) => {
  return { type: UserActionType.SET_USER_DATA_REQUEST, payload };
};

const setBookingDetailsAction = (payload) => {
  return { type: UserActionType.SET_BOOKING_DETAILS_REQUEST, payload };
};

export {
  setUserDataAction,
  setBookingDetailsAction
};
