// BookingSuccess.js
import React, { useContext, useEffect, useState } from "react";
import { Typography, Container, Paper, Button } from "@mui/material";
import { useSelector } from "react-redux";
import {
  Timestamp,
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { AuthContext } from "../AuthProvider";
import AppStore from "../screen/AppStore";

const BookingSuccess = (props) => {
  const { db } = useContext(AuthContext);

  // const userInfos = useSelector((state) => state);
  // const booking = userInfos.usersDataReducer?.booking;
  const booking = props.bookingDetails;

  const [paymentData, setPaymentData] = useState({});

  const uploadBookingDetails = async (paymentDetails) => {
    const ref = doc(collection(db, "bookings"));
    const id = ref.id;
    try {
      let data = booking.bookingDetails;
      data.paymentDetails = paymentDetails;
      data.id = id;
      await setDoc(ref, data);
    } catch (e) {
      let data = { status: "SUCCESS" };
      data.id = id;
      await setDoc(ref, data);
    }
    updateCarDetails(id);
  };

  const updateCarDetails = async (bookingId) => {
    const docRef = doc(db, "cars", booking?.bookingDetails?.car_details);
    // Update the timestamp field with the value from the server
    await updateDoc(docRef, {
      numOfBooking: booking?.bookingDetails?.car_details?.numOfBooking
        ? booking?.bookingDetails?.car_details?.numOfBooking + 1
        : 1,
      bookingStatus: "booked",
      bookingId,
    }).then(() => {});
  };

  useEffect(() => {
    // Get the query parameters from the URL
    const queryParams = new URLSearchParams(window.location.search);
    const dataParam = queryParams.get("data");

    // See more here: https://developer.phonepe.com/v1/reference/check-status-api-1#sample-response
    let SAMPLE_SUCCESS_RESPONSE = {
      success: true,
      code: "PAYMENT_SUCCESS",
      message: "Your request has been successfully completed.",
      data: {
        merchantId: "PGTESTPAYUAT",
        merchantTransactionId: "MT7850590068188104",
        transactionId: "T2111221437456190170379",
        amount: 100,
        state: "COMPLETED",
        responseCode: "SUCCESS",
        paymentInstrument: {
          type: "UPI",
          utr: "206378866112",
        },
      },
    };

    if (dataParam) {
      // Parse the JSON data
      const parsedData = JSON.parse(decodeURIComponent(dataParam));
      setPaymentData(parsedData?.data);
      uploadBookingDetails(parsedData?.data);
      // Now, 'parsedData' contains your object
    }
  }, []);

  return (
    <Container
      maxWidth="md"
      style={{ marginTop: "160px", marginBottom: "160px" }}
    >
      <Paper
        elevation={3}
        style={{
          padding: "20px",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Typography variant="h4" gutterBottom>
          Vehicle Booking Successful! ✅
        </Typography>
        <Typography variant="body1">
          Thank you for your booking. Your payment was successful
        </Typography>
        <Typography
          style={{
            fontWeight: "bold",
            marginTop: "10px",
          }}
          variant="h6"
        >
          Your booking id is: ZXLFNCRT4856739
        </Typography>
        <Typography
          style={{
            fontWeight: "bold",
          }}
          variant="h6"
        >
          Your Transation Id is: {paymentData?.transactionId}
        </Typography>
        <Typography style={{ marginTop: "50px" }} variant="body2">
          You can see your booking in Profile {">"} My Bookings section
        </Typography>
        <Button
          href="/"
          variant="contained"
          style={{
            backgroundColor: "#fc6b65",
            height: "40px",
            width: "150px",
            marginTop: "50px",
          }}
          size="large"
        >
          GO TO HOME
        </Button>
        {/* You can add more Material-UI components or customize the styles */}
      </Paper>
    </Container>
  );
};

export default AppStore(BookingSuccess);
