import { collection, getDocs, query, where } from "firebase/firestore";
import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../AuthProvider";
import { useEffect } from "react";
import moment from "moment";
import "./DCB.css";
import AppStore from '../../screen/AppStore';

const DirecBooking = (props) => {
    const [driver, setDriver] = useState("");
    const [touched, setTouched] = useState(false);
    const {
        db,
        duration,
        setDuration,
        pickupDate,
        setPickupDate,
        returnDate,
        setReturnDate,
        bookedCarID,
    } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [locations, setLocations] = useState([]);
    const [car, setCar] = useState([]);
    const navigate = useNavigate();

    const fetchLocations = async () => {
        setLoading(true);
        const query = collection(db, "location");
        let arr_blogs = [];
        const querySnapshot = await getDocs(query);
        querySnapshot.forEach((doc) => {
            arr_blogs.push(doc.data());
        });
        setLocations(arr_blogs);
        setLoading(false);
    };

    const handleCar = async () => {
        const q = query(collection(db, "cars"), where("id", "==", bookedCarID));
        let availableCar = [];
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            availableCar.push(doc.data());
            setDriver(doc?.data()?.driverType);
        });
        console.log('availableCar', availableCar);
        setCar(availableCar[0]);
        setLoading(true);
    };

    useEffect(() => {
        setLoading(false);
        handleCar();
        fetchLocations();
    }, []);

    const handlePickupDateChange = (e) => {
        setPickupDate(e.target.value);
    };

    const handleReturnLocationChange = (e) => {
        setReturnDate(e.target.value);
    };
    const today = new Date().toISOString().split("T")[0];

    const isPastDate = pickupDate < today;
    const isPastDate2 = returnDate < today;

    const handleSubmit = (e) => {
        setTouched(true);
        e.preventDefault();
        const searchQuery = {
            driver,
            pickupDate,
            returnDate,
        };
        localStorage.setItem("carSearch", JSON.stringify(searchQuery));
        if (
            pickupDate &&
            returnDate &&
            !isPastDate &&
            !isPastDate2 &&
            duration >= 8
        ) {
            if (props.fetchCars) {
                props.fetchCars(searchQuery);
            } else {
                window.scrollTo(0, 0);
                // console.log("car.vehiclesModalName",car.vehiclesModalName);
                navigate(`/book/${car.vehiclesModalName}`);
            }
        }
    };

    // duration

    const handleDuration = (pickupDate, returnDate) => {
        const startMoment = moment(pickupDate);
        const endMoment = moment(returnDate);
        const durationInHours = endMoment.diff(startMoment, "hours");
        setDuration(durationInHours);
    };
    useEffect(() => {
        handleDuration(pickupDate, returnDate);
    }, [pickupDate, returnDate]);

    return (
        <form className="car-rental-form-db" onSubmit={handleSubmit}>
            <h2>Please submit the requirements</h2>
            <br />
            <div className="form-row">
                <div>
                    <label htmlFor="drive-type">Driving type</label>
                    <br />
                    <br />
                    <div>
                        {driver === "Self Drive" && (
                            <div
                                style={{ borderRadius: "5px" }}
                                className="box active"
                            >
                                {/* <img
                                    className="pixel-image"
                                    src="Icons/wheel2.png"
                                    alt=""
                                /> */}
                                <p>
                                    Self Drive
                                    <span className="box-show">
                                        {" "}
                                        /Without Driver
                                    </span>
                                </p>
                            </div>
                        )}
                        {driver === "With Driver" && (
                            <div
                                style={{ borderRadius: "5px" }}
                                className="box active"
                            >
                                {/* <img
                                    className="pixel-image"
                                    src="Icons/driver2.png"
                                    alt=""
                                /> */}
                                <p>With Driver</p>
                            </div>
                        )}
                        {driver === "Tempo Traveller" && (
                            <div
                                style={{ borderRadius: "5px" }}
                                className="box active"
                            >
                                {/* <img
                                    className="pixel-image"
                                    src="Icons/wheel2.png"
                                    alt=""
                                /> */}
                                <p>Tempo Traveller</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <br />
            <div className="form-row-2-db">
                <div className="rent_2-db">
                    <label htmlFor="pickup-date">Pickup Date & Time</label>
                    <div
                        className="inputField-2"
                        style={
                            !pickupDate & touched || isPastDate
                                ? { border: "3px solid red" }
                                : {}
                        }
                    >
                        <input
                            type="datetime-local"
                            className="pickup-date"
                            value={pickupDate}
                            onChange={handlePickupDateChange}
                        />
                    </div>
                    {isPastDate && touched && (
                        <p style={{ color: "red" }}>
                            Please choose a valid pickup date and time
                        </p>
                    )}
                    {!pickupDate && touched && (
                        <p style={{ color: "red" }}>
                            Please choose a valid pickup date and time
                        </p>
                    )}
                </div>
                <div className="rent_2">
                    <label htmlFor="return-location">Return Date & Time</label>
                    <div
                        className="inputField-2"
                        style={
                            !returnDate & touched || isPastDate
                                ? { border: "3px solid red" }
                                : {}
                        }
                    >
                        <input
                            type="datetime-local"
                            id="return-date"
                            value={returnDate}
                            onChange={handleReturnLocationChange}
                        />
                    </div>

                    {isPastDate2 && touched && (
                        <p style={{ color: "red" }}>
                            Please choose a valid return date and time
                        </p>
                    )}
                    {!returnDate && touched && (
                        <p style={{ color: "red" }}>
                            Please choose a valid pickup date and time
                        </p>
                    )}
                </div>
            </div>
            <div className="rent_bottom">
                <div className="negotiated-input">
                    <p>
                        Duration :{" "}
                        <span style={{ color: "red" }}>
                            {duration >= 8
                                ? `${Math.floor(duration / 24)} days ${duration % 24
                                } hours`
                                : 0}
                        </span>{" "}
                    </p>
                </div>
                {duration < 8 && touched && (
                    <p style={{ color: "red" }}>
                        Please choose minimum duration 8 hours
                    </p>
                )}
                <button className="rent-button" type="submit">
                    Proceed
                </button>
            </div>
        </form>
    );
};

export default AppStore(DirecBooking);
