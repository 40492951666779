import React, { useContext, useEffect, useState } from "react";
import { doc, updateDoc } from "@firebase/firestore";
import { AuthContext } from "../../../AuthProvider";
import { collection, getDocs, query, where } from "firebase/firestore";
import "./account.css";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

const Account = () => {
    const { user, db, storage } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [userInfo, setUserInfo] = useState({});
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [aadharNumber, setAadharNumber] = useState("");
    const [address, setAddress] = useState("");
    const [dob, setDob] = useState("");
    const [drivingLicence, setDrivingLicence] = useState("");
    const [passportNumber, setPassportNumber] = useState("");
    const [aadharBackPhotoURL, setAadharBackPhotoURL] = useState("");
    const [aadharBackPhotofile, setAadharBackPhotofile] = useState(null);
    const [aadharFrontPhotoURL, setAadharFrontPhotoURL] = useState("");
    const [aadharFrontPhotofile, setAadharFrontPhotofile] = useState(null);
    const [passportPhotoURL, setPassportPhotoURL] = useState("");
    const [passportPhotoFile, setPassportPhotoFile] = useState(null); // Changed variable name
    const [selfieURL, setselfieoURL] = useState("");
    const [selfieFile, setselfieFile] = useState(null); // Changed variable name
    const [licensebURL, setlicensebURL] = useState("");
    const [licensebFile, setlicensebFile] = useState(null); // Changed variable name
    const [licensefURL, setlicensefURL] = useState("");
    const [licensefFile, setlicensefFile] = useState(null); // Changed variable name

    const handleSub = async () => {
        setLoading(true);
        window.scroll(0, 0);
        let aadharBackPhoto = aadharBackPhotoURL;
        if (aadharBackPhotofile) {
            aadharBackPhoto = await handleUploadPic(aadharBackPhotofile);
        }
        let aadharFrontPhoto = aadharFrontPhotoURL;
        if (aadharFrontPhotofile) {
            aadharFrontPhoto = await handleUploadPic(aadharFrontPhotofile);
        }
        let passportPhoto = passportPhotoURL;
        if (passportPhotoFile) {
            passportPhoto = await handleUploadPic(passportPhotoFile); // Updated variable name here
        }
        let selfie = selfieURL;
        if (selfieFile) {
            // Updated variable name here
            selfie = await handleUploadPic(selfieFile); // Updated variable name here
        }
        let drivingLicenceBackPhoto = licensebURL;
        if (licensebFile) {
            // Updated variable name here
            drivingLicenceBackPhoto = await handleUploadPic(licensebFile); // Updated variable name here
        }
        let drivingLicenceFrontPhoto = licensefURL;
        if (licensefFile) {
            drivingLicenceFrontPhoto = await handleUploadPic(licensefFile);
        }

        const docRef = doc(db, "users", userInfo?.id);
        await updateDoc(docRef, {
            aadharBackPhoto,
            aadharFrontPhoto,
            aadharNumber,
            // address,
            dob,
            drivingLicence,
            mobileNumber,
            name,
            passportNumber,
            email,
            selfie,
            drivingLicenceBackPhoto,
            drivingLicenceFrontPhoto,
            passportPhoto,
        })
            .then(() => {
                setLoading(false);
                fetchUser();
            })
            .catch((error) => console.log(error));
    };

    const handleUploadPic = (imageFile) => {
        return new Promise((resolve, reject) => {
            let ids1 = Math.floor(100000 + Math.random() * 90000).toString();
            let ids2 = new Date();
            let user = mobileNumber;
            let url_path = user + "_" + ids1 + "_" + ids2;
            const storageRef = ref(
                storage,
                `/MainUser/${user}/document/${url_path}`,
            );

            const uploadTask = uploadBytesResumable(storageRef, imageFile);

            uploadTask.on(
                "state_changed",
                (snapshot) => {},
                (err) => {
                    reject(err);
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref)
                        .then((url) => {
                            resolve(url);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                },
            );
        });
    };

    const fetchUser = async () => {
        if (user?.phoneNumber) {
            const q = query(
                collection(db, "users"),
                where("mobileNumber", "==", user.phoneNumber),
            );
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                setName(doc.data().name);
                setEmail(doc.data().email);
                setMobileNumber(doc.data().mobileNumber);
                setAadharBackPhotoURL(doc.data().aadharBackPhoto);
                setAadharFrontPhotoURL(doc.data().aadharFrontPhoto);
                setAadharNumber(doc.data().aadharNumber);
                setAddress(doc.data().address);
                setDob(doc.data().dob);
                setDrivingLicence(doc.data().drivingLicence);
                setPassportNumber(doc.data().passportNumber);
                setPassportPhotoURL(doc.data().passportPhoto);
                setselfieoURL(doc.data().selfie);
                setlicensebURL(doc.data().drivingLicenceBackPhoto);
                setlicensefURL(doc.data().drivingLicenceFrontPhoto);
                setLoading(false);
                setUserInfo(doc.data());
            });
        }
    };

    useEffect(() => {
        fetchUser();
    }, [user]);

    return (
        <div className="acc-main">
            {loading ? (
                <h1>Loading...</h1>
            ) : (
                <div className="user-data-acc">
                    <div>
                        <h2>Account details:</h2>
                        <label>Name</label>
                        <input
                            className="acc-input"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <br />
                        <label>Email</label>
                        <input
                            className="acc-input"
                            type="text"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <br />
                        <label>Mobile Number</label>
                        <input
                            className="acc-input"
                            type="text"
                            value={mobileNumber}
                            onChange={(e) => setMobileNumber(e.target.value)}
                        />
                        <br />
                        <label>Date of Birth</label>
                        <input
                            className="acc-input"
                            type="text"
                            value={dob}
                            onChange={(e) => setDob(e.target.value)}
                        />
                        <br />
                        {/* <label>Address</label>
                        <input
                            className="acc-input"
                            type="text"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                        /> */}
                        <label>Aadhar Number</label>
                        <input
                            className="acc-input"
                            type="text"
                            value={aadharNumber}
                            onChange={(e) => setAadharNumber(e.target.value)}
                        />
                        <br />
                        <div>
                            <label>Aadhar Back Photo</label>
                            <div>
                                <br />
                                <img
                                    className="acc-image"
                                    src={aadharBackPhotoURL}
                                    alt={userInfo?.name}
                                />
                            </div>
                            <div>
                                <br />
                                <input
                                    className="dash_file1"
                                    type="file"
                                    onChange={(e) => {
                                        setAadharBackPhotoURL(
                                            URL.createObjectURL(
                                                e.target.files[0],
                                            ),
                                        );
                                        setAadharBackPhotofile(
                                            e.target.files[0],
                                        );
                                    }}
                                />
                            </div>
                        </div>
                        <br />
                        <div>
                            <label>Aadhar Front Photo</label>

                            <div>
                                <br />
                                <img
                                    className="acc-image"
                                    src={aadharFrontPhotoURL}
                                    alt={userInfo?.name}
                                />
                            </div>

                            <div>
                                <br />
                                <input
                                    className="dash_file1"
                                    type="file"
                                    onChange={(e) => {
                                        setAadharFrontPhotoURL(
                                            URL.createObjectURL(
                                                e.target.files[0],
                                            ),
                                        );
                                        setAadharFrontPhotofile(
                                            e.target.files[0],
                                        );
                                    }}
                                />
                            </div>
                        </div>
                        <br />
                        <label>Driving Licence</label>
                        <input
                            className="acc-input"
                            type="text"
                            value={drivingLicence}
                            onChange={(e) => setDrivingLicence(e.target.value)}
                        />
                        <br />
                        <div>
                            <label>Driving Licence Back Photo</label>

                            <div>
                                <br />
                                <img
                                    className="acc-image"
                                    src={licensebURL}
                                    alt={userInfo?.name}
                                />
                            </div>

                            <div>
                                <br />
                                <input
                                    className="dash_file1"
                                    type="file"
                                    onChange={(e) => {
                                        setlicensebURL(
                                            URL.createObjectURL(
                                                e.target.files[0],
                                            ),
                                        );
                                        setlicensebFile(e.target.files[0]);
                                    }}
                                />
                            </div>
                        </div>
                        <br />
                        <div>
                            <label>Driving Licence Front Photo</label>

                            <div>
                                <br />
                                <img
                                    className="acc-image"
                                    src={licensefURL}
                                    alt={userInfo?.name}
                                />
                            </div>

                            <div>
                                <br />
                                <input
                                    className="dash_file1"
                                    type="file"
                                    onChange={(e) => {
                                        setlicensefURL(
                                            URL.createObjectURL(
                                                e.target.files[0],
                                            ),
                                        );
                                        setlicensefFile(e.target.files[0]);
                                    }}
                                />
                            </div>
                        </div>
                        <br />
                        <div>
                            <label>Selfie Photo</label>

                            <div>
                                <br />
                                <img
                                    className="acc-image"
                                    src={selfieURL}
                                    alt={userInfo?.name}
                                />
                            </div>

                            <div>
                                <br />
                                <input
                                    className="dash_file1"
                                    type="file"
                                    onChange={(e) => {
                                        setselfieoURL(
                                            URL.createObjectURL(
                                                e.target.files[0],
                                            ),
                                        );
                                        setselfieFile(e.target.files[0]);
                                    }}
                                />
                            </div>
                        </div>
                        <label>Passport Number</label>
                        <input
                            className="acc-input"
                            type="text"
                            value={passportNumber}
                            onChange={(e) => setPassportNumber(e.target.value)}
                        />
                        <br />
                        <div>
                            <label>Passport Photo</label>

                            <div>
                                <br />
                                <img
                                    className="acc-image"
                                    src={passportPhotoURL}
                                    alt={userInfo?.name}
                                />
                            </div>

                            <div>
                                <br />
                                <input
                                    className="dash_file1"
                                    type="file"
                                    onChange={(e) => {
                                        setPassportPhotoURL(
                                            URL.createObjectURL(
                                                e.target.files[0],
                                            ),
                                        );
                                        setPassportPhotoFile(
                                            e.target.files[0], // Updated variable name here
                                        );
                                    }}
                                />
                            </div>
                        </div>
                        <br />
                        <br />
                        <div className="car-dtls-btns">
                            <button onClick={handleSub}>Save changes</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Account;
